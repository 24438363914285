import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

export interface ModalCancelButtonOptions {
  text: string
  action?: Function
  appearance?: 'secondary' | 'danger'
}

export interface ModalConfirmButtonOptions {
  text: string
  action: Function
  appearance?: 'primary' | 'danger' | 'secondary'
  disabled?: boolean
}

export interface ModalOptions {
  title: string
  body: string
  confirmButton: ModalConfirmButtonOptions
  cancelButton?: ModalCancelButtonOptions
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  public modalRequest$: Subject<ModalOptions> = new Subject()

  constructor() {}

  public openModal(modalOptions: ModalOptions) {
    this.modalRequest$.next(modalOptions)
  }
}
