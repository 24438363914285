import { Component, Input, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiService } from '../../services/api.service'
@Component({
  selector: 'external-internal-client',
  templateUrl: './external-internal-client.component.html',
  styleUrls: ['./external-internal-client.component.scss'],
})
export class ExternalInternalClientComponent implements OnInit {
  @Input() orgUuid: string
  @Input() clusterUuid: string

  public data
  public baseUrl

  constructor(private apiService: ApiService) {
    this.baseUrl = `${environment.backendUrl}/external/organizations/`
  }

  ngOnInit() {
    this.apiService
      .obtainInternalClient(this.orgUuid, this.clusterUuid)
      .subscribe((data) => {
        this.data = JSON.stringify(data, null, 2)
      })
  }
}
