import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { ApiService } from '../services/api.service'

export interface DatabaseCorpse {
  cluster: ClusterDto
  hasK8sInfo: boolean
  orgInfo: OrganizationDto | undefined
}

export interface ZeebeClientScopeCorpses {
  scope: string
  inConsole: boolean
  inAuth0: boolean
}

export interface CorpseData {
  mOrgIdToName: Map<string, string>
  databaseCorpses: DatabaseCorpse[] | undefined
  k8sCorpses: ClusterDto[] | undefined
}

@Injectable({
  providedIn: 'root',
})
export class CorpseResolverService implements Resolve<CorpseData> {
  constructor(private apiService: ApiService) {}
  public resolve(): Observable<CorpseData> {
    const mOrgIdToName: Map<string, string> = new Map<string, string>()

    return forkJoin([
      this.apiService.getClusterDatabaseCorpses(),
      this.apiService.getClusterK8sCorpses(),
    ]).pipe(
      map(([databaseCorpses, k8sCorpses]) => {
        for (const corpse of databaseCorpses) {
          if (corpse.orgInfo && !mOrgIdToName.has(corpse.orgInfo.uuid)) {
            mOrgIdToName.set(corpse.orgInfo.uuid, corpse.orgInfo.name)
          }
        }

        return {
          mOrgIdToName,
          databaseCorpses,
          k8sCorpses,
        }
      }),
    )
  }
}
