import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import {
  OrganizationDto,
  OrganizationRole,
} from '@camunda-cloud/cloud-node-libs'
import jwtDecode from 'jwt-decode'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  public currentOrg$: Observable<
    OrganizationDto & { roles: OrganizationRole[] }
  >
  public currentOrg: OrganizationDto | undefined
  public orgs$: Observable<OrganizationDto[]>
  public triggerOpenSwitchToProfessionalPlanCcModal = new BehaviorSubject<boolean>(
    false,
  )

  public uuidToName$: Observable<Map<string, string>>
  private uuidToName: BehaviorSubject<
    Map<string, string>
  > = new BehaviorSubject(new Map<string, string>())

  private internalCurrentOrg$ = new BehaviorSubject<
    OrganizationDto | undefined
  >(undefined)
  private internalOrgs$ = new BehaviorSubject<OrganizationDto[]>([])

  constructor(private authService: AuthService, private router: Router) {
    this.currentOrg$ = combineLatest([
      this.internalCurrentOrg$
        .asObservable()
        .pipe(filter((currentOrg) => currentOrg !== undefined)),
      this.authService
        .getAccessTokenSilently()
        .pipe(map((token) => jwtDecode<any>(token))),
    ]).pipe(
      map(([org, decodedToken]) => {
        if (decodedToken[environment.auth0OrgNamespace]) {
          const roles = decodedToken[environment.auth0OrgNamespace].find(
            (orgInToken) => orgInToken.id === org!.uuid,
          ).roles as OrganizationRole[]
          return { ...org!, ...{ roles: roles ? roles : [] } }
        } else {
          return { ...org!, ...{ roles: [] } }
        }
      }),
    )

    this.orgs$ = this.internalOrgs$.asObservable()
    this.uuidToName$ = this.uuidToName.asObservable()
    this.currentOrg$.subscribe((newOrg) => {
      if (newOrg) {
        this.currentOrg = newOrg
      }
    })

    this.internalCurrentOrg$.asObservable().subscribe((org) => {
      if (org && !org.organizationToSalesPlan) {
        this.router.navigate([
          'error',
          {
            title: 'Your Organization was not initialized',
            message: 'Please click the activation link in the email.',
          },
        ])
      }
    })
  }

  public updateCurrentOrg(currentOrg: OrganizationDto) {
    this.internalCurrentOrg$.next(currentOrg)
  }

  public updateOrgs(organizations: OrganizationDto[]) {
    this.internalOrgs$.next(organizations)
  }

  public announceNewUuidToName(uuid: string, name: string) {
    const currentMap = this.uuidToName.value
    currentMap.set(uuid, name)
    this.uuidToName.next(currentMap)
  }

  public openSwitchToProfessionalPlanCcModal() {
    this.triggerOpenSwitchToProfessionalPlanCcModal.next(true)
  }
}
