import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { ChannelDto } from '../../../../commons/Channel.dto'
import { ApiService } from '../services/api.service'

@Injectable({
  providedIn: 'root',
})
export class ChannelsResolverService implements Resolve<ChannelDto[]> {
  constructor(private apiService: ApiService) {}
  public resolve(): Observable<ChannelDto[]> {
    return this.apiService.listChannels()
  }
}
