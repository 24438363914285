import { Component, Input, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'external-org-details',
  templateUrl: './external-org-details.component.html',
  styleUrls: ['./external-org-details.component.scss'],
})
export class ExternalOrgDetailsComponent implements OnInit {
  @Input() orgUuid: string

  public data
  public baseUrl

  constructor(private apiService: ApiService) {
    this.baseUrl = `${environment.accountsUrl}/external/organizations/`
  }

  ngOnInit() {
    this.apiService.getExternalOrgInfo(this.orgUuid).subscribe((data) => {
      this.data = JSON.stringify(data, null, 2)
    })
  }
}
