<cm-entity-list
  [enableCreateButton]="!vss.visibilities.apiclients.create.disabled"
  [hideCreateButton]="!vss.visibilities.apiclients.create.visible"
  headline="Client Credentials"
  createButtonLabel="Create"
  [createHandler]="clientListCreateHandler"
  [columns]="clientListColumns"
  [entities]="clientListEntities"
  [loading]="clientListLoading"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList">
      <h3>No Clients configured</h3>
      <cm-text>
        Want to build something that integrates with Camunda Cloud?
        <cm-button
          appearance="link"
          label="Create a new Client Credential"
          (cmPress)="openCreateClientModal()"
        ></cm-button>
        to get started developing on the Camunda Cloud API, and check the
        <cm-link
          href="http://docs.camunda.io/docs/reference/cloud-console-api-reference"
          label="Console API Reference (REST)"
        ></cm-link>
        documentation to read more about using the API.</cm-text
      >
    </div>
  </div>
</cm-entity-list>

<cm-text *ngIf="clients && clients.length !== 0"
  >Client Credentials can be used to request an OAuth access token. See the
  <cm-link
    href="http://docs.camunda.io/docs/reference/cloud-console-api-reference"
    label="Cloud Management API Clients"
  ></cm-link>
  documentation for details on how to retrieve an access token.
</cm-text>

<cm-modal
  #createModal
  position="top"
  width="555"
  headline="{{
    clientUpdate ? 'Client Credentials' : 'Create New Client Credentials'
  }}"
  cancelLabel="Cancel"
  confirmLabel="{{ clientUpdate ? 'Update' : 'Create' }}"
>
  <cm-form>
    <cm-textfield
      label="Client Name"
      helperText="can contain letters, dashes, underscores and digits"
      maxlength="50"
      required
      formName="clientName"
      [(value)]="clientName"
      [validation]="clientNameValidation"
      validationStyle="live"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>

    <div style="display: grid; gap: 8px">
      <cm-text appearance="emphasis">Scopes</cm-text>
      <cm-text>
        Scopes define the access for Client Credentials. Read more about
        <cm-link
          href="http://docs.camunda.io/docs/reference/cloud-console-api-reference"
          label="Scopes for Client Credentials"
        ></cm-link
        >.
      </cm-text>
    </div>

    <cm-checkbox-group>
      <cm-checkbox
        [(checked)]="allClusterSelected"
        [indeterminate]="someClusterSelected()"
        (cmInput)="setAllClusterPermissions($event.detail.isChecked)"
        [disabled]="clientUpdate"
        label="Cluster"
      ></cm-checkbox>

      <cm-checkbox-group>
        <cm-checkbox
          *ngFor="let permission of clusterPermissions"
          [(checked)]="permission.selected"
          (ngModelChange)="updateAllClusterPermissions()"
          (cmInput)="
            setClusterPermission(permission.name, $event.detail.isChecked)
          "
          [disabled]="clientUpdate"
          label="{{ permission.name }}"
        ></cm-checkbox>
      </cm-checkbox-group>

      <cm-checkbox
        [(checked)]="allZeebeSelected"
        [indeterminate]="someZeebeClientSelected()"
        (cmInput)="setAllZeebeClientPermissions($event.detail.isChecked)"
        [disabled]="clientUpdate"
        label="Zeebe Client"
      >
      </cm-checkbox>
      <cm-checkbox-group>
        <cm-checkbox
          *ngFor="let permission of zeebeClientPermissions"
          [(checked)]="permission.selected"
          (ngModelChange)="updateAllZeebeClientPermissions()"
          (cmInput)="
            setZeebeClientPermission(permission.name, $event.detail.isChecked)
          "
          [disabled]="clientUpdate"
          label="{{ permission.name }}"
        >
        </cm-checkbox>
      </cm-checkbox-group>
    </cm-checkbox-group>
  </cm-form>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Client"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete
    <b>"{{ clientToBeDeleted ? clientToBeDeleted.name : '' }}"</b>?
  </cm-text>
</cm-modal>

<cm-modal
  #clientSecretModal
  headline="Client Credentials"
  class="modal-details"
  cancelLabel="Close"
  confirmLabel="Download"
  confirmAppearance="primary"
>
  <cm-form *ngIf="createdClient">
    <cm-textfield
      label="Client Id"
      [value]="createdClient.client_id"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      label="Client Secret"
      [value]="createdClient.client_secret"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-banner>
      <cm-text appearance="emphasis">
        Make sure to save the Client Secret, because it won't be shown again.
      </cm-text>
    </cm-banner>
  </cm-form>
</cm-modal>

<cm-modal
  #clientDetailsModal
  headline="Client Credential Details"
  confirmLabel="Close"
  paddings="horizontal"
>
  <cm-datalist *ngIf="clientDetails" label-width="150px">
    <cm-datalist-item label="Name">
      <cm-text>{{ clientDetails.name }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Client Id">
      <cm-text>{{ clientDetails.clientId }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Cluster Scopes">
      <cm-text>
        {{
          clientDetails.permissions.cluster.length
            ? clientDetails.permissions.cluster.join(', ')
            : 'None'
        }}
      </cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Zeebe Scopes">
      <cm-text>
        {{
          clientDetails.permissions.zeebe.length
            ? clientDetails.permissions.zeebe.join(', ')
            : 'None'
        }}
      </cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Created at">
      <cm-text>{{ clientDetails.created | date: 'medium' }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Created by">
      <cm-text>{{ clientDetails.createdBy }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Last used">
      <cm-text *ngIf="clientDetails.lastUsed">{{
        clientDetails.lastUsed | date: 'medium'
      }}</cm-text>
      <cm-text *ngIf="!clientDetails.lastUsed">-</cm-text>
    </cm-datalist-item>
  </cm-datalist>
</cm-modal>

<cm-modal
  #connectionInfoModal
  headline="Connection Information of {{
    clientDetails ? clientDetails.name : ''
  }}"
  confirmLabel="Close"
  width="555"
  position="top"
>
  <cm-form *ngIf="clientDetails">
    <cm-text
      >To connect to the Cloud Management API, check the
      <cm-link
        href="http://docs.camunda.io/docs/reference/cloud-console-api-reference"
        label="Client Configuration"
      ></cm-link>
      documentation to learn what information is needed below.</cm-text
    >

    <div style="display: grid; gap: 8px">
      <cm-text appearance="emphasis">Client</cm-text>
      <cm-textfield
        label="Client Name"
        [value]="clientDetails.name"
        [fieldSuffix]="{ type: 'copy' }"
        disabled
      ></cm-textfield>
    </div>

    <cm-textfield
      label="Client Id"
      [value]="clientDetails.clientId"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <div class="secret">
      <span>Client Secret</span>
      <span>only visible on Client creation</span>
    </div>

    <cm-textfield
      label="API URL"
      [value]="'https://api.' + audience"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      label="OAuth URL"
      [value]="'https://login.' + this.audience"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>
  </cm-form>
</cm-modal>
