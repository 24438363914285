import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { SalesPlanType } from '@camunda-cloud/cloud-node-libs'
import { first } from 'rxjs/operators'
import { OrgService } from '../../services/org.service'

@Component({
  selector: 'checkout-success-page',
  templateUrl: './checkout-success.page.html',
  styleUrls: ['./checkout-success.page.scss'],
})
export class CheckoutSuccessPage implements OnInit {
  public success = false
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orgService: OrgService,
  ) {}

  public ngOnInit() {
    let interval = setInterval(async () => {
      const org = await this.orgService
        .getOrg(this.route.snapshot.params.orgUuid, true)
        .pipe(first())
        .toPromise()
      if (
        org.organizationToSalesPlan.salesPlan.salesPlanType ===
        SalesPlanType.PAID_CC
      ) {
        this.success = true
        clearInterval(interval)
        this.router.navigate(
          [`org/${this.route.snapshot.params.orgUuid}/settings/billing`],
          { queryParams: { checkout: 'true' } },
        )
      }
    }, 1000)
  }
}
