import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { K8sContextDto } from '@camunda-cloud/cloud-node-libs'
import { ApiService } from '../services/api.service'

@Injectable({
  providedIn: 'root',
})
export class K8sContextResolverService implements Resolve<K8sContextDto[]> {
  constructor(private apiService: ApiService) {}
  public resolve(): Observable<K8sContextDto[]> {
    return this.apiService.getK8sContextsInDb(true)
  }
}
