<cm-page #cmPage>
  <h1 slot="header">Corpse Management</h1>
  <cm-page-tab label="Database Corpses">
    <div class="scrollContents">
      <h2 *ngIf="databaseCorpses === undefined">looking up... please wait</h2>
      <h2 *ngIf="databaseCorpses && databaseCorpses.length === 0">
        everything is fine, nothing bricked in the database
      </h2>
      <h2 *ngIf="databaseCorpses && databaseCorpses.length > 0">
        what a mess!
      </h2>
      <cm-text *ngIf="databaseCorpses && databaseCorpses.length > 0">
        those records in the database have no corresponding k8s cluster running
        or no valid organisation in accounts
      </cm-text>
      <table
        *ngIf="databaseCorpses && databaseCorpses.length > 0"
        class="corpse-table"
      >
        <tr>
          <th><cm-text appearance="emphasis">UUID</cm-text></th>
          <th><cm-text appearance="emphasis">Name</cm-text></th>
          <th><cm-text appearance="emphasis">Organization ID</cm-text></th>
          <th><cm-text appearance="emphasis">Organization Name</cm-text></th>
          <th><cm-text appearance="emphasis">Has Kubernetes</cm-text></th>
          <th>
            <cm-text appearance="emphasis">Has valid Organization</cm-text>
          </th>
          <th><cm-text appearance="emphasis">Created at</cm-text></th>
        </tr>
        <tr *ngFor="let corpse of databaseCorpses">
          <td><cm-text>{{ corpse.cluster.uuid }}</cm-text></td>
          <td><cm-text>{{ corpse.cluster.name }}</cm-text></td>
          <td><cm-text>{{ corpse.cluster.ownerId }}</cm-text></td>
          <td><cm-text>{{ mOrgIdToName[corpse.cluster.ownerId] }}</cm-text></td>
          <td><cm-text>{{ corpse.hasK8sInfo }}</cm-text></td>
          <td>
            <cm-text
              >{{ corpse.orgInfo !== null && corpse.orgInfo !== undefined
              }}</cm-text
            >
          </td>
          <td><cm-text>{{ corpse.cluster.created }}</cm-text></td>
        </tr>
      </table>

      <cm-button
        *ngIf="
        databaseCorpses &&
        databaseCorpses.length > 0 &&
        vss.visibilities.admin.corpses.delete.visible
      "
        appearance="danger"
        label="Burn it with fire!"
        (cmPress)="openDeleteModal('Delete Database Corpses', 'deletingDatabase')"
        [disabled]="deletingDatabase"
      >
      </cm-button>
      <cm-text *ngIf="deletingDatabase">please wait...</cm-text>
    </div>
  </cm-page-tab>
  <cm-page-tab label="Kubernetes Corpses">
    <div class="scrollContents">
      <h2 *ngIf="k8sCorpses === undefined">looking up... please wait</h2>
      <h2 *ngIf="k8sCorpses && k8sCorpses.length === 0">
        everything is fine, nothing bricked in k8s
      </h2>
      <h2 *ngIf="k8sCorpses && k8sCorpses.length > 0">what a mess!</h2>
      <p *ngIf="k8sCorpses && k8sCorpses.length > 0">
        those records in k8s have no corresponding db cluster entity
      </p>
      <table *ngIf="k8sCorpses && k8sCorpses.length > 0" class="corpse-table">
        <tr>
          <th><cm-text appearance="emphasis">UUID</cm-text></th>
          <th><cm-text appearance="emphasis">Organization ID</cm-text></th>
          <th><cm-text appearance="emphasis">Organization Name</cm-text></th>
          <th>
            <cm-text appearance="emphasis">Kubernetes Context UUID</cm-text>
          </th>
        </tr>
        <tr *ngFor="let corpse of k8sCorpses">
          <td><cm-text>{{ corpse.uuid }}</cm-text></td>
          <td><cm-text>{{ corpse.ownerId }}</cm-text></td>
          <td><cm-text>{{ mOrgIdToName[corpse.ownerId] }}</cm-text></td>
          <td><cm-text>{{ corpse.k8sContext.uuid }}</cm-text></td>
        </tr>
      </table>

      <cm-text
        *ngIf="
        k8sCorpses && k8sCorpses.length > 0 && vss.visibilities.admin.corpses.delete.visible
      "
      >
        Note: if you are running in local dev-mode, deleting stuff from k8s is
        most probably not something you would like to really do. so it is
        forbidden in the backend
      </cm-text>

      <br />
      <br />

      <cm-button
        *ngIf="
        k8sCorpses && k8sCorpses.length > 0 && vss.visibilities.admin.corpses.delete.visible
      "
        appearance="danger"
        label="Burn it with fire!"
        (cmPress)="openDeleteModal('Delete K8s Corpses', 'deletingK8s')"
        [disabled]="deletingK8s"
      ></cm-button>
      <cm-text *ngIf="deletingK8s">Please wait...</cm-text>
    </div>
  </cm-page-tab>
</cm-page>
