import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ApiService } from '../services/api.service'
import { NavbarService } from '../services/navbar.service'

@Injectable({
  providedIn: 'root',
})
export class OrgsResolverService implements Resolve<OrganizationDto[]> {
  constructor(
    private apiService: ApiService,
    private navbarService: NavbarService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<OrganizationDto[]> {
    return this.apiService.getAllUserOrganizations().pipe(
      map((organizations) => {
        this.navbarService.updateOrgs(organizations)

        return organizations
      }),
    )
  }
}
