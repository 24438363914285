import { Injectable } from '@angular/core'
import { forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { Features } from '../../../../commons/Features'
import {
  AdminViewVisibilities,
  ViewVisibilities,
} from '../../../../commons/Visibility'
import { environment } from '../../environments/environment'
import { ApiService } from './api.service'
import { ClusterService } from './cluster.service'
import { FeatureService } from './feature.service'
import { OrgService } from './org.service'
import { UserService } from './user.service'
import { BillingVisibilityUtils } from './VisibilityUtils/billing.visibility.utils'
import { ClusterVisibilityUtils } from './VisibilityUtils/cluster.visibility.utils'
import { OrgVisibilityUtils } from './VisibilityUtils/org.visibility.utils'

@Injectable({
  providedIn: 'root',
})
export class ViewVisibilitiesService {
  public visibilities: ViewVisibilities
  private currentOrgId: string
  public orgVisibilityUtils: OrgVisibilityUtils
  private clusterVisibilityUtils: ClusterVisibilityUtils
  private billingVisibilityUtils: BillingVisibilityUtils
  private features: Features
  private events: string[] = []
  private clusterCap: boolean = true

  private clusterServiceSubscription

  constructor(
    private orgService: OrgService,
    private featuresService: FeatureService,
    private clusterService: ClusterService,
    private apiService: ApiService,
    private userService: UserService,
  ) {
    this.visibilities = this.createEmptyVisibilities()
  }

  public get(orgId?: string): Observable<ViewVisibilities> {
    if (!orgId) {
      return this.featuresService.accessToken$.pipe(
        map((accessToken) => {
          this.features = accessToken.activated
          this.events = accessToken.events
          this.updateViewVisibilities()
          return this.visibilities
        }),
      )
    }
    // cache org information
    if (
      orgId === this.currentOrgId &&
      this.features &&
      this.orgVisibilityUtils &&
      this.billingVisibilityUtils
    ) {
      return forkJoin([
        this.clusterService.getClusters(orgId),
        this.apiService.hasReachedClusterCap(orgId),
      ]).pipe(
        map(([clusters, clusterCap]) => {
          this.clusterVisibilityUtils = new ClusterVisibilityUtils(
            this.orgVisibilityUtils,
            clusters,
            this.features,
            clusterCap.clusterCapReached,
          )
          this.updateViewVisibilities()
          return this.visibilities
        }),
      )
    } else {
      // if org was changed read all information
      return forkJoin([
        this.orgService.getOrg(orgId),
        this.clusterService.getClusters(orgId),
        this.featuresService.accessToken$,
        this.apiService.hasReachedClusterCap(orgId),
      ]).pipe(
        map(([org, clusters, accessToken, clusterCap]) => {
          this.orgVisibilityUtils = new OrgVisibilityUtils(
            org,
            accessToken.orgs,
          )
          this.clusterVisibilityUtils = new ClusterVisibilityUtils(
            this.orgVisibilityUtils,
            clusters,
            accessToken.activated,
            clusterCap.clusterCapReached,
          )
          this.billingVisibilityUtils = new BillingVisibilityUtils(
            this.orgVisibilityUtils,
          )
          this.features = accessToken.activated
          this.clusterCap = clusterCap.clusterCapReached

          this.updateViewVisibilities()
          if (!this.clusterServiceSubscription) {
            this.clusterServiceSubscription = this.clusterService
              .getClustersStatus()
              .subscribe((subscribedClusters) => {
                this.updateClusters(subscribedClusters)
              })
          }
          return this.visibilities
        }),
      )
    }
  }

  public updateClusters(clusters: ClusterDto[]) {
    this.clusterVisibilityUtils = new ClusterVisibilityUtils(
      this.orgVisibilityUtils,
      clusters,
      this.features,
      this.clusterCap,
    )
    this.updateViewVisibilities()
  }

  private createAdminVisibilities(): AdminViewVisibilities {
    if (!this.features) {
      return this.createEmptyAdminViewVisibilities()
    }
    return {
      channels: {
        create: {
          visible: this.features.multiGen.admin.modify,
        },
        update: {
          visible: this.features.multiGen.admin.modify,
        },
        delete: {
          visible: this.features.multiGen.admin.modify,
        },
      },
      clusterplans: {
        create: {
          visible: this.features.clusterPlan.create,
          disabled: !this.features.clusterPlan.create,
        },
        update: {
          visible: this.features.clusterPlan.create,
        },
        delete: {
          visible: this.features.clusterPlan.delete,
        },
        cluster: {
          delete: {
            visible: this.features.clusterPlan.deleteAllClustersOfPlan,
          },
          migrate: {
            visible: this.features.clusterPlan.migrate,
          },
        },
      },
      clusterplantypes: {
        create: {
          visible: this.features.clusterPlan.type.create,
        },
        update: {
          visible: this.features.clusterPlan.type.modify,
        },
        delete: {
          visible: this.features.clusterPlan.type.delete,
        },
        forceDelete: {
          visible: this.features.clusterPlan.type.forceDelete,
        },
      },
      generations: {
        create: {
          visible: this.features.multiGen.admin.modify,
        },
        update: {
          visible: this.features.multiGen.admin.modify,
        },
        delete: {
          visible: this.features.multiGen.admin.modify,
        },
        cluster: {
          delete: {
            visible: this.features.multiGen.admin.deleteAllClustersOfGeneration,
          },
          migrate: {
            visible: this.features.multiGen.admin.migrate,
          },
        },
      },
      corpses: {
        read: {
          visible: this.features.corpses.see,
        },
        delete: {
          visible: this.features.corpses.delete,
        },
      },
      k8scontext: {
        create: {
          visible: this.features.multiCluster.admin,
        },
        read: {
          visible: this.features.multiCluster.admin,
        },
        update: {
          visible: this.features.multiCluster.admin,
        },
        delete: {
          visible: this.features.multiCluster.admin,
        },
      },
    }
  }

  private updateViewVisibilities() {
    if (
      !this.orgVisibilityUtils ||
      !this.clusterVisibilityUtils ||
      !this.billingVisibilityUtils ||
      !this.features
    ) {
      this.visibilities = this.createEmptyVisibilities()
    } else {
      this.visibilities = {
        admin: this.createAdminVisibilities(),
        dialog: {
          cluster: {
            create: {
              amountOfCluster: {
                visible: this.features.clusters.godmodeUnlimitedClusters,
              },
              god: {
                visible: this.features.clusters.godmodeUnlimitedClusters,
              },
            },
          },
        },
        home: {
          welcomeModal: {
            visible:
              !this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired() &&
              ![
                ...this.events,
                ...this.userService.newUserMetadataEvents,
              ].includes('console:welcome-modal'),
          },
          documentation: {
            showTrial: {
              visible: !(
                this.orgVisibilityUtils.isSalesPlanTypePaid() ||
                this.orgVisibilityUtils.isSalesPlanTypePaidCC() ||
                this.orgVisibilityUtils.isSalesPlanTypeEnterprise()
              ),
            },
            showProfessional: {
              visible:
                this.orgVisibilityUtils.isSalesPlanTypePaid() ||
                this.orgVisibilityUtils.isSalesPlanTypePaidCC() ||
                this.orgVisibilityUtils.isSalesPlanTypeEnterprise(),
            },
          },
          feedback: {
            showTrial: {
              visible: !(
                this.orgVisibilityUtils.isSalesPlanTypePaid() ||
                this.orgVisibilityUtils.isSalesPlanTypePaidCC() ||
                this.orgVisibilityUtils.isSalesPlanTypeEnterprise()
              ),
            },
            showProfessional: {
              visible:
                this.orgVisibilityUtils.isSalesPlanTypePaid() ||
                this.orgVisibilityUtils.isSalesPlanTypePaidCC() ||
                this.orgVisibilityUtils.isSalesPlanTypeEnterprise(),
            },
          },
        },
        modeler: {
          message: {
            noclusters: {
              visible:
                !this.clusterVisibilityUtils.clustersAvailable() &&
                this.clusterVisibilityUtils.canCreateCluster(),
            },
          },
          cluster: {
            create: {
              visible: !this.clusterVisibilityUtils.clustersAvailable(),
              disabled: !this.clusterVisibilityUtils.canCreateCluster(),
            },
          },
          read: {
            visible:
              this.orgVisibilityUtils.getOrgPermissions().readModels ||
              this.orgVisibilityUtils.getOrgPermissions().org.diagrams.read,
          },
          create: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.diagrams
              .create,
          },
          save: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.diagrams
              .update,
            disabled:
              !this.orgVisibilityUtils.getOrgPermissions().org.diagrams
                .update ||
              this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
          },
          delete: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.diagrams
              .delete,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().org.diagrams
              .delete,
          },
          deploy: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.workflows
              .create,
            disabled:
              !this.orgVisibilityUtils.getOrgPermissions().org.workflows
                .create ||
              this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
          },
          start: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.instances
              .create,
            disabled:
              !this.orgVisibilityUtils.getOrgPermissions().org.instances
                .create ||
              this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
          },
        },
        forms: {
          read: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.forms.read,
          },
          create: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.forms
              .create,
          },
          update: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.forms
              .update,
          },
          delete: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.forms
              .delete,
          },
        },
        overview: {
          plan: {
            trial: {
              visible:
                !this.orgVisibilityUtils.isSalesPlanTypePaid() &&
                !this.orgVisibilityUtils.isSalesPlanTypeEnterprise(),
            },
            professional: {
              visible: this.orgVisibilityUtils.isSalesPlanTypePaid(),
            },
            enterprise: {
              visible: this.orgVisibilityUtils.isSalesPlanTypeEnterprise(),
            },
          },
        },
        members: {
          newRoleDefinitionsModal: {
            visible:
              this.orgVisibilityUtils.getOrgPermissions().org.users.general
                .read &&
              ![
                ...this.events,
                ...this.userService.newUserMetadataEvents,
              ].includes('console:new-role-definitions-modal'),
          },
          read: {
            visible:
              this.orgVisibilityUtils.getOrgPermissions().seeMembers ||
              this.orgVisibilityUtils.getOrgPermissions().org.users.member.read,
          },
          add: {
            general: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().seeMembers ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.member
                  .create,
              disabled:
                !this.orgVisibilityUtils.getOrgPermissions().manageMembers &&
                !this.orgVisibilityUtils.getOrgPermissions().manageAdmins &&
                !this.orgVisibilityUtils.getOrgPermissions().org.users.member
                  .create &&
                !this.orgVisibilityUtils.getOrgPermissions().org.users.admin
                  .create,
            },
            owner: {
              visible: false,
            },
            admin: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageAdmins ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.admin
                  .create,
            },
            member: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageMembers ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.member
                  .create,
            },
          },
          update: {
            general: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageMembers ||
                this.orgVisibilityUtils.getOrgPermissions().manageAdmins ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.member
                  .update ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.admin
                  .update,
            },
            owner: {
              visible: this.orgVisibilityUtils.getOrgPermissions().org.users
                .owner.update,
            },
            admin: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageAdmins ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.admin
                  .update,
            },
            member: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageMembers ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.member
                  .update,
            },
          },
          remove: {
            general: {
              visible: true,
            },
            owner: {
              visible: false,
            },
            admin: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageAdmins ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.admin
                  .delete,
            },
            member: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().manageMembers ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.member
                  .delete,
            },
          },
        },
        billing: {
          reservations: {
            read: {
              visible: this.billingVisibilityUtils.canSeeReservations(),
            },
            update: {
              visible: true,
              disabled: !this.billingVisibilityUtils.canChangeReservations(),
            },
          },
          valueMetrics: {
            read: {
              visible:
                this.billingVisibilityUtils.canSeeValueMetrics() &&
                (this.orgVisibilityUtils.isPricing1() ||
                  this.orgVisibilityUtils.isSalesPlanTypeEnterprise()),
            },
          },
          view: {
            pricing0: {
              visible: this.orgVisibilityUtils.isPricing0(),
            },
            pricing1: {
              visible: this.orgVisibilityUtils.isPricing1(),
            },
            enterprise: {
              visible:
                this.orgVisibilityUtils.isSalesPlanTypeEnterprise() &&
                environment.production === false,
            },
          },
          manageCcPayments: {
            visible: this.orgVisibilityUtils.isSalesPlanTypePaidCC(),
          },
          subscriptionsAvailable: {
            visible: this.orgVisibilityUtils.hasSubscription(),
          },
        },
        apiclients: {
          create: {
            visible: this.orgVisibilityUtils.getOrgPermissions().org.clients
              .create,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().org.clients
              .create,
          },
          read: {
            visible: true,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().org.clients
              .read,
          },
          update: {
            visible: true,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().org.clients
              .update,
          },
          delete: {
            visible: true,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().org.clients
              .delete,
          },
        },
        alerts: {
          create: {
            visible: this.orgVisibilityUtils.getOrgPermissions().cluster.alerts
              .create,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .alerts.create,
          },
          delete: {
            visible: this.orgVisibilityUtils.getOrgPermissions().cluster.alerts
              .delete,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .alerts.delete,
          },
          update: {
            visible: this.orgVisibilityUtils.getOrgPermissions().cluster.alerts
              .update,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .alerts.update,
          },
        },
        ipWhitelist: {
          create: {
            visible: true,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .ipWhitelist.create,
          },
          delete: {
            visible: true,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .ipWhitelist.delete,
          },
          update: {
            visible: true,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .ipWhitelist.update,
          },
        },
        clients: {
          create: {
            visible: this.orgVisibilityUtils.getOrgPermissions().cluster.clients
              .create,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .clients.create,
          },
          update: {
            visible: this.orgVisibilityUtils.getOrgPermissions().cluster.clients
              .update,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .clients.update,
          },
          delete: {
            visible: this.orgVisibilityUtils.getOrgPermissions().cluster.clients
              .delete,
            disabled: !this.orgVisibilityUtils.getOrgPermissions().cluster
              .clients.delete,
          },
        },
        settings: {
          tabs: {
            overview: {
              visible: true,
            },
            members: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().seeMembers ||
                this.orgVisibilityUtils.getOrgPermissions().org.users.general
                  .read,
            },
            audit: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().auditRead ||
                this.orgVisibilityUtils.getOrgPermissions().org.activity.read,
            },
            api: {
              visible:
                this.orgVisibilityUtils.getOrgPermissions().readSettings ||
                this.orgVisibilityUtils.getOrgPermissions().org.clients.read,
            },
            billing: {
              visible:
                this.billingVisibilityUtils.canSeeReservations() ||
                this.billingVisibilityUtils.canSeeValueMetrics(),
            },
            usage: {
              visible:
                (this.orgVisibilityUtils.isSalesPlanTypeTrial() ||
                  this.orgVisibilityUtils.isSalesPlanTypeInNegotiation()) &&
                (this.orgVisibilityUtils.getOrgPermissions().seeBilling ||
                  this.orgVisibilityUtils.getOrgPermissions().org.usage.read) &&
                !environment.production,
            },
          },
        },
        cluster: {
          details: {
            clusterPlanType: {
              visible:
                this.orgVisibilityUtils.isPricing0() &&
                !this.orgVisibilityUtils.isSalesPlanTypeFree(),
            },
            tabs: {
              overview: {
                visible: true,
              },
              api: {
                visible: this.orgVisibilityUtils.getOrgPermissions().cluster
                  .clients.read,
              },
              applications: {
                visible: true,
              },
              metrics: {
                visible: true,
              },
              alerts: {
                visible: this.orgVisibilityUtils.getOrgPermissions().cluster
                  .alerts.read,
              },
              ipWhitelist: {
                visible:
                  this.orgVisibilityUtils.getOrgPermissions().cluster
                    .ipWhitelist.read &&
                  this.orgVisibilityUtils.isSalesPlanTypeEnterprise() &&
                  !environment.production,
              },
              audits: {
                visible:
                  !environment.production &&
                  (this.orgVisibilityUtils.getOrgPermissions().auditRead ||
                    this.orgVisibilityUtils.getOrgPermissions().org.activity
                      .read),
              },
            },
            menu: {
              general: {
                visible:
                  this.orgVisibilityUtils.getOrgPermissions().org.clusters
                    .delete ||
                  this.orgVisibilityUtils.getOrgPermissions().org.clusters
                    .update ||
                  this.features.clusters.canMoveToOtherOrg ||
                  this.features.clusters.godmodeUnlimitedClusters,
              },
              delete: {
                visible: this.orgVisibilityUtils.getOrgPermissions().org
                  .clusters.delete,
                disabled: !this.orgVisibilityUtils.getOrgPermissions().org
                  .clusters.delete,
              },
              rename: {
                disabled: !this.orgVisibilityUtils.getOrgPermissions().org
                  .clusters.update,
                visible: this.orgVisibilityUtils.getOrgPermissions().org
                  .clusters.update,
              },
              moveToOtherOrg: {
                visible: this.features.clusters.canMoveToOtherOrg,
              },
              boost: {
                visible: this.features.clusters.godmodeUnlimitedClusters,
              },
            },
            operate: {
              launch: {
                visible: this.orgVisibilityUtils.getOrgPermissions().cluster
                  .operate.read,
              },
            },
            tasklist: {
              launch: {
                visible: this.orgVisibilityUtils.getOrgPermissions().cluster
                  .tasklist.read,
              },
            },
            optimize: {
              launch: {
                visible: this.orgVisibilityUtils.getOrgPermissions().cluster
                  .optimize.read,
              },
            },
            zeebe: {
              status: {
                visible:
                  this.orgVisibilityUtils.getOrgPermissions().org.clusters
                    .read &&
                  this.orgVisibilityUtils.getOrgPermissions().cluster.clients
                    .read,
              },
            },
            generation: {
              update: {
                visible:
                  this.orgVisibilityUtils.getOrgPermissions().updateClusters ||
                  this.orgVisibilityUtils.getOrgPermissions().org.clusters
                    .update ||
                  this.features.multiGen.admin.migrate,
              },
            },
          },
          list: {
            create: {
              visible:
                this.features.clusters.canCreateOwnClusters &&
                this.orgVisibilityUtils.getOrgPermissions().org.clusters.create,
              disabled:
                !this.features.clusters.godmodeUnlimitedClusters &&
                (!this.clusterVisibilityUtils.canCreateCluster() ||
                  this.clusterVisibilityUtils.isClusterCapReached() ||
                  !this.orgVisibilityUtils.getOrgPermissions().org.clusters
                    .create),
            },
            message: {
              clusterCap: {
                visible: this.clusterVisibilityUtils.isClusterCapReached(),
              },
              reachedMaxClusters: {
                visible:
                  this.features.clusters.canCreateOwnClusters &&
                  !this.clusterVisibilityUtils.canCreateCluster() &&
                  !this.billingVisibilityUtils.canChangeReservations() &&
                  !this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
              },
              trialExpired: {
                visible: this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
              },
              reachedMaxReservations: {
                visible:
                  this.features.clusters.canCreateOwnClusters &&
                  !this.clusterVisibilityUtils.canCreateCluster() &&
                  this.billingVisibilityUtils.canChangeReservations(),
              },
            },
            delete: {
              all: { visible: this.features.clusters.godmodeUnlimitedClusters },
              healthy: {
                visible: this.features.clusters.godmodeUnlimitedClusters,
              },
              unhealthy: {
                visible: this.features.clusters.godmodeUnlimitedClusters,
              },
            },
          },
        },
        navbar: {
          trial: {
            warning: {
              visible: this.orgVisibilityUtils.isSalesPlanTypeTrial(),
            },
            expired: {
              visible: this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
            },
            requestPaidPlan: {
              visible:
                (this.orgVisibilityUtils.isSalesPlanTypeTrial() ||
                  this.orgVisibilityUtils.isSalesPlanTypeFree()) &&
                this.orgVisibilityUtils.getOrgPermissions().org.billing.update,
            },
          },
          menu: {
            admin: this.generateAdminMenue(),
            userSettings: {
              visible: this.features.features.userSettings,
            },
          },
          stage: { visible: this.isAdmin() },
          supportAgent: {
            visible: this.orgVisibilityUtils.isUserSupportAgent(),
          },
          webIDE: { visible: !environment.production },
        },
        featureFlags: {
          newOnboardingExperience: {
            visible: true,
          },
          prodStageToggle: { visible: environment.production ? false : true },
          nonProdFeatures: { visible: environment.production ? false : true },
          templatePicker: {
            visible: !this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired(),
          },
          templatePickerToggle: {
            visible: false,
          },
          multiRoles: {
            visible: !environment.production,
          },
        },
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private createEmptyVisibilities() {
    const viewVisibilities: ViewVisibilities = {
      admin: this.createAdminVisibilities(),
      dialog: {
        cluster: {
          create: {
            amountOfCluster: {
              visible: false,
            },
            god: {
              visible: false,
            },
          },
        },
      },
      home: {
        welcomeModal: {
          visible: false,
        },
        documentation: {
          showTrial: {
            visible: false,
          },
          showProfessional: {
            visible: false,
          },
        },
        feedback: {
          showTrial: {
            visible: false,
          },
          showProfessional: {
            visible: false,
          },
        },
      },
      modeler: {
        message: {
          noclusters: {
            visible: false,
          },
        },
        cluster: {
          create: {
            visible: false,
            disabled: true,
          },
        },
        read: {
          visible: false,
        },
        create: {
          visible: false,
        },
        save: {
          visible: true,
          disabled: true,
        },
        delete: { visible: true, disabled: true },
        deploy: {
          visible: true,
          disabled: true,
        },
        start: {
          visible: true,
          disabled: true,
        },
      },
      forms: {
        read: {
          visible: false,
        },
        create: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
      },
      overview: {
        plan: {
          trial: {
            visible: false,
          },
          professional: {
            visible: false,
          },
          enterprise: {
            visible: false,
          },
        },
      },
      members: {
        newRoleDefinitionsModal: {
          visible: false,
        },
        read: {
          visible: false,
        },
        add: {
          general: {
            visible: false,
          },
          owner: {
            visible: false,
          },
          admin: {
            visible: false,
          },
          member: {
            visible: false,
          },
        },
        update: {
          general: {
            visible: false,
          },
          owner: {
            visible: false,
          },
          admin: {
            visible: false,
          },
          member: {
            visible: false,
          },
        },
        remove: {
          general: {
            visible: false,
          },
          owner: {
            visible: false,
          },
          admin: {
            visible: false,
          },
          member: {
            visible: false,
          },
        },
      },
      billing: {
        reservations: {
          read: {
            visible: false,
          },
          update: {
            visible: false,
          },
        },
        valueMetrics: {
          read: {
            visible: false,
          },
        },
        view: {
          pricing0: {
            visible: false,
          },
          pricing1: {
            visible: false,
          },
          enterprise: {
            visible: false,
          },
        },
        manageCcPayments: { visible: false },
        subscriptionsAvailable: {
          visible: false,
        },
      },
      apiclients: {
        create: {
          visible: false,
        },
        read: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
      },
      alerts: {
        create: {
          visible: true,
        },
        update: {
          visible: true,
        },
        delete: {
          visible: true,
        },
      },
      ipWhitelist: {
        create: {
          visible: true,
        },
        update: {
          visible: true,
        },
        delete: {
          visible: true,
        },
      },
      clients: {
        create: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
      },
      settings: {
        tabs: {
          overview: {
            visible: false,
          },
          members: {
            visible: false,
          },
          audit: {
            visible: false,
          },
          api: {
            visible: false,
          },
          billing: {
            visible: false,
          },
          usage: {
            visible: false,
          },
        },
      },
      cluster: {
        details: {
          clusterPlanType: { visible: false },
          tabs: {
            overview: {
              visible: false,
            },
            api: {
              visible: false,
            },
            applications: {
              visible: false,
            },
            metrics: {
              visible: false,
            },
            alerts: {
              visible: false,
            },
            ipWhitelist: {
              visible: false,
            },
            audits: { visible: false },
          },
          menu: {
            general: {
              visible: false,
            },
            delete: {
              visible: false,
            },
            rename: {
              visible: false,
            },
            moveToOtherOrg: {
              visible: false,
            },
            boost: { visible: false },
          },
          operate: {
            launch: {
              visible: false,
            },
          },
          tasklist: {
            launch: {
              visible: true,
            },
          },
          optimize: {
            launch: {
              visible: true,
            },
          },
          zeebe: {
            status: {
              visible: false,
            },
          },
          generation: {
            update: {
              visible: false,
            },
          },
        },
        list: {
          create: {
            visible: true,
            disabled: true,
          },
          message: {
            clusterCap: {
              visible: false,
            },
            reachedMaxClusters: {
              visible: false,
            },
            trialExpired: {
              visible: false,
            },
            reachedMaxReservations: {
              visible: false,
            },
          },
          delete: {
            all: { visible: false },
            healthy: { visible: false },
            unhealthy: {
              visible: false,
            },
          },
        },
      },
      navbar: {
        trial: {
          warning: { visible: false },
          expired: { visible: false },
          requestPaidPlan: { visible: false },
        },
        menu: {
          admin: this.generateAdminMenue(),
          userSettings: {
            visible: false,
          },
        },
        stage: { visible: false },
        supportAgent: { visible: false },
        webIDE: { visible: false },
      },

      featureFlags: {
        newOnboardingExperience: { visible: false },
        prodStageToggle: { visible: environment.production ? false : true },
        nonProdFeatures: { visible: environment.production ? false : true },
        templatePicker: { visible: false },
        templatePickerToggle: {
          visible: false,
        },
        multiRoles: {
          visible: false,
        },
      },
    }
    return viewVisibilities
  }

  // eslint-disable-next-line class-methods-use-this
  private createEmptyAdminViewVisibilities(): AdminViewVisibilities {
    return {
      channels: {
        create: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
      },
      clusterplans: {
        create: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
        cluster: {
          delete: {
            visible: false,
          },
          migrate: {
            visible: false,
          },
        },
      },
      clusterplantypes: {
        create: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
        forceDelete: {
          visible: false,
        },
      },
      generations: {
        create: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
        cluster: {
          delete: {
            visible: false,
          },
          migrate: {
            visible: false,
          },
        },
      },
      corpses: {
        read: {
          visible: false,
        },
        delete: {
          visible: false,
        },
      },
      k8scontext: {
        create: {
          visible: false,
        },
        read: {
          visible: false,
        },
        update: {
          visible: false,
        },
        delete: {
          visible: false,
        },
      },
    }
  }

  private isAdmin(): boolean {
    return (
      this.features.multiCluster.admin ||
      this.features.multiGen.admin.see ||
      this.features.multiGen.admin.modify ||
      this.features.multiGen.admin.migrate ||
      this.features.multiGen.admin.deleteAllClustersOfGeneration ||
      this.features.corpses.see ||
      this.features.corpses.delete ||
      this.features.clusterPlan.create ||
      this.features.clusterPlan.delete ||
      this.features.clusterPlan.deleteAllClustersOfPlan ||
      this.features.clusterPlan.migrate ||
      this.features.clusterPlan.seeAll ||
      this.features.clusterPlan.type.create ||
      this.features.clusterPlan.type.delete ||
      this.features.clusterPlan.type.modify
    )
  }
  private generateAdminMenue() {
    return {
      general: { visible: this.features ? this.isAdmin() : false },
      corpse: {
        visible: this.features
          ? this.features.corpses.see || this.features.corpses.delete
          : false,
      },
      k8scontext: {
        visible: this.features ? this.features.multiCluster.admin : false,
      },
      versions: {
        visible: this.features
          ? this.features.multiGen.admin.see ||
            this.features.multiGen.admin.modify ||
            this.features.multiGen.admin.migrate ||
            this.features.multiGen.admin.deleteAllClustersOfGeneration
          : false,
      },
      clusterplans: {
        visible: this.features
          ? this.features.clusterPlan.create ||
            this.features.clusterPlan.delete ||
            this.features.clusterPlan.deleteAllClustersOfPlan ||
            this.features.clusterPlan.migrate ||
            this.features.clusterPlan.seeAll ||
            this.features.clusterPlan.type.create ||
            this.features.clusterPlan.type.delete ||
            this.features.clusterPlan.type.modify
          : false,
      },
    }
  }
}
