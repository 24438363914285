import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, first, shareReplay } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { ClusterService } from './cluster.service'

const CACHE_TTL = 1000 * 60 * 30 // 30mins
const CACHE_TTL_SHORT = 1000 * 60 * 5 // 5 mins

@Injectable({
  providedIn: 'root',
})
export class CacheInterceptorService implements HttpInterceptor {
  private store: Map<string, Observable<HttpEvent<any>>> = new Map()
  private timestamps: Map<string, number> = new Map()

  constructor(private clusterService: ClusterService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const url = req.urlWithParams
    const method = req.method

    if (!this.useCache(req)) {
      this.invalidateRelevantCacheEntries(url, method)
      return next.handle(req)
    }

    const cachedObservable = this.cachedAndValid(url)
      ? this.store.get(url)
      : this.addToCacheAndReturn(req, next)

    return cachedObservable.pipe(first())
  }

  private cachedAndValid(url: string) {
    if (!this.store.has(url) || !this.timestamps.has(url)) {
      return false
    }

    return this.valid(url, CACHE_TTL)
  }

  private useCache(req: HttpRequest<any>) {
    // ignore cache when explicitly asked
    if (req.headers.has('camundaDisableCache')) {
      req.headers.delete('camundaDisableCache')
      return false
    }

    // use cache only for GET requests
    if (req.method !== 'GET') {
      return false
    }

    const isCamundaCloudApi =
      req.url.startsWith(environment.backendUrl) ||
      req.url.startsWith(environment.accountsUrl)

    if (!isCamundaCloudApi) {
      return false
    }

    // check excluded resources
    const shortTtlResources: string[] = ['alerts', 'metrics']

    for (const shortTtlResource of shortTtlResources) {
      if (
        req.url.includes(shortTtlResource) &&
        this.store.has(req.urlWithParams) &&
        !this.valid(req.urlWithParams, CACHE_TTL_SHORT)
      ) {
        return false
      }
    }
    // TODO: after SSE test always return false
    // cluster update running?
    if (
      (!environment.production && req.url.includes(`/clusters`)) ||
      ((this.clusterService.isClusterInCreation() ||
        this.clusterService.isOneClusterUnhealthy()) &&
        req.url.includes(`/clusters`))
    ) {
      return false
    }

    if (req.url.includes('/admin')) {
      return false
    }

    return true
  }

  private invalidateRelevantCacheEntries(url: string, method: string) {
    this.store.delete(url)
    this.timestamps.delete(url)

    if (['DELETE', 'PATCH', 'PUT', 'POST'].includes(method)) {
      if (url.startsWith(environment.accountsUrl)) {
        // any change operations to accounts invalidate the cache
        for (const key of this.store.keys()) {
          if (key.startsWith(environment.accountsUrl)) {
            this.store.delete(key)
            this.timestamps.delete(key)
          }
        }
      }

      if (url.includes('showcases')) {
        this.store.clear()
        this.timestamps.clear()
        return
      }

      const clusterDependencies = ['bpmn-models']
      const routeSplitted = url.split('/')
      const resource1 = routeSplitted[routeSplitted.length - 2]
      const resource2 = routeSplitted[routeSplitted.length - 4]
      const resource3 =
        routeSplitted.includes('invites') || routeSplitted.includes('roles')
          ? 'members'
          : undefined

      for (const key of this.store.keys()) {
        if (
          key.endsWith(resource1) ||
          key.endsWith(resource2) ||
          (resource3 && key.endsWith(resource3))
        ) {
          this.store.delete(key)
          this.timestamps.delete(key)
        }

        if (method === 'DELETE' && resource1 === 'clusters') {
          for (const clusterDependency of clusterDependencies) {
            if (key.endsWith(clusterDependency)) {
              this.store.delete(key)
              this.timestamps.delete(key)
            }
          }
        }
      }
    }
  }

  private addToCacheAndReturn(req: HttpRequest<any>, next: HttpHandler) {
    this.store.set(
      req.urlWithParams,
      next.handle(req).pipe(
        filter((res) => res instanceof HttpResponse),
        shareReplay(1),
      ),
    )

    this.timestamps.set(req.urlWithParams, Date.now())

    return this.store.get(req.urlWithParams)
  }

  private valid(url: string, ttl: number): boolean {
    return Date.now() - this.timestamps.get(url) < ttl
  }
}
