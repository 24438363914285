<cm-header>
  <a class="header-name" routerLink="{{ routeToHome }}" slot="left">
    <cm-logo></cm-logo><span>Camunda Cloud</span>
  </a>

  <div class="header-vertical-divider" slot="left"></div>

  <!-- NEW HOME NAVIGATION -->
  <nav slot="left">
    <ng-container *ngIf="selectedOrg">
      <a
        routerLink="/org/{{ selectedOrg.uuid }}/diagrams"
        routerLinkActive="active"
        data-text="Diagrams"
        (click)="navbarSectionMixpanelEvent('diagrams')"
        *ngIf="vvs.visibilities.modeler.read.visible"
      >
        Diagrams
      </a>
      <a
        routerLink="/org/{{ selectedOrg.uuid }}/forms"
        routerLinkActive="active"
        data-text="Forms"
        (click)="navbarSectionMixpanelEvent('forms')"
        *ngIf="vvs.visibilities.forms.read.visible"
      >
        Forms
      </a>
      <a
        routerLink="/org/{{ selectedOrg.uuid }}/clusters"
        routerLinkActive="active"
        data-text="Clusters"
        (click)="navbarSectionMixpanelEvent('clusters')"
      >
        Clusters
      </a>

      <a
        *ngIf="vvs.visibilities.navbar.webIDE.visible"
        [href]="webIdeLink"
        data-text="WebIDE"
        >WebIDE
      </a>
    </ng-container>
  </nav>
  <div
    *ngIf="
      vvs.visibilities.navbar.trial.warning.visible &&
      !vvs.visibilities.navbar.trial.expired.visible &&
      trialCountdownDays
    "
    slot="right"
  >
    <cm-text style="margin-right: 10px">
      <a
        title="Trial expires on {{
          (navbarService.currentOrg$ | async)?.organizationToSalesPlan
            .trialExpirationDate | date: 'd MMMM y'
        }}"
      >
        Trial expires in
        {{ trialCountdownDays }}
        {{ trialCountdownDays === '1' ? 'day' : 'days' }}
      </a>
    </cm-text>

    <cm-button
      appearance="primary"
      label="Update Subscription"
      size="small"
      (cmPress)="openRequestPaidPlanModal()"
    >
    </cm-button>

    <div class="header-vertical-divider narrow"></div>
  </div>

  <cm-dropdown
    [trigger]="helpMenuTrigger"
    [options]="helpMenuOptions"
    slot="right"
  ></cm-dropdown>

  <ng-container *ngIf="user$ | async">
    <div class="header-vertical-divider narrow" slot="right"></div>

    <cm-dropdown
      [trigger]="homeMenuTrigger"
      [options]="homeMenuOptions"
      slot="right"
    ></cm-dropdown>
  </ng-container>
</cm-header>

<cm-modal
  #requestPaidPlanModal
  headline="Update Subscription"
  confirmLabel="Close"
  position="top"
>
  <div class="requestPaidPlan">
    <span class="text">Select a subscription that fits your needs:</span>

    <div class="cards">
      <div class="planCard">
        <div class="cardHeader">
          <h2>Professional</h2>
          <span class="text">Get started quickly and pay as you go</span>
          <cm-link
            label="Pricing FAQ"
            href="https://camunda.com/products/cloud/camunda-cloud-professional-pricing-and-frequently-asked-questions/"
          ></cm-link>
        </div>

        <ul>
          <li>Full Camunda Cloud Feature Set</li>
          <li>Pay for What You Use</li>
          <li>8x5 Support</li>
          <li>Standard Legal Terms</li>
        </ul>

        <div class="contactArea">
          <cm-button
            label="Pay with Credit Card"
            appearance="primary"
            (cmPress)="openSwitchToProfessionalPlanCcModal()"
          >
          </cm-button>

          <cm-link
            label="Contact Us"
            href="https://camunda.com/products/cloud/camunda-cloud-professional-signup/"
            (click)="contactUsProfessionalEvent()"
          ></cm-link>
        </div>
      </div>

      <div class="planCard">
        <div class="cardHeader">
          <h2>Enterprise</h2>
          <span class="text">
            Scale up an automation platform to power all your mission-critical
            business processes
          </span>
        </div>

        <ul>
          <li>Full Camunda Cloud Feature Set</li>
          <li>Define Your Enterprise Usage Plan</li>
          <li>8 x 5 or 24 x 7 Support</li>
          <li>Enterprise Legal Terms</li>
        </ul>

        <div class="contactArea">
          <cm-link
            label="Contact Us"
            href="https://camunda.com/products/cloud/camunda-cloud-enterprise-contact/"
            (click)="contactUsEnterpriseEvent()"
          >
          </cm-link>
        </div>
      </div>
    </div>
  </div>
</cm-modal>

<cm-modal
  #switchToProfessionalPlanCcModal
  headline="Select Country"
  cancelLabel="Back"
  confirmLabel="Go to Checkout"
  position="top"
>
  <div class="countrySelection">
    <div class="countryFilterInput">
      <cm-icon icon="search"></cm-icon>
      <input
        type="text"
        placeholder="Type to search..."
        [(ngModel)]="countryFilter"
      />
    </div>
    <cm-radiobutton-group *ngIf="loadCountryFlagSvgs">
      <cm-radiobutton
        *ngFor="let country of filterCountries()"
        [label]="country.country.replace(' And ', ' & ')"
        [value]="country.countryCode"
        [selected]="country.countryCode === selectedCountry"
        (cmSelected)="selectCountry(country.countryCode)"
      >
        <img
          slot="beforeLabel"
          width="16"
          height="12"
          src="{{
            '/assets/flags/4x3/' + country.countryCode.toLowerCase() + '.svg'
          }}"
        />
      </cm-radiobutton>
    </cm-radiobutton-group>
  </div>
</cm-modal>
