import { ClusterDto } from '../../../../../commons/Cluster.dto'
import { Features } from '../../../../../commons/Features'
import { OrgVisibilityUtils } from './org.visibility.utils'

export class ClusterVisibilityUtils {
  constructor(
    private orgVisibilityUtils: OrgVisibilityUtils,
    private clusters: ClusterDto[],
    private features: Features,
    private clusterCap: boolean,
  ) {}

  public isClusterCapReached(): boolean {
    return (
      (this.orgVisibilityUtils.isSalesPlanTypeFree() ||
        this.orgVisibilityUtils.isSalesPlanTypeTrial()) &&
      this.clusterCap &&
      !this.features.clusters.godmodeUnlimitedClusters &&
      this.canCreateCluster()
    )
  }

  public clustersAvailable(): boolean {
    return this.clusters && this.clusters.length > 0
  }

  public canCreateCluster(): boolean {
    // 0) shortcut
    if (this.features.clusters.godmodeUnlimitedClusters) {
      return true
    }

    // 1) sales plan is not available
    if (!this.orgVisibilityUtils.isSalesPlanAttached()) {
      return false
    }

    // 2) trial sales plan expired
    if (this.orgVisibilityUtils.isSalesPlanTypeTrialAndExpired()) {
      return false
    }

    // 3) reservations matching the clustercount
    if (this.clusters.length > 0) {
      const currentReservations = this.orgVisibilityUtils.getSalesPlanReservations()
      if (currentReservations) {
        const max = Object.values(currentReservations).reduce((prev, curr) => {
          return prev + curr
        }, 0)
        return this.clusters.length < max
      }
    }
    if (this.clusters) {
      const max = Object.values(
        this.orgVisibilityUtils.getSalesPlanReservations(),
      ).reduce((prev, curr) => {
        return prev + curr
      }, 0)
      return this.clusters.length < max
    }

    // 4) dead end
    return false
  }
}
