import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { AuditDto } from '@camunda-cloud/cloud-node-libs'
import { iif, Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { ApiService } from '../services/api.service'
import { AuditService } from '../services/audit.service'

@Injectable({
  providedIn: 'root',
})
export class AuditLogResolverService implements Resolve<AuditDto[]> {
  constructor(
    private apiService: ApiService,
    private auditService: AuditService,
  ) {}
  public resolve(route: ActivatedRouteSnapshot): Observable<AuditDto[]> {
    return this.apiService
      .getOrganization(route.paramMap.get('orgUuid')!)
      .pipe(
        mergeMap((org) =>
          iif(
            () => org!.permissions!.auditRead,
            this.auditService.getAuditLogs(org.uuid),
            of([]),
          ),
        ),
      )
  }
}

@Injectable({
  providedIn: 'root',
})
export class ClusterAuditLogResolverService implements Resolve<AuditDto[]> {
  constructor(
    private apiService: ApiService,
    private auditService: AuditService,
  ) {}
  public resolve(route: ActivatedRouteSnapshot): Observable<AuditDto[]> {
    return this.apiService
      .getOrganization(route.paramMap.get('orgUuid')!)
      .pipe(
        mergeMap((org) =>
          iif(
            () => org!.permissions!.auditRead && !environment.production,
            this.auditService.getClusterAuditLogs(
              org.uuid,
              route.paramMap.get('clusterId'),
            ),
            of([]),
          ),
        ),
      )
  }
}
