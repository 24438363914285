import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { ClusterUsageMetricsData } from '@camunda-cloud/cloud-node-libs'
import { iif, Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { ApiService } from '../services/api.service'
import { ViewVisibilitiesService } from '../services/view.visibilities.service'

@Injectable({
  providedIn: 'root',
})
export class ValueMetricsTrialResolverService
  implements Resolve<ClusterUsageMetricsData[]> {
  constructor(
    private vvs: ViewVisibilitiesService,
    private apiService: ApiService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<ClusterUsageMetricsData[]> {
    return this.vvs
      .get(route.paramMap.get('orgUuid'))
      .pipe(
        mergeMap((visibilities) =>
          iif(
            () => visibilities.settings.tabs.usage.visible,
            this.apiService.clusterUsage(route.paramMap.get('orgUuid')),
            of([]),
          ),
        ),
      )
  }
}
