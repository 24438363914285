import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@auth0/auth0-angular'
import { ErrorNotificationInterceptorService } from './auth/errorNotificationInterceptor.service'
import { ChannelDetailsComponent } from './components/admin/channel-details/channel-details.component'
import { BpmnFormDesignerComponent } from './components/bpmn-form-designer/bpmn-form-designer.component'
import { BpmnModelerComponent } from './components/bpmn-modeler/bpmn-modeler.component'
import { TasklistOperateRedirectComponent } from './components/taskist-operate-redirect/tasklist-operate-redirect.component'
import { ClusterPlanDetailsPage } from './pages/admin/cluster-plan-details/cluster-plan-details.page'
import { ClusterPlanManagementPage } from './pages/admin/cluster-plan-management/cluster-plan-management.page'
import { CorpseManagementPage } from './pages/admin/corpse-management/corpse-management.page'
import { GenerationDetailsPage } from './pages/admin/generation-details/generation-details.page'
import { KubernetesContextPage } from './pages/admin/kubernetes-context/kubernetes-context.page'
import { VersionManagementPage } from './pages/admin/version-management/version-management.page'
import { CheckoutSuccessPage } from './pages/checkout-success/checkout-success.page'
import { ClusterDetailsPage } from './pages/cluster-details/cluster-details.page'
import { DashboardPage } from './pages/dashboard/dashboard.page'
import { ErrorPage } from './pages/error/error.page'
import { ExternalViewPage } from './pages/external-page/external-page.component'
import { NoOrganizationPage } from './pages/no-organization/no-organization.page'
import { OrganizationManagementPage } from './pages/organization-management/organization-management.page'
import { OrganizationRedirectPage } from './pages/organization-redirect/organization-redirect.page'
import { ProductWrapperPage } from './pages/product-wrapper/product-wrapper.page'
import { SignupPage } from './pages/signup/signup.page'
import { TermsPage } from './pages/terms/terms.page'
import { UserSettingsPage } from './pages/user-settings/user-settings.page'
import { AllOrgUuidsResolverService } from './resolver/all-org-uuids-resolver.resolver'
import {
  AuditLogResolverService,
  ClusterAuditLogResolverService,
} from './resolver/audit.resolver'
import { BpmnFormResolver } from './resolver/bpmn-form.resolver'
import { BpmnFormsResolver } from './resolver/bpmn-forms.resolver'
import { BpmnModelResolver } from './resolver/bpmn-model.resolver'
import { BpmnModelsResolver } from './resolver/bpmn-models.resolver'
import { ChannelsResolverService } from './resolver/channels.resolver'
import { ChannelResolverService } from './resolver/channel.resolver'
import { ClusterDetailsResolverService } from './resolver/cluster-details.resolver'
import { ClusterPlanActiveTypesResolverService } from './resolver/cluster-plan-active-types.resolver'
import { ClusterPlanDetailsResolverService } from './resolver/cluster-plan-details.resolver'
import { ClusterPlanTypesResolverService } from './resolver/cluster-plan-types.resolver'
import { ClusterPlansResolverService } from './resolver/cluster-plans.resolver'
import { ClustersResolverService } from './resolver/clusters.resolver'
import { ConnectorsResolverService } from './resolver/connectors.resolver'
import { CorpseResolverService } from './resolver/corpse.resolver'
import { GenerationResolverService } from './resolver/generation.resolver'
import { GenerationsResolverService } from './resolver/generations.resolver'
import { K8sContextResolverService } from './resolver/k8sContext.resolver'
import { OrgResolverService } from './resolver/org-resolver.resolver'
import { OrgsResolverService } from './resolver/orgs-resolver.resolver'
import { ReservationsResolverService } from './resolver/reservations.resolver'
import { ValueMetricsEnterpriseResolverService } from './resolver/valuemetrics-enterprise.resolver'
import { ValueMetricsTrialResolverService } from './resolver/valuemetrics-trial.resolver'
import { ValueMetricsResolverService } from './resolver/valuemetrics.resolver'
import { ValueMetricsHistoryResolverService } from './resolver/valuemetricshistory.resolver'
import { ViewVisibilitiesResolverService } from './resolver/view.visibilities.resolver'
import { NoCacheRouteReuseStrategy } from './route-reuse-strategy'
import { AdminClusterPlanGuard } from './services/admin.clusterplan.guard'
import { AdminCorpseGuard } from './services/admin.corpse.guard'
import { AdminMultiClusterGuard } from './services/admin.multicluster.guard'
import { AdminVersionGuard } from './services/admin.version.guard'
import { DebugGuard } from './services/debug.guard'
import { OrgGuard } from './services/org.guard'

export const routes: Routes = [
  {
    path: 'login',
    canActivate: [AuthGuard],
    children: [],
  },
  {
    path: 'no-organization',
    canActivate: [AuthGuard],
    component: NoOrganizationPage,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: {
      allOrgUuids: AllOrgUuidsResolverService,
    },
    component: OrganizationRedirectPage,
  },
  {
    path: 'onboarding/organization/:orgId/invitation/:inviteToken',
    canActivate: [AuthGuard],
    resolve: {
      allOrgUuids: AllOrgUuidsResolverService,
    },
    component: OrganizationRedirectPage,
  },
  {
    path: 'onboarding/organization/:orgId/invitation/:inviteToken/:inviteState',
    canActivate: [AuthGuard],
    resolve: {
      allOrgUuids: AllOrgUuidsResolverService,
    },
    component: OrganizationRedirectPage,
  },
  {
    path: 'terms',
    component: TermsPage,
  },
  { path: 'signup', component: SignupPage },
  {
    path: 'org/:orgUuid',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    component: DashboardPage,
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Clusters',
    },
  },
  {
    path: 'org/:orgUuid/checkout',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    component: DashboardPage,
    resolve: {
      clusters: ClustersResolverService,
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      models: BpmnModelsResolver,
      forms: BpmnFormsResolver,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      checkout: true,
    },
  },
  {
    path: 'org/:orgUuid/diagrams',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    component: ProductWrapperPage,
    resolve: {
      clusters: ClustersResolverService,
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      models: BpmnModelsResolver,
      forms: BpmnFormsResolver,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Diagrams',
    },
  },
  {
    path: 'org/:orgUuid/forms',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    component: ProductWrapperPage,
    resolve: {
      clusters: ClustersResolverService,
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      models: BpmnModelsResolver,
      forms: BpmnFormsResolver,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Forms',
    },
  },
  {
    path: 'org/:orgUuid/connectors',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    component: ProductWrapperPage,
    resolve: {
      connectors: ConnectorsResolverService,
      org: OrgResolverService,
      orgs: OrgsResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Connectors',
    },
  },
  {
    path: 'org/:orgUuid/clusters',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'pathParamsChange',
    component: ProductWrapperPage,
    resolve: {
      clusters: ClustersResolverService,
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      models: BpmnModelsResolver,
      forms: BpmnFormsResolver,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Clusters',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: ClusterDetailsPage,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
      auditLogs: ClusterAuditLogResolverService,
    },
    data: {
      activeLabel: 'Overview',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/clients',
    redirectTo: 'org/:orgUuid/cluster/:clusterId/api',
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/api',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: ClusterDetailsPage,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
      auditLogs: ClusterAuditLogResolverService,
    },
    data: {
      activeLabel: 'API',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/applications',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: ClusterDetailsPage,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
      auditLogs: ClusterAuditLogResolverService,
    },
    data: {
      activeLabel: 'Applications',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/metrics',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: ClusterDetailsPage,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
      auditLogs: ClusterAuditLogResolverService,
    },
    data: {
      activeLabel: 'Metrics',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/alerts',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: ClusterDetailsPage,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
      auditLogs: ClusterAuditLogResolverService,
    },
    data: {
      activeLabel: 'Alerts',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/activity',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: ClusterDetailsPage,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
      auditLogs: ClusterAuditLogResolverService,
    },
    data: {
      activeLabel: 'Activity',
    },
  },
  {
    path: 'org/:orgUuid/cluster/:clusterId/:tasklistOrOperate',
    pathMatch: 'prefix',
    runGuardsAndResolvers: 'always',
    component: TasklistOperateRedirectComponent,
    resolve: {
      clusterDetails: ClusterDetailsResolverService,
    },
    children: [
      {
        path: '**',
        component: TasklistOperateRedirectComponent,
      },
    ],
  },
  {
    path: 'org/:orgUuid/checkout/state/:countryCode',
    pathMatch: 'prefix',
    runGuardsAndResolvers: 'always',
    component: CheckoutSuccessPage,
  },
  {
    path: 'user-settings',
    pathMatch: 'prefix',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    component: UserSettingsPage,
  },
  {
    path: 'external',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, DebugGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    component: ExternalViewPage,
  },
  {
    path: 'org/:orgUuid/management',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: OrganizationManagementPage,
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      setReservationsData: ReservationsResolverService,
      valueMetrics: ValueMetricsResolverService,
      valueMetricsHistory: ValueMetricsHistoryResolverService,
      valueMetricsEnterprise: ValueMetricsEnterpriseResolverService,
      valueMetricsTrial: ValueMetricsTrialResolverService,
      auditLogs: AuditLogResolverService,
      visibilities: ViewVisibilitiesResolverService,
      clusters: ClustersResolverService,
    },
    data: {
      activeLabel: 'Overview',
    },
  },
  {
    path: 'org/:orgUuid/management/users',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: OrganizationManagementPage,
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      setReservationsData: ReservationsResolverService,
      valueMetrics: ValueMetricsResolverService,
      valueMetricsHistory: ValueMetricsHistoryResolverService,
      valueMetricsEnterprise: ValueMetricsEnterpriseResolverService,
      valueMetricsTrial: ValueMetricsTrialResolverService,
      auditLogs: AuditLogResolverService,
      visibilities: ViewVisibilitiesResolverService,
      clusters: ClustersResolverService,
    },
    data: {
      activeLabel: 'Users',
    },
  },
  {
    path: 'org/:orgUuid/management/activity',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: OrganizationManagementPage,
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      setReservationsData: ReservationsResolverService,
      valueMetrics: ValueMetricsResolverService,
      valueMetricsHistory: ValueMetricsHistoryResolverService,
      valueMetricsEnterprise: ValueMetricsEnterpriseResolverService,
      valueMetricsTrial: ValueMetricsTrialResolverService,
      auditLogs: AuditLogResolverService,
      visibilities: ViewVisibilitiesResolverService,
      clusters: ClustersResolverService,
    },
    data: {
      activeLabel: 'Activity',
    },
  },
  {
    path: 'org/:orgUuid/management/billing',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: OrganizationManagementPage,
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      setReservationsData: ReservationsResolverService,
      valueMetrics: ValueMetricsResolverService,
      valueMetricsHistory: ValueMetricsHistoryResolverService,
      valueMetricsEnterprise: ValueMetricsEnterpriseResolverService,
      valueMetricsTrial: ValueMetricsTrialResolverService,
      auditLogs: AuditLogResolverService,
      visibilities: ViewVisibilitiesResolverService,
      clusters: ClustersResolverService,
    },
    data: {
      activeLabel: 'Billing',
    },
  },
  {
    path: 'org/:orgUuid/management/usage',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: OrganizationManagementPage,
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      setReservationsData: ReservationsResolverService,
      valueMetrics: ValueMetricsResolverService,
      valueMetricsHistory: ValueMetricsHistoryResolverService,
      valueMetricsEnterprise: ValueMetricsEnterpriseResolverService,
      valueMetricsTrial: ValueMetricsTrialResolverService,
      auditLogs: AuditLogResolverService,
      visibilities: ViewVisibilitiesResolverService,
      clusters: ClustersResolverService,
    },
    data: {
      activeLabel: 'Usage',
    },
  },
  {
    path: 'org/:orgUuid/management/api',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: OrganizationManagementPage,
    resolve: {
      orgs: OrgsResolverService,
      org: OrgResolverService,
      setReservationsData: ReservationsResolverService,
      valueMetrics: ValueMetricsResolverService,
      valueMetricsHistory: ValueMetricsHistoryResolverService,
      valueMetricsEnterprise: ValueMetricsEnterpriseResolverService,
      valueMetricsTrial: ValueMetricsTrialResolverService,
      auditLogs: AuditLogResolverService,
      visibilities: ViewVisibilitiesResolverService,
      clusters: ClustersResolverService,
    },
    data: {
      activeLabel: 'Cloud Management API',
    },
  },
  {
    path: 'org/:orgUuid/modeler/:modelUuid',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: BpmnModelerComponent,
    resolve: {
      org: OrgResolverService,
      model: BpmnModelResolver,
      models: BpmnModelsResolver,
      clusters: ClustersResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      visibilities: ViewVisibilitiesResolverService,
      forms: BpmnFormsResolver,
    },
  },
  {
    path: 'org/:orgUuid/modeler',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: BpmnModelerComponent,
    resolve: {
      org: OrgResolverService,
      models: BpmnModelsResolver,
      clusters: ClustersResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      visibilities: ViewVisibilitiesResolverService,
      forms: BpmnFormsResolver,
    },
  },
  {
    path: 'org/:orgUuid/modeler/templates/:templateId',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: BpmnModelerComponent,
    resolve: {
      org: OrgResolverService,
      models: BpmnModelsResolver,
      clusters: ClustersResolverService,
      channels: ChannelsResolverService,
      planTypes: ClusterPlanActiveTypesResolverService,
      visibilities: ViewVisibilitiesResolverService,
      forms: BpmnFormsResolver,
    },
  },
  {
    path: 'org/:orgUuid/formdesigner/:formUuid',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: BpmnFormDesignerComponent,
    resolve: {
      org: OrgResolverService,
      form: BpmnFormResolver,
      forms: BpmnFormsResolver,
      visibilities: ViewVisibilitiesResolverService,
    },
  },
  {
    path: 'org/:orgUuid/formdesigner',
    pathMatch: 'prefix',
    canActivate: [AuthGuard, OrgGuard],
    runGuardsAndResolvers: 'always',
    component: BpmnFormDesignerComponent,
    resolve: {
      org: OrgResolverService,
      forms: BpmnFormsResolver,
      visibilities: ViewVisibilitiesResolverService,
    },
  },
  {
    path: 'admin/corpse',
    canActivate: [AuthGuard, AdminCorpseGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      corpseData: CorpseResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Database Corpses',
    },
    component: CorpseManagementPage,
  },
  {
    path: 'admin/corpse/kubernetes',
    canActivate: [AuthGuard, AdminCorpseGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      corpseData: CorpseResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Kubernetes Corpses',
    },
    component: CorpseManagementPage,
  },
  {
    path: 'admin/corpse/auth0',
    canActivate: [AuthGuard, AdminCorpseGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      corpseData: CorpseResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Auth0 Corpses',
    },
    component: CorpseManagementPage,
  },
  {
    path: 'admin/corpse/zeebeclient',
    canActivate: [AuthGuard, AdminCorpseGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      corpseData: CorpseResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Zeebe Client Corpses',
    },
    component: CorpseManagementPage,
  },
  {
    path: 'admin/versions',
    canActivate: [AuthGuard, AdminVersionGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channels: ChannelsResolverService,
      generations: GenerationsResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Channels',
    },
    component: VersionManagementPage,
  },
  {
    path: 'admin/versions/generations',
    canActivate: [AuthGuard, AdminVersionGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channels: ChannelsResolverService,
      generations: GenerationsResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Generations',
    },
    component: VersionManagementPage,
  },
  {
    path: 'admin/versions/generations/:generationId',
    canActivate: [AuthGuard, AdminVersionGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      generationData: GenerationResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    component: GenerationDetailsPage,
  },
  {
    path: 'admin/versions/channels/:channelId',
    canActivate: [AuthGuard, AdminVersionGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      channel: ChannelResolverService,
      generations: GenerationsResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    component: ChannelDetailsComponent,
  },
  {
    path: 'admin/cluster-plan',
    canActivate: [AuthGuard, AdminClusterPlanGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      clusterPlans: ClusterPlansResolverService,
      clusterPlanTypes: ClusterPlanTypesResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Cluster Plan Types',
    },
    component: ClusterPlanManagementPage,
  },
  {
    path: 'admin/cluster-plan/plans',
    canActivate: [AuthGuard, AdminClusterPlanGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      clusterPlans: ClusterPlansResolverService,
      clusterPlanTypes: ClusterPlanTypesResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    data: {
      activeLabel: 'Cluster Plans',
    },
    component: ClusterPlanManagementPage,
  },
  {
    path: 'admin/cluster-plan/plans/:clusterPlanId',
    canActivate: [AuthGuard, AdminClusterPlanGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      clusterPlanData: ClusterPlanDetailsResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    component: ClusterPlanDetailsPage,
  },
  {
    path: 'admin/k8sContext',
    canActivate: [AuthGuard, AdminMultiClusterGuard],
    runGuardsAndResolvers: 'always',
    resolve: {
      org: OrgResolverService,
      orgs: OrgsResolverService,
      k8sContexts: K8sContextResolverService,
      visibilities: ViewVisibilitiesResolverService,
    },
    component: KubernetesContextPage,
  },
  {
    path: 'error',
    component: ErrorPage,
  },
  {
    path: '**',
    redirectTo: '/error/404',
  },
]

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorNotificationInterceptorService,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: NoCacheRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
