import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import jwtDecode from 'jwt-decode'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import {
  Features,
  userFeaturesFromPermissions,
} from '../../../../commons/Features'

const authTokenSettingsKey = `https://camunda.com/settings`
const authTokenOrgsKey = `https://camunda.com/orgs`

export interface AccessToken {
  activated: Features
  hasAcknowledgedPrivacyNotice: boolean
  hasAcknowledgedCookieBanner: boolean
  hasOptedIntoPerformanceCookies: boolean
  hasDismissedGseOverlay: boolean
  hasDismissedCardZeebeModeler: boolean
  hasDismissedCardGithubSamples: boolean
  events: string[]
  orgs: AccessTokenOrg[]
}

export interface AccessTokenOrg {
  id: string
  roles: string[]
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  public accessToken$: Observable<AccessToken>

  constructor(private authService: AuthService) {
    this.accessToken$ = this.authService.getAccessTokenSilently().pipe(
      map((token) => {
        const decoded = jwtDecode<any>(token)

        let activated: Features = userFeaturesFromPermissions([])
        let hasAcknowledgedPrivacyNotice: boolean = false
        let hasAcknowledgedCookieBanner: boolean = false
        let hasOptedIntoPerformanceCookies: boolean = false
        let hasDismissedGseOverlay: boolean = false
        let hasDismissedCardZeebeModeler: boolean = false
        let hasDismissedCardGithubSamples: boolean = false
        let events: string[] = []

        let orgs: AccessTokenOrg[] = []

        if (decoded) {
          if ((decoded as any).permissions) {
            activated = userFeaturesFromPermissions(
              (decoded as any).permissions,
            )
          }

          if (decoded[authTokenOrgsKey]) {
            orgs = decoded[authTokenOrgsKey]
          }

          if (decoded[authTokenSettingsKey]) {
            events = decoded[authTokenSettingsKey].events ?? []
            hasAcknowledgedPrivacyNotice =
              decoded[authTokenSettingsKey].hasAcknowledgedPrivacyNotice

            hasAcknowledgedCookieBanner =
              decoded[authTokenSettingsKey].hasAcknowledgedCookieBanner

            hasOptedIntoPerformanceCookies =
              decoded[authTokenSettingsKey].hasOptedIntoPerformanceCookies

            hasDismissedGseOverlay =
              decoded[authTokenSettingsKey].hasDismissedGseOverlay

            hasDismissedCardZeebeModeler =
              decoded[authTokenSettingsKey].hasDismissedCardZeebeModeler

            hasDismissedCardGithubSamples =
              decoded[authTokenSettingsKey].hasDismissedCardGithubSamples
          }
        }

        return {
          activated,
          hasAcknowledgedPrivacyNotice,
          hasAcknowledgedCookieBanner,
          hasOptedIntoPerformanceCookies,
          hasDismissedGseOverlay,
          hasDismissedCardZeebeModeler,
          hasDismissedCardGithubSamples,
          events,
          orgs,
        }
      }),
      shareReplay(1),
    )
  }
}
