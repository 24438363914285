import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { BehaviorSubject } from 'rxjs'
import { filter } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { NavbarService } from './navbar.service'
import { NotificationService } from './notification.service'

@Injectable({
  providedIn: 'root',
})
export class SseService {
  public clusterNotifications$: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject<string | undefined>(undefined)
  public bpmnModelNotifications$: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject<string | undefined>(undefined)
  public organizationNotifications$: BehaviorSubject<
    string | undefined
  > = new BehaviorSubject<string | undefined>(undefined)

  public notificationService: NotificationService
  private clusterEventSource: EventSourcePolyfill
  constructor(
    private authService: AuthService,
    private navbarService: NavbarService,
    notificationService: NotificationService,
  ) {
    this.notificationService = notificationService
    this.navbarService.currentOrg$
      .pipe(filter((org) => org !== undefined))
      .subscribe((currentOrg) => {
        this.startClusterNotificationStream(currentOrg.uuid)
      })
  }

  public startClusterNotificationStream(currentOrg: string) {
    this.authService.getAccessTokenSilently().subscribe((accessToken) => {
      try {
        if (this.clusterEventSource) {
          this.clusterEventSource.close()
        }
        this.clusterEventSource = new EventSourcePolyfill(
          `${environment.backendUrl}/api/events/cluster`,
          {
            headers: {
              authorization: `Bearer ${accessToken}`,
            },
          },
        )

        this.clusterEventSource.onmessage = (event) => {
          console.log(event.data)
          const data = JSON.parse(event.data)
          if (data && data.org && data.org === currentOrg) {
            this.clusterNotifications$.next(currentOrg)
            if (data.show && data.headline) {
              this.notificationService.enqueueNotification(data)
            }
          }
        }
        this.clusterEventSource.onerror = (_error) => {
          console.log(`SSE ONERROR`)
        }
      } catch (_error) {
        console.log(`SSE ERROR`)
      }
    })
  }
}
