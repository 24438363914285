import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { BpmnFormDto } from '../../../../commons/BpmnForm.dto'
import { ApiService } from '../services/api.service'
import { NavbarService } from '../services/navbar.service'

@Injectable({
  providedIn: 'root',
})
export class BpmnFormResolver implements Resolve<BpmnFormDto> {
  constructor(
    private apiService: ApiService,
    private navbarService: NavbarService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<BpmnFormDto> {
    return this.apiService.getBpmnForm(
      route.paramMap.get('orgUuid'),
      route.paramMap.get('formUuid'),
    )
  }
}
