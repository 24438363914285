import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ChannelResolverService } from '../../../resolver/channel.resolver'
import { ChannelDto } from '../../../../../../commons/Channel.dto'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { ChannelService } from '../../../services/channel.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { NotificationService } from '../../../services/notification.service'
import { UserService } from '../../../services/user.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { ChannelEditComponent } from '../channel-edit/channel-edit.component'

@Component({
  selector: 'channel-details',
  templateUrl: './channel-details.component.html',
  styleUrls: ['./channel-details.component.scss'],
})
export class ChannelDetailsComponent implements OnInit {
  public channel: ChannelDto & { createdByName: string }
  public generations: (GenerationDto & { createdByName: string })[] = []

  @ViewChild(ChannelEditComponent)
  private channelEdit: ChannelEditComponent

  constructor(
    public vvs: ViewVisibilitiesService,
    private channelService: ChannelService,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
    private channelResolverService: ChannelResolverService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
  public async ngOnInit() {
    this.channel = this.route.snapshot.data.channel
    this.generations = this.route.snapshot.data.generations

    await this.addCreatedByField()
  }

  async addCreatedByField() {
    await Promise.all(
      [
        this.channel.createdBy,
        ...this.generations.map((generation) => generation.createdBy),
      ]
        .filter((creator, i, a) => a.indexOf(creator) === i)
        .filter(
          (creator) =>
            creator && !['migration-external', 'console-web'].includes(creator),
        )
        .map((creator) => {
          return new Promise<void>((resolve, _reject) => {
            this.userService
              .getUserById(creator)
              .toPromise()
              .then(
                (richData) => {
                  if (this.channel.createdBy === creator) {
                    this.channel.createdByName = `${richData.name} (${richData.email})`
                  }

                  this.generations
                    .filter((generation) => generation.createdBy === creator)
                    .forEach((generation) => {
                      generation.createdByName = `${richData.name} (${richData.email})`
                    })
                  resolve()
                },
                () => {
                  // maybe the current user is just not allowed to fetch userdetails, thats fine
                  resolve()
                },
              )
          })
        }),
    )
  }

  public deleteChannel() {
    this.channelEdit.deleteChannel(this.channel).then((updated) => {
      if (updated) {
        this.goBack()
      }
    })
  }

  public openEditChannelDialog() {
    this.channelEdit.openEditChannelDialog(this.channel).then((updated) => {
      if (updated) {
        this.channelResolverService
          .resolve(this.route.snapshot)
          .subscribe((newChannel: any) => {
            newChannel.createdByName = this.channel.createdByName
            this.channel = newChannel
          })
      }
    })
  }
  public goBack() {
    this.router.navigate(['/admin/versions/'])
  }
  public routeToGeneration(generationUuid: string) {
    this.router.navigate([`/admin/versions/generations/${generationUuid}`])
  }
}
