import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot } from '@angular/router'
import { ClusterPlanTypeDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { ClusterPlanApiService } from '../services/clusterPlan.api.service'

@Injectable({
  providedIn: 'root',
})
export class ClusterPlanActiveTypesResolverService
  implements Resolve<ClusterPlanTypeDto[]> {
  constructor(private apiService: ClusterPlanApiService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<ClusterPlanTypeDto[]> {
    return this.apiService.getActive(route.paramMap.get('orgUuid'))
  }
}
