import { Injectable } from '@angular/core'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  constructor(private apiService: ApiService) {}

  public create(
    name: string,
    description: string,
    isDefault: boolean,
    defaultGenerationId: string,
    allowedGenerationIds: string[],
  ) {
    return this.apiService
      .createChannel(
        name,
        description,
        isDefault,
        defaultGenerationId,
        allowedGenerationIds,
      )
      .toPromise()
  }

  public delete(channelId: string) {
    return this.apiService.deleteChannel(channelId).toPromise()
  }

  public update(
    channelId: string,
    name: string,
    description: string,
    isDefault: boolean,
    defaultGenerationId: string,
    allowedGenerationIds: string[],
  ) {
    return this.apiService
      .updateChannel(
        channelId,
        name,
        description,
        isDefault,
        defaultGenerationId,
        allowedGenerationIds,
      )
      .toPromise()
  }
}
