import { DatePipe } from '@angular/common'
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { first } from 'rxjs/operators'
import {
  AlertSubscriptionDto,
  AlertSubscriptionReportType,
  AlertSubscriptionType,
} from '../../../../../../commons/AlertSubscription.dto'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { ApiService } from '../../../services/api.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { NotificationService } from '../../../services/notification.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'cluster-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class ClusterAlertsComponent implements OnInit {
  @Input()
  public cluster: ClusterDto

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('createModal', { read: ElementRef })
  public createModal: ElementRef<CmModal>

  @ViewChild('detailModal', { read: ElementRef })
  public detailModal: ElementRef<CmModal>

  public alertUpdate: boolean = false

  public alertTypes = [
    { label: 'Process Incident', value: AlertSubscriptionType.ProcessAlert },
    // { label: 'Cluster Alerts', value: AlertSubscriptionType.ClusterAlert },
    // { label: 'All Alerts', value: AlertSubscriptionType.AllAlerts },
  ]
  public reportTypes = [
    { label: 'Email', value: AlertSubscriptionReportType.Email },
    { label: 'Webhook', value: AlertSubscriptionReportType.Webhook },
  ]

  public selectedAlertType: string = AlertSubscriptionType.AllAlerts
  public selectedReportType: string = AlertSubscriptionReportType.Email

  public alertSubscriptions: AlertSubscriptionDto[]
  public currentSubscription: AlertSubscriptionDto

  public webhookUrl: string = ''
  public webhookTextfieldPrefix = { type: 'text', value: 'https://' }

  public alertListCreateHandler: () => void
  public alertListColumns = [
    {
      name: '',
      width: '35px',
    },
    { name: 'Type', width: '1fr' },
    { name: 'Recepient', width: 'minmax(150px, 1fr)' },
    { name: 'Created At', width: 'minmax(150px, 1fr)' },
    { name: 'Created By', width: 'minmax(150px, 1fr)' },
    {
      name: '',
      width: '20px',
      overrideCSS: {
        justifyContent: 'end',
      },
    },
  ]
  public alertListEntities = []
  public alertListLoading: boolean = true
  public userEmail: string = ''
  public userName: string = ''
  public updateButtonDirty = false

  constructor(
    private apiService: ApiService,
    public vvs: ViewVisibilitiesService,
    private ngZone: NgZone,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
  ) {
    this.authService.user$.subscribe((user) => {
      if (user.email) {
        this.userEmail = user.email
      }

      if (user.name) {
        this.userName = user.name
      }
    })
  }

  public async ngOnInit() {
    if (this.alertTypes.length === 1) {
      this.selectedAlertType = this.alertTypes[0].value
    }

    this.alertListCreateHandler = () => this.openCreateSubscriptionModal()
    await this.updateSubscriptions()
  }

  private async updateSubscriptions() {
    this.alertSubscriptions = await this.apiService
      .getAlerts(this.cluster.ownerId, this.cluster.uuid)
      .pipe(first())
      .toPromise()

    this.alertListEntities = this.alertSubscriptions.map((alert) => {
      return {
        data: [
          {
            type: 'image',
            src: this.ICON,
          },
          {
            type: 'text',
            content: this.convertAlertType(alert.type),
          },
          { type: 'text', content: alert.userIdOrWebhook },
          {
            type: 'text',
            content: alert.created
              ? this.datePipe.transform(alert.created, 'medium')
              : '',
          },
          { type: 'text', content: alert.createdBy },
          this.getConextMenu(alert),
        ],
      }
    })

    this.alertListLoading = false
  }

  public openCreateSubscriptionModal() {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_CREATE_MODAL_OPEN,
    )

    this.alertUpdate = false

    if (this.alertTypes.length === 1) {
      this.selectedAlertType = this.alertTypes[0].value
    } else {
      this.selectedAlertType = AlertSubscriptionType.AllAlerts
    }

    if (this.hasEmailSubscription()) {
      this.selectedReportType = AlertSubscriptionReportType.Webhook
    } else {
      this.selectedReportType = AlertSubscriptionReportType.Email
    }

    this.ngZone.run(() => {
      this.createModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_CONFIRM,
          )
          this.alertListLoading = true

          try {
            if (this.selectedReportType === AlertSubscriptionReportType.Email) {
              await this.apiService
                .createAlert(
                  this.cluster.ownerId,
                  this.cluster.uuid,
                  this.selectedAlertType,
                  this.selectedReportType,
                )
                .pipe(first())
                .toPromise()
            } else {
              await this.apiService
                .createAlert(
                  this.cluster.ownerId,
                  this.cluster.uuid,
                  this.selectedAlertType,
                  this.selectedReportType,
                  `https://${result.formData.webhookURL}`,
                )
                .pipe(first())
                .toPromise()
            }

            await this.updateSubscriptions()
          } catch (err) {
            this.handleAlertError(err)
          }
        } else {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_CANCEL,
          )
        }
      })
    })
  }

  public openEditSubscriptionModal(subscription: AlertSubscriptionDto) {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_OPEN,
    )
    this.alertUpdate = true
    this.updateButtonDirty = false
    this.selectedAlertType = subscription.type
    this.currentSubscription = subscription
    this.selectedReportType = subscription.report

    if (this.selectedReportType === AlertSubscriptionReportType.Webhook) {
      this.webhookUrl = subscription.userIdOrWebhook
    } else {
      this.webhookUrl = ''
    }

    this.ngZone.run(() => {
      this.createModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_CONFIRM,
          )
          this.alertListLoading = true

          try {
            if (this.selectedReportType === AlertSubscriptionReportType.Email) {
              await this.apiService
                .updateAlert(
                  this.cluster.ownerId,
                  this.cluster.uuid,
                  subscription.uuid!,
                  this.selectedAlertType,
                  this.selectedReportType,
                )
                .pipe(first())
                .toPromise()
            } else {
              await this.apiService
                .updateAlert(
                  this.cluster.ownerId,
                  this.cluster.uuid,
                  subscription.uuid!,
                  this.selectedAlertType,
                  this.selectedReportType,
                  `https://${result.formData.webhookURL}`,
                )
                .pipe(first())
                .toPromise()
            }
            await this.updateSubscriptions()
          } catch (err) {
            this.handleAlertError(err)
          }
        } else {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_CANCEL,
          )
        }
      })
    })
  }

  public openDetailModal(subscription: AlertSubscriptionDto) {
    this.currentSubscription = subscription

    this.ngZone.run(() => {
      this.detailModal.nativeElement.open()
    })
  }

  private handleAlertError(err) {
    if (err.status === 400) {
      if (err.error && err.error.includes('Webhook')) {
        this.notificationService.enqueueNotification({
          headline: 'Webhook URL not okay',
          appearance: 'error',
          description: err.error,
          showCreationTime: false,
        })
      }
    }

    this.alertListLoading = false
  }

  public openDeleteSubscriptionModal(subscription: AlertSubscriptionDto) {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_OPEN,
    )
    this.currentSubscription = subscription

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_CONFIRM,
          )
          this.alertListLoading = true
          await this.apiService
            .deleteAlert(
              this.cluster.ownerId,
              this.cluster.uuid,
              subscription.uuid,
            )
            .pipe(first())
            .toPromise()
          this.updateSubscriptions()
        } else {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_CANCEL,
          )
        }
      })
    })
  }

  public hasEmailSubscription(updateSubscription?: AlertSubscriptionDto) {
    if (!updateSubscription) {
      if (!this.alertSubscriptions) {
        return false
      }

      return (
        this.alertSubscriptions.find((sub) => {
          return (
            sub.report === AlertSubscriptionReportType.Email &&
            (sub.userIdOrWebhook === this.userEmail ||
              sub.userIdOrWebhook === this.userName)
          )
        }) !== undefined
      )
    }

    if (!this.alertSubscriptions) {
      return false
    }

    return (
      this.alertSubscriptions.find((sub) => {
        return (
          sub.report === AlertSubscriptionReportType.Email &&
          (sub.userIdOrWebhook === this.userEmail ||
            sub.userIdOrWebhook === this.userName) &&
          updateSubscription.uuid !== sub.uuid
        )
      }) !== undefined
    )
  }

  // eslint-disable-next-line class-methods-use-this
  public convertAlertType(type: AlertSubscriptionType) {
    switch (type) {
      case AlertSubscriptionType.ProcessAlert:
        return 'Process Incident'
      case AlertSubscriptionType.ClusterAlert:
        return 'Cluster Incident'
      default:
        return type
    }
  }

  private getConextMenu(alert: AlertSubscriptionDto) {
    const contextMenu: { type: 'contextMenu'; options: any[] } = {
      type: 'contextMenu',
      options: [
        {
          options: [
            {
              label: 'Details',
              handler: () => {
                this.openDetailModal(alert)
              },
            },
          ],
        },
      ],
    }

    if (this.vvs.visibilities.alerts.update.visible) {
      contextMenu.options[0].options.push({
        label: 'Update',
        handler: () => {
          this.openEditSubscriptionModal(alert)
        },
        isDisabled: this.vvs.visibilities.alerts.update.disabled,
      })
    }

    if (this.vvs.visibilities.alerts.delete.visible) {
      contextMenu.options.push({
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            handler: () => {
              this.openDeleteSubscriptionModal(alert)
            },
            isDisabled: this.vvs.visibilities.alerts.delete.disabled,
          },
        ],
      })
    }
    return contextMenu
  }

  private ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
      <defs>
        <path id="alert-a" d="M13.5020579,17.3574468 C13.2176662,18.7536735 11.979867,19.8085106 10.5003125,19.8085106 C9.02060839,19.8085106 7.7828092,18.7536735 7.49856714,17.3574468 L7.49856714,17.3574468 Z M10.5003125,0.2 C10.9512101,0.2 11.3172839,0.565176187 11.3172839,1.0169714 L11.3172839,1.0169714 L11.3172839,1.89946809 C14.0837068,2.29815491 16.2194116,4.67815826 16.2194116,7.55319149 L16.2194116,7.55319149 L16.2194116,9.83101727 C16.2194116,11.4479056 16.9277726,12.9741356 18.1631782,14.0183511 C18.4882613,14.2969083 18.6704754,14.6923038 18.6704754,15.1105884 C18.6704754,15.8991356 18.0291356,16.5404754 17.2407381,16.5404754 L17.2407381,16.5404754 L3.75988699,16.5404754 C2.97133977,16.5404754 2.33,15.8991356 2.33,15.1105884 C2.33,14.6923038 2.51221411,14.2969083 2.83011637,14.0247839 C4.07270282,12.9741356 4.78106383,11.4479056 4.78106383,9.83101727 L4.78106383,9.83101727 L4.78106383,7.55319149 C4.78106383,4.67815826 6.91602062,2.29815491 9.68319149,1.89946809 L9.68319149,1.89946809 L9.68319149,1.0169714 C9.68319149,0.565176187 10.0492653,0.2 10.5003125,0.2 Z"/>
      </defs>
      <g fill="none" fill-rule="evenodd" transform="translate(-.5)">
        <mask id="alert-b" fill="#fff">
          <use xlink:href="#alert-a"/>
        </mask>
        <use fill="#000" fill-rule="nonzero" xlink:href="#alert-a"/>
        <g mask="url(#alert-b)">
          <rect width="20" height="20" fill="#62626e" transform="translate(.5)"/>
        </g>
      </g>
    </svg>
    `),
    ),
  )}`
}
