<div>
  <!-- Leave me, im keeping the grid stable -->
  <cm-banner *ngIf="!clientsAvailable">
    No Client configured. To deploy processes and interact with them, create
    Client Credentials under
    <cm-button
      appearance="link"
      (cmPress)="switchTab($event, 'api')"
      label="API"
    ></cm-button>
    tab.</cm-banner
  >
</div>

<!-- APPLICATIONS -->

<div class="scrollContents">
  <cm-datalist headline="Applications" label-width="150px">
    <!-- ZEEBE -->
    <cm-datalist-item
      label="Zeebe"
      *ngIf="vss.visibilities.cluster.details.zeebe.status.visible"
    >
      <span
        *ngIf="cluster.status.zeebeStatus === 'Healthy'"
        class="cluster-state ready"
      ></span>
      <span
        *ngIf="
          cluster.status &&
          cluster.status.zeebeStatus &&
          cluster.status.ready !== 'Creating' &&
          cluster.status.zeebeStatus !== 'Healthy'
        "
        class="cluster-state not-ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.zeebeStatus !== 'Healthy'
        "
        class="cluster-state creating"
      ></span>
      <cm-text
        *ngIf="
          !(
            cluster.status.ready === 'Creating' &&
            cluster.status.zeebeStatus !== 'Healthy'
          )
        "
        >{{ cluster.status.zeebeStatus }}</cm-text
      >
      <cm-text
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.zeebeStatus !== 'Healthy'
        "
        >{{ cluster.status.ready }}</cm-text
      >
    </cm-datalist-item>

    <!-- OPERATE -->
    <cm-datalist-item
      label="Operate"
      *ngIf="vss.visibilities.cluster.details.operate.launch.visible"
    >
      <span
        *ngIf="cluster.status.operateStatus === 'Healthy'"
        class="cluster-state ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready &&
          cluster.status.operateStatus &&
          cluster.status.ready !== 'Creating' &&
          cluster.status.operateStatus !== 'Healthy'
        "
        class="cluster-state not-ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.operateStatus !== 'Healthy'
        "
        class="cluster-state creating"
      ></span>
      <cm-text
        *ngIf="
          !(
            cluster.status.ready === 'Creating' &&
            cluster.status.operateStatus !== 'Healthy'
          )
        "
        >{{ cluster.status.operateStatus }}</cm-text
      >
      <cm-text
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.operateStatus !== 'Healthy'
        "
        >{{ cluster.status.ready }}</cm-text
      >
      <span
        label="Process Instances"
        *ngIf="
          vss.visibilities.cluster.details.operate.launch &&
          cluster.status.operateStatus === 'Healthy'
        "
      >
        <cm-button
          class="launch-link"
          appearance="link"
          (cmPress)="openOperate()"
          label="Launch"
        ></cm-button>
      </span>
    </cm-datalist-item>

    <!-- TASKLIST -->
    <cm-datalist-item
      label="Tasklist"
      *ngIf="vss.visibilities.cluster.details.tasklist.launch.visible"
    >
      <span
        *ngIf="cluster.status.tasklistStatus === 'Healthy'"
        class="cluster-state ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready &&
          cluster.status.tasklistStatus &&
          cluster.status.ready !== 'Creating' &&
          cluster.status.tasklistStatus !== 'Healthy'
        "
        class="cluster-state not-ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.tasklistStatus !== 'Healthy'
        "
        class="cluster-state creating"
      ></span>
      <cm-text
        *ngIf="
          !(
            cluster.status.ready === 'Creating' &&
            cluster.status.tasklistStatus !== 'Healthy'
          )
        "
        >{{ cluster.status.tasklistStatus }}</cm-text
      >
      <cm-text
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.tasklistStatus !== 'Healthy'
        "
        >{{ cluster.status.ready }}</cm-text
      >
      <span
        label="Human Tasks"
        *ngIf="
          vss.visibilities.cluster.details.tasklist.launch &&
          cluster.status.tasklistStatus === 'Healthy'
        "
      >
        <cm-button
          class="launch-link"
          appearance="link"
          (cmPress)="openTasklist()"
          label="Launch"
        ></cm-button>
      </span>
    </cm-datalist-item>
    <!-- OPTIMIZE -->
    <cm-datalist-item
      label="Optimize"
      *ngIf="
        cluster.hasOptimize &&
        vss.visibilities.cluster.details.optimize.launch.visible
      "
    >
      <span
        *ngIf="cluster.status.optimizeStatus === 'Healthy'"
        class="cluster-state ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready &&
          cluster.status.optimizeStatus &&
          cluster.status.ready !== 'Creating' &&
          cluster.status.optimizeStatus !== 'Healthy'
        "
        class="cluster-state not-ready"
      ></span>
      <span
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.optimizeStatus !== 'Healthy'
        "
        class="cluster-state creating"
      ></span>
      <cm-text
        *ngIf="
          !(
            cluster.status.ready === 'Creating' &&
            cluster.status.optimizeStatus !== 'Healthy'
          )
        "
        >{{ cluster.status.optimizeStatus }}</cm-text
      >
      <cm-text
        *ngIf="
          cluster.status.ready === 'Creating' &&
          cluster.status.optimizeStatus !== 'Healthy'
        "
        >{{ cluster.status.ready }}</cm-text
      >
      <span
        label="Optimize"
        *ngIf="
          vss.visibilities.cluster.details.optimize.launch &&
          cluster.status.optimizeStatus === 'Healthy'
        "
      >
        <cm-button
          class="launch-link"
          appearance="link"
          (cmPress)="openOptimize()"
          label="Launch"
        ></cm-button>
      </span>
    </cm-datalist-item>
  </cm-datalist>

  <!-- CLUSTER DETAILS-->

  <cm-datalist
    class="cluster-details"
    headline="Cluster Details"
    label-width="150px"
  >
    <cm-datalist-item label="Cluster Id">
      <div class="copy-row">
        <cm-text>{{ cluster.uuid }}</cm-text>
        <copy-to-clipboard
          [value]="cluster.uuid"
          [eventComponent]="MIXPANEL_COMPONENT"
          eventElement="clusterId"
        >
        </copy-to-clipboard>
      </div>
    </cm-datalist-item>
    <cm-datalist-item label="Created at">
      <cm-text> {{ cluster.created | date: 'medium' }} </cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Region">
      <div class="copy-row">
        <cm-text
          >{{ cluster.k8sContext.name }}
          <span *ngIf="airport">({{ airport }})</span>
        </cm-text>
        <copy-to-clipboard
          [value]="airport"
          [eventComponent]="MIXPANEL_COMPONENT"
          eventElement="airport"
        >
        </copy-to-clipboard>
      </div>
    </cm-datalist-item>

    <cm-datalist-item
      label="Type"
      *ngIf="vss.visibilities.cluster.details.clusterPlanType.visible"
    >
      <cm-text
        >{{ cluster.planType.name
        }}<span style="margin-left: 0.3em" *ngIf="cluster.plan"
          >({{ cluster.plan.name }})</span
        ></cm-text
      >
    </cm-datalist-item>
    <cm-datalist-item label="Generation">
      <cm-text>{{ cluster.generation.name }}</cm-text>
      <div class="generation-update-container">
        <cm-button
          *ngIf="
            vss.visibilities.cluster.details.generation.update.visible &&
            upgradableGenerations.length &&
            !updating
          "
          appearance="link"
          (cmPress)="openUpgradeModal()"
          label="Update"
          [disabled]="cluster.status.ready !== 'Healthy'"
        >
        </cm-button>
        <span *ngIf="updating">
          <cm-loader size="small"></cm-loader><cm-text>Updating</cm-text>
        </span>
      </div>
    </cm-datalist-item>
  </cm-datalist>
</div>

<!-- UPDATE GENERATION MODAL -->

<cm-modal
  #upgradeModal
  headline="Update Cluster &quot;{{ cluster.name }}&quot;"
  cancelLabel="Cancel"
  confirmLabel="Update"
  confirmAppearance="danger"
>
  <cm-text>
    Update Cluster <b>{{ cluster.name }}</b> to new Generation
    <b>{{
      upgradableGenerations.length > 0 ? upgradableGenerations[0].name : ''
    }}</b
    >?
  </cm-text>
</cm-modal>
