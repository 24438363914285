import { Component, Input, OnInit } from '@angular/core'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { OrgService } from '../../../services/org.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'organization-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OrganizationOverviewComponent implements OnInit {
  @Input() public org: OrganizationDto

  public orgOwner

  constructor(
    private orgService: OrgService,
    public vss: ViewVisibilitiesService,
  ) {}

  public ngOnInit() {
    this.orgService.getOwner(this.org.uuid).subscribe((owner) => {
      this.orgOwner = owner
    })
  }
}
