<cm-entity-list
  headline="Forms"
  createButtonLabel="Create New Form"
  [hideCreateButton]="!vvs.visibilities.forms.create.visible"
  [createHandler]="formListCreateHandler"
  [columns]="formListColumns"
  [entities]="formListEntities"
  [loading]="formListLoading"
  [groupOptions]="groupOptions"
  [defaultSorting]="{ columnIndex: 3, method: 'descending' }"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList"><h3>No Forms created</h3></div>
  </div>
</cm-entity-list>

<div *ngIf="!vvs.visibilities.modeler.read.visible">
  Sorry, you are not allowed to see the Forms
</div>

<cm-modal
  #multiDeleteModal
  headline="Delete Forms"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete these Forms?
    <ul>
      <li *ngFor="let formToBeDeleted of formsToBeDeleted">
        <b>{{ formToBeDeleted.name }}</b>
      </li>
    </ul>
  </cm-text>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Form"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete
    <cm-text appearance="emphasis">{{
      formToBeDeleted ? formToBeDeleted.name : ''
    }}</cm-text>
    ?
  </cm-text>
</cm-modal>

<cm-modal
  #renameModal
  headline="Rename Form"
  cancelLabel="Cancel"
  confirmLabel="Rename"
>
  <cm-form>
    <cm-textfield
      label="Current Name"
      disabled
      [value]="formToBeRenamed ? formToBeRenamed.name : ''"
    ></cm-textfield>
    <cm-textfield
      label="New Name"
      maxlength="50"
      formName="newName"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>
  </cm-form>
</cm-modal>
