import { Injectable } from '@angular/core'
import { interval, Observable } from 'rxjs'
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { ApiService } from './api.service'
import { NavbarService } from './navbar.service'
import { NotificationService } from './notification.service'

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  private creatingClustersInternal: string[] = []
  private previousClusterStates: Map<string, string> = new Map()
  private ignoredClusters: Array<string> = []
  private clusterStatusInternal$: Observable<ClusterDto[]>
  private reload = true
  private isOneClusterUnhealthyness: boolean = false

  static statusIsEqual(
    statusA: ClusterDto['status'],
    statusB: ClusterDto['status'],
  ) {
    return (
      statusA &&
      statusB &&
      statusA.elasticsearchStatus === statusB.elasticsearchStatus &&
      statusA.operateStatus === statusB.operateStatus &&
      statusA.operateUrl === statusB.operateUrl &&
      statusA.optimizeStatus === statusB.optimizeStatus &&
      statusA.optimizeUrl === statusB.optimizeUrl &&
      statusA.ready === statusB.ready &&
      statusA.tasklistStatus === statusB.tasklistStatus &&
      statusA.tasklistUrl === statusB.tasklistUrl &&
      statusA.zeebeStatus === statusB.zeebeStatus &&
      statusA.zeebeUrl === statusB.zeebeUrl
    )
  }

  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private navbarService: NavbarService,
  ) {
    let previousClusters: Array<ClusterDto> = []
    this.clusterStatusInternal$ = interval(3000).pipe(
      filter((_) => {
        return this.reload
      }),
      switchMap((_) => {
        return this.navbarService.currentOrg$
      }),
      switchMap((org) => {
        return this.apiService.listClusters(org.uuid)
      }),
      filter((clusters) => {
        if (previousClusters.length) {
          let clustersAreChanged = false

          if (previousClusters.length !== clusters.length) {
            previousClusters = JSON.parse(JSON.stringify(clusters))
            return true
          }

          for (let cluster of clusters) {
            let previousCluster = previousClusters.find(
              (previousCluster) => previousCluster.uuid === cluster.uuid,
            )

            if (!previousCluster) {
              clustersAreChanged = true
              break
            }

            if (
              !ClusterService.statusIsEqual(
                previousCluster.status,
                cluster.status,
              )
            ) {
              clustersAreChanged = true
              break
            }
          }

          previousClusters = JSON.parse(JSON.stringify(clusters))
          return clustersAreChanged
        } else {
          previousClusters = JSON.parse(JSON.stringify(clusters))
          return true
        }
      }),
      map((clusters) => {
        return clusters.map((cluster) => {
          if (cluster.status && cluster.status.ready === 'Healthy') {
            // send Analytics Event: cluster created
            if (this.creatingClustersInternal.includes(cluster.uuid)) {
              this.apiService
                .sendClusterStatus(cluster.ownerId, cluster.uuid, 'created')
                .subscribe()
            }

            this.creatingClustersInternal = this.creatingClustersInternal.filter(
              (creatingCluster) => creatingCluster !== cluster.uuid,
            )

            if (this.previousClusterStates.get(cluster.uuid) === 'Creating') {
              this.notificationService.enqueueNotification({
                appearance: 'success',
                headline: `Cluster "${cluster.name}" created`,
              })
            }
          }

          let clusterWithStatus = ClusterService.addCreatingStatus(cluster)

          this.previousClusterStates.set(
            clusterWithStatus.uuid,
            clusterWithStatus.status.ready,
          )

          return clusterWithStatus
        })
      }),
      tap((_clusters) => {
        let healthy = true
        for (let [uuid, health] of this.previousClusterStates) {
          if (!this.ignoredClusters.includes(uuid)) {
            healthy = healthy && health === 'Healthy'
          }
        }

        if (healthy) {
          this.reload = false
        }
      }),
      shareReplay(1),
    )

    this.clusterStatusInternal$.subscribe((clusters) => {
      if (clusters) {
        this.isOneClusterUnhealthyness = false

        for (const cluster of clusters) {
          if (cluster.status.ready !== 'Healthy') {
            this.isOneClusterUnhealthyness = true
          }
        }
      }
    })
  }

  public isClusterInCreation() {
    return this.creatingClustersInternal.length > 0
  }

  public getClustersStatus(): Observable<ClusterDto[]> {
    this.reload = true
    return this.clusterStatusInternal$
  }

  public isOneClusterUnhealthy() {
    return this.isOneClusterUnhealthyness
  }

  public setOneClusterUnhealthy(state: boolean) {
    this.isOneClusterUnhealthyness = state
  }

  public create(
    orgUuid: string,
    name: string,
    planTypeId: string,
    channelId: string,
    generationId: string,
    k8sContextId: string,
  ) {
    return this.apiService
      .createCluster(
        name,
        planTypeId,
        orgUuid,
        channelId,
        generationId,
        k8sContextId,
      )
      .pipe(
        tap((res) => {
          this.creatingClustersInternal.push(res.clusterId)
          this.reload = true
          this.apiService
            .sendClusterStatus(orgUuid, res.clusterId, 'creating')
            .subscribe()
        }),
      )
  }

  public rename(currentOrgId: string, clusterUuid: string, newName: string) {
    return this.apiService.renameCluster(currentOrgId, clusterUuid, newName)
  }

  public moveToOrg(currentOrgId: string, id: string, newOrgUuid: string) {
    this.apiService.moveCluster(currentOrgId, id, newOrgUuid).subscribe()
  }

  public boost(clusterUuid: string) {
    return this.apiService.boost(clusterUuid)
  }

  public boostAvailable(clusterUuid: string) {
    return this.apiService.checkForAvailableBoost(clusterUuid)
  }

  public delete(currentOrgId: string, id: string) {
    this.ignoredClusters.push(id)
    return this.apiService.deleteCluster(currentOrgId, id)
  }

  public getCluster(
    currentOrgId: string,
    uuid: string,
  ): Observable<ClusterDto> {
    return this.apiService.clusterDetails(currentOrgId, uuid).pipe(
      map((clusterDto) => {
        return ClusterService.addCreatingStatus(clusterDto)
      }),
    )
  }

  public getClusters(orgUuid: string): Observable<ClusterDto[]> {
    return this.apiService.listClusters(orgUuid).pipe(
      map((clusterDtos) => {
        return clusterDtos.map((clusterDto) => {
          return ClusterService.addCreatingStatus(clusterDto)
        })
      }),
    )
  }

  public static addCreatingStatus(cluster: ClusterDto) {
    const CLUSTER_CREATE_TIMEOUT = 5 * 60 * 1000

    if (!cluster.status) {
      cluster.status = {
        ready: 'Unhealthy',
        operateStatus: 'Unhealthy',
        zeebeStatus: 'Unhealthy',
        tasklistStatus: 'Unhealthy',
        optimizeStatus: 'Unhealthy',
      }
    }

    let now = new Date().getTime()
    let clusterCreattionDate = new Date(cluster.created).getTime()

    if (
      cluster.status.ready === 'Unhealthy' &&
      now - clusterCreattionDate < CLUSTER_CREATE_TIMEOUT
    ) {
      cluster.status.ready = 'Creating'
    }

    if (
      cluster.status.zeebeStatus &&
      cluster.status.zeebeStatus === 'Unhealthy' &&
      now - clusterCreattionDate < CLUSTER_CREATE_TIMEOUT
    ) {
      cluster.status.zeebeStatus = 'Creating'
    }

    if (
      cluster.status.operateStatus &&
      cluster.status.operateStatus === 'Unhealthy' &&
      now - clusterCreattionDate < CLUSTER_CREATE_TIMEOUT
    ) {
      cluster.status.operateStatus = 'Creating'
    }

    if (
      cluster.status.tasklistStatus &&
      cluster.status.tasklistStatus === 'Unhealthy' &&
      now - clusterCreattionDate < CLUSTER_CREATE_TIMEOUT
    ) {
      cluster.status.operateStatus = 'Creating'
    }

    if (
      cluster.hasOptimize &&
      cluster.status.optimizeStatus &&
      cluster.status.optimizeStatus === 'Unhealthy' &&
      now - clusterCreattionDate < CLUSTER_CREATE_TIMEOUT
    ) {
      cluster.status.optimizeStatus = 'Creating'
    }

    return cluster
  }
}
