import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { BehaviorSubject } from 'rxjs'
import { AuditLogResolverService } from '../../resolver/audit.resolver'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'

@Component({
  selector: 'organization-management-page',
  templateUrl: './organization-management.page.html',
  styleUrls: ['./organization-management.page.scss'],
})
export class OrganizationManagementPage implements OnInit, AfterViewInit {
  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef

  public org: OrganizationDto

  public tabChangedTo: BehaviorSubject<string>

  constructor(
    private route: ActivatedRoute,
    public vss: ViewVisibilitiesService,
    private mixpanel: MixpanelService,
    public auditResolverService: AuditLogResolverService,
  ) {
    this.tabChangedTo = new BehaviorSubject('')
  }

  public ngOnInit() {
    this.org = this.route.snapshot.data.org
  }

  public ngAfterViewInit() {
    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel

    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        const settingsIndex = location.href.split('/').indexOf('management')
        const url = location.href
          .split('/')
          .slice(0, settingsIndex + 1)
          .join('/')
        switch (cmPage.activeLabel) {
          case 'Users':
            history.replaceState({}, '', `${url}/users`)
            this.tabChangedTo.next('users')
            this.mixpanel.track(
              FrontendAnalyticEvents.ORGANIZATION_SETTINGS_TAB_CHANGED_USERS,
            )
            break
          case 'Activity':
            history.replaceState({}, '', `${url}/activity`)
            this.tabChangedTo.next('activity')
            this.mixpanel.track(
              FrontendAnalyticEvents.ORGANIZATION_SETTINGS_TAB_CHANGED_ACTIVITIY,
            )
            break
          case 'Billing':
            history.replaceState({}, '', `${url}/billing`)
            this.tabChangedTo.next('billing')
            this.mixpanel.track(
              FrontendAnalyticEvents.ORGANIZATION_SETTINGS_TAB_CHANGED_BILLING,
            )
            break
          case 'Usage':
            history.replaceState({}, '', `${url}/usage`)
            this.tabChangedTo.next('usage')
            this.mixpanel.track(
              FrontendAnalyticEvents.ORGANIZATION_SETTINGS_TAB_CHANGED_USAGE,
            )
            break
          case 'Cloud Management API':
            history.replaceState({}, '', `${url}/api`)
            this.tabChangedTo.next('api')
            this.mixpanel.track(
              FrontendAnalyticEvents.ORGANIZATION_SETTINGS_TAB_CHANGED_API,
            )
            break
          default:
            history.replaceState({}, '', `${url}`)
            this.tabChangedTo.next('')
            this.mixpanel.track(
              FrontendAnalyticEvents.ORGANIZATION_SETTINGS_TAB_CHANGED_OVERVIEW,
            )
            break
        }
      })
    })
  }
}
