<cm-entity-list
  [enableCreateButton]="!vvs.visibilities.ipWhitelist.create.disabled"
  headline="IP Whitelist"
  createButtonLabel="Create"
  [createHandler]="whitelistCreateHandler"
  [columns]="whitelistColumns"
  [entities]="whitelistEntities"
  [loading]="whitelistLoading"
  style="margin-bottom: 15px"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList">
      <h3>No IPs configured</h3>

      <cm-text>
        Want to limit access to the cluster from specific networks?
        <cm-button
          appearance="link"
          (cmPress)="openCreateWhitelistModal()"
          label="Add new IP(s)"
          [disabled]="vvs.visibilities.ipWhitelist.create.disabled"
        ></cm-button>
        which can access the cluster and check the
        <cm-link
          href="https://docs.camunda.io/docs/product-manuals/cloud-console/manage-clusters/manage-whitelist"
          label="IP Whitelist"
        ></cm-link>
        documentation how to limit access.
      </cm-text>
    </div>
  </div>
</cm-entity-list>

<cm-text *ngIf="cluster.ipWhitelist && cluster.ipWhitelist.length > 0">
  IP Whitelist can be used to limit access to the cluster. See
  <cm-link
    href="https://docs.camunda.io/docs/product-manuals/cloud-console/manage-clusters/manage-whitelist"
    label="IP Whitelist"
  ></cm-link>
  documentation to configure allowed networks.
</cm-text>

<cm-modal
  #createModal
  headline="Add new IP(s)"
  cancelLabel="Cancel"
  confirmLabel="Add"
  [confirmDisabled]="!validInput"
  position="top"
>
  <cm-form>
    <div style="display: grid; gap: 8px">
      <cm-textfield
        label="IP(s)"
        helperText="Comma separated list of CIDRs, e.g. 10.0.0.0/24,172.10.0.1"
        [value]="ips"
        formName="whitelistIp"
        [validation]="ipValidation"
        validationStyle="delay"
        required
      ></cm-textfield>
    </div>

    <div style="display: grid; gap: 8px">
      <cm-textfield
        label="Description"
        showRequired="true"
        formName="whitelistDescription"
        maxLength="50"
        [fieldSuffix]="{ type: 'maxlength' }"
      ></cm-textfield>
    </div>
  </cm-form>
</cm-modal>

<cm-modal
  #updateModal
  headline="Update IP(s)"
  cancelLabel="Cancel"
  confirmLabel="Update"
  [confirmDisabled]="!validInput"
  position="top"
>
  <cm-form>
    <div style="display: grid; gap: 8px">
      <cm-textfield
        label="IP(s)"
        helperText="Comma separated list of CIDRs, e.g. 10.0.0.0/24,172.10.0.1"
        [value]="ips"
        formName="whitelistIp"
        [validation]="ipValidation"
        validationStyle="delay"
        required
      ></cm-textfield>
    </div>

    <div style="display: grid; gap: 8px">
      <cm-textfield
        label="Description"
        showRequired="true"
        formName="whitelistDescription"
        [value]="description"
        maxLength="50"
        validationStyle="live"
        [fieldSuffix]="{ type: 'maxlength' }"
      ></cm-textfield>
    </div>
  </cm-form>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete IP(s)"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete the IP(s)
    <b>{{ ips }}</b>
    -<b>"{{ description }}"</b>?
  </cm-text>
</cm-modal>
