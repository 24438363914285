import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  ClusterPlanDto,
  ClusterPlanTypeDto,
} from '@camunda-cloud/cloud-node-libs'
import { of } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { UserService } from '../../../services/user.service'

@Component({
  selector: 'cluster-plan-management-page',
  templateUrl: './cluster-plan-management.page.html',
  styleUrls: ['./cluster-plan-management.page.scss'],
})
export class ClusterPlanManagementPage implements OnInit {
  public clusterPlans: (ClusterPlanDto & { createdByName: string })[] = []
  public clusterPlanTypes: (ClusterPlanTypeDto & {
    createdByName: string
  })[] = []

  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
  ) {}

  public ngOnInit() {
    this.clusterPlans = this.route.snapshot.data.clusterPlans
    this.clusterPlanTypes = this.route.snapshot.data.clusterPlanTypes

    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel

    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        if (cmPage.activeLabel === 'Cluster Plans') {
          history.replaceState({}, '', `${location.href}/plans`)
        } else {
          history.replaceState(
            {},
            '',
            location.href.slice(0, location.href.length - '/plans'.length),
          )
        }
      })
    })
    ;[
      ...this.clusterPlanTypes.map((cpt) => cpt.createdBy),
      ...this.clusterPlans.map((clusterplan) => clusterplan.createdBy),
    ]
      .filter((creator, i, a) => a.indexOf(creator) === i)
      .filter(
        (creator) =>
          creator && !['migration-external', 'console-web'].includes(creator),
      )
      .forEach((creator) => {
        this.userService
          .getUserById(creator)
          .pipe(
            catchError((_error) =>
              of({
                name: `unknown user: ${creator}`,
                email: ``,
              }),
            ),
          )
          .subscribe(
            (richData) => {
              this.clusterPlanTypes
                .filter((cpt) => cpt.createdBy === creator)
                .forEach((cpt) => {
                  cpt.createdByName = `${richData.name} (${richData.email})`
                })
              this.clusterPlans
                .filter((clusterplan) => clusterplan.createdBy === creator)
                .forEach((clusterplan) => {
                  clusterplan.createdByName = `${richData.name} (${richData.email})`
                })
            },
            (_error) => {
              // maybe the current user is just not allowed to fetch userdetails, thats fine
            },
          )
      })
  }
}
