<cm-page #cmPage *ngIf="vss.visibilities.admin.k8scontext.read.visible">
  <cm-page-tab label="Kubernetes Context">
    <cm-entity-list
      [enableCreateButton]="vss.visibilities.admin.k8scontext.create.visible"
      headline="Kubernetes Context"
      createButtonLabel="Create New Kubernetes Config"
      [createHandler]="createHandler"
      [columns]="columns"
      [entities]="entities"
    >
    </cm-entity-list>
  </cm-page-tab>
</cm-page>

<cm-modal
  #addModal
  headLine="Create new Kubernetes-Context"
  cancelLabel="Cancel"
  confirmLabel="Create new Context"
  position="top"
>
  <cm-form>
    <cm-textfield
      label="Name"
      formName="name"
      required
      showRequired
    ></cm-textfield>
    <cm-textfield
      label="Region"
      formName="region"
      required
      showRequired
    ></cm-textfield>
    <cm-textfield
      label="Zone"
      formName="zone"
      required
      showRequired
    ></cm-textfield>
    <cm-textfield
      label="Thanos Endpoint URL"
      formName="thanosEndpoint"
      required
      showRequired
    ></cm-textfield>
    <cm-textfield
      label="Kubernetes Identifier"
      formName="contextIdentifier"
      required
      showRequired
    ></cm-textfield>
    <cm-textfield
      label="KubeConfig File Path"
      formName="kubeConfigFilePath"
      showRequired
      [validation]="noWhitespaceValidator"
      validationStyle="delay"
    ></cm-textfield>
  </cm-form>
</cm-modal>

<cm-modal
  #updateModal
  headLine="Update existing Kubernetes-Context"
  cancelLabel="Cancel"
  confirmLabel="Update Context"
>
  <cm-text>
    You are about to update the existing Kubernetes-Context with UUID:
    <br />
    <cm-text appearance="emphasis"> {{ selectedContext?.uuid }} </cm-text>
  </cm-text>
  <br />
  <br />
  <cm-form>
    <cm-textfield
      label="Name"
      formName="name"
      required
      showRequired
      [value]="selectedContext?.name"
    ></cm-textfield>
    <cm-textfield
      label="Region"
      formName="region"
      required
      showRequired
      [value]="selectedContext?.region"
    ></cm-textfield>
    <cm-textfield
      label="Zone"
      formName="zone"
      required
      showRequired
      [value]="selectedContext?.zone"
    ></cm-textfield>
    <cm-textfield
      label="Thanos Endpoint URL"
      formName="thanosEndpoint"
      required
      showRequired
      [value]="selectedContext?.thanosEndpoint"
    ></cm-textfield>
    <cm-textfield
      label="Kubernetes Identifier"
      formName="contextIdentifier"
      required
      showRequired
      [value]="selectedContext?.k8sIdentifier"
    ></cm-textfield>
    <cm-textfield
      label="KubeConfig File Path"
      formName="kubeConfigFilePath"
      showRequired
      [value]="selectedContext?.kubeConfigFilePath"
      [validation]="noWhitespaceValidator"
      validationStyle="delay"
    ></cm-textfield>
  </cm-form>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Kubernetes-Context"
  cancelLabel="Cancel"
  confirmLabel="Delete this Context"
  confirmAppearance="danger"
  paddings="horizontal"
>
  <cm-text *ngIf="selectedContext">
    <br />
    <cm-text>Are you sure you want to delete this context?</cm-text>
    <br />
    <br />
    <cm-datalist label-width="160px">
      <cm-datalist-item label="Name"
        >{{selectedContext?.name}}</cm-datalist-item
      >
      <cm-datalist-item label="UUID"
        >{{selectedContext?.uuid}}</cm-datalist-item
      >
      <cm-datalist-item label="Kubernetes Identifier"
        >{{selectedContext?.k8sIdentifier}}</cm-datalist-item
      >
      <cm-datalist-item label="KubeConfig Path"
        >{{selectedContext?.kubeConfigFilePath}}</cm-datalist-item
      >
      <cm-datalist-item label="Thanos Endpoint"
        >{{selectedContext?.thanosEndpoint}}</cm-datalist-item
      >
    </cm-datalist>
  </cm-text>
</cm-modal>
