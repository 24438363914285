import { Injectable } from '@angular/core'
import { CanActivate, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { FeatureService } from './feature.service'

@Injectable({
  providedIn: 'root',
})
export class AdminMultiClusterGuard implements CanActivate {
  constructor(private featureService: FeatureService) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.featureService.accessToken$.pipe(
      map((accessToken) => {
        return accessToken.activated.multiCluster.admin
      }),
      first(),
    )
  }
}
