<cm-page>
  <h1 slot="header">Channel details for {{ channel.name }}</h1>
  <cm-page-tab label="Channel details">
    <div class="scrollContents">
      <div class="sections">
        <div class="section">
          <h2>Channel details</h2>

          <cm-datalist labelWidth="175px">
            <cm-datalist-item label="UUID">
              {{ channel.uuid }}
            </cm-datalist-item>
            <cm-datalist-item label="description">
              {{ channel.description }}
            </cm-datalist-item>
            <cm-datalist-item label="Created on">
              {{ channel.created | date: 'MMMM d, y' }} at
              {{ channel.created | date: 'hh:mm' }}
            </cm-datalist-item>
            <cm-datalist-item label="Created by">
              {{ channel.createdByName }}
            </cm-datalist-item>

            <cm-datalist-item label="Default Generation">
              <cm-button
                appearance="link"
                label="{{ channel.defaultGeneration.name }}"
                (cmPress)="routeToGeneration(channel.defaultGeneration.uuid)"
              ></cm-button>
              {{ ' ' }}
              <cm-text appearance="helperText" color="subtle">
                ({{ channel.defaultGeneration.versions.zeebe }},
                {{ channel.defaultGeneration.versions.operate }},
                {{
                  channel.defaultGeneration.versions.optimize
                    ? channel.defaultGeneration.versions.optimize
                    : 'no optimize'
                }}
                )
              </cm-text>
            </cm-datalist-item>

            <cm-datalist-item
              label="Allowed Generations"
              *ngIf="
                channel.allowedGenerations &&
                channel.allowedGenerations.length > 0
              "
            >
              {{ channel.allowedGenerations.length }}
            </cm-datalist-item>
          </cm-datalist>
        </div>
      </div>
    </div>
    <div class="buttons">
      <cm-button label="Back" (cmPress)="goBack()"></cm-button>

      <cm-button
        *ngIf="vvs.visibilities.admin.clusterplantypes.update.visible"
        label="Edit"
        appearance="primary"
        (cmPress)="openEditChannelDialog()"
      ></cm-button>

      <cm-button
        appearance="danger"
        *ngIf="vvs.visibilities.admin.channels.delete.visible"
        [disabled]="channel.isDefault"
        (cmPress)="deleteChannel()"
        label="Delete"
      ></cm-button>
    </div>
  </cm-page-tab>
</cm-page>

<channel-edit [generations]="generations"></channel-edit>
