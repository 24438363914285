import 'zone.js/dist/zone-error' // Included with Angular CLI.
export const environment = {
  production: false,
  integration: false,
  accountsUrl: 'https://accounts.cloud.dev.ultrawombat.com',
  auth0OrgNamespace: 'https://camunda.com/organizations',
  camundaCloudAudience: 'cloud.dev.ultrawombat.com',
  backendUrl: 'https://console.cloud.dev.ultrawombat.com',
  auditBackendUrl: 'https://audit.cloud.dev.ultrawombat.com',
  webIdeUrl: 'https://webide.cloud.dev.ultrawombat.com',
  zeebeUrl: 'zeebe.dev.ultrawombat.com',
  tasklistUrl: 'tasklist.dev.ultrawombat.com',
  mixpanelToken: '1104cabe553c23b7e67d56b1976437aa',
  includedProcessInstances: 100,
  pricePerAdditionalProcessInstance: '8€',
  includedTaskUsers: 10,
  pricePerAdditionalTaskUser: '250€',
  eventBroker: 'https://events.cloud.dev.ultrawombat.com',
}
