import { Component } from '@angular/core'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'

@Component({
  selector: 'no-organization-page',
  templateUrl: './no-organization.page.html',
})
export class NoOrganizationPage {
  constructor(private mixpanel: MixpanelService) {
    this.mixpanel.track(FrontendAnalyticEvents.NO_ORG_PAGE_SEEN)
  }
}
