<h1>Cluster Plan details for {{ clusterPlan.name }}</h1>
<div class="scrollContents">
  <p>
    Created by {{ creator }} on {{ clusterPlan.created | date: 'MMMM d, y' }} at
    {{ clusterPlan.created | date: 'hh:mm' }}
  </p>
  <p *ngFor="let p of clusterPlan.flatPlan | keyvalue">
    <b>{{ p.key.split('.').join(' ') }}</b
    >:{{ p.value }}
  </p>
  <h2>Migratable from</h2>
  <mat-selection-list [(ngModel)]="selectedUpgradeablePlans">
    <mat-list-option
      *ngFor="let o of otherClusterPlans"
      [value]="o.uuid"
      [disabled]="vvs.visibilities.admin.clusterplans.create.disabled"
    >
      <h4 mat-line>
        {{ o.name }} <small>({{ o.uuid }})</small>
      </h4>
    </mat-list-option>
  </mat-selection-list>

  <h2>Running clusters for Cluster Plan</h2>
  <div>
    <p>Real Cluster Count: {{ clusterPlan.clusterCount }}</p>
    <cm-button
      (cmPress)="checkIfDeleteAllClusters()"
      *ngIf="
        vvs.visibilities.admin.clusterplans.cluster.delete.visible &&
        !deleteAllClustersQuestion
      "
      label="Delete all clusters"
      appearance="danger"
    ></cm-button>
    <div
      *ngIf="
        vvs.visibilities.admin.clusterplans.cluster.delete.visible &&
        deleteAllClustersQuestion
      "
    >
      <mat-form-field>
        <input
          matInput
          placeholder="Enter plan name"
          [formControl]="planName"
        />
      </mat-form-field>
      <cm-button
        (cmPress)="deleteAllClusters(clusterPlan.uuid)"
        *ngIf="
          vvs.visibilities.admin.clusterplans.cluster.delete.visible &&
          planName.valid &&
          planName.value === clusterPlan.name
        "
        label="Delete all clusters"
        appearance="danger"
      ></cm-button>
      <cm-button
        (cmPress)="cancelDeleteAllClusters()"
        label="Cancel"
      ></cm-button>
    </div>
  </div>

  <div
    *ngIf="
      clusterPlan.migratableFrom.length > 0 &&
      vvs.visibilities.admin.clusterplans.cluster.migrate.visible
    "
  >
    <h2>Migrate all Clusters from selected Plan to this Plan</h2>
    <div *ngFor="let g of clusterPlan.migratableFromPlan">
      <div *ngIf="g.clusterCount > 0">
        {{ g.name }} - {{ g.uuid }} - ClusterCount: {{ g.clusterCount }}
        <cm-button
          (cmPress)="migratePlan(g.uuid, clusterPlan.uuid)"
          label="Migrate all Clusters"
        ></cm-button>
      </div>
      <div>
        <div *ngFor="let d of g.diff | keyvalue">{{ d.key }}:{{ d.value }}</div>
      </div>
    </div>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Migrate a specific Cluster to this Plan
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card *ngFor="let c of upgradableClusters">
        <p>
          Cluster: <b>{{ c.name }}</b> uuid: <b>{{ c.uuid }}</b>
        </p>
        <p>
          Org: <b>{{ c.organization ? c.organization.name : 'no org' }}</b> -
          Plan: <b>{{ c.plan.name }}</b> ({{ c.plan.uuid }})
        </p>
        <cm-button
          style="float: right; margin-top: -70px"
          appearance="secondary"
          size="small"
          label="Migrate Cluster"
          (cmPress)="migrateClusters([c])"
        ></cm-button>
      </mat-card>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Migrate Clusters of a specific Sales Plan
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card *ngFor="let sp of clustersBySalesPlans">
        <p>
          SalesPlan: <b>{{ sp.salesPlan.name }}</b>
        </p>
        <p>
          Clusters: <b>{{ sp.clusters.length }}</b>
        </p>
        <cm-button
          style="float: right; margin-top: -70px"
          appearance="secondary"
          size="small"
          label="Migrate Clusters"
          (cmPress)="migrateClusters(sp.clusters)"
        ></cm-button>
      </mat-card>
    </mat-expansion-panel>
  </div>
</div>

<div class="buttons">
  <cm-button (cmPress)="goBack()" label="Back"></cm-button>
  <cm-button
    *ngIf="vvs.visibilities.admin.clusterplans.update.visible"
    (cmPress)="save(clusterPlan.uuid)"
    label="Save"
    appearance="primary"
  ></cm-button>
  <cm-button
    (cmPress)="downloadJson(clusterPlan)"
    label="Download JSON"
  ></cm-button>
  <cm-button
    *ngIf="vvs.visibilities.admin.clusterplans.delete.visible"
    (cmPress)="delete(clusterPlan.uuid)"
    label="Delete"
    [disabled]="clusterPlan.clusterCount > 0"
    appearance="danger"
  ></cm-button>
</div>
