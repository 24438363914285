import { Component, Input } from '@angular/core'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import {
  getOperateUrl,
  getOptimizeUrl,
  getTasklistUrl,
} from '../../taskist-operate-redirect/tasklist-operate-redirect.component'

@Component({
  selector: 'cluster-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
})
export class ClusterApplicationsComponent {
  @Input() public clusterData: ClusterDto

  constructor(
    private mixpanel: MixpanelService,
    public vvs: ViewVisibilitiesService,
  ) {}

  public openOperate() {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_APPLICATION_OPEN_OPERATE)
    window.open(getOperateUrl(this.clusterData), '_blank')
  }

  public openTasklist() {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_APPLICATION_OPEN_TASKLIST,
    )
    window.open(getTasklistUrl(this.clusterData), '_blank')
  }

  public openOperateDocs() {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_APPLICATION_OPEN_OPERATE_DOCS,
    )
    window.open(
      `https://docs.camunda.io/docs/product-manuals/operate/index`,
      '_blank',
    )
  }

  public openTasklistDocs() {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_APPLICATION_OPEN_TASKLIST_DOCS,
    )
    window.open(
      'https://docs.camunda.io/docs/product-manuals/tasklist/introduction',
      '_blank',
    )
  }

  public openOptimize() {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_APPLICATION_OPEN_OPTIMIZE,
    )
    window.open(getOptimizeUrl(this.clusterData), '_blank')
  }

  public openOptimizeDocs() {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_APPLICATION_OPEN_OPTIMIZE_DOCS,
    )
    window.open(
      'https://docs.camunda.org/optimize/latest/user-guide/',
      '_blank',
    )
  }
}
