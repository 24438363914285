import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { OrganizationRole, SalesPlanType } from '@camunda-cloud/cloud-node-libs'
import mixpanel, { Dict } from 'mixpanel-browser'
import { environment } from '../../environments/environment'
import { NavbarService } from './navbar.service'

export interface MixpanelEvent {
  view: Page | Component
  element: Element | string | null
  action: Action | string
  description?: string
}
export enum Page {
  clusterList = 'clusterList',
  diagramList = 'diagramlist',
  formList = 'formList',
  orgOverview = 'orgOverview',
  clusterOverview = 'clusterOverview',
  clusterOverviewMetrics = 'clusterOverviewMetrics',
  clusterOverviewAlerts = 'clusterOverviewAlerts',
  clusterOverviewIpWhitelists = 'clusterOverviewIpWhitelists',
  orgSettings = 'orgSettings',
  userSettings = 'userSettings',
  noOrg = 'noOrg',
  orgNotInitialized = 'orgNotInitialized',
  terms = 'terms',
}

export enum Component {
  createCluster = 'createCluster',
  apiClients = 'apiClients',
  clusterClients = 'clusterClients',
  clusterDetails = 'clusterDetails',
  listBpmnModels = 'listBpmnModels',
  listBpmnForms = 'listBpmnForms',
  listConnectors = 'listConnectors',
  modeler = 'modeler',
  clusterApps = 'clusterApps',
  orgMembers = 'orgMembers',
  navBar = 'navBar',
  reservations = 'reservations',
  backendError = 'backendError',
  gse = 'gse',
  formDesigner = 'formDesigner',
  billing = 'billing',
  home = 'home',
  templatePicker = 'templatePicker',
}
export enum Element {
  tabChange = 'tabChange',
  create = 'create',
  createClusterButton = 'createClusterButton',
  deleteClusterButton = 'deleteClusterButton',
  updateClusterButton = 'updateClusterButton',
  renameClusterButton = 'renameClusterButton',
  createCluster = 'createCluster',
  save = 'save',
  update = 'update',
  delete = 'delete',
  details = 'details',
  connectionInfo = 'connectionInfo',
  deploy = 'deploy',
  execute = 'execute',
  start = 'start',
  import = 'import',
  operate = 'operate',
  tasklist = 'tasklist',
  optimize = 'optimize',
  docs = 'docs',
  operateDocs = 'operateDocs',
  tasklistDocs = 'tasklistDocs',
  optimizeDocs = 'optimizeDocs',
  jira = 'jira',
  forum = 'forum',
  enterpriseSupport = 'enterpriseSupport',
  privacy = 'privacy',
  slack = 'slack',
  logout = 'logout',
  add = 'add',
  invite = 'invite',
  changeRole = 'changeRole',
  newRoleDefinitionModal = 'newRoleDefinitionModal',
  assignNewOwner = 'assignNewOwner',
  requestPaid = 'requestPaid',
  edit = 'edit',
  confirm = 'confirm',
  curlStatement = 'curlStatement',
  envStatement = 'envStatement',
  openModeler = 'openModeler',
  model = 'model',
  orgSettings = 'orgSettings',
  orgSwitch = 'orgSwitch',
  startInstance = 'startInstance',
  timeframeSwitcher = 'timeframeSwitcher',
  createModal = 'createModal',
  deleteModal = 'deleteModal',
  updateModal = 'updateModal',
  propertyPanel = 'propertyPanel',
  tokenSimulator = 'tokenSimulator',
  githubGse = 'githubGse',
  checkoutCc = 'checkoutCc',
  checkoutCcChargebee = 'checkoutCcChargebee',
  chargebeePortal = 'chargebeePortal',
  contactUsProfessional = 'contactUsProfessional',
  contactUsEnterprise = 'contactUsEnterprise',
  welcome = 'welcome',
  productWalkthrough = 'productWalkthrough',
  welcomeModalProductWalkthrough = 'welcomeModalProductWalkthrough',
  welcomeModalUseTemplate = 'welcomeModalUseTemplate',
  startBlank = 'startBlank',
  uploadDiagram = 'uploadDiagram',
  diagramsList = 'diagramsList',
  formsList = 'formsList',
  clusterList = 'clusterList',
  docsWhatIsCamundaCloud = 'docsWhatIsCamundaCloud',
  docsModelYourFirstProcess = 'docsModelYourFirstProcess',
  docsClients = 'docsClients',
  docsMessageCorrelation = 'docsMessageCorrelation',
}
export enum Action {
  click = 'click',
  open = 'open',
  confirm = 'confirm',
  cancel = 'cancel',
  error = 'error',
  close = 'close',
  copyToClipBoard = 'copyToClipBoard',
  download = 'download',
  start = 'start',
  stop = 'stop',
  toggle = 'toggle',
}
export function MixpanelEventFn(
  view: Page | Component,
  element: Element | string | null,
  action: Action | string,
  description?: string,
): MixpanelEvent {
  return { view, element, action, description }
}
export class FrontendAnalyticEvents {
  // PAGES
  static LIST_CLUSTERS_CREATE_CLUSTER_DIALOG = MixpanelEventFn(
    Page.clusterList,
    Element.createClusterButton,
    Action.click,
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_OVERVIEW = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'overview',
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_API = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'api',
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_APPS = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'apps',
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_ALERTS = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'alerts',
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_METRICS = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'metrics',
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_ACTIVITY = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'activity',
  )
  static CLUSTER_OVERVIEW_TAB_CHANGED_IP_WHITELIST = MixpanelEventFn(
    Page.clusterOverview,
    Element.tabChange,
    'ip-whitelist',
  )
  static CLUSTER_OVERVIEW_DELETE_CLUSTER_DIALOG = MixpanelEventFn(
    Page.clusterOverview,
    Element.deleteClusterButton,
    Action.open,
  )
  static CLUSTER_OVERVIEW_DELETE_CLUSTER_DIALOG_CONFIRMED = MixpanelEventFn(
    Page.clusterOverview,
    Element.deleteClusterButton,
    Action.confirm,
  )
  static CLUSTER_OVERVIEW_DELETE_CLUSTER_DIALOG_CANCELED = MixpanelEventFn(
    Page.clusterOverview,
    Element.deleteClusterButton,
    Action.cancel,
  )

  static CLUSTER_OVERVIEW_METRICS_SWITCH_TIMEFRAME = MixpanelEventFn(
    Page.clusterOverviewMetrics,
    Element.timeframeSwitcher,
    Action.click,
  )

  static CLUSTER_OVERVIEW_ALERTS_CREATE_MODAL_OPEN = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.createModal,
    Action.open,
  )

  static CLUSTER_OVERVIEW_ALERTS_CREATE_MODAL_CANCEL = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.createModal,
    Action.cancel,
  )

  static CLUSTER_OVERVIEW_ALERTS_CREATE_MODAL_CONFIRM = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.createModal,
    Action.confirm,
  )

  static CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_OPEN = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.updateModal,
    Action.open,
  )

  static CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_CANCEL = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.updateModal,
    Action.cancel,
  )

  static CLUSTER_OVERVIEW_ALERTS_UPDATE_MODAL_CONFIRM = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.updateModal,
    Action.confirm,
  )

  static CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_OPEN = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.updateModal,
    Action.open,
  )

  static CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_CANCEL = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.updateModal,
    Action.cancel,
  )

  static CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_CONFIRM = MixpanelEventFn(
    Page.clusterOverviewAlerts,
    Element.updateModal,
    Action.confirm,
  )

  static CLUSTER_OVERVIEW_IP_WHITELIST_CREATE_MODAL_OPEN = MixpanelEventFn(
    Page.clusterOverviewIpWhitelists,
    Element.createModal,
    Action.open,
  )

  static CLUSTER_OVERVIEW_IP_WHITELIST_CREATE_MODAL_CONFIRM = MixpanelEventFn(
    Page.clusterOverviewIpWhitelists,
    Element.createModal,
    Action.confirm,
  )

  static CLUSTER_OVERVIEW_IP_WHITELIST_CREATE_MODAL_CANCEL = MixpanelEventFn(
    Page.clusterOverviewIpWhitelists,
    Element.createModal,
    Action.cancel,
  )

  static CLUSTER_OVERVIEW_IP_WHITELIST_UPDATE_MODAL_OPEN = MixpanelEventFn(
    Page.clusterOverviewIpWhitelists,
    Element.updateModal,
    Action.open,
  )

  static CLUSTER_OVERVIEW_IP_WHITELIST_UPDATE_MODAL_CONFIRM = MixpanelEventFn(
    Page.clusterOverviewIpWhitelists,
    Element.updateModal,
    Action.confirm,
  )

  static CLUSTER_OVERVIEW_IP_WHITELIST_UPDATE_MODAL_CANCEL = MixpanelEventFn(
    Page.clusterOverviewIpWhitelists,
    Element.updateModal,
    Action.cancel,
  )

  static CLUSTER_LIST_DELETE_CLUSTER_DIALOG = MixpanelEventFn(
    Page.clusterList,
    Element.deleteClusterButton,
    Action.open,
  )
  static CLUSTER_LIST_DELETE_CLUSTER_DIALOG_CONFIRMED = MixpanelEventFn(
    Page.clusterList,
    Element.deleteClusterButton,
    Action.confirm,
  )
  static CLUSTER_LIST_DELETE_CLUSTER_DIALOG_CANCELED = MixpanelEventFn(
    Page.clusterList,
    Element.deleteClusterButton,
    Action.cancel,
  )
  static CLUSTER_OVERVIEW_UPDATE_CLUSTER_DIALOG = MixpanelEventFn(
    Page.clusterOverview,
    Element.updateClusterButton,
    Action.open,
  )
  static CLUSTER_OVERVIEW_UPDATE_CLUSTER_DIALOG_CONFIRMED = MixpanelEventFn(
    Page.clusterOverview,
    Element.updateClusterButton,
    Action.confirm,
  )
  static CLUSTER_OVERVIEW_UPDATE_CLUSTER_DIALOG_CANCELED = MixpanelEventFn(
    Page.clusterOverview,
    Element.updateClusterButton,
    Action.cancel,
  )
  static CLUSTER_OVERVIEW_RENAME_CLUSTER = MixpanelEventFn(
    Page.clusterOverview,
    Element.renameClusterButton,
    Action.open,
  )
  static CLUSTER_OVERVIEW_RENAME_CLUSTER_CONFIRM = MixpanelEventFn(
    Page.clusterOverview,
    Element.renameClusterButton,
    Action.confirm,
  )
  static CLUSTER_OVERVIEW_RENAME_CLUSTER_CANCEL = MixpanelEventFn(
    Page.clusterOverview,
    Element.renameClusterButton,
    Action.cancel,
  )
  static ORGANIZATION_SETTINGS_TAB_CHANGED_OVERVIEW = MixpanelEventFn(
    Page.orgSettings,
    Element.tabChange,
    'overview',
  )
  static ORGANIZATION_SETTINGS_TAB_CHANGED_USERS = MixpanelEventFn(
    Page.orgSettings,
    Element.tabChange,
    'users',
  )
  static ORGANIZATION_SETTINGS_TAB_CHANGED_ACTIVITIY = MixpanelEventFn(
    Page.orgSettings,
    Element.tabChange,
    'activity',
  )
  static ORGANIZATION_SETTINGS_TAB_CHANGED_BILLING = MixpanelEventFn(
    Page.orgSettings,
    Element.tabChange,
    'billing',
  )
  static ORGANIZATION_SETTINGS_TAB_CHANGED_USAGE = MixpanelEventFn(
    Page.orgSettings,
    Element.tabChange,
    'usage',
  )
  static ORGANIZATION_SETTINGS_TAB_CHANGED_API = MixpanelEventFn(
    Page.orgSettings,
    Element.tabChange,
    'api',
  )
  static USERSETTINGS_VISITED = MixpanelEventFn(
    Page.userSettings,
    null,
    Action.open,
  )

  static NO_ORG_PAGE_SEEN = MixpanelEventFn(Page.noOrg, null, Action.open)
  static ORG_NOT_INITIALIZED_PAGE_SEEN = MixpanelEventFn(
    Page.orgNotInitialized,
    null,
    Action.open,
  )
  static TERMS_PAGE_SEEN = MixpanelEventFn(Page.terms, null, Action.open)

  // COMPONENTS
  static CREATE_CLUSTER_DIALOG_CANCELED = MixpanelEventFn(
    Component.createCluster,
    Element.createCluster,
    Action.cancel,
  )
  static CREATE_CLUSTER_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.createCluster,
    Element.createCluster,
    Action.confirm,
  )
  static CREATE_CLUSTER_DIALOG_ERROR = MixpanelEventFn(
    Component.createCluster,
    Element.createCluster,
    Action.error,
  )

  static API_CLIENTS_CREATE_DIALOG = MixpanelEventFn(
    Component.apiClients,
    Element.create,
    Action.open,
  )
  static API_CLIENTS_CREATE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.apiClients,
    Element.create,
    Action.confirm,
  )
  static API_CLIENTS_CREATE_DIALOG_CANCELED = MixpanelEventFn(
    Component.apiClients,
    Element.create,
    Action.cancel,
  )

  static API_CLIENTS_UPDATE_DIALOG = MixpanelEventFn(
    Component.apiClients,
    Element.update,
    Action.open,
  )
  static API_CLIENTS_UPDATE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.apiClients,
    Element.update,
    Action.confirm,
  )
  static API_CLIENTS_UPDATE_DIALOG_CANCELED = MixpanelEventFn(
    Component.apiClients,
    Element.update,
    Action.cancel,
  )

  static API_CLIENTS_DELETE_DIALOG = MixpanelEventFn(
    Component.apiClients,
    Element.delete,
    Action.open,
  )
  static API_CLIENTS_DELETE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.apiClients,
    Element.delete,
    Action.confirm,
  )
  static API_CLIENTS_DELETE_DIALOG_CANCELED = MixpanelEventFn(
    Component.apiClients,
    Element.delete,
    Action.cancel,
  )

  static API_CLIENTS_DETAILS_DIALOG = MixpanelEventFn(
    Component.apiClients,
    Element.details,
    Action.open,
  )
  static API_CLIENTS_DETAILS_DIALOG_CLOSE = MixpanelEventFn(
    Component.apiClients,
    Element.details,
    Action.close,
  )
  static API_CLIENTS_CONNECTIONINFO_DIALOG = MixpanelEventFn(
    Component.apiClients,
    Element.connectionInfo,
    Action.open,
  )
  static API_CLIENTS_CONNECTIONINFO_DIALOG_CLOSE = MixpanelEventFn(
    Component.apiClients,
    Element.connectionInfo,
    Action.close,
  )
  static API_CLIENTS_DOWNLOAD_CURL = MixpanelEventFn(
    Component.apiClients,
    Element.curlStatement,
    Action.download,
  )

  static CLUSTER_CLIENTS_CREATE_DIALOG = MixpanelEventFn(
    Component.clusterClients,
    Element.create,
    Action.open,
  )
  static CLUSTER_CLIENTS_CREATE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.clusterClients,
    Element.create,
    Action.confirm,
  )
  static CLUSTER_CLIENTS_CREATE_DIALOG_CANCELED = MixpanelEventFn(
    Component.clusterClients,
    Element.create,
    Action.cancel,
  )

  static CLUSTER_CLIENTS_UPDATE_DIALOG = MixpanelEventFn(
    Component.clusterClients,
    Element.update,
    Action.open,
  )
  static CLUSTER_CLIENTS_UPDATE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.clusterClients,
    Element.update,
    Action.confirm,
  )
  static CLUSTER_CLIENTS_UPDATE_DIALOG_CANCELED = MixpanelEventFn(
    Component.clusterClients,
    Element.update,
    Action.cancel,
  )

  static CLUSTER_CLIENTS_DELETE_DIALOG = MixpanelEventFn(
    Component.clusterClients,
    Element.delete,
    Action.open,
  )
  static CLUSTER_CLIENTS_DELETE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.clusterClients,
    Element.delete,
    Action.confirm,
  )
  static CLUSTER_CLIENTS_DELETE_DIALOG_CANCELED = MixpanelEventFn(
    Component.clusterClients,
    Element.delete,
    Action.cancel,
  )

  static CLUSTER_CLIENTS_DETAILS_DIALOG = MixpanelEventFn(
    Component.clusterClients,
    Element.details,
    Action.open,
  )
  static CLUSTER_CLIENTS_DETAILS_DIALOG_CLOSE = MixpanelEventFn(
    Component.clusterClients,
    Element.details,
    Action.close,
  )
  static CLUSTER_CLIENTS_CONNECTIONINFO_DIALOG = MixpanelEventFn(
    Component.clusterClients,
    Element.connectionInfo,
    Action.open,
  )
  static CLUSTER_CLIENTS_CONNECTIONINFO_DIALOG_CLOSE = MixpanelEventFn(
    Component.clusterClients,
    Element.connectionInfo,
    Action.close,
  )

  static CLUSTER_CLIENTS_DOWNLOAD_ENV = MixpanelEventFn(
    Component.clusterClients,
    Element.envStatement,
    Action.download,
  )

  static LIST_MODELS_DELETE_MODEL = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.open,
  )
  static LIST_MODELS_DELETE_MODEL_CONFIRMED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.confirm,
  )
  static LIST_MODELS_DELETE_MODEL_CANCELED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.cancel,
  )

  static LIST_MODELS_OPEN_MODELER = MixpanelEventFn(
    Component.listBpmnModels,
    Element.openModeler,
    Action.click,
  )

  static LIST_MODELS_DEPLOY_MODAL = MixpanelEventFn(
    Component.listBpmnModels,
    Element.deploy,
    Action.open,
  )
  static LIST_MODELS_DEPLOY_MODAL_CANCELED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.deploy,
    Action.cancel,
  )
  static LIST_MODELS_DEPLOY_MODAL_CONFIRMED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.deploy,
    Action.confirm,
  )
  static LIST_MODELS_DEPLOY_MODAL_ERROR = MixpanelEventFn(
    Component.listBpmnModels,
    Element.deploy,
    Action.error,
  )

  static LIST_MODELS_START_MODAL = MixpanelEventFn(
    Component.listBpmnModels,
    Element.start,
    Action.open,
  )
  static LIST_MODELS_START_MODAL_CANCELED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.start,
    Action.cancel,
  )
  static LIST_MODELS_START_MODAL_CONFIRMED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.start,
    Action.confirm,
  )
  static LIST_MODELS_START_MODAL_ERROR = MixpanelEventFn(
    Component.listBpmnModels,
    Element.start,
    Action.error,
  )

  static LIST_MODELS_DOWNLOAD = MixpanelEventFn(
    Component.listBpmnModels,
    Element.model,
    Action.download,
  )
  static LIST_MODELS_OPEN_OPERATE = MixpanelEventFn(
    Component.listBpmnModels,
    Element.operate,
    Action.open,
  )
  static LIST_MODELS_CREATE_CLUSTER_DIALOG = MixpanelEventFn(
    Component.listBpmnModels,
    Element.createClusterButton,
    Action.click,
  )

  static MODELER_SAVE_MODEL = MixpanelEventFn(
    Component.modeler,
    Element.save,
    Action.click,
  )
  static MODELER_SAVE_MODEL_COLLISION_DETECTED = MixpanelEventFn(
    Component.modeler,
    Element.save,
    Action.error,
  )

  static MODELER_DEPLOY_MODAL = MixpanelEventFn(
    Component.modeler,
    Element.deploy,
    Action.open,
  )
  static MODELER_DEPLOY_MODAL_CANCELED = MixpanelEventFn(
    Component.modeler,
    Element.deploy,
    Action.cancel,
  )
  static MODELER_DEPLOY_MODAL_CONFIRMED = MixpanelEventFn(
    Component.modeler,
    Element.deploy,
    Action.confirm,
  )
  static MODELER_DEPLOY_MODAL_ERROR = MixpanelEventFn(
    Component.modeler,
    Element.deploy,
    Action.error,
  )

  static MODELER_EXECUTE_MODAL = MixpanelEventFn(
    Component.modeler,
    Element.execute,
    Action.open,
  )
  static MODELER_EXECUTE_MODAL_CANCELED = MixpanelEventFn(
    Component.modeler,
    Element.execute,
    Action.cancel,
  )
  static MODELER_EXECUTE_MODAL_CONFIRMED = MixpanelEventFn(
    Component.modeler,
    Element.execute,
    Action.confirm,
  )
  static MODELER_EXECUTE_MODAL_ERROR = MixpanelEventFn(
    Component.modeler,
    Element.execute,
    Action.error,
  )

  static MODELER_START_MODAL = MixpanelEventFn(
    Component.modeler,
    Element.start,
    Action.open,
  )
  static MODELER_START_MODAL_CANCELED = MixpanelEventFn(
    Component.modeler,
    Element.start,
    Action.cancel,
  )
  static MODELER_START_MODAL_CONFIRMED = MixpanelEventFn(
    Component.modeler,
    Element.start,
    Action.confirm,
  )
  static MODELER_START_MODAL_ERROR = MixpanelEventFn(
    Component.modeler,
    Element.start,
    Action.error,
  )

  static MODELER_OPEN_OPERATE = MixpanelEventFn(
    Component.modeler,
    Element.operate,
    Action.open,
  )

  static MODELER_OPEN_TASKLIST = MixpanelEventFn(
    Component.modeler,
    Element.tasklist,
    Action.open,
  )

  static MODELER_CREATE_CLUSTER_DIALOG = MixpanelEventFn(
    Component.modeler,
    Element.createClusterButton,
    Action.click,
  )

  static MODELER_IMPORT_FILE = MixpanelEventFn(
    Component.modeler,
    Element.import,
    Action.open,
  )
  static MODELER_IMPORT_FILE_SELECTED = MixpanelEventFn(
    Component.modeler,
    Element.import,
    Action.close,
  )
  static MODELER_IMPORT_FILE_ERROR_IN_IMPORT = MixpanelEventFn(
    Component.modeler,
    Element.import,
    Action.error,
  )
  static LIST_FORMS_DELETE_FORM = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.open,
  )
  static LIST_FORMS_DELETE_FORM_CONFIRMED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.confirm,
  )
  static LIST_FORMS_DELETE_FORM_CANCELED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.cancel,
  )

  static LIST_FORMS_OPEN_FORM_DESIGNER = MixpanelEventFn(
    Component.listBpmnModels,
    Element.openModeler,
    Action.click,
  )

  static LIST_FORMS_DOWNLOAD = MixpanelEventFn(
    Component.listBpmnModels,
    Element.model,
    Action.download,
  )

  static LIST_CONNECTORS_DELETE_CONNECTOR = MixpanelEventFn(
    Component.listConnectors,
    Element.delete,
    Action.open,
  )
  static LIST_CONNECTORS_DELETE_CONNECTOR_CONFIRMED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.confirm,
  )
  static LIST_CONNECTORS_DELETE_CONNECTOR_CANCELED = MixpanelEventFn(
    Component.listBpmnModels,
    Element.delete,
    Action.cancel,
  )

  static FORM_DESIGNER_SAVE = MixpanelEventFn(
    Component.formDesigner,
    Element.save,
    Action.click,
  )
  static FORM_DESIGNER_SAVE_ERROR = MixpanelEventFn(
    Component.formDesigner,
    Element.save,
    Action.error,
  )

  static CLUSTER_DETAIL_UPDATE_DIALOG = MixpanelEventFn(
    Component.clusterDetails,
    Element.update,
    Action.open,
  )
  static CLUSTER_DETAIL_UPDATE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.clusterDetails,
    Element.update,
    Action.confirm,
  )
  static CLUSTER_DETAIL_UPDATE_DIALOG_CANCELED = MixpanelEventFn(
    Component.clusterDetails,
    Element.update,
    Action.cancel,
  )
  static CLUSTER_DETAIL_OPEN_OPERATE = MixpanelEventFn(
    Component.clusterDetails,
    Element.operate,
    Action.open,
  )
  static CLUSTER_DETAIL_OPEN_TASKLIST = MixpanelEventFn(
    Component.clusterDetails,
    Element.tasklist,
    Action.open,
  )
  static CLUSTER_DETAIL_OPEN_OPTIMIZE = MixpanelEventFn(
    Component.clusterDetails,
    Element.optimize,
    Action.open,
  )

  static CLUSTER_APPLICATION_OPEN_OPERATE = MixpanelEventFn(
    Component.clusterApps,
    Element.operate,
    Action.open,
  )
  static CLUSTER_APPLICATION_OPEN_TASKLIST = MixpanelEventFn(
    Component.clusterApps,
    Element.tasklist,
    Action.open,
  )
  static CLUSTER_APPLICATION_OPEN_OPTIMIZE = MixpanelEventFn(
    Component.clusterApps,
    Element.optimize,
    Action.open,
  )
  static CLUSTER_APPLICATION_OPEN_OPERATE_DOCS = MixpanelEventFn(
    Component.clusterApps,
    Element.operateDocs,
    Action.open,
  )
  static CLUSTER_APPLICATION_OPEN_TASKLIST_DOCS = MixpanelEventFn(
    Component.clusterApps,
    Element.tasklistDocs,
    Action.open,
  )

  static CLUSTER_APPLICATION_OPEN_OPTIMIZE_DOCS = MixpanelEventFn(
    Component.clusterApps,
    Element.optimizeDocs,
    Action.open,
  )
  static LIST_ORG_MEMBERS_ADD_DIALOG = MixpanelEventFn(
    Component.orgMembers,
    Element.add,
    Action.open,
  )
  static LIST_ORG_MEMBERS_ADD_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.orgMembers,
    Element.add,
    Action.confirm,
  )
  static LIST_ORG_MEMBERS_ADD_DIALOG_CANCELED = MixpanelEventFn(
    Component.orgMembers,
    Element.add,
    Action.cancel,
  )
  static LIST_ORG_MEMBERS_ADD_DIALOG_ERROR = MixpanelEventFn(
    Component.orgMembers,
    Element.add,
    Action.error,
  )

  static LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG = MixpanelEventFn(
    Component.orgMembers,
    Element.invite,
    Action.open,
  )
  static LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.orgMembers,
    Element.invite,
    Action.confirm,
  )
  static LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG_CANCELED = MixpanelEventFn(
    Component.orgMembers,
    Element.invite,
    Action.cancel,
  )
  static LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG_ERROR = MixpanelEventFn(
    Component.orgMembers,
    Element.invite,
    Action.error,
  )

  static LIST_ORG_MEMBERS_REMOVE_DIALOG = MixpanelEventFn(
    Component.orgMembers,
    Element.delete,
    Action.open,
  )
  static LIST_ORG_MEMBERS_REMOVE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.orgMembers,
    Element.delete,
    Action.confirm,
  )
  static LIST_ORG_MEMBERS_REMOVE_DIALOG_CANCELED = MixpanelEventFn(
    Component.orgMembers,
    Element.delete,
    Action.cancel,
  )
  static LIST_ORG_MEMBERS_REMOVE_DIALOG_ERROR = MixpanelEventFn(
    Component.orgMembers,
    Element.delete,
    Action.error,
  )

  static LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG = MixpanelEventFn(
    Component.orgMembers,
    Element.changeRole,
    Action.open,
  )
  static LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.orgMembers,
    Element.changeRole,
    Action.confirm,
  )
  static LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_CANCELED = MixpanelEventFn(
    Component.orgMembers,
    Element.changeRole,
    Action.cancel,
  )
  static LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_ERROR = MixpanelEventFn(
    Component.orgMembers,
    Element.changeRole,
    Action.error,
  )
  static LIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG = MixpanelEventFn(
    Component.orgMembers,
    Element.assignNewOwner,
    Action.open,
  )
  static LIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.orgMembers,
    Element.assignNewOwner,
    Action.confirm,
  )
  static LIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG_CANCELED = MixpanelEventFn(
    Component.orgMembers,
    Element.assignNewOwner,
    Action.cancel,
  )
  static LLIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG_ERROR = MixpanelEventFn(
    Component.orgMembers,
    Element.assignNewOwner,
    Action.error,
  )
  static LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL = MixpanelEventFn(
    Component.orgMembers,
    Element.newRoleDefinitionModal,
    Action.open,
  )
  static LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL_CONFIRMED = MixpanelEventFn(
    Component.orgMembers,
    Element.newRoleDefinitionModal,
    Action.confirm,
  )
  static LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL_CANCEL = MixpanelEventFn(
    Component.orgMembers,
    Element.newRoleDefinitionModal,
    Action.cancel,
  )

  static NAVBAR_LINK_TO_DOCS = MixpanelEventFn(
    Component.navBar,
    Element.docs,
    Action.click,
  )
  static NAVBAR_LINK_TO_JIRA = MixpanelEventFn(
    Component.navBar,
    Element.jira,
    Action.click,
  )
  static NAVBAR_LINK_TO_FORUM = MixpanelEventFn(
    Component.navBar,
    Element.forum,
    Action.click,
  )
  static NAVBAR_LINK_TO_PRIVACY = MixpanelEventFn(
    Component.navBar,
    Element.privacy,
    Action.click,
  )
  static NAVBAR_LINK_TO_SLACK = MixpanelEventFn(
    Component.navBar,
    Element.slack,
    Action.click,
  )
  static NAVBAR_LINK_TO_CONTACT_US_PROFESSIONAL = MixpanelEventFn(
    Component.navBar,
    Element.contactUsProfessional,
    Action.click,
  )
  static NAVBAR_LINK_TO_CONTACT_US_ENTERPRISE = MixpanelEventFn(
    Component.navBar,
    Element.contactUsEnterprise,
    Action.click,
  )
  static NAVBAR_ORG_SETTINGS = MixpanelEventFn(
    Component.navBar,
    Element.orgSettings,
    Action.click,
  )
  static NAVBAR_ORG_SWITCH = MixpanelEventFn(
    Component.navBar,
    Element.orgSwitch,
    Action.click,
  )
  static NAVBAR_LOGOUT = MixpanelEventFn(
    Component.navBar,
    Element.logout,
    Action.click,
  )

  static NAVBAR_REQUEST_PAID_PLAN_DIALOG = MixpanelEventFn(
    Component.navBar,
    Element.requestPaid,
    Action.open,
  )
  static NAVBAR_REQUEST_PAID_PLAN_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.navBar,
    Element.requestPaid,
    Action.confirm,
  )

  static NAVBAR_REQUEST_PAID_PLAN_DIALOG_CANCELED = MixpanelEventFn(
    Component.navBar,
    Element.requestPaid,
    Action.cancel,
  )

  static NAVBAR_CC_CHECKOUT_OPEN = MixpanelEventFn(
    Component.navBar,
    Element.checkoutCc,
    Action.open,
  )

  static NAVBAR_CC_CHECKOUT_CANCEL = MixpanelEventFn(
    Component.navBar,
    Element.checkoutCc,
    Action.cancel,
  )

  static NAVBAR_CC_CHECKOUT_CHARGEBEE_CANCEL = MixpanelEventFn(
    Component.navBar,
    Element.checkoutCcChargebee,
    Action.cancel,
  )

  static NAVBAR_CC_CHECKOUT_CHARGEBEE_ERROR = MixpanelEventFn(
    Component.navBar,
    Element.checkoutCcChargebee,
    Action.error,
  )

  static NAVBAR_CC_CHECKOUT_CONFIRM = MixpanelEventFn(
    Component.navBar,
    Element.checkoutCc,
    Action.confirm,
  )

  static NAVBAR_CC_CHECKOUT_SELECT_COUNTRY = MixpanelEventFn(
    Component.navBar,
    Element.checkoutCc,
    Action.click,
  )

  static NAVBAR_DIAGRAMS = MixpanelEventFn(
    Component.navBar,
    Element.diagramsList,
    Action.click,
  )

  static NAVBAR_FORMS = MixpanelEventFn(
    Component.navBar,
    Element.formsList,
    Action.click,
  )

  static NAVBAR_CLUSTERS = MixpanelEventFn(
    Component.navBar,
    Element.clusterList,
    Action.click,
  )

  static RESERVATION_EDIT = MixpanelEventFn(
    Component.reservations,
    Element.edit,
    Action.start,
  )
  static RESERVATION_EDIT_STOP = MixpanelEventFn(
    Component.reservations,
    Element.edit,
    Action.stop,
  )

  static RESERVATION_CONFIRM_DIALOG = MixpanelEventFn(
    Component.reservations,
    Element.confirm,
    Action.open,
  )
  static RESERVATION_CONFIRM_DIALOG_CONFIRMED = MixpanelEventFn(
    Component.reservations,
    Element.confirm,
    Action.confirm,
  )
  static RESERVATION_CONFIRM_DIALOG_CANCELED = MixpanelEventFn(
    Component.reservations,
    Element.confirm,
    Action.cancel,
  )
  static RESERVATION_CONFIRM_DIALOG_ERROR = MixpanelEventFn(
    Component.reservations,
    Element.confirm,
    Action.error,
  )

  // GSE
  static GSE_START = MixpanelEventFn(Component.gse, Element.start, Action.click)
  static GSE_DEPLOY = MixpanelEventFn(
    Component.gse,
    Element.deploy,
    Action.click,
  )
  static GSE_START_INSTANCE = MixpanelEventFn(
    Component.gse,
    Element.startInstance,
    Action.click,
  )
  static GSE_JUMP_TO_OPERATE = MixpanelEventFn(
    Component.gse,
    Element.operate,
    Action.start,
  )
  static GSE_RETURN_FROM_OPERATE = MixpanelEventFn(
    Component.gse,
    Element.operate,
    Action.stop,
  )
  static GSE_JUMP_TO_TASKLIST = MixpanelEventFn(
    Component.gse,
    Element.tasklist,
    Action.start,
  )
  static GSE_RETURN_FROM_TASKLIST = MixpanelEventFn(
    Component.gse,
    Element.tasklist,
    Action.stop,
  )
  static GSE_OPEN_GITHUB = MixpanelEventFn(
    Component.gse,
    Element.githubGse,
    Action.open,
  )
  static MODELER_TOGGLE_PROPERTY_PANEL = MixpanelEventFn(
    Component.modeler,
    Element.propertyPanel,
    Action.toggle,
  )
  static MODELER_PROPERTY_PANEL_INNER = MixpanelEventFn(
    Component.modeler,
    Element.propertyPanel,
    Action.click,
  )
  static MODELER_TOKEN_SIMULATOR_INNER = MixpanelEventFn(
    Component.modeler,
    Element.tokenSimulator,
    Action.click,
  )

  static BILLING_CHARGEBEE_PORTAL_OPEN = MixpanelEventFn(
    Component.billing,
    Element.chargebeePortal,
    Action.open,
  )

  static BILLING_CHARGEBEE_PORTAL_CLOSE = MixpanelEventFn(
    Component.billing,
    Element.chargebeePortal,
    Action.close,
  )

  static BILLING_CHARGEBEE_PORTAL_ERROR = MixpanelEventFn(
    Component.billing,
    Element.chargebeePortal,
    Action.error,
  )

  static HOME_WELCOME_MODAL = MixpanelEventFn(
    Component.home,
    Element.welcome,
    Action.open,
  )

  static HOME_WELCOME_MODAL_MAYBE_LATER = MixpanelEventFn(
    Component.home,
    Element.welcome,
    Action.confirm,
  )

  static HOME_WELCOME_MODAL_CLOSE = MixpanelEventFn(
    Component.home,
    Element.welcome,
    Action.close,
  )

  static HOME_PRODUCT_WALKTHROUGH = MixpanelEventFn(
    Component.home,
    Element.productWalkthrough,
    Action.start,
  )

  static HOME_WELCOME_MODAL_PRODUCT_WALKTHROUGH = MixpanelEventFn(
    Component.home,
    Element.welcomeModalProductWalkthrough,
    Action.start,
  )

  static HOME_WELCOME_MODAL_START_BLANK = MixpanelEventFn(
    Component.home,
    Element.startBlank,
    Action.start,
  )

  static HOME_WELCOME_MODAL_UPLOAD_DIAGRAM = MixpanelEventFn(
    Component.home,
    Element.uploadDiagram,
    Action.start,
  )

  static HOME_WELCOME_MODAL_USE_TEMPLATE = MixpanelEventFn(
    Component.home,
    Element.welcomeModalUseTemplate,
    Action.click,
  )

  static HOME_LINK_TO_DIAGRAMS = MixpanelEventFn(
    Component.home,
    Element.diagramsList,
    Action.click,
  )

  static HOME_LINK_TO_FORMS = MixpanelEventFn(
    Component.home,
    Element.formsList,
    Action.click,
  )

  static HOME_LINK_TO_CLUSTERS = MixpanelEventFn(
    Component.home,
    Element.clusterList,
    Action.click,
  )

  static HOME_LINK_TO_GITHUB_GSE = MixpanelEventFn(
    Component.home,
    Element.githubGse,
    Action.click,
  )

  static HOME_LINK_TO_DOCS_WHAT_IS_CAMUNDA_CLOUD = MixpanelEventFn(
    Component.home,
    Element.docsWhatIsCamundaCloud,
    Action.click,
  )

  static HOME_LINK_TO_DOCS_MODEL_YOUR_FIRST_PROCESS = MixpanelEventFn(
    Component.home,
    Element.docsModelYourFirstProcess,
    Action.click,
  )

  static HOME_LINK_TO_DOCS_CLIENTS = MixpanelEventFn(
    Component.home,
    Element.docsClients,
    Action.click,
  )

  static HOME_LINK_TO_DOCS_MESSAGE_CORRELATION = MixpanelEventFn(
    Component.home,
    Element.docsMessageCorrelation,
    Action.click,
  )

  static HOME_LINK_TO_FEEDBACK_JIRA = MixpanelEventFn(
    Component.home,
    Element.jira,
    Action.click,
  )

  static HOME_LINK_TO_FEEDBACK_SLACK = MixpanelEventFn(
    Component.home,
    Element.slack,
    Action.click,
  )

  static HOME_LINK_TO_FEEDBACK_FORUM = MixpanelEventFn(
    Component.home,
    Element.forum,
    Action.click,
  )

  static HOME_LINK_TO_FEEDBACK_ENTERPRISE_SUPPORT = MixpanelEventFn(
    Component.home,
    Element.enterpriseSupport,
    Action.click,
  )

  static TEMPLATE_PICKER_CREATE_BLANK = MixpanelEventFn(
    Component.templatePicker,
    Element.startBlank,
    Action.click,
  )

  static TEMPLATE_PICKER_CANCEL = MixpanelEventFn(
    Component.templatePicker,
    'modal',
    Action.cancel,
  )
}

mixpanel.init(environment.mixpanelToken, {
  opt_out_tracking_by_default: true,
  // TODO: we should be there i guess
  // api_host: 'https://api-eu.mixpanel.com',
})

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private superProperties: {
    orgId?: string
    userId?: string
    stage?: string
    clusterId?: string
    frontend: boolean
    // groups
    org_id?: string
    cluster_id?: string
  } = {
    frontend: true,
  }
  public hasOptedIntoPerformanceCookies: boolean | undefined = undefined

  public orgBasedMixpanelEvents: boolean
  constructor(
    private authService: AuthService,
    private navbarService: NavbarService,
  ) {
    if (environment.production) {
      this.superProperties.stage = 'prod'
    } else if (environment.integration) {
      this.superProperties.stage = 'int'
    } else {
      this.superProperties.stage = 'dev'
    }
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.superProperties.userId = user.sub
        mixpanel.identify(user.sub)
        mixpanel.register(this.superProperties)
      }
    })
    this.navbarService.currentOrg$.subscribe((currentOrg) => {
      if (currentOrg) {
        this.superProperties.orgId = currentOrg.uuid
        this.superProperties.org_id = currentOrg.uuid
        mixpanel.register(this.superProperties)
        if (
          currentOrg.organizationToSalesPlan?.salesPlan.salesPlanType !==
            SalesPlanType.INTERNAL &&
          !currentOrg.roles.includes(OrganizationRole.SUPPORTAGENT)
        ) {
          this.orgBasedMixpanelEvents = true
          return
        }
      }
      this.orgBasedMixpanelEvents = false
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public async track(event: MixpanelEvent, data?: Dict) {
    this.checkIfOsanaChanged()
    if (this.orgBasedMixpanelEvents) {
      const analyticsData: Dict = data ? data : {}
      analyticsData.view = event.view
      analyticsData.element = event.element
      analyticsData.action = event.action
      mixpanel.track(
        `${event.view}:${event.element ? `${event.element}:` : ''}${
          event.action
        }`,
        analyticsData,
      )
      await new Promise((resolve) => {
        setTimeout(resolve, 100)
      })
    }
  }

  public optIntoTracking() {
    mixpanel.opt_in_tracking()
    this.hasOptedIntoPerformanceCookies = true
  }

  public optOutOfTracking() {
    mixpanel.opt_out_tracking()
    this.hasOptedIntoPerformanceCookies = false
  }
  public checkIfOsanaChanged() {
    if (this.hasOptedIntoPerformanceCookies !== this.osanoAnalyticsEnabled()) {
      if (this.osanoAnalyticsEnabled()) {
        this.optIntoTracking()
      } else {
        this.optOutOfTracking()
      }
    }
  }
  // eslint-disable-next-line class-methods-use-this
  private osanoAnalyticsEnabled(): boolean {
    const osano = (window as any).Osano
    return osano?.cm?.analytics
  }
}
