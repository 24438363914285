import { Component, Input, OnInit } from '@angular/core'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { first } from 'rxjs/operators'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: [],
})
export class CreditCardComponent implements OnInit {
  @Input() organization: OrganizationDto

  private chargebeeInstance: any
  constructor(
    public apiService: ApiService,
    public mixpanel: MixpanelService,
  ) {}

  public async ngOnInit() {
    const pubkey = await this.apiService
      .getChargebeePubkey(this.organization.chargebeeSite)
      .pipe(first())
      .toPromise()
    const sessionData = await this.apiService
      .getChargebeeSession(this.organization.uuid)
      .pipe(first())
      .toPromise()
    ;(window as any).Chargebee.init({
      site: this.organization.chargebeeSite,
      publishableKey: pubkey.publishableKey,
    })
    this.chargebeeInstance = (window as any).Chargebee.getInstance()
    this.chargebeeInstance.setPortalSession(() => {
      return Promise.resolve(sessionData)
    })
  }

  public open() {
    let cbPortal = this.chargebeeInstance.createChargebeePortal()
    this.mixpanel.track(FrontendAnalyticEvents.BILLING_CHARGEBEE_PORTAL_OPEN)
    const that = this

    cbPortal.open({
      close() {
        that.mixpanel.track(
          FrontendAnalyticEvents.BILLING_CHARGEBEE_PORTAL_CLOSE,
        )
      },
      error(error) {
        that.mixpanel.track(
          FrontendAnalyticEvents.BILLING_CHARGEBEE_PORTAL_ERROR,
          {
            error,
          },
        )
      },
    })
  }
}
