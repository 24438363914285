import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { BehaviorSubject } from 'rxjs'
import { first } from 'rxjs/operators'
import * as semver from 'semver'
import { ClusterAuditLogResolverService } from '../../resolver/audit.resolver'
import { GenerationDto } from '../../../../../commons/Generation.dto'
import { ClusterDetailWithGenerationData } from '../../resolver/cluster-details.resolver'
import { ClusterService } from '../../services/cluster.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { NotificationService } from '../../services/notification.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
import { ClusterDto } from '../../../../../commons/Cluster.dto'
import { environment } from 'src/environments/environment'

@Component({
  templateUrl: './cluster-details.page.html',
  styleUrls: ['./cluster-details.page.scss'],
})
export class ClusterDetailsPage implements OnInit, AfterViewInit {
  public clusterDetails: ClusterDetailWithGenerationData
  public editName = false
  public upgradableGenerations: GenerationDto[]
  public orgs: OrganizationDto[]
  public currentOrg: OrganizationDto
  public cluster: ClusterDto
  public tabChangedTo: BehaviorSubject<string>

  clusterDetailOptions = []
  clusterDetailTrigger = { type: 'icon', icon: 'contextMenu' }

  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('moveModal', { read: ElementRef })
  public moveModal: ElementRef<CmModal>

  @ViewChild('renameModal', { read: ElementRef })
  public renameModal: ElementRef<CmModal>

  public targetOrganization: [string] | [] = []
  public targetOrganizationOptions = []

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clusterService: ClusterService,
    public vss: ViewVisibilitiesService,
    private notificationService: NotificationService,
    private mixpanel: MixpanelService,
    public auditResolverService: ClusterAuditLogResolverService,
    private ngZone: NgZone,
  ) {
    this.tabChangedTo = new BehaviorSubject('')
    this.clusterDetailOptions = [{ options: [] }]
  }

  public ngOnInit() {
    this.clusterDetails = this.route.snapshot.data.clusterDetails
    this.upgradableGenerations = this.clusterDetails.upgradableGenerations
    this.orgs = this.route.snapshot.data.orgs
    this.currentOrg = this.route.snapshot.data.org
    this.cluster = this.clusterDetails.cluster
  }

  public ngAfterViewInit() {
    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel

    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        const settingsIndex = location.href
          .split('/')
          .indexOf(this.cluster.uuid)
        const url = location.href
          .split('/')
          .slice(0, settingsIndex + 1)
          .join('/')
        switch (cmPage.activeLabel) {
          case 'API':
            history.replaceState({}, '', `${url}/api`)
            this.tabChangedTo.next('api')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_API,
            )
            break
          case 'Metrics':
            history.replaceState({}, '', `${url}/metrics`)
            this.tabChangedTo.next('metrics')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_METRICS,
            )
            break
          case 'Applications':
            history.replaceState({}, '', `${url}/applications`)
            this.tabChangedTo.next('applications')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_APPS,
            )
            break
          case 'Alerts':
            history.replaceState({}, '', `${url}/alerts`)
            this.tabChangedTo.next('alerts')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_ALERTS,
            )
            break
          case 'Activity':
            history.replaceState({}, '', `${url}/activity`)
            this.tabChangedTo.next('activity')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_ACTIVITY,
            )
            break
          case 'IP Whitelist':
            history.replaceState({}, '', `${url}/ip-whitelist`)
            this.tabChangedTo.next('ip-whitelist')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_IP_WHITELIST,
            )
            break
          default:
            history.replaceState({}, '', `${url}`)
            this.tabChangedTo.next('')
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_TAB_CHANGED_OVERVIEW,
            )
            break
        }
      })
    })

    this.clusterDetailOptions[0].options.push({
      label: 'Rename',
      handler: () => {
        this.openRenameModal()
      },
      isDisabled: this.vss.visibilities.cluster.details.menu.rename.disabled,
    })

    if (this.vss.visibilities.cluster.details.menu.delete.visible) {
      this.clusterDetailOptions.push({
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            isDisabled: this.vss.visibilities.cluster.details.menu.delete
              .disabled,
            handler: () => {
              this.openDeleteModal()
            },
          },
        ],
      })
    }

    let adminOptions = { options: [], title: 'Admin' }

    if (
      this.vss.visibilities.cluster.details.menu.moveToOtherOrg.visible &&
      this.orgs.length > 1
    ) {
      adminOptions.options.push({
        label: 'Move',
        isDangerous: true,
        handler: () => {
          this.openMoveModal()
        },
      })
    }

    if (this.vss.visibilities.cluster.details.menu.boost.visible) {
      adminOptions.options.push({
        label: 'Boost',
        isDangerous: true,
        handler: () => {
          this.clusterService
            .boostAvailable(this.cluster.uuid)
            .subscribe((available) => {
              if (available.availableClusterplan) {
                this.clusterService.boost(this.cluster.uuid).subscribe(
                  (_) => {
                    this.notificationService.enqueueNotification({
                      headline: 'Boosted',
                    })
                    this.router.navigate([
                      `org/${this.cluster.ownerId}/cluster/${this.cluster.uuid}`,
                    ])
                  },
                  (_error) => {
                    this.notificationService.enqueueNotification({
                      headline: 'Boost failed',
                      appearance: 'error',
                    })
                  },
                )
              } else {
                this.notificationService.enqueueNotification({
                  headline: 'Boost unavailable',
                  description: `for cluster ${this.cluster.name}`,
                  appearance: 'info',
                })
              }
            })
        },
      })
    }

    if (adminOptions.options.length) {
      this.clusterDetailOptions.push(adminOptions)
    }
  }

  public alertWorks() {
    // only zeebe 1.0.0-alpha4 and above are capable of doing alertings
    const version = this.cluster.generation.versions.zeebe.replace(
      'camunda/zeebe:',
      '',
    )

    if (version === 'SNAPSHOT') {
      // Whatever ... lol
      return true
    }

    const zeebeSemver = semver.coerce(version)
    const zeebeTag = version.replace(`${zeebeSemver}-`, '')

    if (semver.gt(zeebeSemver, '1.0.0')) {
      return true
    }

    if (semver.lt(zeebeSemver, '1.0.0')) {
      return false
    }

    if (zeebeTag.startsWith('alpha')) {
      const alphaVersion = Number.parseInt(zeebeTag.replace('alpha', ''))
      return alphaVersion >= 4
    }

    return true
  }

  public async updateName(newName) {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_RENAME_CLUSTER_CONFIRM,
    )
    let trimmedNewName = newName.trim()
    await this.clusterService
      .rename(
        this.currentOrg.uuid,
        this.clusterDetails.cluster.uuid,
        trimmedNewName,
      )
      .pipe(first())
      .toPromise()

    this.clusterDetails.cluster.name = trimmedNewName
    this.editName = false

    this.router.navigate([
      `org/${this.cluster.ownerId}/cluster/${this.cluster.uuid}`,
    ])
  }

  setTargetOrganisation(event) {
    this.targetOrganization = event.detail.newValue
  }

  public openMoveModal() {
    this.targetOrganizationOptions = [
      {
        options: this.orgs
          .map((org) => {
            if (org.uuid !== this.cluster.ownerId) {
              return { value: org.uuid, label: org.name }
            } else {
              return undefined
            }
          })
          .filter((value) => value !== undefined),
      },
    ]

    this.targetOrganization = [this.targetOrganizationOptions[0].value]

    this.ngZone.run(() => {
      this.moveModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.clusterService.moveToOrg(
            this.currentOrg.uuid,
            this.cluster.uuid,
            result.formData.targetOrganization as string,
          )

          this.router.navigate([`/org/${this.currentOrg.uuid}`])
        }

        this.targetOrganizationOptions = []
      })
    })
  }

  public openDeleteModal() {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_DELETE_CLUSTER_DIALOG,
    )

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_DELETE_CLUSTER_DIALOG_CONFIRMED,
          )
          this.clusterService
            .delete(this.currentOrg.uuid, this.cluster.uuid)
            .subscribe(
              (_) => {
                // TODO: remove after SSE test
                if (environment.production) {
                  this.notificationService.enqueueNotification({
                    headline: 'Cluster deleted',
                    appearance: 'success',
                    showCreationTime: false,
                  })
                }
                this.router.navigate([`/org/${this.currentOrg.uuid}`])
              },
              (_error) => {
                this.notificationService.enqueueNotification({
                  headline: 'Cluster could not be deleted',
                  appearance: 'error',
                  showCreationTime: false,
                })
              },
            )
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_DELETE_CLUSTER_DIALOG_CANCELED,
          )
        }
      })
    })
  }

  private openRenameModal() {
    this.ngZone.run(() => {
      this.renameModal.nativeElement.open({
        preConfirmationHandler: (data) => {
          const newClusterName = (data.formData.newName as string).trim()

          return this.clusterService
            .rename(this.currentOrg.uuid, this.cluster.uuid, newClusterName)
            .toPromise()
            .then(
              (_) => {
                this.cluster.name = newClusterName
                this.notificationService.enqueueNotification({
                  headline: 'Cluster renamed',
                  appearance: 'success',
                  showCreationTime: false,
                })
              },
              (_error) => {
                this.notificationService.enqueueNotification({
                  headline: 'Cluster could not be renamed',
                  appearance: 'error',
                  showCreationTime: false,
                })
              },
            )
        },
      })
    })
  }
}
