import { Component, ElementRef, ViewChild } from '@angular/core'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import {
  ModalOptions,
  ConfirmationModalService,
} from '../../services/confirmation-modal.service'

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @ViewChild('modal', { read: ElementRef })
  public modalRef: ElementRef<CmModal>

  public currentModalOptions: ModalOptions = {
    title: '',
    body: '',
    cancelButton: {
      text: '',
    },
    confirmButton: {
      text: 'Confirm',
      action: () => {
        throw new Error('Confirm Action not defined!')
      },
    },
  }

  constructor(modalService: ConfirmationModalService) {
    modalService.modalRequest$.subscribe((modalOptions) => {
      this.openModal(modalOptions)
    })
  }

  private openModal(modalOptions: ModalOptions) {
    this.currentModalOptions = Object.assign(
      {},
      this.currentModalOptions,
      modalOptions,
    )

    this.modalRef.nativeElement.open().then((result) => {
      if (result.result === 'confirm') {
        modalOptions.confirmButton.action()
      } else {
        modalOptions.cancelButton?.action()
      }
    })
  }
}
