import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { BillingViewResponse } from '@camunda-cloud/subscription-management'
import { iif, Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { OrgService } from '../services/org.service'
import { ViewVisibilitiesService } from '../services/view.visibilities.service'

@Injectable({
  providedIn: 'root',
})
export class ValueMetricsHistoryResolverService
  implements Resolve<BillingViewResponse> {
  constructor(
    private vvs: ViewVisibilitiesService,
    private orgService: OrgService,
  ) {}
  // eslint-disable-next-line class-methods-use-this
  public resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<BillingViewResponse> {
    return this.vvs
      .get(route.paramMap.get('orgUuid'))
      .pipe(
        mergeMap((visibilities) =>
          iif(
            () =>
              visibilities.billing.valueMetrics.read.visible &&
              visibilities.billing.subscriptionsAvailable.visible,
            this.orgService.getValueMetricsHistory(
              route.paramMap.get('orgUuid'),
            ),
            of(undefined),
          ),
        ),
      )
  }
}
