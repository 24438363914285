import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { ChannelDto } from '../../../../../../commons/Channel.dto'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { TAG_DEFAULT } from '../../../icons'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { ChannelEditComponent } from '../channel-edit/channel-edit.component'

@Component({
  selector: 'list-channels',
  templateUrl: './list-channels.component.html',
  styleUrls: ['./list-channels.component.scss'],
})
export class ListChannelsComponent implements OnInit {
  @Input()
  public channels: (ChannelDto & { createdByName: string })[]

  @Input()
  public generations: (GenerationDto & { createdByName: string })[] = []

  @Output() channelsChanged = new EventEmitter<void>()
  @ViewChild(ChannelEditComponent)
  private channelEdit: ChannelEditComponent

  constructor(
    public vvs: ViewVisibilitiesService,
    private router: Router,
    private ngZone: NgZone,
  ) {}

  public createHandler: () => void
  public columns = [
    { name: '', width: '60px' },
    { name: 'Name', width: '1fr', ellipsis: 'right' },
    { name: 'Uuid', width: '1fr', ellipsis: 'right' },
    { name: 'Default Generation', width: '1fr', ellipsis: 'right' },
    { name: 'Allowed Generations', width: '1fr', ellipsis: 'right' },
    {
      name: '',
      width: '32px',
      overrideCSS: { justifyContent: 'end' },
    },
  ]
  public entities = []
  public loading: boolean = true

  public ngOnInit(): void {
    this.createHandler = () =>
      this.channelEdit.openCreateChannelDialog().then((expectRefresh) => {
        if (expectRefresh) {
          this.loading = true
        }
      })
    this.refreshList()
  }

  public routeToGeneration(generationUuid: string) {
    this.router.navigate([`/admin/versions/generations/${generationUuid}`])
  }

  public refreshList() {
    this.entities = this.channels.map((channel) => {
      return {
        onPress: () => {
          this.ngZone.run(() => {
            this.router.navigate([`/admin/versions/channels/${channel.uuid}`])
          })
        },
        data: [
          {
            type: 'image',
            src: channel.isDefault ? TAG_DEFAULT : undefined,
          },
          {
            type: 'text',
            content: channel.name,
          },
          { type: 'text', content: channel.uuid },
          {
            type: 'button',
            label: channel.defaultGeneration.name,
            onPress: () =>
              this.ngZone.run(() =>
                this.routeToGeneration(channel.defaultGeneration.uuid),
              ),
          },
          {
            type: 'text',
            content: channel.allowedGenerations.length.toString(),
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: [
                  {
                    label: 'Edit',
                    isDisabled: !this.vvs.visibilities.admin.channels.update
                      .visible,
                    handler: (_event) =>
                      this.channelEdit
                        .openEditChannelDialog(channel)
                        .then((expectRefresh) => {
                          if (expectRefresh) {
                            this.loading = true
                          }
                        }),
                  },

                  {
                    label: 'Delete',
                    isDangerous: true,
                    isDisabled: !this.vvs.visibilities.admin.channels.delete
                      .visible,
                    handler: (_event) =>
                      this.channelEdit
                        .deleteChannel(channel)
                        .then((expectRefresh) => {
                          if (expectRefresh) {
                            this.loading = true
                          }
                        }),
                  },
                ],
              },
            ],
          },
        ],
      }
    })
    this.loading = false
  }

  public updateChannels() {
    this.channelsChanged.emit()
  }
}
