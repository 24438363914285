import { Component, Input } from '@angular/core'
import {
  TimeFrame,
  ZeebeMetricType,
} from '../../../../../../commons/ZeebeMetrics.dto'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { ClusterDetailWithGenerationData } from 'src/app/resolver/cluster-details.resolver'
import { ActivatedRoute } from '@angular/router'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'

@Component({
  selector: 'cluster-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class ClusterMetricsComponent {
  public clusterDetails: ClusterDetailWithGenerationData

  @Input()
  public cluster: ClusterDto

  public metricsTimeFrame: string = TimeFrame.H1
  public metricsTimeFrames: string[] = [
    TimeFrame.H1,
    TimeFrame.H6,
    TimeFrame.H12,
    TimeFrame.D1,
    TimeFrame.D2,
    TimeFrame.D4,
    TimeFrame.W1,
  ]

  public chartTypes: string[] = [
    // ZeebeMetricType.PROCESSES,
    // ZeebeMetricType.CAPACITY,
    ZeebeMetricType.CAPACITY_ZEEBE,
    ZeebeMetricType.CAPACITY_ELASTIC,
    // ZeebeMetricType.THROUGHPUT,
  ]

  public filters = [
    {
      label: '1 Hour',
      value: TimeFrame.H1,
      disabled: false,
    },
    {
      label: '6 Hours',
      value: TimeFrame.H6,
      disabled: false,
    },
    {
      label: '12 Hours',
      value: TimeFrame.H12,
      disabled: false,
    },
    {
      label: '1 Day',
      value: TimeFrame.D1,
      disabled: false,
    },
    {
      label: '2 Days',
      value: TimeFrame.D2,
      disabled: false,
    },
    {
      label: '4 Days',
      value: TimeFrame.D4,
      disabled: false,
    },
    {
      label: '1 Week',
      value: TimeFrame.W1,
      disabled: false,
    },
  ]

  constructor(
    private route: ActivatedRoute,
    private mixpanelService: MixpanelService,
  ) {}

  public ngOnInit() {
    this.clusterDetails = this.route.snapshot.data.clusterDetails

    const clusterAgeInHours =
      (Date.now() - new Date(this.cluster.created).getTime()) / 1000 / 60 / 60

    let hourFormat = new Intl.DateTimeFormat('en', {
      hour: '2-digit',
      minute: '2-digit',
    })

    let dayFormat = new Intl.DateTimeFormat('en', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    let now = new Date()

    let oneHourAgo = new Date()
    oneHourAgo.setHours(now.getHours() - 1)

    let sixHourAgo = new Date()
    sixHourAgo.setHours(now.getHours() - 6)

    let twelveHourAgo = new Date()
    twelveHourAgo.setHours(now.getHours() - 12)

    let oneDayAgo = new Date()
    oneDayAgo.setDate(now.getDate() - 1)

    let twoDaysAgo = new Date()
    twoDaysAgo.setDate(now.getDate() - 2)

    let fourDaysAgo = new Date()
    fourDaysAgo.setDate(now.getDate() - 4)

    let oneWeekAgo = new Date()
    oneWeekAgo.setDate(now.getDate() - 7)

    let oneHourFilter = {
      disabled: true,
      label: '1 Hour',
      value: TimeFrame.H1,
      title: `${hourFormat.format(oneHourAgo)} - ${hourFormat.format(now)}`,
    }

    let sixHourFilter = {
      disabled: true,
      label: '6 Hours',
      value: TimeFrame.H6,
      title: `${hourFormat.format(sixHourAgo)} - ${hourFormat.format(now)}`,
    }

    let twelveHourFilter = {
      disabled: true,
      label: '12 Hours',
      value: TimeFrame.H12,
      title: `${hourFormat.format(twelveHourAgo)} - ${hourFormat.format(now)}`,
    }

    let oneDayFilter = {
      disabled: true,
      label: '1 Day',
      value: TimeFrame.D1,
      title: `${dayFormat.format(oneDayAgo)} - ${dayFormat.format(now)}`,
    }

    let twoDayFilter = {
      disabled: true,
      label: '2 Days',
      value: TimeFrame.D2,
      title: `${dayFormat.format(twoDaysAgo)} - ${dayFormat.format(now)}`,
    }

    let fourDayFilter = {
      disabled: true,
      label: '4 Days',
      value: TimeFrame.D4,
      title: `${dayFormat.format(fourDaysAgo)} - ${dayFormat.format(now)}`,
    }

    let oneWeekFilter = {
      disabled: true,
      label: '1 Week',
      value: TimeFrame.W1,
      title: `${dayFormat.format(oneWeekAgo)} - ${dayFormat.format(now)}`,
    }

    this.filters = [
      oneHourFilter,
      sixHourFilter,
      twelveHourFilter,
      oneDayFilter,
      twoDayFilter,
      fourDayFilter,
      oneWeekFilter,
    ]

    if (clusterAgeInHours > 1) {
      oneHourFilter.disabled = false
    }

    if (clusterAgeInHours > 6) {
      sixHourFilter.disabled = false
    }

    if (clusterAgeInHours > 12) {
      twelveHourFilter.disabled = false
    }

    if (clusterAgeInHours > 24) {
      oneDayFilter.disabled = false
    }

    if (clusterAgeInHours > 48) {
      twoDayFilter.disabled = false
    }

    if (clusterAgeInHours > 96) {
      fourDayFilter.disabled = false
    }

    if (clusterAgeInHours > 168) {
      oneWeekFilter.disabled = false
    }
  }

  public filterSelected(event) {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_METRICS_SWITCH_TIMEFRAME,
      { timeframe: event.detail.value },
    )
    this.metricsTimeFrame = event.detail.value
  }
}
