import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  OrganizationDto,
  OrganizationPermissions,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { BehaviorSubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { Features } from '../../../../../../commons/Features'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import {
  ClusterDetailsResolverService,
  ClusterDetailWithGenerationData,
} from '../../../resolver/cluster-details.resolver'
import { ApiService } from '../../../services/api.service'
import { ClusterService } from '../../../services/cluster.service'
import { GenerationService } from '../../../services/generation.service'
import {
  Component as MixpanelComponent,
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import {
  getOperateUrl,
  getOptimizeUrl,
  getTasklistUrl,
} from '../../taskist-operate-redirect/tasklist-operate-redirect.component'

@Component({
  selector: 'cluster-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class ClusterDetailsComponent implements OnInit, OnDestroy {
  public MIXPANEL_COMPONENT = MixpanelComponent.clusterDetails
  @Input() public clusterData: ClusterDetailWithGenerationData
  @Input() public tabChangedTo: BehaviorSubject<string>
  public upgradableGenerations: GenerationDto[]
  public cluster: ClusterDto
  public clientsAvailable: boolean = true
  public zeebeStatus: string
  public operateStatus: string
  public tasklistStatus: string
  public optimizeStatus: string
  public activatedFeatures: Features
  public activatedOrgPermissions: OrganizationPermissions
  public orgs: OrganizationDto[]
  public org: OrganizationDto
  public clusterSubscription: Subscription
  public updating: boolean = false
  private updateRunningChecker
  public airport

  @ViewChild('upgradeModal', { read: ElementRef })
  public upgradeModal: ElementRef<CmModal>

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clusterService: ClusterService,
    private generationService: GenerationService,
    private apiService: ApiService,
    private mixpanel: MixpanelService,
    public vss: ViewVisibilitiesService,
    private clusterDetailResolver: ClusterDetailsResolverService,
  ) {}

  public ngOnInit() {
    this.cluster = this.clusterData.cluster
    this.upgradableGenerations = this.clusterData.upgradableGenerations
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    this.activatedOrgPermissions = this.route.snapshot.data.activatedOrgPermissions
    this.orgs = this.route.snapshot.data.orgs
    this.org = this.route.snapshot.data.org

    this.tabChangedTo.subscribe((tab) => {
      if (
        tab === '' &&
        this.vss.visibilities.cluster.details.tabs.api.visible
      ) {
        this.apiService
          .listZeebeClients(this.cluster.ownerId, this.cluster.uuid)
          .subscribe((clients) => {
            this.clientsAvailable = clients && clients.length > 0
          })
      }
    })

    if (this.cluster.status.ready !== 'Healthy') {
      this.updateClusterStatus()
    }
    this.airport = this.cluster.status?.zeebeUrl?.split('.')[1]
  }

  public ngOnDestroy() {
    this.unsubscribe()

    if (this.updateRunningChecker) {
      clearInterval(this.updateRunningChecker)
    }
  }

  public switchTab(event, newTab: string) {
    event.preventDefault()
    this.router.navigate([
      'org',
      this.cluster.ownerId,
      'cluster',
      this.cluster.uuid,
      newTab,
    ])
  }

  public openUpgradeModal() {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_DETAIL_UPDATE_DIALOG)

    requestAnimationFrame(() => {
      this.upgradeModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_DETAIL_UPDATE_DIALOG_CONFIRMED,
          )
          this.updating = true

          await this.generationService.upgradeGenerationforCluster(
            this.upgradableGenerations[0]!.uuid,
            this.cluster.uuid,
          )

          // TODO: Remove after SSE Test
          this.clusterService.setOneClusterUnhealthy(true)

          let updateStartedAtK8s = false

          this.updateRunningChecker = setInterval(() => {
            if (!updateStartedAtK8s) {
              if (this.cluster.status.ready === 'Healthy') {
                // TODO: Remove after SSE Test
                this.clusterService.setOneClusterUnhealthy(true)

                this.updateClusterStatus()
              } else if (this.cluster.status.ready !== 'Healthy') {
                updateStartedAtK8s = true
              }
            }

            if (updateStartedAtK8s) {
              if (this.cluster.status.ready === 'Healthy') {
                this.unsubscribe()

                clearInterval(this.updateRunningChecker)

                // TODO: Remove after SSE Test
                this.clusterService.setOneClusterUnhealthy(true)

                this.clusterDetailResolver
                  .getClusterDetails(this.org.uuid, this.cluster.uuid)
                  .then((data) => {
                    this.clusterData = data
                    this.cluster = data.cluster
                    this.updating = false
                    this.ngOnInit()
                  })
              } else {
                this.updateClusterStatus()
              }
            }
          }, 1000)
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_DETAIL_UPDATE_DIALOG_CANCELED,
          )
        }
      })
    })
  }

  public openOperate() {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_DETAIL_OPEN_OPERATE)
    window.open(getOperateUrl(this.cluster), '_blank')
  }

  public openTasklist() {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_DETAIL_OPEN_TASKLIST)
    window.open(getTasklistUrl(this.cluster), '_blank')
  }

  public openOptimize() {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_DETAIL_OPEN_OPTIMIZE)
    window.open(getOptimizeUrl(this.cluster), '_blank')
  }

  private updateClusterStatus() {
    this.unsubscribe()

    this.clusterSubscription = this.clusterService
      .getClustersStatus()
      .pipe(
        map((clusters) => {
          const foundCluster = clusters.find(
            (cluster) => cluster.uuid === this.cluster.uuid,
          )
          return foundCluster
        }),
      )
      .subscribe((cluster) => {
        this.cluster = cluster
      })
  }

  private unsubscribe() {
    if (this.clusterSubscription) {
      this.clusterSubscription.unsubscribe()
    }
  }
}
