import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { ApiClientDto } from '../../../../../../commons/ApiClient.dto'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { environment } from '../../../../environments/environment'
import { ApiService } from '../../../services/api.service'
import {
  Component as MixpanelComponent,
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

type ApiClientDtoWithBetterPermissions = Omit<ApiClientDto, 'permissions'> & {
  permissions: { zeebe: string[]; cluster: string[] }
}
@Component({
  selector: 'organization-cloud-api',
  templateUrl: './cloud-api.component.html',
  styleUrls: ['./cloud-api.component.scss'],
})
export class OrganizationCloudApiComponent implements OnInit {
  public MIXPANEL_COMPONENT = MixpanelComponent.apiClients
  @Input() public cluster: ClusterDto
  public clients: ApiClientDtoWithBetterPermissions[]
  public clusterPermissions: Array<{ name: string; selected: boolean }> = []
  public zeebeClientPermissions: Array<{ name: string; selected: boolean }> = []
  public createdClient:
    | { name: string; client_id: string; client_secret: string }
    | undefined
  public allClusterSelected = false
  public allZeebeSelected = false
  public clientUpdate = false
  public audience = environment.camundaCloudAudience

  public clientToBeDeleted: ApiClientDtoWithBetterPermissions
  public clientDetails: ApiClientDtoWithBetterPermissions

  @ViewChild('createModal', { static: true, read: ElementRef })
  public createModal: ElementRef<CmModal>

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('clientDetailsModal', { read: ElementRef })
  public clientDetailsModal: ElementRef<CmModal>

  @ViewChild('connectionInfoModal', { read: ElementRef })
  public connectionInfoModal: ElementRef<CmModal>

  @ViewChild('clientSecretModal', { read: ElementRef })
  public clientSecretModal: ElementRef<CmModal>

  public clientListCreateHandler: () => void
  public clientListColumns = [
    { name: '', width: '35px' },
    { name: 'Name', width: 'minmax(100px, 1fr)', ellipsis: 'right' },
    { name: 'Client Id', width: '170px', ellipsis: 'right' },
    { name: 'Cluster Scopes', width: 'minmax(130px, 1fr)' },
    { name: 'Zeebe Scopes', width: 'minmax(130px, 1fr)' },
    {
      name: 'Connection Information',
      width: 'minmax(150px, 1fr)',
      ellipsis: 'right',
    },
    {
      name: '',
      width: '20px',
      overrideCSS: {
        justifyContent: 'end',
      },
    },
  ]
  public clientListEntities = []
  public clientListLoading: boolean = true

  public clientName: string = ''
  public clientNameValidation
  public showClientNameHint = false
  private currentOrg: OrganizationDto

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private mixpanel: MixpanelService,
    public vss: ViewVisibilitiesService,
    private ngZone: NgZone,
  ) {}

  public ngOnInit() {
    this.clients = []
    this.currentOrg = this.route.snapshot.data.org

    this.clientNameValidation = {
      type: 'custom',
      validator: (value) => {
        const isValidShape = /^[a-zA-Z0-9_-]+$/u.test(value)

        if (isValidShape) {
          const isUnique = !this.clients
            .map((client) => client.name)
            .includes(value)

          if (isUnique) {
            return { isValid: true }
          } else {
            return {
              isValid: false,
              type: 'invalid',
              message:
                'This name is already in use, please choose a different one.',
            }
          }
        } else {
          return {
            isValid: false,
            type: 'incomplete',
            message: 'Please only use letters, dashes, underscores, or digits.',
          }
        }
      },
    }

    this.clientListCreateHandler = () => this.openCreateClientModal()
    this.loadClients()
    this.loadPermissions()
  }

  static improveClients(clients: Array<ApiClientDto>) {
    return clients.map((client) => {
      let newClient: ApiClientDtoWithBetterPermissions
      newClient = {
        ...client,
        permissions: client.permissions.reduce(
          (acc, permissionName) => {
            if (permissionName.includes('Cluster')) {
              acc.cluster.push(permissionName.replace('Cluster', ''))
            }

            if (permissionName.includes('ZeebeClient')) {
              acc.zeebe.push(permissionName.replace('ZeebeClient', ''))
            }

            return acc
          },
          { cluster: [] as string[], zeebe: [] as string[] },
        ),
      }

      return newClient
    })
  }

  public openCreateClientModal() {
    this.clientUpdate = false
    this.mixpanel.track(FrontendAnalyticEvents.API_CLIENTS_CREATE_DIALOG)

    this.ngZone.run(() => {
      this.createModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.clientListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.API_CLIENTS_CREATE_DIALOG_CONFIRMED,
          )

          const newClientName = result.formData.clientName as string
          let permissions = this.clusterPermissions
            .filter((permission) => permission.selected)
            .map((permission) => `${permission.name}Cluster`)
            .concat(
              this.zeebeClientPermissions
                .filter((permission) => permission.selected)
                .map((permission) => `${permission.name}ZeebeClient`),
            )

          this.apiService
            .createApiClient(this.currentOrg.uuid, newClientName, permissions)
            .subscribe((createdClient) => {
              this.createdClient = {
                name: newClientName,
                client_id: createdClient.clientId,
                client_secret: createdClient.clientSecret,
              }

              this.apiService
                .listApiClients(this.currentOrg.uuid)
                .subscribe((clients) => {
                  this.clients = OrganizationCloudApiComponent.improveClients(
                    clients,
                  )

                  this.updateClients()
                })

              requestAnimationFrame(() => {
                this.clientSecretModal.nativeElement.open().then((result2) => {
                  if (result2.result === 'confirm') {
                    this.downloadCurl(this.createdClient)
                  }
                })
              })
            })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.API_CLIENTS_CREATE_DIALOG_CANCELED,
          )
        }

        this.clusterPermissions.map(
          (permission) => (permission.selected = false),
        )

        this.zeebeClientPermissions.map(
          (permission) => (permission.selected = false),
        )

        this.allClusterSelected = false
        this.allZeebeSelected = false
      })
    })
  }

  public openUpdateClientModal(
    event$: MouseEvent,
    client: ApiClientDtoWithBetterPermissions,
  ) {
    if (
      event$ &&
      !(event$.target as any).classList.contains('client-list-item-click')
    ) {
      return
    }
    this.mixpanel.track(FrontendAnalyticEvents.API_CLIENTS_UPDATE_DIALOG)

    this.clientUpdate = true
    this.clientName = client.name

    this.zeebeClientPermissions = this.zeebeClientPermissions.map((perm) => {
      perm.selected = client.permissions.zeebe.includes(
        `${perm.name}ZeebeClient`,
      )
      return perm
    })

    this.clusterPermissions = this.clusterPermissions.map((perm) => {
      perm.selected = client.permissions.cluster.includes(`${perm.name}Cluster`)
      return perm
    })

    this.allClusterSelected = this.clusterPermissions.every(
      (permission) => permission.selected,
    )

    this.allZeebeSelected = this.zeebeClientPermissions.every(
      (permission) => permission.selected,
    )

    this.ngZone.run(() => {
      this.createModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.clientListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.API_CLIENTS_UPDATE_DIALOG_CONFIRMED,
          )
          this.clientUpdate = false
          this.apiService
            .updateApiClient(client.uuid, this.currentOrg.uuid, this.clientName)
            .subscribe((_updatedClient) => {
              this.loadClients()
            })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.API_CLIENTS_UPDATE_DIALOG_CANCELED,
          )
        }

        this.clusterPermissions.map(
          (permission) => (permission.selected = false),
        )

        this.zeebeClientPermissions.map(
          (permission) => (permission.selected = false),
        )

        this.allClusterSelected = false
        this.allZeebeSelected = false
      })
    })
  }

  public openDeleteModal(clientId) {
    this.mixpanel.track(FrontendAnalyticEvents.API_CLIENTS_DELETE_DIALOG)
    this.clientToBeDeleted = this.clients.find(
      (client) => client.clientId === clientId,
    )!

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.clientListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.API_CLIENTS_DELETE_DIALOG_CONFIRMED,
          )
          this.apiService
            .deleteApiClient(this.currentOrg.uuid, this.clientToBeDeleted.uuid)
            .subscribe((_) => {
              this.loadClients()
            })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.API_CLIENTS_DELETE_DIALOG_CANCELED,
          )
        }
      })
    })
  }

  public openDetailsModal(client) {
    this.mixpanel.track(FrontendAnalyticEvents.API_CLIENTS_DETAILS_DIALOG)
    this.clientDetails = client

    this.ngZone.run(() => {
      this.clientDetailsModal.nativeElement.open().then((_result) => {
        this.mixpanel.track(
          FrontendAnalyticEvents.API_CLIENTS_DETAILS_DIALOG_CLOSE,
        )
      })
    })
  }

  public openConnectionInfoModal(client) {
    this.mixpanel.track(
      FrontendAnalyticEvents.API_CLIENTS_CONNECTIONINFO_DIALOG,
    )
    this.clientDetails = client
    this.ngZone.run(() => {
      this.connectionInfoModal.nativeElement.open().then((_result) => {
        this.mixpanel.track(
          FrontendAnalyticEvents.API_CLIENTS_CONNECTIONINFO_DIALOG_CLOSE,
        )
      })
    })
  }

  public updateAllClusterPermissions() {
    this.allClusterSelected = this.clusterPermissions.every(
      (perm) => perm.selected,
    )
  }

  public updateAllZeebeClientPermissions() {
    this.allZeebeSelected = this.zeebeClientPermissions.every(
      (perm) => perm.selected,
    )
  }

  public setAllClusterPermissions(selected: boolean) {
    this.allClusterSelected = selected

    this.clusterPermissions.map(
      (permission) => (permission.selected = selected),
    )
  }

  public setClusterPermission(name: string, value: boolean) {
    const clusterPermission = this.clusterPermissions.find(
      (permission) => permission.name === name,
    )

    if (clusterPermission) {
      clusterPermission.selected = value
    }

    if (value === false) {
      this.allClusterSelected = false
    } else {
      for (const permission of this.clusterPermissions) {
        if (!permission.selected) {
          this.allClusterSelected = false
          return
        }
      }

      this.allClusterSelected = true
    }
  }

  public setAllZeebeClientPermissions(selected: boolean) {
    this.allZeebeSelected = selected
    this.zeebeClientPermissions.map(
      (zeebePerm) => (zeebePerm.selected = selected),
    )
  }

  public setZeebeClientPermission(name: string, value: boolean) {
    const zeebeClientPermission = this.zeebeClientPermissions.find(
      (permission) => permission.name === name,
    )

    if (zeebeClientPermission) {
      zeebeClientPermission.selected = value
    }

    if (value === false) {
      this.allZeebeSelected = false
    } else {
      for (const permission of this.zeebeClientPermissions) {
        if (!permission.selected) {
          this.allZeebeSelected = false
          return
        }
      }

      this.allZeebeSelected = true
    }
  }

  public someClusterSelected() {
    const numberOfSelectedClusterPermissions = this.clusterPermissions.reduce(
      (prev, curr) => {
        return prev + Number(curr.selected)
      },
      0,
    )

    return (
      numberOfSelectedClusterPermissions > 0 &&
      numberOfSelectedClusterPermissions < this.clusterPermissions.length
    )
  }

  public someZeebeClientSelected() {
    const numberOfSelectedZeebeClientPermissions = this.zeebeClientPermissions.reduce(
      (prev, curr) => {
        return prev + Number(curr.selected)
      },
      0,
    )

    return (
      numberOfSelectedZeebeClientPermissions > 0 &&
      numberOfSelectedZeebeClientPermissions <
        this.zeebeClientPermissions.length
    )
  }

  public noScopeChecked() {
    return this.clusterPermissions
      .concat(this.zeebeClientPermissions)
      .reduce((prev, curr) => prev && !curr.selected, true)
  }

  private loadClients() {
    this.apiService
      .listApiClients(this.currentOrg.uuid)
      .subscribe((clients) => {
        this.clients = OrganizationCloudApiComponent.improveClients(clients)
        this.updateClients()
      })
  }

  private updateClients() {
    this.clientListEntities = this.clients.map((client) => {
      return {
        data: [
          { type: 'image', src: this.CLIENT_ICON },
          { type: 'text', content: client.name },
          { type: 'text', content: client.clientId, showCopyButton: true },
          {
            type: 'text',
            content: `${
              client.permissions.cluster.length === 0
                ? 'None'
                : client.permissions.cluster.join(', ')
            }`,
          },
          {
            type: 'text',
            content: `${
              client.permissions.zeebe.length === 0
                ? 'None'
                : client.permissions.zeebe.join(', ')
            }`,
          },

          {
            type: 'button',
            label: 'View',
            onPress: () => this.openConnectionInfoModal(client),
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: [
                  {
                    label: 'Details',
                    handler: () => {
                      this.openDetailsModal(client)
                    },
                  },
                  {
                    label: 'Update',
                    handler: () => this.openUpdateClientModal(null, client),
                    isDisabled: this.vss.visibilities.apiclients.update
                      .disabled,
                  },
                ],
              },
              {
                options: [
                  {
                    label: 'Delete',
                    isDangerous: true,
                    handler: () => {
                      this.openDeleteModal(client.clientId)
                    },
                    isDisabled: this.vss.visibilities.apiclients.delete
                      .disabled,
                  },
                ],
              },
            ],
          },
        ],
      }
    })
    this.clientListLoading = false
  }

  private CLIENT_ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="20" viewBox="0 0 21 20" fill="#62626E">
      <path fill-rule="evenodd" d="M7.18485971,8.99679487 L11.9366173,13.7485524 L10.2652597,15.41991 C9.17470745,16.5104623 7.59440029,16.7602773 6.32896098,16.1884199 L6.32896098,16.1884199 L2.83167264,19.6857082 L1.24774108,18.1017766 L4.74502942,14.6044883 C4.16894342,13.3432033 4.42294988,11.7587047 5.51350216,10.6681524 L5.51350216,10.6681524 L7.18485971,8.99679487 Z M18.7687913,0.398898178 C19.2283979,-0.0607084992 19.9568601,-0.0843513636 20.3963977,0.355186212 C20.8359353,0.794723787 20.8122924,1.52318594 20.3526857,1.98279262 L20.3526857,1.98279262 L17.0100098,5.32546854 L18.5939414,6.9094001 L13.5799079,11.9234336 L8.82815032,7.171676 L13.8421838,2.15764253 L15.4261154,3.7415741 Z"/>
  </svg>
  `),
    ),
  )}`

  private loadPermissions() {
    this.apiService
      .loadApiClientPermissions(this.currentOrg.uuid)
      .subscribe((permissions) => {
        this.clusterPermissions = permissions
          .filter((perm) => perm.includes('Cluster'))
          .map((perm) => {
            return {
              name: perm.replace('Cluster', ''),
              selected: false,
            }
          })

        this.zeebeClientPermissions = permissions
          .filter((perm) => perm.includes('Zeebe'))
          .map((perm) => {
            return {
              name: perm.replace('ZeebeClient', ''),
              selected: false,
            }
          })
      })
  }

  private downloadCurl(client) {
    this.mixpanel.track(FrontendAnalyticEvents.API_CLIENTS_DOWNLOAD_CURL)
    const a = document.createElement('a')
    a.download = `CamundaCloudMgmtAPI-Client-${client.name}.sh`
    let content = `#!/bin/sh 
curl --header "Content-Type: application/json" --request POST --data '{"grant_type":"client_credentials", "audience":"api.${this.audience}", "client_id":"${client.client_id}", "client_secret":"${client.client_secret}"}' https://login.${this.audience}/oauth/token`
    a.href = window.URL.createObjectURL(
      new Blob([content], { type: 'text/x-shellscript' }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }
}
