import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'

import { ActivatedRoute } from '@angular/router'
import { K8sContextDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { ApiService } from '../../../services/api.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'kubernetes-context-page',
  templateUrl: './kubernetes-context.page.html',
})
export class KubernetesContextPage implements OnInit {
  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('addModal', { read: ElementRef })
  public addModal: ElementRef<CmModal>

  @ViewChild('updateModal', { read: ElementRef })
  public updateModal: ElementRef<CmModal>

  public k8sContextsInDb: K8sContextDto[]
  public selectedContext: K8sContextDto

  public columns = [
    { name: 'Name', width: '150px' },
    { name: 'Kubernetes Identifier', width: '2fr', ellipsis: 'left' },
    { name: 'KubeConfig Path', width: '2fr', ellipsis: 'left' },
    { name: 'Thanos URL', width: '2fr', ellipsis: 'right' },
    { name: 'Region', width: '130px' },
    { name: 'Zone', width: '35px' },
    { name: 'Clusters', width: '55px' },
    { name: '', width: '20px' },
  ]

  public entities = []
  public createHandler: () => void

  public noWhitespaceValidator = {
    type: 'custom',
    validator(value: string) {
      if (value.includes(' ')) {
        return {
          isValid: false,
          type: 'invalid',
          message: 'A file path can not contain spaces.',
        }
      } else {
        return { isValid: true }
      }
    },
  }

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    public vss: ViewVisibilitiesService,
    private ngZone: NgZone,
  ) {}

  public ngOnInit() {
    this.createHandler = () => {
      this.openAddModal()
    }
    this.k8sContextsInDb = this.route.snapshot.data.k8sContexts
    this.updateEntities()
  }

  public updateEntities() {
    this.entities = this.k8sContextsInDb.map((k8sContext) => {
      let contextMenuOptions = []

      if (this.vss.visibilities.admin.k8scontext.update.visible) {
        contextMenuOptions.push({
          options: [
            {
              label: 'Update',
              handler: () => this.openUpdateModal(k8sContext),
            },
          ],
        })
      }

      if (this.vss.visibilities.admin.k8scontext.delete.visible) {
        contextMenuOptions.push({
          options: [
            {
              label: 'Delete',
              handler: () => this.openDeleteModal(k8sContext),
              isDangerous: true,
              isDisabled: !k8sContext.kubeConfigFilePath,
            },
          ],
        })
      }

      return {
        data: [
          { type: 'text', content: k8sContext.name },
          {
            type: 'text',
            content: k8sContext.k8sIdentifier,
            showCopyButton: true,
          },
          {
            type: 'text',
            content: k8sContext.kubeConfigFilePath,
            showCopyButton: true,
          },
          {
            type: 'text',
            content: k8sContext.thanosEndpoint,
            showCopyButton: true,
          },
          { type: 'text', content: k8sContext.region },
          { type: 'text', content: k8sContext.zone },
          { type: 'text', content: k8sContext.clusterCount },
          { type: 'contextMenu', options: contextMenuOptions },
        ],
      }
    })
  }

  private refreshContexts(): void | PromiseLike<void> {
    return this.apiService
      .getK8sContextsInDb(true)
      .toPromise()
      .then((kubernetesContext) => {
        this.k8sContextsInDb = kubernetesContext
        this.updateEntities()
      })
  }

  public openAddModal() {
    this.ngZone.run(() => {
      this.addModal.nativeElement.open({
        preConfirmationHandler: (data) => {
          return this.apiService
            .createInDbContextFromNativeK8sContext(
              data.formData.contextIdentifier as string,
              data.formData.kubeConfigFilePath as string,
              data.formData.name as string,
              data.formData.region as string,
              data.formData.zone as string,
              data.formData.thanosEndpoint as string,
            )
            .toPromise()
            .then(() => {
              return this.refreshContexts()
            })
        },
      })
    })
  }

  public openUpdateModal(k8scontext: K8sContextDto) {
    this.selectedContext = k8scontext

    this.ngZone.run(() => {
      this.updateModal.nativeElement.open({
        preConfirmationHandler: (data) => {
          return this.apiService
            .updateInDbK8sContext(
              this.selectedContext.uuid,
              data.formData.contextIdentifier as string,
              data.formData.kubeConfigFilePath as string,
              data.formData.name as string,
              data.formData.region as string,
              data.formData.zone as string,
              data.formData.thanosEndpoint as string,
            )
            .toPromise()
            .then(() => {
              return this.refreshContexts()
            })
        },
      })
    })
  }

  public openDeleteModal(k8scontext: K8sContextDto) {
    this.selectedContext = k8scontext

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open({
        preConfirmationHandler: () => {
          return this.apiService
            .deleteInDbK8sContext(k8scontext.uuid)
            .toPromise()
            .then(() => {
              return this.refreshContexts()
            })
        },
      })
    })
  }
}
