<cm-entity-list
  [enableCreateButton]="!vvs.visibilities.alerts.create.disabled"
  [hideCreateButton]="!vvs.visibilities.alerts.create.visible"
  headline="Alerts"
  createButtonLabel="Create"
  [createHandler]="alertListCreateHandler"
  [columns]="alertListColumns"
  [entities]="alertListEntities"
  [loading]="alertListLoading"
  style="margin-bottom: 15px"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList">
      <h3>No Alerts created</h3>

      <cm-text>
        Want to be notified when an important event occurs in the Cluster?
        <cm-button
          appearance="link"
          (cmPress)="openCreateSubscriptionModal()"
          label="Create a new Alert"
          [disabled]="vvs.visibilities.alerts.create.disabled"
        ></cm-button>
        to get informed, and check the
        <cm-link
          href="https://docs.camunda.io/docs/product-manuals/cloud-console/manage-clusters/manage-alerts"
          label="Alerts"
        ></cm-link>
        documentation to learn how to configure an Alert.
      </cm-text>
    </div>
  </div>
</cm-entity-list>

<cm-text *ngIf="alertSubscriptions && alertSubscriptions.length > 0">
  Alerts can be used to get notified about important events in the Cluster. See
  the
  <cm-link
    href="https://docs.camunda.io/docs/product-manuals/cloud-console/manage-clusters/manage-alerts"
    label="Alerts"
  ></cm-link>
  documentation to learn more details.
</cm-text>

<cm-modal
  #createModal
  headline="{{ alertUpdate ? 'Update Alert' : 'Create New Alert' }}"
  cancelLabel="Cancel"
  confirmLabel="{{ alertUpdate ? 'Update' : 'Create' }}"
  position="top"
>
  <cm-form>
    <div style="display: grid; gap: 8px">
      <cm-text appearance="emphasis">Alert Type</cm-text>
      <select
        *ngIf="alertTypes.length > 1"
        name="Alert Type"
        [(ngModel)]="selectedAlertType"
      >
        <option *ngFor="let type of alertTypes" [value]="type.value">
          {{ type.label }}
        </option>
      </select>
      <cm-text *ngIf="alertTypes.length === 1">{{
        alertTypes[0].label
      }}</cm-text>
    </div>

    <cm-radiobutton-group
      label="Report Type"
      formName="selectedReportType"
      [value]="selectedReportType"
      (cmSelected)="selectedReportType = $event.detail.value"
    >
      <cm-radiobutton
        label="Email (to {{ userEmail }})"
        value="Email"
        [disabled]="!alertUpdate && this.hasEmailSubscription()"
      ></cm-radiobutton>
      <cm-radiobutton label="Webhook" value="Webhook"></cm-radiobutton>

      <cm-textfield
        *ngIf="selectedReportType === 'Webhook'"
        label="Webhook URL"
        [value]="webhookUrl"
        [fieldPrefix]="webhookTextfieldPrefix"
        formName="webhookURL"
        required
      ></cm-textfield>
    </cm-radiobutton-group>
  </cm-form>
</cm-modal>

<cm-modal
  #detailModal
  headline="Alert Details"
  confirmLabel="Close"
  paddings="horizontal"
>
  <cm-datalist *ngIf="currentSubscription">
    <cm-datalist-item label="Type">
      <cm-text>{{ convertAlertType(currentSubscription.type) }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Recipient">
      <cm-text>{{ currentSubscription.userIdOrWebhook }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Created at">
      <cm-text>{{ currentSubscription.created | date: 'medium' }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Created by">
      <cm-text>{{ currentSubscription.createdBy }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Updated at">
      <cm-text>{{
        currentSubscription.updatedBy
          ? (currentSubscription.updated | date: 'medium')
          : '-'
      }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Updated by">
      <cm-text>{{
        currentSubscription.updatedBy ? currentSubscription.updatedBy : '-'
      }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Last time triggered">
      <cm-text>{{
        currentSubscription.lastTimeTriggered
          ? (currentSubscription.lastTimeTriggered | date: 'medium')
          : '-'
      }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Number of times triggered">
      <cm-text>{{ currentSubscription.numberOfTimesTriggered }}</cm-text>
    </cm-datalist-item>
  </cm-datalist>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Alert Subscription"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text *ngIf="currentSubscription">
    Are you sure you want to delete the Alert Subscription
    <b>{{ convertAlertType(currentSubscription.type) }}</b> for
    <b>"{{ currentSubscription ? currentSubscription.userIdOrWebhook : '' }}"</b
    >?
  </cm-text>
</cm-modal>
