import { Component } from '@angular/core'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'signup-page',
  templateUrl: './signup.page.html',
})
export class SignupPage {
  constructor() {
    window.location.href = `${environment.accountsUrl}/signup`
  }
}
