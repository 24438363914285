<div class="list-header">
  <h1 class="list-title">Billing</h1>
  <cm-button
    *ngIf="!edit"
    [disabled]="vss.visibilities.billing.reservations.update.disabled"
    appearance="primary"
    (cmPress)="editing(true)"
    label="Edit Reservations"
  >
  </cm-button>
  <div id="editButtons" *ngIf="edit">
    <cm-button (cmPress)="editing(false)" label="Cancel"></cm-button>
    <cm-button
      appearance="primary"
      [disabled]="!(dirty$ | async) || loading"
      (cmPress)="showSaveModal(org, reservations)"
      label="Save"
    ></cm-button>
  </div>
</div>

<div *ngIf="showSavedMessage" class="message">
  You reservations have been updated.
</div>

<div class="cluster-list-container" style="position: relative">
  <div class="loader" *ngIf="loading">
    <cm-loader></cm-loader>
  </div>
  <div class="cluster-list-index">
    <span class="cluster-list-index-name">Type</span>
    <span class="cluster-list-index-region">Region</span>
    <span class="cluster-list-index-usage">Current usage</span>
    <span class="cluster-list-index-reserved">Reserved</span>
    <span *ngIf="edit" class="cluster-list-index-limit">Limit</span>
    <span class="cluster-list-index-partitions">Partitions</span>
    <span class="cluster-list-index-monthly-price">Monthly price per unit</span>
  </div>
  <ul class="cluster-list">
    <li *ngFor="let reservation of reservations" class="cluster-list-item">
      <div class="cluster-list-item-card">
        <div class="cluster-list-item-inner">
          <span class="cluster-list-item-name"
            >{{ reservation.clusterPlanName }}
            <div class="description">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                <circle
                  fill-rule="evenodd"
                  cx="8"
                  cy="8"
                  r="7"
                  fill="#62626E"
                />
                <path
                  fill-rule="evenodd"
                  fill="#FFF"
                  d="M9,7 L9,12 L7,12 L7,7 L9,7 Z M8,4 C8.55228475,4 9,4.44771525 9,5 C9,5.55228475 8.55228475,6 8,6 C7.44771525,6 7,5.55228475 7,5 C7,4.44771525 7.44771525,4 8,4 Z"
                />
              </svg>
              <div class="descriptiontext">
                <h3>{{ reservation.clusterPlanName }}</h3>
                <div [innerHtml]="reservation.description"></div>
              </div>
            </div>
          </span>
          <span class="cluster-list-item-region">{{ reservation.region }}</span>
          <span class="cluster-list-item-usage">{{ reservation.usage }}</span>
          <span class="cluster-list-item-reserved">
            <input
              class="edit-reservation-input"
              *ngIf="edit && reservation.editable"
              onkeydown="return false"
              min="{{ reservation.minReservations }}"
              max="{{ reservation.maxReservations }}"
              type="number"
              [(ngModel)]="newReservationsBuffer[reservation.clusterPlanUuid]"
              (change)="triggerDirty()"
            />
            <cm-text *ngIf="!edit && !reservation.editable">
              {{ reservation.currentReservations }}
            </cm-text>
            <cm-text *ngIf="!edit && reservation.editable">
              {{ newReservationsBuffer[reservation.clusterPlanUuid] }}
            </cm-text>
          </span>
          <span *ngIf="edit" class="cluster-list-item-limit">
            {{ reservation.editable ? reservation.maxReservations : '' }}
          </span>
          <span class="cluster-list-item-partitions">
            {{ reservation.partitions }}
          </span>
          <span
            *ngIf="reservation.price"
            class="cluster-list-item-monthly-price"
          >
            {{ reservation.price }}
          </span>
          <span
            *ngIf="!reservation.price"
            class="cluster-list-item-monthly-price"
          >
            Included
          </span>
        </div>
      </div>
    </li>
  </ul>
</div>

<cm-text *ngIf="edit" appearance="helperText">
  If you need to increase your reservation's limits, contact your Camunda
  Account Manager.
</cm-text>

<cm-modal
  #confirmReservationChangeModal
  headline="Reservation Summary"
  confirmLabel="Confirm"
  cancelLabel="Cancel"
>
  <table>
    <tr>
      <th style="width: 120px">
        <cm-text appearance="emphasis">Type</cm-text>
      </th>
      <th style="width: 120px; padding: 5px">
        <cm-text appearance="emphasis">Region</cm-text>
      </th>
      <th style="width: 200px; padding: 5px">
        <cm-text appearance="emphasis">Reservation Update</cm-text>
      </th>
      <th><cm-text appearance="emphasis">Monthly price per unit</cm-text></th>
    </tr>
    <tr *ngFor="let res of deltas">
      <td>
        <cm-text>{{ res.name }}</cm-text>
      </td>
      <td style="padding: 5px">
        <cm-text>{{ res.region }}</cm-text>
      </td>
      <td style="padding: 5px">
        <cm-text>{{ res.update }}</cm-text>
      </td>
      <td style="text-align: right">
        <cm-text>{{ res.price }}</cm-text>
      </td>
    </tr>
  </table>
  <br />
  <br />
  <cm-text>
    Additional reservations will be charged directly to the account at the point
    of creation.
  </cm-text>
</cm-modal>
