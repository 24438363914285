<div class="layoutWrapper">
  <cm-entity-list
    [hideCreateButton]="!vvs.visibilities.modeler.create.visible"
    headline="Diagrams"
    createButtonLabel="Create New Diagram"
    [createHandler]="modelListCreateHandler"
    [columns]="modelListColumns"
    [entities]="modelListEntities"
    [loading]="modelListLoading"
    [groupOptions]="groupOptions"
    [defaultSorting]="{ columnIndex: 5, method: 'descending' }"
  >
    <div slot="empty" class="emptyEntityListContainer">
      <div class="emptyEntityList"><h3>No Diagrams created</h3></div>
    </div>
  </cm-entity-list>

  <div *ngIf="!vvs.visibilities.modeler.read.visible">
    Sorry, you are not allowed to see the Models
  </div>

  <div
    class="card-container"
    *ngIf="
      vvs.visibilities.featureFlags.newOnboardingExperience.visible &&
      (showZeebeCard || showGithubCard)
    "
  >
    <cm-card
      isDismissable="true"
      (cmDismissed)="dismissZeebeModeler()"
      [ngClass]="{ hidden: !showZeebeCard }"
    >
      <div slot="header">
        <svg
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <rect id="a" x="0" y="0" width="18" height="18" rx="3.889" />
            <rect id="c" x="0" y="0" width="18" height="18" rx="3.889" />
            <rect id="e" x="0" y="0" width="18" height="18" rx="3.889" />
            <rect id="g" x="0" y="0" width="18" height="18" rx="3.889" />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <rect
              width="18"
              height="18"
              rx="3.889"
              fill="#FF3A00"
              fill-rule="nonzero"
              transform="translate(1 1)"
            />
            <g transform="translate(1 1)">
              <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
              <path
                fill="#FF5400"
                fill-rule="nonzero"
                mask="url(#b)"
                d="M9 9l12.25 17.25L-2 24.5z"
              />
            </g>
            <g transform="translate(1 1)">
              <mask id="d" fill="#fff"><use xlink:href="#c" /></mask>
              <path
                fill="#FF6F00"
                fill-rule="nonzero"
                mask="url(#d)"
                d="M9 9l20 10-10 7.25z"
              />
            </g>
            <g transform="translate(1 1)">
              <mask id="f" fill="#fff"><use xlink:href="#e" /></mask>
              <path
                fill="#FF8A01"
                fill-rule="nonzero"
                mask="url(#f)"
                d="M9 9l3.75-17.75L29 20z"
              />
            </g>
            <g transform="translate(1 1)">
              <mask id="h" fill="#fff"><use xlink:href="#g" /></mask>
              <path
                fill="#FF6200"
                fill-rule="nonzero"
                mask="url(#h)"
                d="M9 9L-6.25-1.75l18.75-3.5z"
              />
            </g>
            <g fill="#FFF" fill-rule="nonzero">
              <path
                d="M8 14h4v2.5H8zM10 12.525c1.308 0 2-.8 2-2.15V9.138h-1.284v1.337c0 .575-.272.8-.68.8-.407 0-.678-.225-.678-.8V5.588c0-.575.271-.813.679-.813.407 0 .679.238.679.813V6.6H12v-.925c0-1.35-.692-2.15-2-2.15-1.309 0-2 .8-2 2.15v4.7c0 1.35.691 2.15 2 2.15z"
              />
            </g>
          </g>
        </svg>

        <h3>Camunda Modeler for Desktop</h3>
      </div>

      <cm-text>
        Camunda Modeler is a desktop application that can be installed and used
        locally for modeling Diagrams with BPMN.
        <br /><br />
        The application can be run on Windows, MacOS, and Linux.
      </cm-text>

      <div slot="footer">
        <cm-link
          href="https://camunda.com/download/modeler"
          label="Download"
        ></cm-link>
        <cm-link
          href="https://docs.camunda.io/docs/guides/getting-started/model-your-first-process"
          label="Learn more"
        ></cm-link>
      </div>
    </cm-card>

    <cm-card
      isDismissable="true"
      (cmDismissed)="dismissGithubSamples()"
      [ngClass]="{ hidden: !showGithubCard }"
    >
      <div slot="header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <defs>
            <path
              id="github-a"
              d="M8,0 C12.418278,0 16,3.581722 16,8 C16,11.6084066 13.6109968,14.6588316 10.3283852,15.6558801 L10.3297901,15.6665786 C10.3267845,15.6442043 10.3252812,15.6216538 10.3252901,15.5990786 C10.3317901,15.3225786 10.3422901,14.4090786 10.3422901,13.2770786 C10.3855798,12.6959664 10.1759957,12.1244398 9.76729011,11.7090786 C11.6537901,11.5025786 13.6327901,10.7840786 13.6327901,7.53157855 C13.6457969,6.69083559 13.3341921,5.87743968 12.7627901,5.26057855 C13.0209455,4.53006114 12.9907246,3.72858109 12.6782901,3.01957855 C12.6782901,3.01957855 11.9687901,2.79207855 10.3527901,3.88807855 C8.9652937,3.50841248 7.50128651,3.50841248 6.11379011,3.88807855 C4.49729011,2.79257855 3.78579011,3.01957855 3.78579011,3.01957855 C3.47374485,3.72875079 3.44405908,4.53026645 3.70279011,5.26057855 C3.13119479,5.87746175 2.81894542,6.69067084 2.83079011,7.53157855 C2.83079011,10.7755786 4.80729011,11.5025786 6.68679011,11.7155786 C6.37851499,12.0168488 6.1879108,12.4182701 6.14929011,12.8475786 L6.13329011,12.8565786 C5.71398348,13.0873072 5.22014614,13.141858 4.76058948,13.0082114 C4.30103281,12.8745648 3.91346786,12.5636878 3.68329011,12.1440786 C3.4088916,11.6687837 2.93042817,11.3467942 2.38679011,11.2715786 L2.36433461,11.2717961 C2.23689011,11.2747436 1.63434011,11.3131286 2.32779011,11.7860786 C2.77885532,12.0735359 3.11226047,12.5128149 3.26779011,13.0245786 C3.26779011,13.0245786 3.76429011,14.6710786 6.11779011,14.1585786 C6.12099011,14.7245786 6.12611011,15.1782586 6.12803011,15.4397466 L6.12879011,15.5985786 L6.12879011,15.5985786 C6.12881426,15.6206681 6.13172612,15.7554933 6.12891592,15.780608 C2.61304185,14.9373158 0,11.7738122 0,8 C0,3.581722 3.581722,0 8,0 Z"
            />
          </defs>
          <g fill="none" fill-rule="evenodd" transform="translate(2 2)">
            <circle cx="8" cy="8" r="8" fill="#FFF" fill-rule="nonzero" />
            <use fill="#000" fill-rule="nonzero" xlink:href="#github-a" />
          </g>
        </svg>
        <h3>Sample Projects on GitHub</h3>
      </div>

      <cm-text>
        Visit our Sample Projects repository on GitHub to learn how to bootstrap
        your first project.
      </cm-text>

      <div slot="footer">
        <cm-link
          href="https://github.com/camunda-cloud/camunda-cloud-get-started"
          label="Visit GitHub Project"
        ></cm-link>
      </div>
    </cm-card>
  </div>
</div>

<cm-modal
  #multiDeleteModal
  headline="Delete Models"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete these Models?
    <ul>
      <li *ngFor="let modelToBeDeleted of modelsToBeDeleted">
        <b>{{ modelToBeDeleted.name }}</b>
      </li>
    </ul>
  </cm-text>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Model"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete
    <b>{{ modelToBeDeleted ? modelToBeDeleted.name : '' }}</b
    >?
  </cm-text>
</cm-modal>

<cm-modal
  #deployModal
  headline="Deploy Diagram"
  cancelLabel="Cancel"
  confirmLabel="{{
    clusters && clusters.length > 0 ? 'Deploy' : 'Create Cluster'
  }}"
  [confirmDisabled]="
    (clusters && clusters.length > 0 && !allowDeploy) ||
    (!(clusters && clusters.length > 0) &&
      vvs.visibilities.modeler.cluster.create.disabled)
  "
>
  <cm-banner
    *ngIf="
      vvs.visibilities.modeler.message.noclusters.visible && !creatingTheCluster
    "
    appearance="warning"
  >
    <cm-text>
      Unfortunately, you have no Clusters to deploy to. Please create a Cluster.
    </cm-text>
  </cm-banner>

  <cm-form>
    <ng-container *ngIf="clusters && clusters.length > 0">
      <cm-form-group label="Select Cluster">
        <cm-text>Your Diagram will be executed in Cluster:</cm-text>
      </cm-form-group>
      <cm-radiobutton-group
        [value]="selectedClusterUuid"
        formName="selectedClusterUuid"
      >
        <div *ngFor="let cluster of clusters; let i = index">
          <cm-radiobutton
            [value]="cluster.uuid"
            [label]="cluster.name"
            [helperText]="cluster.uuid"
            [disabled]="isUnsupportedAlpha(cluster)"
            (cmSelected)="recalcAllowDeploy(cluster)"
          ></cm-radiobutton>
          <div class="status">
            <span
              *ngIf="cluster.status.ready === 'Healthy'"
              class="cluster-state ready"
            ></span>
            <span
              *ngIf="
                cluster.status.ready !== 'Creating' &&
                cluster.status.ready !== 'Healthy'
              "
              class="cluster-state not-ready"
            ></span>
            <span
              *ngIf="cluster.status.ready === 'Creating'"
              class="cluster-state creating"
            ></span>
            <cm-text>{{ cluster.status.ready }}</cm-text>
          </div>
        </div>
      </cm-radiobutton-group>
    </ng-container>
    <cm-text *ngIf="getHealthyClusters().length === 0">
      Please wait until at least one Cluster is healthy.
    </cm-text>
  </cm-form>
</cm-modal>

<cm-modal #deployFailedModal headline="Deploy failed" confirmLabel="Ok">
  <cm-text>
    Deploy diagram on cluster {{ selectedClusterUuid }} failed:
    <p>{{ deployErrorMessage }}</p>
  </cm-text>
</cm-modal>

<cm-modal
  #deploySuccessModal
  headline="Deploy successful"
  cancelLabel="Ok"
  confirmLabel="Go to Operate"
>
  <cm-text>Deployment was successful. Check your Process in Operate.</cm-text>
</cm-modal>

<cm-modal
  #startInstanceModal
  headline="Start Instance"
  cancelLabel="Cancel"
  confirmLabel="Start Instance"
  confirmAppearance="primary"
>
  <cm-form>
    <cm-text>
      Start an Instance on Cluster
      {{
        selectedModel && selectedModel.deployedOnCluster
          ? '"' + selectedModel.deployedOnCluster.name + '"'
          : ''
      }}
    </cm-text>
    <cm-form-group label="Add Variable (Optional)">
      <cm-text>
        Optionally specify data to add to the newly created Instance. Variables
        can be accessed inside the Process Instance. Read more about
        <cm-link
          href="https://docs.camunda.io/docs/product-manuals/concepts/variables"
          label="Variables"
        ></cm-link>
      </cm-text>

      <cm-textfield
        label="Variable"
        helperText='in JSON format e.g., {"orderNumber": "A12BH98", "date": "2020-10-15", "amount": 185.34}'
        type="multiline"
        showRequired
        formName="startInstanceMetadata"
        [validation]="isValidJson"
      ></cm-textfield>
    </cm-form-group>
  </cm-form>
</cm-modal>

<cm-modal
  #startInstanceFailedModal
  headline="Start Instance failed"
  confirmLabel="Ok"
>
  <cm-text *ngIf="selectedModel">
    Start Instance on cluster
    {{
      selectedModel.deployedOnCluster
        ? selectedModel.deployedOnCluster.name
        : ''
    }}
    failed:
    <p>{{ startInstanceErrorMessage }}</p>
  </cm-text>
</cm-modal>

<cm-modal
  #startInstanceSuccessModal
  headline="Start Instance successful"
  cancelLabel="Ok"
  confirmLabel="Go to Operate"
>
  <cm-text>
    Process Instance Start was successful. Review your Process in Operate.
  </cm-text>
</cm-modal>

<cm-modal
  #renameModal
  headline="Rename Diagram"
  cancelLabel="Cancel"
  confirmLabel="Rename"
>
  <cm-form>
    <cm-textfield
      label="Current Name"
      disabled
      [value]="modelToBeRenamed ? modelToBeRenamed.name : ''"
    ></cm-textfield>
    <cm-textfield
      label="New Name"
      maxlength="50"
      formName="newName"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>
  </cm-form>
</cm-modal>

<create-cluster-dialog></create-cluster-dialog>
