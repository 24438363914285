<cm-modal
  #selectTemplateModal
  headline="Select Template"
  [confirmLabel]="!vvs.visibilities.modeler.save.disabled ? 'Open' : 'OK'"
  position="top"
  width="730"
>
  <div class="container" *ngIf="templateCategories">
    <div class="sidebar-templates">
      <h4 class="title">Templates</h4>
      <div class="categories">
        <div class="category" *ngFor="let category of templateCategories">
          <div
            class="categoryHeader"
            (click)="expandCategory(category, !category.expanded)"
          >
            <cm-icon [icon]="category.expanded ? 'down' : 'right'"></cm-icon>
            <span [class.category-selected]="category.selected">
              {{ category.name }}
            </span>
          </div>
          <div class="templates" *ngIf="category.expanded">
            <div
              class="template"
              [class.template-selected]="template.selected"
              *ngFor="let template of category.templates"
              (click)="selectTemplate(template)"
            >
              {{ template.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-startblank" (click)="createBlank()">
      <cm-icon icon="plus"></cm-icon>
      <cm-text>Create Blank</cm-text>
    </div>
    <div class="details">
      <span *ngIf="selectedTemplate">
        <h4 class="title">{{ selectedTemplate.name }}</h4>
        <div [innerHTML]="selectedTemplate.description"></div>
        <bpmn-viewer
          [bpmnXml]="selectedTemplate.diagram"
          [options]="{ fitViewPort: true, height: '200px' }"
        ></bpmn-viewer>
      </span>
    </div>
  </div>
</cm-modal>
