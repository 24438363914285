import {
  ClusterPlanTypesToReserved,
  OrganizationDto,
  OrganizationPermissions,
  OrganizationRole,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'

export class OrgVisibilityUtils {
  constructor(
    private org: OrganizationDto,
    private orgs: Array<{ id: string; roles: string[] }>,
  ) {}

  public isSalesPlanTypeFree(): boolean {
    return this.isSalesPlanType(SalesPlanType.FREE)
  }

  public isSalesPlanTypeTrial(): boolean {
    return this.isSalesPlanType(SalesPlanType.TRIAL)
  }

  public isSalesPlanTypeInNegotiation(): boolean {
    return this.isSalesPlanType(SalesPlanType.IN_NEGOTIATION)
  }

  public isSalesPlanTypePaid(): boolean {
    return (
      this.isSalesPlanType(SalesPlanType.PAID) ||
      this.isSalesPlanType(SalesPlanType.PAID_CC)
    )
  }

  public isSalesPlanTypePaidCC(): boolean {
    return this.isSalesPlanType(SalesPlanType.PAID_CC)
  }

  public isSalesPlanTypeEnterprise(): boolean {
    return this.isSalesPlanType(SalesPlanType.ENTERPRISE)
  }

  public isSalesPlanAttached(): boolean {
    const orgToSalesPlan =
      this.org && this.org.organizationToSalesPlan
        ? this.org.organizationToSalesPlan
        : undefined
    return orgToSalesPlan ? true : false
  }

  public isSalesPlanTypeTrialAndExpired(): boolean {
    return this.org &&
      this.org.organizationToSalesPlan &&
      this.org.organizationToSalesPlan.salesPlan &&
      this.isSalesPlanType(SalesPlanType.TRIAL) &&
      this.org.organizationToSalesPlan.trialStateExpired
      ? true
      : false
  }

  public getSalesPlanReservations(): ClusterPlanTypesToReserved {
    const salesPlanReservations = this.org.organizationToSalesPlan!
      .clusterPlanTypesToReserved
    return salesPlanReservations
  }

  public hasSubscription(): boolean {
    return this.org &&
      this.org.chargebeeSite &&
      this.org.subscriptionIds &&
      this.org.subscriptionIds.length > 0
      ? true
      : false
  }

  public getOrgPermissions(): OrganizationPermissions {
    if (this.org && this.org.permissions) {
      return this.org.permissions
    } else {
      const emptyPermissions: OrganizationPermissions = {
        org: {
          clusters: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          clients: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          settings: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          usage: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          billing: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          users: {
            general: {
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            owner: {
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            admin: {
              create: false,
              read: false,
              update: false,
              delete: false,
            },
            member: {
              create: false,
              read: false,
              update: false,
              delete: false,
            },
          },
          activity: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          diagrams: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          forms: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          instances: {
            create: false,
            read: false,
            update: false,
            delete: false,
          }, // create === start new instances
          workflows: {
            create: false,
            read: false,
            update: false,
            delete: false,
          }, // create === deploy diagrams
          webide: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        },
        cluster: {
          clients: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          alerts: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          operate: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          tasklist: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          optimize: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
          ipWhitelist: {
            create: false,
            read: false,
            update: false,
            delete: false,
          },
        },
        // legacy permissions
        manageAdmins: false,
        manageClients: false,
        manageMembers: false,
        manageSettings: false,
        manageClusters: false,
        updateClusters: false,
        readClusters: false,
        readSettings: false,
        seeBilling: false,
        auditRead: false,
        seeMembers: false,
        useOperate: false,
        deployAndStartModels: false,
        manageModels: false,
        readModels: false,
        readAlertSubscriptions: false,
        manageAlertSubscriptions: false,
      }
      return emptyPermissions
    }
  }

  public isUserSupportAgent() {
    const foundOrg = this.orgs.find((org) => org.id === this.org.uuid)
    if (foundOrg) {
      return (
        foundOrg.roles.find(
          (allRoles) => allRoles === OrganizationRole.SUPPORTAGENT,
        ) !== undefined
      )
    } else {
      return false
    }
  }

  // Initial Pricing until GA
  public isPricing0(): boolean {
    return this.isPricingVersion(0)
  }

  // Pricing 1 starting with GA
  public isPricing1(): boolean {
    return this.isPricingVersion(1)
  }

  private isPricingVersion(version: number): boolean {
    if (
      this.org &&
      this.org.organizationToSalesPlan &&
      this.org.organizationToSalesPlan.salesPlan
    ) {
      return this.org.organizationToSalesPlan.salesPlan.version === version
    } else {
      return false
    }
  }

  private isSalesPlanType(salesPlanType: SalesPlanType): boolean {
    return this.org &&
      this.org.organizationToSalesPlan &&
      this.org.organizationToSalesPlan.salesPlan &&
      this.org.organizationToSalesPlan.salesPlan.salesPlanType === salesPlanType
      ? true
      : false
  }
}
