import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { first, tap } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { ApiService } from './api.service'
import { NavbarService } from './navbar.service'
import { SseService } from './sse.service'

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  private clusterStatusInternal$: BehaviorSubject<
    ClusterDto[]
  > = new BehaviorSubject([])

  constructor(
    private apiService: ApiService,
    private navbarService: NavbarService,
    private sseService: SseService,
  ) {
    this.navbarService.currentOrg$.subscribe(async (currentOrg) => {
      if (currentOrg) {
        const clusters = await this.apiService
          .listClusters(currentOrg.uuid)
          .pipe(first())
          .toPromise()
        this.clusterStatusInternal$.next(clusters)
      }
    })
    this.sseService.clusterNotifications$.subscribe(async (currentOrgUuid) => {
      if (currentOrgUuid) {
        const clusters = await this.apiService
          .listClusters(currentOrgUuid)
          .pipe(first())
          .toPromise()
        this.clusterStatusInternal$.next(clusters)
      }
    })
  }

  public getClustersStatus(): Observable<ClusterDto[]> {
    return this.clusterStatusInternal$
  }

  public create(
    orgUuid: string,
    name: string,
    planTypeId: string,
    channelId: string,
    generationId: string,
    k8sContextId: string,
  ) {
    return this.apiService
      .createCluster(
        name,
        planTypeId,
        orgUuid,
        channelId,
        generationId,
        k8sContextId,
      )
      .pipe(
        tap((res) => {
          this.apiService
            .sendClusterStatus(orgUuid, res.clusterId, 'creating')
            .subscribe()
        }),
      )
  }

  public rename(currentOrgId: string, clusterUuid: string, newName: string) {
    return this.apiService.renameCluster(currentOrgId, clusterUuid, newName)
  }

  public moveToOrg(currentOrgId: string, id: string, newOrgUuid: string) {
    this.apiService.moveCluster(currentOrgId, id, newOrgUuid).subscribe()
  }

  public boost(clusterUuid: string) {
    return this.apiService.boost(clusterUuid)
  }

  public boostAvailable(clusterUuid: string) {
    return this.apiService.checkForAvailableBoost(clusterUuid)
  }

  public delete(currentOrgId: string, id: string) {
    return this.apiService.deleteCluster(currentOrgId, id)
  }

  public getCluster(
    currentOrgId: string,
    uuid: string,
  ): Observable<ClusterDto> {
    return this.apiService.clusterDetails(currentOrgId, uuid)
  }

  public getClusters(orgUuid: string): Observable<ClusterDto[]> {
    return this.apiService.listClusters(orgUuid)
  }
}
