import { Component, ElementRef, NgZone, ViewChild } from '@angular/core'
import {
  ClusterPlanDto,
  ClusterPlanTypeDto,
  K8sContextDto,
  OrganizationDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { ChannelDto } from '../../../../../../commons/Channel.dto'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { environment } from '../../../../environments/environment'
import { ClusterService } from '../../../services/cluster.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

export interface CreateClusterDialogData {
  currentOrg: OrganizationDto
  clusters: ClusterDto[]
  channels: ChannelDto[]
  planTypes: ClusterPlanTypeDto[]
}

interface ExtendedClusterPlanTypeDto extends ClusterPlanTypeDto {
  allowed: boolean
}

interface ExtendedK8sContextDto extends K8sContextDto {
  allowed: boolean
}

interface RegionIndependentClusterPlanType {
  uuid: string
  name: string
  activePlan: ClusterPlanDto
  k8sContexts: ExtendedK8sContextDto[]
  extendedClusterPlanTypes: ExtendedClusterPlanTypeDto[]
  description: string
  allowed: boolean
}

type FilterItem = {
  label: string
  value: string
  title?: string
  disabled?: boolean
}

@Component({
  selector: 'create-cluster-dialog',
  templateUrl: './create-cluster-dialog.component.html',
  styleUrls: ['./create-cluster-dialog.component.scss'],
})
export class CreateClusterDialogComponent {
  public formData: {
    planTypes: RegionIndependentClusterPlanType[]
    planTypeIdToPlanType: any
    channels: ChannelDto[]
    channelIdToGenerations: any
  }
  public selectedPlanTypeUuid: string
  public selectedChannelId: string
  public channelNames = []
  public channelDescriptions = []
  public selectedGenerationId: string
  public selectedK8sContextUuid: string
  public environment = environment
  public readyToCreate$: Observable<boolean>
  private readyToCreate = new BehaviorSubject<boolean>(false)

  @ViewChild('createClusterModal', { read: ElementRef })
  public modalRef: ElementRef<CmModal>

  public typeFilters: Array<FilterItem> = []
  public typeFilterDefault: number | undefined = undefined

  public channelFilters: Array<FilterItem> = []
  public channelFilterDefault: number | undefined = undefined

  public generationFilters: Array<FilterItem> = []
  public generationFilterDefault: number | undefined = undefined

  public regionFilters: Array<FilterItem> = []
  public regionFilterDefault: number | undefined = undefined

  constructor(
    public vss: ViewVisibilitiesService,
    private clusterService: ClusterService,
    private mixpanel: MixpanelService,
    private ngZone: NgZone,
  ) {
    this.readyToCreate$ = this.readyToCreate.asObservable()
  }

  public open(
    data: CreateClusterDialogData,
    onModalConfirm: () => void = () => {},
  ) {
    data.channels = data.channels.sort((a, b) => {
      if (a.isDefault) {
        return -1
      }

      if (b.isDefault) {
        return 1
      }

      return 0
    })

    this.fillFormData(data)

    const modalResultPromise = new Promise<{ clusterId: string } | null>(
      (resolve, _reject) => {
        this.ngZone.run(() => {
          this.modalRef.nativeElement
            .open({
              preConfirmationHandler: () => {
                return new Promise((resolve, reject) => {
                  if (
                    !this.selectedPlanTypeUuid ||
                    !this.selectedChannelId ||
                    !this.selectedGenerationId ||
                    !this.selectedK8sContextUuid
                  ) {
                    reject(new Error('Form not valid'))
                  } else {
                    resolve()
                  }
                })
              },
            })
            .then((result) => {
              let firstClusterPromise: Promise<{
                clusterId: string
              }> | null = null

              if (result.result === 'confirm') {
                onModalConfirm()
                let selectedRegionIndependentClusterPlanType = this.formData
                  .planTypeIdToPlanType[
                  this.selectedPlanTypeUuid
                ] as RegionIndependentClusterPlanType

                let selectedClusterPlanType = selectedRegionIndependentClusterPlanType.extendedClusterPlanTypes.find(
                  (planType) =>
                    planType.k8sContext.uuid === this.selectedK8sContextUuid,
                )

                this.mixpanel.track(
                  FrontendAnalyticEvents.CREATE_CLUSTER_DIALOG_CONFIRMED,
                )

                try {
                  firstClusterPromise = this.clusterService
                    .create(
                      data.currentOrg.uuid,
                      result.formData!.name as string,
                      selectedClusterPlanType!.uuid,
                      this.selectedChannelId,
                      this.selectedGenerationId,
                      this.selectedK8sContextUuid,
                    )
                    .pipe(first())
                    .toPromise()
                } catch (error) {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.CREATE_CLUSTER_DIALOG_ERROR,
                  )
                  throw error
                }
              } else {
                this.mixpanel.track(
                  FrontendAnalyticEvents.CREATE_CLUSTER_DIALOG_CANCELED,
                )
              }

              resolve(firstClusterPromise)
            })
        })
      },
    )

    return modalResultPromise
  }

  private fillFormData(data: CreateClusterDialogData) {
    const extendedClusterPlanTypes = CreateClusterDialogComponent.createExtendedClusterPlanTypes(
      data,
      this.vss.visibilities.dialog.cluster.create.god.visible,
    )

    let regionIndependentClusterPlanTypes = CreateClusterDialogComponent.createRegionIndependentClusterPlanTypes(
      extendedClusterPlanTypes,
    )

    const planTypeIdToPlanType = {}

    regionIndependentClusterPlanTypes.forEach((plan) => {
      planTypeIdToPlanType[plan.uuid] = plan
    })

    const channelIdToGenerations = {}

    data.channels.forEach((channel) => {
      this.channelDescriptions[channel.uuid] = channel.description
      this.channelNames[channel.uuid] = channel.name
      channelIdToGenerations[channel.uuid] = channel.allowedGenerations.sort(
        (b, a) => {
          if (a.created > b.created) {
            return 1
          } else if (a.created < b.created) {
            return -1
          } else {
            return 0
          }
        },
      )
    })

    const selectedPlanType = regionIndependentClusterPlanTypes.filter(
      (planType) => planType.allowed,
    )[0]
    this.selectedPlanTypeUuid = selectedPlanType.uuid
    this.selectedK8sContextUuid = selectedPlanType.k8sContexts.find(
      (k8s) => k8s.allowed,
    )!.uuid

    this.formData = {
      planTypes: regionIndependentClusterPlanTypes,
      planTypeIdToPlanType,
      channels: data.channels,
      channelIdToGenerations,
    }
    this.typeFilters = this.formData.planTypes.map((planType) => {
      return {
        label: planType.name,
        value: planType.uuid,
        disabled: !planType.allowed,
      }
    })
    this.typeFilterDefault = this.formData.planTypes.indexOf(selectedPlanType)

    this.channelFilters = this.formData.channels.map((channel) => {
      return {
        label: channel.name,
        value: channel.uuid,
      }
    })

    if (!this.selectedChannelId) {
      this.selectChannel({ detail: { value: data.channels[0].uuid } })
      this.channelFilterDefault = 0
    }

    this.regionFilters = this.formData.planTypeIdToPlanType[
      this.selectedPlanTypeUuid
    ]!.k8sContexts.map((kubernetesContext) => {
      return {
        label: kubernetesContext.name,
        value: kubernetesContext.uuid,
        disabled: !kubernetesContext.allowed,
      }
    })

    this.regionFilterDefault = this.formData.planTypeIdToPlanType[
      this.selectedPlanTypeUuid
    ]!.k8sContexts.findIndex(
      (kubernetesContext) =>
        kubernetesContext.uuid === this.selectedK8sContextUuid,
    )

    this.readyToCreate.next(true)
  }

  updateGenerationFilters() {
    this.generationFilters = this.formData.channelIdToGenerations[
      this.selectedChannelId
    ].map((generation) => {
      return {
        label: generation.name,
        value: generation.uuid,
      }
    })

    // update generation filter index
    for (
      let generationFilterIndex = 0;
      generationFilterIndex < this.generationFilters.length;
      generationFilterIndex++
    ) {
      if (
        this.selectedGenerationId ===
        this.generationFilters[generationFilterIndex].value
      ) {
        this.generationFilterDefault = generationFilterIndex
      }
    }
  }

  public selectPlanType(event) {
    this.selectedPlanTypeUuid = event.detail.value

    let selectedPlanType = this.formData.planTypeIdToPlanType[
      this.selectedPlanTypeUuid
    ]

    this.selectedK8sContextUuid = selectedPlanType.k8sContexts.find(
      (k8s) => k8s.allowed,
    )!.uuid

    this.regionFilters = this.formData.planTypeIdToPlanType[
      this.selectedPlanTypeUuid
    ]!.k8sContexts.map((kubernetesContext) => {
      return {
        label: kubernetesContext.name,
        value: kubernetesContext.uuid,
        disabled: !kubernetesContext.allowed,
      }
    })
  }

  public selectChannel(event) {
    this.selectedChannelId = event.detail.value
    this.selectedGenerationId = this.formData.channels.find(
      (channel) => channel.uuid === this.selectedChannelId,
    )!.defaultGeneration.uuid

    this.updateGenerationFilters()
  }

  public selectGeneration(event) {
    this.selectedGenerationId = event.detail.value
  }

  public selectRegion(event) {
    this.selectedK8sContextUuid = event.detail.value
  }

  public static createExtendedClusterPlanTypes(
    data: CreateClusterDialogData,
    override: boolean,
  ): ExtendedClusterPlanTypeDto[] {
    const extendedClusterPlanTypes = data.planTypes.map((planType) => {
      const clustersWithClusterPlanType = data.clusters.filter(
        (cluster) => cluster.planType.uuid === planType.uuid,
      ).length

      const allowedNumber = data.currentOrg.organizationToSalesPlan!
        .clusterPlanTypesToReserved[planType.uuid]

      const allowed = clustersWithClusterPlanType < allowedNumber || override

      return { ...planType, ...{ allowed } }
    })
    return extendedClusterPlanTypes
  }

  public static createRegionIndependentClusterPlanTypes(
    extendedClusterPlanTypes: ExtendedClusterPlanTypeDto[],
  ): RegionIndependentClusterPlanType[] {
    let regionIndependentClusterPlanTypes = extendedClusterPlanTypes.reduce(
      (prev, cur) => {
        let existing = prev.find(
          (pre) =>
            pre.activePlan.uuid === cur.activePlan.uuid &&
            pre.name === cur.name,
        )

        if (
          existing &&
          existing.k8sContexts.find(
            (k8s) => k8s.uuid === cur.k8sContext.uuid,
          ) === undefined
        ) {
          existing.extendedClusterPlanTypes.push(cur)
          existing.k8sContexts.push({
            ...cur.k8sContext,
            ...{ allowed: cur.allowed },
          })
          existing.allowed = existing.allowed || cur.allowed
        } else {
          let regionIndependentClusterPlanType = {
            uuid: cur.uuid,
            name: cur.name,
            activePlan: cur.activePlan,
            k8sContexts: [{ ...cur.k8sContext, ...{ allowed: cur.allowed } }],
            extendedClusterPlanTypes: [cur],
            description: cur.description,
            allowed: cur.allowed,
          }
          prev.push(regionIndependentClusterPlanType)
        }
        return prev
      },
      new Array<RegionIndependentClusterPlanType>(),
    )
    regionIndependentClusterPlanTypes = regionIndependentClusterPlanTypes.map(
      (planType) => {
        planType.description = planType.description
          .split('</p>')[0]
          .replace('<p>', '')
        return planType
      },
    )

    return regionIndependentClusterPlanTypes
  }
}
