import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Observable, of } from 'rxjs'
import { filter, map, mergeMap, take } from 'rxjs/operators'
import { environment } from '../environments/environment'
import { ApiService } from './services/api.service'
import { FeatureService } from './services/feature.service'
import { MixpanelService } from './services/mixpanel.service'
import { NotificationService } from './services/notification.service'
import { PendoService } from './services/pendo.service'
import { UserService } from './services/user.service'
import { ViewVisibilitiesService } from './services/view.visibilities.service'

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public title = 'Camunda Cloud Console'
  public sha$: Observable<string>

  @ViewChild('notificationContainer', { static: true, read: ElementRef })
  public notificationContainer: ElementRef<HTMLCmNotificationContainerElement>

  private userId: string
  constructor(
    private api: ApiService,
    private features: FeatureService,
    private userService: UserService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private mixpanel: MixpanelService,
    public vvs: ViewVisibilitiesService,
    private router: Router,
    public pendoService: PendoService,
  ) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.userId = user.sub
      }
    })

    this.sha$ = this.features.accessToken$.pipe(
      filter((accessToken) => accessToken.activated.gitSha),
      mergeMap((accessToken) => {
        if (accessToken.activated.gitSha) {
          return this.api.getAppInfo()
        }

        return of({ info: { shortSha: '' } })
      }),

      map((info) => {
        if (info.info.shortSha) {
          return `sha: ${info.info.shortSha} |`
        } else {
          return ''
        }
      }),

      take(1),
    )

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        const currentEvent = event as NavigationStart
        if (
          currentEvent.navigationTrigger &&
          currentEvent.navigationTrigger === 'popstate' &&
          currentEvent.url.includes(`callback`)
        ) {
          this.router.navigate(['/'])
        }
      })
  }

  ngOnInit() {
    this.notificationService.notifications$.subscribe((notification) => {
      this.notificationContainer.nativeElement.enqueueNotification(notification)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public openOsano() {
    ;(window as any).Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
  }

  public stage(): 'development' | 'integration' | 'production' | undefined {
    if (!this.vvs.visibilities.navbar.stage.visible) {
      return undefined
    } else {
      if (environment.production) {
        return 'production'
      } else if (environment.integration) {
        return 'integration'
      } else {
        return 'development'
      }
    }
  }
}
