import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'

@Component({
  selector: 'app-product-wrapper',
  templateUrl: './product-wrapper.page.html',
  styleUrls: ['./product-wrapper.page.scss'],
})
export class ProductWrapperPage implements OnInit {
  public currentOrg: OrganizationDto
  public activeLabel: string

  constructor(
    private route: ActivatedRoute,
    public vvs: ViewVisibilitiesService,
  ) {}

  public ngOnInit() {
    this.currentOrg = this.route.snapshot.data.org
    this.activeLabel = this.route.snapshot.data.activeLabel
  }
}
