<cm-page #cmPage>
  <div slot="header">
    <h1>External View</h1>
  </div>
  <cm-page-tab label="All Orgs">
    <div class="scrollContents">
      <external-org-details
        *ngFor="let orgId of orgIds"
        [orgUuid]="orgId"
      ></external-org-details>
    </div>
  </cm-page-tab>
  <cm-page-tab label="Members">
    <div class="scrollContents">
      <external-org-members
        *ngFor="let orgId of orgIds"
        [orgUuid]="orgId"
      ></external-org-members>
    </div>
  </cm-page-tab>
  <cm-page-tab label="Me">
    <div class="scrollContents">
      <external-org-member-me
        *ngFor="let orgId of orgIds"
        [orgUuid]="orgId"
      ></external-org-member-me>
    </div>
  </cm-page-tab>
  <cm-page-tab label="Clusters">
    <div class="scrollContents">
      <external-clusters *ngFor="let orgId of orgIds" [orgUuid]="orgId">
      </external-clusters></div
  ></cm-page-tab>
  <cm-page-tab label="Internal Clients">
    <div class="scrollContents">
      <external-internal-client-org-level
        *ngFor="let orgId of orgIds"
        [orgUuid]="orgId"
      ></external-internal-client-org-level>
    </div>
  </cm-page-tab>
</cm-page>
