import { Component, ElementRef, NgZone, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { User } from '@auth0/auth0-spa-js'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import {
  DropdownOption,
  DropdownOptionGroup,
} from '@camunda-cloud/common-ui/dist/types/components/cm-dropdown/cm-dropdown'
import { CountriesToSubsidiary } from '@camunda-cloud/subscription-management'
import { combineLatest, Observable } from 'rxjs'
import { first, startWith } from 'rxjs/operators'
import { BpmnModelDto } from '../../../../../commons/BpmnModel.dto'
import { environment } from '../../../environments/environment'
import { ApiService } from '../../services/api.service'
import { FeatureService } from '../../services/feature.service'
import {
  FrontendAnalyticEvents,
  MixpanelEvent,
  MixpanelService,
} from '../../services/mixpanel.service'
import { NavbarService } from '../../services/navbar.service'
import {
  ExternalLink,
  NavigationService,
} from '../../services/navigation.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
import { OrgVisibilityUtils } from '../../services/VisibilityUtils/org.visibility.utils'

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  public helpMenuTrigger = {
    type: 'icon',
    icon: 'help',
  }

  public helpMenuOptions = [{ options: [] }]

  public homeMenuTrigger = {
    type: 'label',
    label: '',
  }
  public homeMenuOptions = []

  @ViewChild('requestPaidPlanModal', { static: true, read: ElementRef })
  public requestPaidPlanModal: ElementRef<CmModal>

  @ViewChild('switchToProfessionalPlanCcModal', {
    static: true,
    read: ElementRef,
  })
  public switchToProfessionalPlanCcModal: ElementRef<CmModal>

  public webIdeLink: string

  public countryFilter = ''

  public isCollapsed = true
  public user$: Observable<User>
  public selectedOrg: OrganizationDto | undefined
  public routeToHome = '/'
  public uuidToName$: Observable<Map<string, string>>

  public requestingPaidPlan: boolean = false
  public emailModel: BpmnModelDto
  public loadCountryFlagSvgs: boolean = false

  public selectedCountry: string

  public trialCountdownDays: string

  constructor(
    private authService: AuthService,
    public navbarService: NavbarService,
    private router: Router,
    private mixpanel: MixpanelService,
    public vvs: ViewVisibilitiesService,
    public apiService: ApiService,
    public navigation: NavigationService,
    public ngZone: NgZone,
    private featureService: FeatureService,
  ) {
    this.selectedCountry = 'GB'

    this.user$ = this.authService.user$

    this.uuidToName$ = this.navbarService.uuidToName$
    // #region Set up Help Dropdown

    this.navbarService.currentOrg$.subscribe((currentOrg) => {
      this.webIdeLink = `${environment.webIdeUrl}/login?returnUrl=/org/${currentOrg?.uuid}`
      this.routeToHome = `/org/${currentOrg.uuid}`
      this.selectedOrg = currentOrg

      this.helpMenuOptions[0].options = []

      this.helpMenuOptions[0].options.push({
        label: 'Documentation',
        handler: () => {
          this.ngZone.run(() => {
            this.openDocs()
          })
        },
      })
      const orgVisibilityUtils = new OrgVisibilityUtils(currentOrg, [])
      if (
        orgVisibilityUtils.isSalesPlanTypePaid() ||
        orgVisibilityUtils.isSalesPlanTypeEnterprise()
      ) {
        this.helpMenuOptions[0].options.push({
          label: 'Feedback and Support',
          handler: () => {
            this.ngZone.run(() => {
              this.openJira()
            })
          },
        })
      } else {
        this.helpMenuOptions[0].options.push({
          label: 'Feedback and Support',
          handler: () => {
            this.ngZone.run(() => {
              this.openForum()
            })
          },
        })
      }

      this.helpMenuOptions[0].options.push({
        label: 'Slack Community Channel',
        handler: () => {
          this.ngZone.run(() => {
            this.openSlack()
          })
        },
      })

      this.helpMenuOptions[0].options.push({
        label: 'Terms of Use',
        handler: () => {
          this.ngZone.run(() => {
            this.router.navigate(['/terms/'])
          })
        },
      })

      this.helpMenuOptions[0].options.push({
        label: 'Privacy Policy',
        handler: () => {
          this.ngZone.run(() => {
            this.openPrivacy()
          })
        },
      })

      if (currentOrg.organizationToSalesPlan.trialExpirationDate) {
        let trialExpirationDate = new Date(
          currentOrg.organizationToSalesPlan.trialExpirationDate,
        )
        const now = new Date()
        let countdownMs = trialExpirationDate.getTime() - now.getTime()
        let countdownDays = countdownMs / 1000 / 60 / 60 / 24
        this.trialCountdownDays = countdownDays.toFixed(0)
      }

      organizationOption.isDisabled = !currentOrg
    })

    // #endregion

    let organizationOption: DropdownOption = {
      label: 'Organization Management',
      handler: () => {
        this.ngZone.run(() => {
          this.showOrganizationManagement()
        })
      },
      isDisabled: true,
    }

    let settingsOptionGroup: DropdownOptionGroup = {
      options: [organizationOption],
    }

    let debugOptionGroup = {
      title: 'Debug',
      options: [],
    }

    // #region Set up Home Dropdown
    this.user$.subscribe((value) => {
      if (value) {
        this.homeMenuTrigger.label = value.name
      }
    })

    this.featureService.accessToken$.subscribe((features) => {
      if (
        features.activated.features.userSettings &&
        !settingsOptionGroup.options.find(
          (option) => option.label === 'User Settings',
        )
      ) {
        settingsOptionGroup.options.unshift({
          label: 'User Settings',
          handler: () => {
            this.ngZone.run(() => {
              this.router.navigate(['/user-settings'])
            })
          },
        })
      }

      if (
        features.activated.features.debug &&
        !debugOptionGroup.options.find(
          (option) => option.label === 'External View',
        )
      ) {
        debugOptionGroup.options.push({
          label: 'External View',
          handler: () => {
            this.ngZone.run(() => {
              this.router.navigate(['/external'])
            })
          },
        })
      }
    })

    let logoutOptionGroup = {
      options: [
        {
          label: 'Logout',
          handler: () => {
            this.ngZone.run(() => {
              this.logout()
            })
          },
          isDangerous: true,
        },
      ],
    }

    this.homeMenuOptions = [settingsOptionGroup, logoutOptionGroup]

    combineLatest([
      this.navbarService.orgs$,
      this.navbarService.currentOrg$.pipe(startWith(undefined as any)),
      this.vvs.get(),
    ]).subscribe(([organizations, activeOrganization, visibilities]) => {
      // #region AdminOptionGroup

      let adminOptionGroup = { title: 'Admin', options: [] }
      if (visibilities.navbar.menu.admin.corpse.visible) {
        adminOptionGroup.options.push({
          label: 'Corpse Management',
          handler: () => {
            this.ngZone.run(() => {
              this.router.navigate(['/admin/corpse'])
            })
          },
        })
      }

      if (visibilities.navbar.menu.admin.k8scontext.visible) {
        adminOptionGroup.options.push({
          label: 'Kubernetes Contexts',
          handler: () => {
            this.ngZone.run(() => {
              this.router.navigate(['/admin/k8sContext'])
            })
          },
        })
      }

      if (visibilities.navbar.menu.admin.versions.visible) {
        adminOptionGroup.options.push({
          label: 'Versions',
          handler: () => {
            this.ngZone.run(() => {
              this.router.navigate(['/admin/versions'])
            })
          },
        })
      }

      if (visibilities.navbar.menu.admin.clusterplans.visible) {
        adminOptionGroup.options.push({
          label: 'Cluster Plans',
          handler: () => {
            this.ngZone.run(() => {
              this.router.navigate(['/admin/cluster-plan'])
            })
          },
        })
      }

      // #endregion

      // #region OrganizationsOptionGroup
      let organizationsOptionGroup = {
        title: 'Organizations',
        options: [],
      }

      for (let organization of organizations) {
        organizationsOptionGroup.options.push({
          label: organization.name,
          handler: () => {
            this.ngZone.run(() => {
              this.switchActiveOrganization(organization.uuid)
            })
          },
          isDisabled: organization.uuid === activeOrganization?.uuid,
        })
      }

      // #endregion

      if (
        organizations?.length &&
        visibilities.navbar.menu.admin.general.visible
      ) {
        if (debugOptionGroup.options.length) {
          this.homeMenuOptions = [
            settingsOptionGroup,
            organizationsOptionGroup,
            debugOptionGroup,
            adminOptionGroup,
            logoutOptionGroup,
          ]
        } else {
          this.homeMenuOptions = [
            settingsOptionGroup,
            organizationsOptionGroup,
            adminOptionGroup,
            logoutOptionGroup,
          ]
        }
      } else if (organizations?.length) {
        if (debugOptionGroup.options.length) {
          this.homeMenuOptions = [
            settingsOptionGroup,
            organizationsOptionGroup,
            debugOptionGroup,
            logoutOptionGroup,
          ]
        } else {
          this.homeMenuOptions = [
            settingsOptionGroup,
            organizationsOptionGroup,
            logoutOptionGroup,
          ]
        }
      } else if (visibilities.navbar.menu.admin.general.visible) {
        if (debugOptionGroup.options.length) {
          this.homeMenuOptions = [
            settingsOptionGroup,
            debugOptionGroup,
            adminOptionGroup,
            logoutOptionGroup,
          ]
        } else {
          this.homeMenuOptions = [
            settingsOptionGroup,
            adminOptionGroup,
            logoutOptionGroup,
          ]
        }
      } else {
        if (debugOptionGroup.options.length) {
          this.homeMenuOptions = [
            settingsOptionGroup,
            debugOptionGroup,
            logoutOptionGroup,
          ]
        } else {
          this.homeMenuOptions = [settingsOptionGroup, logoutOptionGroup]
        }
      }
    })

    // #endregion

    this.navbarService.triggerOpenSwitchToProfessionalPlanCcModal.subscribe(
      (open) => {
        if (
          open &&
          !this.vvs.orgVisibilityUtils.isSalesPlanTypePaid() &&
          !this.vvs.orgVisibilityUtils.isSalesPlanTypePaidCC() &&
          !this.vvs.orgVisibilityUtils.isSalesPlanTypeEnterprise()
        ) {
          this.openRequestPaidPlanModal()
        }
      },
    )
  }

  public navbarSectionMixpanelEvent(
    section: 'diagrams' | 'forms' | 'clusters',
  ) {
    let events: MixpanelEvent[] = []
    switch (section) {
      case 'diagrams':
        events = [FrontendAnalyticEvents.NAVBAR_DIAGRAMS]
        break
      case 'forms':
        events = [FrontendAnalyticEvents.NAVBAR_FORMS]
        break
      case 'clusters':
        events = [FrontendAnalyticEvents.NAVBAR_CLUSTERS]
        break
    }
    if (events?.length > 0) {
      events.map((event) => this.mixpanel.track(event))
    }
  }

  public openDocs() {
    this.navigation.navigateExternal({
      dest: ExternalLink.DOCS,
      mixpanel: {
        event: FrontendAnalyticEvents.NAVBAR_LINK_TO_DOCS,
      },
    })
  }

  public openJira() {
    this.navigation.navigateExternal({
      dest: ExternalLink.JIRA,
      mixpanel: {
        event: FrontendAnalyticEvents.NAVBAR_LINK_TO_JIRA,
      },
    })
  }

  public openForum() {
    this.navigation.navigateExternal({
      dest: ExternalLink.FORUM,
      mixpanel: {
        event: FrontendAnalyticEvents.NAVBAR_LINK_TO_FORUM,
      },
    })
  }

  public openPrivacy() {
    this.navigation.navigateExternal({
      dest: ExternalLink.PRIVACY,
      mixpanel: {
        event: FrontendAnalyticEvents.NAVBAR_LINK_TO_PRIVACY,
      },
    })
  }

  public openSlack() {
    this.navigation.navigateExternal({
      dest: ExternalLink.SLACK,
      mixpanel: {
        event: FrontendAnalyticEvents.NAVBAR_LINK_TO_SLACK,
      },
    })
  }

  public filterCountries() {
    if (this.countryFilter) {
      return CountriesToSubsidiary.filter((country) => {
        return country.country
          .toLocaleLowerCase()
          .includes(this.countryFilter.toLocaleLowerCase())
      })
    } else {
      return CountriesToSubsidiary
    }
  }

  public selectCountry(value) {
    this.mixpanel.track(
      FrontendAnalyticEvents.NAVBAR_CC_CHECKOUT_SELECT_COUNTRY,
      { country: value },
    )
    this.selectedCountry = value
  }

  public openRequestPaidPlanModal() {
    this.loadCountryFlagSvgs = true
    this.mixpanel.track(FrontendAnalyticEvents.NAVBAR_REQUEST_PAID_PLAN_DIALOG)
    requestAnimationFrame(() => {
      this.requestPaidPlanModal.nativeElement.open()
    })
  }

  public openSwitchToProfessionalPlanCcModal() {
    this.loadCountryFlagSvgs = true
    this.mixpanel.track(FrontendAnalyticEvents.NAVBAR_CC_CHECKOUT_OPEN)
    requestAnimationFrame(() => {
      this.switchToProfessionalPlanCcModal.nativeElement
        .open()
        .then(async (result) => {
          if (result.result === 'confirm') {
            const chargebeeSite = await this.apiService
              .getChargebeeSite(this.selectedCountry)
              .pipe(first())
              .toPromise()
            this.mixpanel.track(
              FrontendAnalyticEvents.NAVBAR_CC_CHECKOUT_CONFIRM,
              { chargebeeSite },
            )
            ;(window as any).Chargebee.init({
              site: chargebeeSite.site,
              publishableKey: chargebeeSite.publishableKey,
            })
            const chargebeeInstance = (window as any).Chargebee.getInstance()
            const that = this
            const checkoutPage = this.apiService
              .createChargebeeCheckout(
                this.selectedOrg.uuid,
                this.selectedCountry,
              )
              .pipe(first())
              .toPromise()
            chargebeeInstance.openCheckout({
              hostedPage() {
                return checkoutPage
              },
              success(_hostedPageId) {},
              close(_) {
                that.mixpanel.track(
                  FrontendAnalyticEvents.NAVBAR_CC_CHECKOUT_CHARGEBEE_CANCEL,
                )
                window.location.reload()
              },
              error(error) {
                that.mixpanel.track(
                  FrontendAnalyticEvents.NAVBAR_CC_CHECKOUT_CHARGEBEE_ERROR,
                  { error },
                )
                window.location.reload()
              },
            })
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.NAVBAR_CC_CHECKOUT_CANCEL,
            )
          }
        })
    })
  }

  public switchActiveOrganization(orgUuid: string) {
    this.mixpanel.track(FrontendAnalyticEvents.NAVBAR_ORG_SWITCH, {
      target: orgUuid,
    })
    this.router.navigate([`/org/${orgUuid}`])
  }

  public showOrganizationManagement() {
    this.navigation.navigateInApp({
      dest: 'org:management',
      context: { orgId: this.selectedOrg.uuid },
      mixpanel: { event: FrontendAnalyticEvents.NAVBAR_ORG_SETTINGS },
    })
  }

  public login() {
    this.authService.loginWithRedirect()
  }

  public logout() {
    this.mixpanel.track(FrontendAnalyticEvents.NAVBAR_LOGOUT)
    this.authService.logout({ returnTo: window.location.origin })
  }

  public contactUsProfessionalEvent() {
    this.mixpanel.track(
      FrontendAnalyticEvents.NAVBAR_LINK_TO_CONTACT_US_PROFESSIONAL,
    )
  }
  public contactUsEnterpriseEvent() {
    this.mixpanel.track(
      FrontendAnalyticEvents.NAVBAR_LINK_TO_CONTACT_US_ENTERPRISE,
    )
  }
}
