import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { first } from 'rxjs/operators'
import { ViewVisibilitiesService } from 'src/app/services/view.visibilities.service'
import { environment } from 'src/environments/environment'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { UserService } from '../../services/user.service'

@Component({
  selector: 'user-settings-page',
  templateUrl: './user-settings.page.html',
  styleUrls: ['./user-settings.page.scss'],
})
export class UserSettingsPage implements AfterViewInit {
  private userId: string

  @ViewChild('prodFeatureToggle', { static: false, read: ElementRef })
  public prodFeatureToggle: ElementRef<HTMLCmToggleElement>

  public env = environment

  constructor(
    private userService: UserService,
    private authService: AuthService,
    public mixpanel: MixpanelService,
    public vvs: ViewVisibilitiesService,
  ) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.userId = user.sub
      }
    })
    this.mixpanel.track(FrontendAnalyticEvents.USERSETTINGS_VISITED)
  }

  ngAfterViewInit() {
    if (this.prodFeatureToggle?.nativeElement.checked) {
      this.prodFeatureToggle.nativeElement.label = 'Active'
    } else if (this.prodFeatureToggle) {
      this.prodFeatureToggle.nativeElement.label = 'Inactive'
    }

    this.prodFeatureToggle?.nativeElement.addEventListener(
      'cmInput',
      (event: CustomEvent) => {
        if (event.detail.isChecked) {
          this.prodFeatureToggle.nativeElement.label = 'Active'
        } else {
          this.prodFeatureToggle.nativeElement.label = 'Inactive'
        }
      },
    )
  }

  // eslint-disable-next-line class-methods-use-this
  public toggleProdFeatures(value: boolean) {
    if (value) {
      environment.production = true
    }
  }

  public resetWelcomeModal() {
    this.userService.removeEvent('console:welcome-modal').subscribe()
  }

  public resetNewRolesDefinitionModal() {
    this.userService
      .removeEvent('console:new-role-definitions-modal')
      .subscribe()
  }

  public async unDismissGseOverlay() {
    await this.userService.unDismissGseOverlay(this.userId)
    await this.authService.getAccessTokenSilently().pipe(first()).toPromise()
  }

  public async resetCookieBanner() {
    await this.userService
      .acknowledgeCookieBanner(this.userId, false, false)
      .pipe(first())
      .toPromise()
    await this.authService.getAccessTokenSilently().pipe(first()).toPromise()
  }
}
