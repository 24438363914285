import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { iif, Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { BpmnFormDto } from '../../../../commons/BpmnForm.dto'
import { ApiService } from '../services/api.service'
import { ViewVisibilitiesService } from '../services/view.visibilities.service'

@Injectable({
  providedIn: 'root',
})
export class BpmnFormsResolver implements Resolve<BpmnFormDto[]> {
  constructor(
    private apiService: ApiService,
    private vvs: ViewVisibilitiesService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<BpmnFormDto[]> {
    return this.vvs
      .get(route.paramMap.get('orgUuid'))
      .pipe(
        mergeMap((visibilities) =>
          iif(
            () => visibilities.modeler.read.visible,
            this.apiService.listBpmnForms(route.paramMap.get('orgUuid')),
            of([]),
          ),
        ),
      )
  }
}
