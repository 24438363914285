import { Component, Input, OnInit } from '@angular/core'
import { environment } from '../../../environments/environment'
import { ApiService } from '../../services/api.service'
@Component({
  selector: 'external-clusters',
  templateUrl: './external-clusters.component.html',
  styleUrls: ['./external-clusters.component.scss'],
})
export class ExternalClustersComponent implements OnInit {
  @Input() orgUuid: string

  public data
  public baseUrl

  constructor(private apiService: ApiService) {
    this.baseUrl = `${environment.backendUrl}/external/organizations/`
  }

  ngOnInit() {
    this.apiService.getExternalClusters(this.orgUuid).subscribe((data) => {
      this.data = JSON.stringify(data, null, 2)
    })
  }
}
