import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import jwtDecode from 'jwt-decode'
import { Observable } from 'rxjs'
import { map, filter, first } from 'rxjs/operators'
import { AuthService } from '@auth0/auth0-angular'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AllOrgUuidsResolverService implements Resolve<string[]> {
  constructor(private authService: AuthService) {}
  public resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<string[]> {
    return this.authService.getAccessTokenSilently().pipe(
      filter(
        (accessToken) => accessToken !== undefined && accessToken !== null,
      ),
      map((accessToken) => {
        const decoded = jwtDecode(accessToken)
        if (decoded && decoded[environment.auth0OrgNamespace]) {
          const orgIds = decoded[environment.auth0OrgNamespace].map(
            (org) => org.id,
          )
          return orgIds
        }
        return []
      }),
      first(),
    )
  }
}
