<cm-datalist headline="Overview" label-width="150px">
  <cm-datalist-item label="Organization name">
    <cm-text>{{ org.name }}</cm-text>
  </cm-datalist-item>
  <cm-datalist-item label="Plan">
    <cm-text *ngIf="vss.visibilities.overview.plan.professional.visible"
      >Professional</cm-text
    >
    <cm-text *ngIf="vss.visibilities.overview.plan.enterprise.visible"
      >Enterprise</cm-text
    >
    <cm-text *ngIf="vss.visibilities.overview.plan.trial.visible"
      >Trial</cm-text
    >
  </cm-datalist-item>
  <cm-datalist-item label="Owner">
    <cm-text>{{ orgOwner?.owner }}</cm-text>
  </cm-datalist-item>
</cm-datalist>
