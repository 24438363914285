<cm-page #cmPage>
  <h1 slot="header">Cluster Plan Management</h1>
  <cm-page-tab label="Cluster Plan Types">
    <list-cluster-plan-types
      [clusterPlanTypes]="clusterPlanTypes"
    ></list-cluster-plan-types>
  </cm-page-tab>
  <cm-page-tab label="Cluster Plans">
    <list-cluster-plans
      [clusterPlans]="clusterPlans"
      [clusterPlanTypes]="clusterPlanTypes"
    ></list-cluster-plans>
  </cm-page-tab>
</cm-page>
