import { Component, Input } from '@angular/core'
import {
  Action,
  MixpanelService,
  Element,
  Component as MixpanelComponent,
} from '../../../services/mixpanel.service'

@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
})
export class CopyToClipboardComponent {
  @Input()
  public value: string

  @Input()
  public eventComponent: MixpanelComponent

  @Input() public eventElement: Element

  constructor(private mixpanel: MixpanelService) {}

  // eslint-disable-next-line class-methods-use-this
  public click() {
    navigator.clipboard.writeText(this.value)
    if (this.eventComponent && this.eventElement) {
      this.mixpanel.track({
        view: this.eventComponent,
        element: this.eventElement,
        action: Action.copyToClipBoard,
      })
    }
  }
}
