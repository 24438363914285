import { environment } from '../../../environments/environment'
import { OrgVisibilityUtils } from './org.visibility.utils'

export class BillingVisibilityUtils {
  constructor(private orgVisibilityUtils: OrgVisibilityUtils) {}

  public canSeeReservations() {
    return (
      (this.orgVisibilityUtils.getOrgPermissions().seeBilling ||
        this.orgVisibilityUtils.getOrgPermissions().org.billing.read) &&
      this.orgVisibilityUtils.isSalesPlanTypePaid()
    )
  }

  public canSeeValueMetrics() {
    return (
      (this.orgVisibilityUtils.getOrgPermissions().seeBilling ||
        this.orgVisibilityUtils.getOrgPermissions().org.billing.read) &&
      (this.orgVisibilityUtils.isSalesPlanTypePaid() ||
        (this.orgVisibilityUtils.isSalesPlanTypeEnterprise() &&
          environment.production === false))
    )
  }

  public canChangeReservations() {
    return (
      this.canSeeReservations() &&
      (this.orgVisibilityUtils.getOrgPermissions().manageSettings ||
        this.orgVisibilityUtils.getOrgPermissions().org.billing.update)
    )
  }
}
