import { Template, TemplateCategory } from './Template.types'

export enum TemplateCategoryEnum {
  HUMAN_TASK = 'human-task',
  ORCHESTRATE_MICROSERVICES = 'orchestrate-micorservices',
  ORCHESTRATE_API_ENDPOINTS = 'orchestrate-api-endpoints',
}

export const DEFAULT_DIAGRAM = `
<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_0rbumvm" targetNamespace="http://bpmn.io/schema/bpmn" xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:modeler="http://camunda.org/schema/modeler/1.0" modeler:executionPlatform="Camunda Cloud" modeler:executionPlatformVersion="1.0.0">
<bpmn:process id="camunda-cloud-quick-start" isExecutable="true">
  <bpmn:startEvent id="StartEvent_1" />
</bpmn:process>
<bpmndi:BPMNDiagram id="BPMNDiagram_1">
  <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="camunda-cloud-quick-start">
    <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
      <dc:Bounds x="179" y="159" width="36" height="36" />
    </bpmndi:BPMNShape>
  </bpmndi:BPMNPlane>
</bpmndi:BPMNDiagram>
</bpmn:definitions>  
`

export const ABSENCE_REQUEST = `
<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" id="Definitions_0o87biy" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Zeebe Modeler" exporterVersion="0.9.1">
  <bpmn:collaboration id="Collaboration_1trvlnk">
    <bpmn:participant id="Participant_056nh3b" name="Absence Request" processRef="absence-request" />
  </bpmn:collaboration>
  <bpmn:process id="absence-request" name="Camunda Cloud Quick start" isExecutable="true">
    <bpmn:laneSet id="LaneSet_08mslvx">
      <bpmn:lane id="Lane_0jbpf0w" name="Employee">
        <bpmn:flowNodeRef>StartEvent_1</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Activity_1ihlcws</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Activity_17tgveb</bpmn:flowNodeRef>
      </bpmn:lane>
      <bpmn:lane id="Lane_0dur4gj" name="Line Manager">
        <bpmn:flowNodeRef>Activity_0jfbxdq</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Gateway_1pdgva1</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Gateway_1uhcky9</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Event_02m7n8y</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Activity_0aebkkx</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Activity_1yyoofc</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Event_0zynvca</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Activity_1evq7au</bpmn:flowNodeRef>
        <bpmn:flowNodeRef>Event_0z73nuo</bpmn:flowNodeRef>
      </bpmn:lane>
    </bpmn:laneSet>
    <bpmn:startEvent id="StartEvent_1">
      <bpmn:outgoing>Flow_15yg3k5</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:userTask id="Activity_1ihlcws" name="Request Absence">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="test-worker" retries="1" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_15yg3k5</bpmn:incoming>
      <bpmn:outgoing>Flow_0tdfmxq</bpmn:outgoing>
    </bpmn:userTask>
    <bpmn:userTask id="Activity_0jfbxdq" name="Approve Absence">
      <bpmn:incoming>Flow_0tdfmxq</bpmn:incoming>
      <bpmn:incoming>Flow_0axp5vf</bpmn:incoming>
      <bpmn:outgoing>Flow_0225a20</bpmn:outgoing>
    </bpmn:userTask>
    <bpmn:exclusiveGateway id="Gateway_1pdgva1" name="Result?">
      <bpmn:incoming>Flow_0225a20</bpmn:incoming>
      <bpmn:outgoing>Flow_1a3eadh</bpmn:outgoing>
      <bpmn:outgoing>Flow_1f7d78d</bpmn:outgoing>
      <bpmn:outgoing>Flow_12c4qv2</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:userTask id="Activity_17tgveb" name="Provide further Explanation">
      <bpmn:incoming>Flow_1f7d78d</bpmn:incoming>
      <bpmn:outgoing>Flow_0axp5vf</bpmn:outgoing>
    </bpmn:userTask>
    <bpmn:exclusiveGateway id="Gateway_1uhcky9">
      <bpmn:incoming>Flow_1jdrgyl</bpmn:incoming>
      <bpmn:incoming>Flow_1a3eadh</bpmn:incoming>
      <bpmn:outgoing>Flow_1wt1dkc</bpmn:outgoing>
    </bpmn:exclusiveGateway>
    <bpmn:endEvent id="Event_02m7n8y">
      <bpmn:incoming>Flow_1cusxs4</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:serviceTask id="Activity_0aebkkx" name="Notify Employee">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="dummy" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1wt1dkc</bpmn:incoming>
      <bpmn:outgoing>Flow_1cusxs4</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:serviceTask id="Activity_1yyoofc" name="Log Absence in System">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="dummy" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_12c4qv2</bpmn:incoming>
      <bpmn:outgoing>Flow_1jdrgyl</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:endEvent id="Event_0zynvca">
      <bpmn:incoming>Flow_15iwk99</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:serviceTask id="Activity_1evq7au" name="Remind Line Manager">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="dummy" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_1izp9te</bpmn:incoming>
      <bpmn:outgoing>Flow_15iwk99</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:boundaryEvent id="Event_0z73nuo" name="Every 2 Days" cancelActivity="false" attachedToRef="Activity_0jfbxdq">
      <bpmn:outgoing>Flow_1izp9te</bpmn:outgoing>
      <bpmn:timerEventDefinition id="TimerEventDefinition_0c27emr">
        <bpmn:timeDuration xsi:type="bpmn:tFormalExpression">P2D</bpmn:timeDuration>
      </bpmn:timerEventDefinition>
    </bpmn:boundaryEvent>
    <bpmn:sequenceFlow id="Flow_15yg3k5" sourceRef="StartEvent_1" targetRef="Activity_1ihlcws" />
    <bpmn:sequenceFlow id="Flow_0225a20" sourceRef="Activity_0jfbxdq" targetRef="Gateway_1pdgva1" />
    <bpmn:sequenceFlow id="Flow_1a3eadh" name="Rejected" sourceRef="Gateway_1pdgva1" targetRef="Gateway_1uhcky9">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression">= return = "rejected"</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1f7d78d" name="Need&#10;Clarification" sourceRef="Gateway_1pdgva1" targetRef="Activity_17tgveb">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression">= return = "Pong"</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_0tdfmxq" sourceRef="Activity_1ihlcws" targetRef="Activity_0jfbxdq" />
    <bpmn:sequenceFlow id="Flow_0axp5vf" sourceRef="Activity_17tgveb" targetRef="Activity_0jfbxdq" />
    <bpmn:sequenceFlow id="Flow_12c4qv2" name="Approved" sourceRef="Gateway_1pdgva1" targetRef="Activity_1yyoofc">
      <bpmn:conditionExpression xsi:type="bpmn:tFormalExpression">= return = "Pong"</bpmn:conditionExpression>
    </bpmn:sequenceFlow>
    <bpmn:sequenceFlow id="Flow_1wt1dkc" sourceRef="Gateway_1uhcky9" targetRef="Activity_0aebkkx" />
    <bpmn:sequenceFlow id="Flow_1jdrgyl" sourceRef="Activity_1yyoofc" targetRef="Gateway_1uhcky9" />
    <bpmn:sequenceFlow id="Flow_1cusxs4" sourceRef="Activity_0aebkkx" targetRef="Event_02m7n8y" />
    <bpmn:sequenceFlow id="Flow_1izp9te" sourceRef="Event_0z73nuo" targetRef="Activity_1evq7au" />
    <bpmn:sequenceFlow id="Flow_15iwk99" sourceRef="Activity_1evq7au" targetRef="Event_0zynvca" />
    <bpmn:textAnnotation id="TextAnnotation_0l3tdsg">
      <bpmn:text>Just a Dummy (could send an Email)</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_1jt3mi3" sourceRef="Activity_1evq7au" targetRef="TextAnnotation_0l3tdsg" />
    <bpmn:textAnnotation id="TextAnnotation_0voy4yj">
      <bpmn:text>Just a Dummy (could call an API Endpoint)</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_0x7v55m" sourceRef="Activity_1yyoofc" targetRef="TextAnnotation_0voy4yj" />
    <bpmn:textAnnotation id="TextAnnotation_1jioq23">
      <bpmn:text>Just a Dummy (could send an Email)</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_0wwvy0w" sourceRef="Activity_0aebkkx" targetRef="TextAnnotation_1jioq23" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_1trvlnk">
      <bpmndi:BPMNShape id="Participant_056nh3b_di" bpmnElement="Participant_056nh3b" isHorizontal="true">
        <dc:Bounds x="158" y="60" width="1082" height="550" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Lane_0dur4gj_di" bpmnElement="Lane_0dur4gj" isHorizontal="true">
        <dc:Bounds x="188" y="295" width="1052" height="315" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Lane_0jbpf0w_di" bpmnElement="Lane_0jbpf0w" isHorizontal="true">
        <dc:Bounds x="188" y="60" width="1052" height="235" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge id="Flow_15iwk99_di" bpmnElement="Flow_15iwk99">
        <di:waypoint x="640" y="540" />
        <di:waypoint x="682" y="540" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1izp9te_di" bpmnElement="Flow_1izp9te">
        <di:waypoint x="500" y="428" />
        <di:waypoint x="500" y="540" />
        <di:waypoint x="540" y="540" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1cusxs4_di" bpmnElement="Flow_1cusxs4">
        <di:waypoint x="1070" y="370" />
        <di:waypoint x="1132" y="370" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1jdrgyl_di" bpmnElement="Flow_1jdrgyl">
        <di:waypoint x="840" y="474" />
        <di:waypoint x="890" y="474" />
        <di:waypoint x="890" y="395" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1wt1dkc_di" bpmnElement="Flow_1wt1dkc">
        <di:waypoint x="915" y="370" />
        <di:waypoint x="970" y="370" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_12c4qv2_di" bpmnElement="Flow_12c4qv2">
        <di:waypoint x="660" y="395" />
        <di:waypoint x="660" y="474" />
        <di:waypoint x="740" y="474" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="666" y="423" width="47" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0axp5vf_di" bpmnElement="Flow_0axp5vf">
        <di:waypoint x="850" y="210" />
        <di:waypoint x="880" y="210" />
        <di:waypoint x="880" y="140" />
        <di:waypoint x="520" y="140" />
        <di:waypoint x="520" y="330" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0tdfmxq_di" bpmnElement="Flow_0tdfmxq">
        <di:waypoint x="420" y="221" />
        <di:waypoint x="420" y="370" />
        <di:waypoint x="470" y="370" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1f7d78d_di" bpmnElement="Flow_1f7d78d">
        <di:waypoint x="660" y="345" />
        <di:waypoint x="660" y="210" />
        <di:waypoint x="750" y="210" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="670" y="306" width="58" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1a3eadh_di" bpmnElement="Flow_1a3eadh">
        <di:waypoint x="685" y="370" />
        <di:waypoint x="865" y="370" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="698" y="352" width="44" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0225a20_di" bpmnElement="Flow_0225a20">
        <di:waypoint x="570" y="370" />
        <di:waypoint x="635" y="370" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_15yg3k5_di" bpmnElement="Flow_15yg3k5">
        <di:waypoint x="285" y="181" />
        <di:waypoint x="370" y="181" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="249" y="163" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0o4nisz_di" bpmnElement="Activity_1ihlcws">
        <dc:Bounds x="370" y="141" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1lb7152_di" bpmnElement="Activity_0jfbxdq">
        <dc:Bounds x="470" y="330" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1pdgva1_di" bpmnElement="Gateway_1pdgva1" isMarkerVisible="true">
        <dc:Bounds x="635" y="345" width="50" height="50" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="611" y="333" width="38" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1bx0fjx_di" bpmnElement="Activity_17tgveb">
        <dc:Bounds x="750" y="170" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Gateway_1uhcky9_di" bpmnElement="Gateway_1uhcky9" isMarkerVisible="true">
        <dc:Bounds x="865" y="345" width="50" height="50" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_02m7n8y_di" bpmnElement="Event_02m7n8y">
        <dc:Bounds x="1132" y="352" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1aaztne_di" bpmnElement="Activity_0aebkkx">
        <dc:Bounds x="970" y="330" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1si5swf_di" bpmnElement="Activity_1yyoofc">
        <dc:Bounds x="740" y="434" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_0zynvca_di" bpmnElement="Event_0zynvca">
        <dc:Bounds x="682" y="522" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_09059yp_di" bpmnElement="Activity_1evq7au">
        <dc:Bounds x="540" y="500" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_0l3tdsg_di" bpmnElement="TextAnnotation_0l3tdsg">
        <dc:Bounds x="350" y="530" width="100" height="54" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_0voy4yj_di" bpmnElement="TextAnnotation_0voy4yj">
        <dc:Bounds x="810" y="530" width="100" height="54" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_1jioq23_di" bpmnElement="TextAnnotation_1jioq23">
        <dc:Bounds x="1000" y="430" width="100" height="54" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1xxzzm1_di" bpmnElement="Event_0z73nuo">
        <dc:Bounds x="482" y="392" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="417" y="423" width="66" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge id="Association_1jt3mi3_di" bpmnElement="Association_1jt3mi3">
        <di:waypoint x="540" y="560" />
        <di:waypoint x="450" y="550" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Association_0x7v55m_di" bpmnElement="Association_0x7v55m">
        <di:waypoint x="829" y="514" />
        <di:waypoint x="845" y="530" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Association_0wwvy0w_di" bpmnElement="Association_0wwvy0w">
        <di:waypoint x="1036" y="410" />
        <di:waypoint x="1044" y="430" />
      </bpmndi:BPMNEdge>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`

export const TEST_BPMN = `
<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:modeler="http://camunda.org/schema/modeler/1.0" id="Definitions_0rbumvm" targetNamespace="http://bpmn.io/schema/bpmn" modeler:executionPlatform="Camunda Cloud" modeler:executionPlatformVersion="1.0.0">
  <bpmn:process id="test" name="Test" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1">
      <bpmn:outgoing>Flow_0i6ozyi</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:task id="Activity_1ros9vf" name="test">
      <bpmn:incoming>Flow_0i6ozyi</bpmn:incoming>
      <bpmn:outgoing>Flow_0z94ozb</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0i6ozyi" sourceRef="StartEvent_1" targetRef="Activity_1ros9vf" />
    <bpmn:task id="Activity_02rzy56" name="test2">
      <bpmn:incoming>Flow_0z94ozb</bpmn:incoming>
      <bpmn:outgoing>Flow_02a27oh</bpmn:outgoing>
    </bpmn:task>
    <bpmn:sequenceFlow id="Flow_0z94ozb" sourceRef="Activity_1ros9vf" targetRef="Activity_02rzy56" />
    <bpmn:endEvent id="Event_1h0xn9f">
      <bpmn:incoming>Flow_02a27oh</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_02a27oh" sourceRef="Activity_02rzy56" targetRef="Event_1h0xn9f" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="test">
      <bpmndi:BPMNEdge id="Flow_02a27oh_di" bpmnElement="Flow_02a27oh">
        <di:waypoint x="530" y="117" />
        <di:waypoint x="592" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0z94ozb_di" bpmnElement="Flow_0z94ozb">
        <di:waypoint x="370" y="117" />
        <di:waypoint x="430" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0i6ozyi_di" bpmnElement="Flow_0i6ozyi">
        <di:waypoint x="215" y="117" />
        <di:waypoint x="270" y="117" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="179" y="99" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1ros9vf_di" bpmnElement="Activity_1ros9vf">
        <dc:Bounds x="270" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_02rzy56_di" bpmnElement="Activity_02rzy56">
        <dc:Bounds x="430" y="77" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1h0xn9f_di" bpmnElement="Event_1h0xn9f">
        <dc:Bounds x="592" y="99" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`

export const FEATURE_REQUEST = `
<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:modeler="http://camunda.org/schema/modeler/1.0" id="Definitions_0rbumvm" targetNamespace="http://bpmn.io/schema/bpmn" modeler:executionPlatform="Camunda Cloud" modeler:executionPlatformVersion="1.0.0">
  <bpmn:process id="feature-request" name="Feature Request" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1">
      <bpmn:outgoing>Flow_0rv69fq</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:sequenceFlow id="Flow_0rv69fq" sourceRef="StartEvent_1" targetRef="Activity_03hqr0l" />
    <bpmn:userTask id="Activity_03hqr0l" name="Create Feature Request">
      <bpmn:incoming>Flow_0rv69fq</bpmn:incoming>
      <bpmn:outgoing>Flow_0rg325o</bpmn:outgoing>
    </bpmn:userTask>
    <bpmn:sequenceFlow id="Flow_0rg325o" sourceRef="Activity_03hqr0l" targetRef="Activity_0hyrmxb" />
    <bpmn:serviceTask id="Activity_0hyrmxb" name="Add Request to Backlog">
      <bpmn:incoming>Flow_0rg325o</bpmn:incoming>
      <bpmn:outgoing>Flow_0bughlw</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_0bughlw" sourceRef="Activity_0hyrmxb" targetRef="Activity_0ayg2vd" />
    <bpmn:serviceTask id="Activity_0ayg2vd" name="Create GitHub Issue">
      <bpmn:incoming>Flow_0bughlw</bpmn:incoming>
      <bpmn:outgoing>Flow_046bmgv</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:sequenceFlow id="Flow_046bmgv" sourceRef="Activity_0ayg2vd" targetRef="Activity_0lu6lc2" />
    <bpmn:serviceTask id="Activity_0lu6lc2" name="Notify Dev Team">
      <bpmn:incoming>Flow_046bmgv</bpmn:incoming>
      <bpmn:outgoing>Flow_1ew189j</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:endEvent id="Event_0bavvs2">
      <bpmn:incoming>Flow_1ew189j</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:sequenceFlow id="Flow_1ew189j" sourceRef="Activity_0lu6lc2" targetRef="Event_0bavvs2" />
    <bpmn:textAnnotation id="TextAnnotation_03slkzx">
      <bpmn:text>Airtable: Create Record</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_0h76cj8" sourceRef="Activity_0hyrmxb" targetRef="TextAnnotation_03slkzx" />
    <bpmn:textAnnotation id="TextAnnotation_0mm55h3">
      <bpmn:text>GitHub: Create Issue</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_1ssw6ui" sourceRef="Activity_0ayg2vd" targetRef="TextAnnotation_0mm55h3" />
    <bpmn:textAnnotation id="TextAnnotation_1rvvtp9">
      <bpmn:text>Slack: Send Message</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_1xz1qii" sourceRef="Activity_0lu6lc2" targetRef="TextAnnotation_1rvvtp9" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="feature-request">
      <bpmndi:BPMNEdge id="Flow_1ew189j_di" bpmnElement="Flow_1ew189j">
        <di:waypoint x="840" y="177" />
        <di:waypoint x="902" y="177" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_046bmgv_di" bpmnElement="Flow_046bmgv">
        <di:waypoint x="680" y="177" />
        <di:waypoint x="740" y="177" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0bughlw_di" bpmnElement="Flow_0bughlw">
        <di:waypoint x="520" y="177" />
        <di:waypoint x="580" y="177" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0rg325o_di" bpmnElement="Flow_0rg325o">
        <di:waypoint x="370" y="177" />
        <di:waypoint x="420" y="177" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0rv69fq_di" bpmnElement="Flow_0rv69fq">
        <di:waypoint x="215" y="177" />
        <di:waypoint x="270" y="177" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="179" y="159" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1nlpbc0_di" bpmnElement="Activity_03hqr0l">
        <dc:Bounds x="270" y="137" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1mio01w_di" bpmnElement="Activity_0hyrmxb">
        <dc:Bounds x="420" y="137" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0evosu7_di" bpmnElement="Activity_0ayg2vd">
        <dc:Bounds x="580" y="137" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0tds8tg_di" bpmnElement="Activity_0lu6lc2">
        <dc:Bounds x="740" y="137" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_0bavvs2_di" bpmnElement="Event_0bavvs2">
        <dc:Bounds x="902" y="159" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_03slkzx_di" bpmnElement="TextAnnotation_03slkzx">
        <dc:Bounds x="490" y="80" width="100" height="40" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_0mm55h3_di" bpmnElement="TextAnnotation_0mm55h3">
        <dc:Bounds x="650" y="85" width="100" height="40" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_1rvvtp9_di" bpmnElement="TextAnnotation_1rvvtp9">
        <dc:Bounds x="810" y="85" width="100" height="40" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge id="Association_0h76cj8_di" bpmnElement="Association_0h76cj8">
        <di:waypoint x="504" y="137" />
        <di:waypoint x="519" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Association_1ssw6ui_di" bpmnElement="Association_1ssw6ui">
        <di:waypoint x="666" y="137" />
        <di:waypoint x="677" y="125" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Association_1xz1qii_di" bpmnElement="Association_1xz1qii">
        <di:waypoint x="826" y="137" />
        <di:waypoint x="837" y="125" />
      </bpmndi:BPMNEdge>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`

export const TICKET_BOOKING = `
<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" id="Definitions_1a4gt8m" targetNamespace="http://bpmn.io/schema/bpmn" exporter="Zeebe Modeler" exporterVersion="0.9.0">
  <bpmn:collaboration id="Collaboration_0t1vxrh">
    <bpmn:participant id="ticket-booking-participant" name="TicketBooking" processRef="ticket-booking" />
    <bpmn:participant id="Participant_1l2gxa0" name="Payment" />
    <bpmn:participant id="Participant_0y2e8ja" name="Seat Reservation" />
    <bpmn:participant id="Participant_1i465kq" name="Ticket Generation" />
    <bpmn:messageFlow id="Flow_11rhvrd" name="gRPC&#10;(Pub/Sub Engine)" sourceRef="Activity_1g89uec" targetRef="Participant_0y2e8ja" />
    <bpmn:messageFlow id="Flow_08b5p57" name="AMQP" sourceRef="Activity_0lox1kf" targetRef="Participant_1l2gxa0" />
    <bpmn:messageFlow id="Flow_1lm74ik" name="AMQP" sourceRef="Participant_1l2gxa0" targetRef="Activity_0h19mxb" />
    <bpmn:messageFlow id="Flow_02bhzx9" name="REST" sourceRef="Activity_0etdda4" targetRef="Participant_1i465kq" />
  </bpmn:collaboration>
  <bpmn:process id="ticket-booking" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1">
      <bpmn:outgoing>Flow_19ebf54</bpmn:outgoing>
    </bpmn:startEvent>
    <bpmn:serviceTask id="Activity_1g89uec" name="Reserve Seats">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="reserve-seats" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_19ebf54</bpmn:incoming>
      <bpmn:outgoing>Flow_0qbwde5</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:receiveTask id="Activity_0h19mxb" name="Wait for &#39;payment retrieved&#39; event" messageRef="Message_04xnjbt">
      <bpmn:incoming>Flow_0mwrroh</bpmn:incoming>
      <bpmn:outgoing>Flow_0podi1a</bpmn:outgoing>
    </bpmn:receiveTask>
    <bpmn:endEvent id="Event_1ne48r4" name="Booking cancelled">
      <bpmn:incoming>Flow_18sbo4b</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:endEvent id="Event_05eni0s" name="Booking cancelled">
      <bpmn:incoming>Flow_012b8wg</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:endEvent id="Event_1mqmjv4" name="Booking succeeded">
      <bpmn:incoming>Flow_19x2sei</bpmn:incoming>
    </bpmn:endEvent>
    <bpmn:serviceTask id="Activity_0lox1kf" name="Send &#39;retrieve payment&#39; command">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="retrieve-payment" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0qbwde5</bpmn:incoming>
      <bpmn:outgoing>Flow_0mwrroh</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:serviceTask id="Activity_0etdda4" name="Generate Ticket">
      <bpmn:extensionElements>
        <zeebe:taskDefinition type="generate-ticket" retries="3" />
      </bpmn:extensionElements>
      <bpmn:incoming>Flow_0podi1a</bpmn:incoming>
      <bpmn:outgoing>Flow_19x2sei</bpmn:outgoing>
    </bpmn:serviceTask>
    <bpmn:boundaryEvent id="Event_076cmo0" name="seat could not be reserved" attachedToRef="Activity_1g89uec">
      <bpmn:outgoing>Flow_18sbo4b</bpmn:outgoing>
      <bpmn:errorEventDefinition id="ErrorEventDefinition_0t3hnlu" errorRef="Error_11kp03o" />
    </bpmn:boundaryEvent>
    <bpmn:boundaryEvent id="Event_1ywtp2y" attachedToRef="Activity_0h19mxb">
      <bpmn:outgoing>Flow_012b8wg</bpmn:outgoing>
      <bpmn:timerEventDefinition id="TimerEventDefinition_17ojlyf">
        <bpmn:timeDuration xsi:type="bpmn:tFormalExpression">PT30S</bpmn:timeDuration>
      </bpmn:timerEventDefinition>
    </bpmn:boundaryEvent>
    <bpmn:sequenceFlow id="Flow_19x2sei" sourceRef="Activity_0etdda4" targetRef="Event_1mqmjv4" />
    <bpmn:sequenceFlow id="Flow_18sbo4b" sourceRef="Event_076cmo0" targetRef="Event_1ne48r4" />
    <bpmn:sequenceFlow id="Flow_012b8wg" sourceRef="Event_1ywtp2y" targetRef="Event_05eni0s" />
    <bpmn:sequenceFlow id="Flow_0podi1a" sourceRef="Activity_0h19mxb" targetRef="Activity_0etdda4" />
    <bpmn:sequenceFlow id="Flow_0mwrroh" sourceRef="Activity_0lox1kf" targetRef="Activity_0h19mxb" />
    <bpmn:sequenceFlow id="Flow_0qbwde5" sourceRef="Activity_1g89uec" targetRef="Activity_0lox1kf" />
    <bpmn:sequenceFlow id="Flow_19ebf54" sourceRef="StartEvent_1" targetRef="Activity_1g89uec" />
    <bpmn:textAnnotation id="TextAnnotation_1jzfvr1">
      <bpmn:text>Including retries if REST API is not available</bpmn:text>
    </bpmn:textAnnotation>
    <bpmn:association id="Association_0klei9i" sourceRef="Activity_0etdda4" targetRef="TextAnnotation_1jzfvr1" />
  </bpmn:process>
  <bpmn:message id="Message_04xnjbt" name="msg-payment-received">
    <bpmn:extensionElements>
      <zeebe:subscription correlationKey="=paymentRequestId" />
    </bpmn:extensionElements>
  </bpmn:message>
  <bpmn:error id="Error_11kp03o" name="ErrorSeatsNotAvailable" errorCode="ErrorSeatsNotAvailable" />
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_0t1vxrh">
      <bpmndi:BPMNShape id="Participant_0yfjoqi_di" bpmnElement="ticket-booking-participant" isHorizontal="true">
        <dc:Bounds x="160" y="59" width="1120" height="301" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge id="Flow_19ebf54_di" bpmnElement="Flow_19ebf54">
        <di:waypoint x="445" y="187" />
        <di:waypoint x="500" y="187" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0qbwde5_di" bpmnElement="Flow_0qbwde5">
        <di:waypoint x="600" y="187" />
        <di:waypoint x="680" y="187" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0mwrroh_di" bpmnElement="Flow_0mwrroh">
        <di:waypoint x="780" y="187" />
        <di:waypoint x="820" y="187" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0podi1a_di" bpmnElement="Flow_0podi1a">
        <di:waypoint x="920" y="187" />
        <di:waypoint x="970" y="187" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_012b8wg_di" bpmnElement="Flow_012b8wg">
        <di:waypoint x="890" y="245" />
        <di:waypoint x="890" y="292" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_18sbo4b_di" bpmnElement="Flow_18sbo4b">
        <di:waypoint x="570" y="245" />
        <di:waypoint x="570" y="292" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_19x2sei_di" bpmnElement="Flow_19x2sei">
        <di:waypoint x="1070" y="187" />
        <di:waypoint x="1112" y="187" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="409" y="169" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0g77fwc_di" bpmnElement="Activity_1g89uec">
        <dc:Bounds x="500" y="147" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0z5d04d_di" bpmnElement="Activity_0h19mxb">
        <dc:Bounds x="820" y="147" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1ne48r4_di" bpmnElement="Event_1ne48r4">
        <dc:Bounds x="552" y="292" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="525" y="335" width="90" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_05eni0s_di" bpmnElement="Event_05eni0s">
        <dc:Bounds x="872" y="292" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="845" y="335" width="90" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_1mqmjv4_di" bpmnElement="Event_1mqmjv4">
        <dc:Bounds x="1112" y="169" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1103" y="212" width="54" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1e9t3gn_di" bpmnElement="Activity_0lox1kf">
        <dc:Bounds x="680" y="147" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0zlzkpv_di" bpmnElement="Activity_0etdda4">
        <dc:Bounds x="970" y="147" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="TextAnnotation_1jzfvr1_di" bpmnElement="TextAnnotation_1jzfvr1">
        <dc:Bounds x="1070" y="80" width="150" height="40" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_0pqivlb_di" bpmnElement="Event_1ywtp2y">
        <dc:Bounds x="872" y="209" width="36" height="36" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_02ljv02_di" bpmnElement="Event_076cmo0">
        <dc:Bounds x="552" y="209" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="578" y="252" width="84" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge id="Association_0klei9i_di" bpmnElement="Association_0klei9i">
        <di:waypoint x="1065" y="148" />
        <di:waypoint x="1112" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="Participant_1xi7sa7_di" bpmnElement="Participant_1l2gxa0" isHorizontal="true">
        <dc:Bounds x="654" y="430" width="300" height="60" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Participant_03ofn7t_di" bpmnElement="Participant_0y2e8ja" isHorizontal="true">
        <dc:Bounds x="340" y="430" width="300" height="60" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Participant_0whvqa2_di" bpmnElement="Participant_1i465kq" isHorizontal="true">
        <dc:Bounds x="980" y="430" width="300" height="60" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNEdge id="Flow_11rhvrd_di" bpmnElement="Flow_11rhvrd">
        <di:waypoint x="520" y="227" />
        <di:waypoint x="520" y="430" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="527" y="383" width="87" height="27" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_08b5p57_di" bpmnElement="Flow_08b5p57">
        <di:waypoint x="730" y="227" />
        <di:waypoint x="730" y="430" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="734" y="383" width="33" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_1lm74ik_di" bpmnElement="Flow_1lm74ik">
        <di:waypoint x="840" y="430" />
        <di:waypoint x="840" y="227" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="844" y="383" width="33" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_02bhzx9_di" bpmnElement="Flow_02bhzx9">
        <di:waypoint x="1020" y="227" />
        <di:waypoint x="1020" y="430" />
        <bpmndi:BPMNLabel>
          <dc:Bounds x="1020" y="383" width="30" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNEdge>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`

export function TemplateCategories(): TemplateCategory[] {
  const templateCategories: TemplateCategory[] = [
    {
      category: TemplateCategoryEnum.HUMAN_TASK,
      expanded: false,
      selected: false,
      name: 'Orchestrate Human Tasks',
      description: `Assign tasks to users and let them enter data to drive the business process.`,
      icon: `/assets/home/human-work.svg`,
      templates: [
        {
          id: 'absence-request',
          name: 'Absence Request',
          description: `This process handles absence requests. As an employee, you can file an absence request which will then be reviewed by your manager. The manager might accept or reject your request, or request a further explanation for it before reviewing it again. If the review has not happened within two days, the manager will be reminded.<br/><br/>`,
          diagram: ABSENCE_REQUEST,
          selected: false,
          config: {
            propertyPanel: {
              tooltips: [],
            },

            diagram: {
              tooltips: [
                {
                  id: 'Activity_1ihlcws',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/user-tasks/user-tasks" target="_blank">User Task</a> assigns work to a human user. The task will show up in <a href="https://docs.camunda.io/docs/product-manuals/tasklist/introduction" target="_blank">Tasklist</a> or any other frontend you integrate with Camunda. The user can claim the task, fill out the <a href="https://docs.camunda.io/docs/product-manuals/tasklist/userguide/camunda-forms" target="_blank">Task Form</a> and submit it to complete the task. When the task is completed, the process will continue to the next step.',
                },
                {
                  id: 'Event_0z73nuo',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/timer-events/timer-events" target="_blank">Timer Event</a> is attached to a User Task. It triggers based on the interval that you specify in the <a href="https://docs.camunda.io/docs/reference/bpmn-processes/timer-events/timer-events#timers" target="_blank">properties</a> - in this case, every two days.  Because it is <a href="https://docs.camunda.io/docs/reference/bpmn-processes/timer-events/timer-events#timer-boundary-events" target="_blank">non-interrupting</a> it will not cancel the User Task that it’s attached to when triggered, but instead initiate a flow that runs in parallel - in this case, to remind the line manager that this task is overdue.',
                },
                {
                  id: 'Gateway_1pdgva1',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/exclusive-gateways/exclusive-gateways" target="_blank">Exclusive Gateway</a> will determine how the process should continue. There are three possible options (Approved, Rejected, and Need Clarification). Technically, you need to define a <a href="https://docs.camunda.io/docs/reference/bpmn-processes/exclusive-gateways/exclusive-gateways#conditions" target="_blank">condition expression</a> as a property for each of the  outgoing sequence flows.',
                },
                {
                  id: 'Flow_1a3eadh',
                  text:
                    'We have defined a <a href="https://docs.camunda.io/docs/reference/bpmn-processes/exclusive-gateways/exclusive-gateways#conditions" target="_blank">condition expression</a> in the properties of this Sequence Flow. If the value of the variable “result” is “rejected”, then the process will continue on this flow. The variable has been populated by the task form that had been submitted in the previous User task “Approve Absence”.',
                },
              ],
            },
          },
        },
      ],
    },
    {
      category: TemplateCategoryEnum.ORCHESTRATE_MICROSERVICES,
      expanded: false,
      selected: false,
      name: 'Orchestrate Microservices',
      description: `Orchestrate Microservices along a business process for visibility and resilience.`,
      icon: `/assets/home/microservices-orchestration.svg`,
      templates: [
        {
          id: 'ticket-booking',
          name: 'Ticket Booking',
          description: `This process handles ticket sales for events. In the first step, we reserve the respective seats via gRPC. If this fails due to an error, the booking is cancelled. Once the seats have been reserved, we request via AMQP that the payment will be retrieved and then wait for an event that confirms the arrival of the payment. If that event does not arrive within 30 seconds, the booking is cancelled. Once we’ve received the payment, we trigger the ticket generation in the respective system via REST.<br/><br/>`,
          diagram: TICKET_BOOKING,
          selected: false,
          config: {
            propertyPanel: {
              tooltips: [],
            },

            diagram: {
              tooltips: [
                {
                  id: 'Activity_1g89uec',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/service-tasks/service-tasks/" target="_blank">Service Task</a> assigns work to a microservice by creating a job. The process instance stops here and waits until the job is complete. The workflow engine will not call the microservice, but instead wait to be called: A <a href="https://docs.camunda.io/docs/components/concepts/job-workers/" target="_blank">job worker</a> can subscribe to the job type, process the jobs, and complete them using one of the Zeebe clients. When the job is complete, the service task is completed and the process instance continues.',
                },
                {
                  id: 'Event_076cmo0',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/error-events/error-events/" target="_blank">Error Event</a> is attached to a Service Task. It triggers if the job worker tells the workflow engine via client command that an error has occurred that should be handled by the process. ',
                },
                {
                  id: 'Participant_0y2e8ja',
                  text:
                    'This Collapsed Pool does not have any execution semantics, i.e. it will be ignored by the workflow engine. It’s only here to make the diagram more expressive since it represents the external system that the Microservice either belongs to or invokes. You can learn more about pools and lanes in our <a href="https://camunda.com/bpmn/reference/#participants-pool" target="_blank">general BPMN reference</a>.',
                },
                {
                  id: 'Activity_0h19mxb',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/receive-tasks/receive-tasks/" target="_blank">Receive Task</a> does not assign work to a microservice, but just waits for an incoming message. It’s a visual alternative to the <a href="https://docs.camunda.io/docs/reference/bpmn-processes/message-events/message-events/#intermediate-message-catch-events" target="_blank">Intermediate Message Catch Event</a>, with the same execution semantics. ',
                },
                {
                  id: 'Event_1ywtp2y',
                  text:
                    'This <a href="https://docs.camunda.io/docs/reference/bpmn-processes/timer-events/timer-events" target="_blank">Timer Event</a> is attached to a Receive Task. It triggers based on the duration interval that you specify in the <a href="https://docs.camunda.io/docs/reference/bpmn-processes/timer-events/timer-events#timers" target="_blank">properties</a> - in this case, after 30 seconds. It is interrupting which means that once 30 seconds have passed since the workflow engine has waited for the message in ‘payment received’, it will cancel the Receive Task and not process that message anymore. Instead, it will end the process in the End Event ‘Booking cancelled”.',
                },
              ],
            },
          },
        },
      ],
    },
  ]
  return templateCategories
}

export function TemplateById(id: string): Template | undefined {
  const categories = TemplateCategories()
  for (const category of categories) {
    for (const template of category.templates) {
      if (template.id === id) {
        return template
      }
    }
  }
  return undefined
}
