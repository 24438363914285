import { Injectable } from '@angular/core'
import { first } from 'rxjs/operators'
import { ZeebeClusterVersionsDto } from '../../../../commons/ZeebeClusterVersions.dto'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class GenerationService {
  constructor(private apiService: ApiService) {}

  public create(
    name: string,
    versions: ZeebeClusterVersionsDto,
    upgradeableFrom: string[],
  ) {
    return this.apiService
      .createGeneration(name, versions, upgradeableFrom)
      .toPromise()
  }

  public delete(generationId: string) {
    return this.apiService.deleteGeneration(generationId)
  }

  public deleteClustersOfGeneration(generationId: string) {
    return this.apiService.deleteClustersOfGeneration(generationId).toPromise()
  }

  public updateUpgradeableFromGenerations(
    generationId: string,
    upgradeableFrom: string[],
  ) {
    return this.apiService.updateUpgradeableFromGenerations(
      generationId,
      upgradeableFrom,
    )
  }

  public upgradeGenerationTo(oldGenerationId: string, newGenerationId: string) {
    return this.apiService
      .upgradeGenerationTo(oldGenerationId, newGenerationId)
      .pipe(first())
      .toPromise()
  }

  public upgradeGenerationforCluster(
    generationUuid: string,
    clusterUuid: string,
  ) {
    return this.apiService
      .upgradeGenerationforCluster(generationUuid, clusterUuid)
      .pipe(first())
      .toPromise()
  }
}
