<div>
  <div
    class="admin-header"
    *ngIf="
      vvs.visibilities.cluster.list.delete.all.visible ||
      vvs.visibilities.cluster.list.delete.unhealthy.visible ||
      vvs.visibilities.cluster.list.delete.healthy.visible
    "
  >
    <cm-button
      appearance="danger"
      (cmPress)="deleteAllClusters(org)"
      *ngIf="vvs.visibilities.cluster.list.delete.all.visible"
      label="Delete all clusters"
    >
    </cm-button>

    <cm-button
      appearance="danger"
      (cmPress)="deleteAllUnhealthyClusters(org)"
      *ngIf="vvs.visibilities.cluster.list.delete.unhealthy.visible"
      label="Delete all unhealthy clusters"
    >
    </cm-button>

    <cm-button
      appearance="danger"
      (cmPress)="deleteAllHealthyClusters(org)"
      *ngIf="vvs.visibilities.cluster.list.delete.healthy.visible"
      label="Delete all healthy clusters"
    >
    </cm-button>
  </div>
  <div
    *ngIf="vvs.visibilities.cluster.list.message.clusterCap.visible"
    class="message fullwidth"
    id="clusterCapReached"
  >
    We are overwhelmed by the success of Camunda Cloud. For this reason, we are
    currently unable to provision any additional Clusters. You can try later,
    but we will reach out to you as soon as we have free capacity again.
  </div>
</div>

<cm-entity-list
  [loading]="entityListLoading"
  [enableCreateButton]="
    !entityListLoading &&
    !vvs.visibilities.cluster.list.message.trialExpired.visible &&
    !vvs.visibilities.cluster.list.message.clusterCap.visible &&
    !vvs.visibilities.cluster.list.create.disabled
  "
  [hideCreateButton]="!vvs.visibilities.cluster.list.create.visible"
  headline="Clusters"
  createButtonLabel="Create New Cluster"
  [createHandler]="clusterListCreateHandler"
  [columns]="clusterListColumns"
  [entities]="clusterListEntities"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList">
      <h3 *ngIf="!vvs.visibilities.cluster.list.message.trialExpired.visible">
        No Clusters created
      </h3>
      <h3 *ngIf="vvs.visibilities.cluster.list.message.trialExpired.visible">
        No Clusters available
      </h3>

      <cm-text
        *ngIf="!vvs.visibilities.cluster.list.message.trialExpired.visible"
      >
        Want to design, automate, and manage your processes?
        <cm-button
          appearance="link"
          label="Create a new Cluster"
          (cmPress)="openCreateClusterDialog()"
        ></cm-button>
        to get started using Camunda Cloud and also check our
        <cm-link
          label="getting started guide"
          href="https://docs.camunda.io/docs/guides/getting-started/setup-client-connection-credentials"
        >
        </cm-link>
        step-by-step description on what to do next.
      </cm-text>
      <cm-text
        *ngIf="vvs.visibilities.cluster.list.message.trialExpired.visible"
      >
        Your Cluster and data were deleted because your trial has expired.
      </cm-text>
    </div>
  </div>
</cm-entity-list>

<cm-modal
  #defaultCallToActionModal
  headline="While we create your cluster"
  cancelLabel="Close"
  confirmLabel="Create Diagram"
>
  <cm-text>
    The next step is to create your first BPMN diagram. You can use our built-in
    Modeler or
    <cm-link
      href="https://camunda.com/download/modeler"
      label="download the Camunda Modeler"
    ></cm-link
    >. </cm-text
  ><br /><br />
  <cm-text>
    In both cases, following the
    <cm-link
      href="http://docs.camunda.io/docs/guides/getting-started/model-your-first-process"
      label="Getting Started Guide and documentation"
    ></cm-link>
    is a good idea.
  </cm-text>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Cluster &quot;{{
    clusterToBeDeleted ? clusterToBeDeleted.name : ''
  }}&quot;"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text *ngIf="clusterToBeDeleted">
    Are you sure you want to delete the Cluster with ID
    <b>{{ clusterToBeDeleted.uuid }}</b
    >?
  </cm-text>
</cm-modal>

<cm-modal
  #renameModal
  headline="Rename Cluster"
  cancelLabel="Cancel"
  confirmLabel="Rename"
>
  <cm-form>
    <cm-textfield
      label="Current Name"
      disabled
      [value]="clusterToBeRenamed ? clusterToBeRenamed.name : ''"
    ></cm-textfield>
    <cm-textfield
      label="New Name"
      maxlength="50"
      formName="newName"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>
  </cm-form>
</cm-modal>

<cm-modal
  #createClusterWarningTrial
  headline="Create New Cluster"
  cancelLabel="Cancel"
  confirmLabel="Update Subscription"
>
  <p *ngIf="vvs.visibilities.navbar.trial.warning.visible">
    As a trial user, you have 1 reserved Cluster.
  </p>
  <p>
    To create a new Cluster, delete an existing one first, or click the
    <b>Update Subscription</b> button to reach out to sales.
  </p>
</cm-modal>

<cm-modal
  #createClusterWarningAdmin
  headline="Create New Cluster"
  cancelLabel="Cancel"
  confirmLabel="Edit Hardware Packages"
>
  <p>All reserved Clusters are in use.</p>
  <p>
    To create a new Cluster, delete an existing one first, or increase the
    reserved Hardware Packages.
  </p>
</cm-modal>

<cm-modal
  #createClusterWarningMemberModal
  headline="Create New Cluster"
  confirmLabel="Close"
>
  <cm-text>All reserved Clusters are in use. </cm-text>
  <cm-text>
    To create a new Cluster, delete an existing one first, or ask an
    administrator to increase the reserved Hardware Packages.
  </cm-text>
</cm-modal>

<cm-modal
  #requestPaidPlanModal
  headline="Request paid plan"
  cancelLabel="Cancel"
  confirmLabel="Send Request"
>
  <cm-text>
    Your request will be sent to our sales team; they will respond within 24
    hours via email.
  </cm-text>
</cm-modal>

<create-cluster-dialog></create-cluster-dialog>
