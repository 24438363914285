import { OverlayModule } from '@angular/cdk/overlay'
import { DatePipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgModule } from '@angular/core'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSliderModule } from '@angular/material/slider'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'

import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular'
import { ChargebeeJsAngularWrapperModule } from '@chargebee/chargebee-js-angular-wrapper'

import { CommonUiModule } from '@camunda-cloud/common-ui-angular'

import * as config from '../../auth.config.json'
import { environment } from '../environments/environment'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'

import { CheckoutSuccessPage } from './pages/checkout-success/checkout-success.page'
import { ClusterDetailsPage } from './pages/cluster-details/cluster-details.page'
import { ErrorPage } from './pages/error/error.page'
import { NoOrganizationPage } from './pages/no-organization/no-organization.page'
import { DashboardPage } from './pages/dashboard/dashboard.page'
import { OrganizationRedirectPage } from './pages/organization-redirect/organization-redirect.page'
import { OrganizationManagementPage } from './pages/organization-management/organization-management.page'
import { ProductWrapperPage } from './pages/product-wrapper/product-wrapper.page'
import { SignupPage } from './pages/signup/signup.page'
import { TermsPage } from './pages/terms/terms.page'
import { UserSettingsPage } from './pages/user-settings/user-settings.page'
import { ExternalViewPage } from './pages/external-page/external-page.component'

import { GenerationDetailsPage } from './pages/admin/generation-details/generation-details.page'
import { VersionManagementPage } from './pages/admin/version-management/version-management.page'
import { ClusterPlanDetailsPage } from './pages/admin/cluster-plan-details/cluster-plan-details.page'
import { ClusterPlanManagementPage } from './pages/admin/cluster-plan-management/cluster-plan-management.page'
import { KubernetesContextPage } from './pages/admin/kubernetes-context/kubernetes-context.page'
import { CorpseManagementPage } from './pages/admin/corpse-management/corpse-management.page'

import { ClusterDetailsComponent } from './components/cluster/details/details.component'
import { ClusterClientsComponent } from './components/cluster/clients/clients.component'
import { ClusterApplicationsComponent } from './components/cluster/applications/applications.component'
import { ClusterMetricsComponent } from './components/cluster/metrics/metrics.component'
import { LineChartComponent } from './components/cluster/metrics/line-chart/line-chart.component'
import { ClusterAlertsComponent } from './components/cluster/alerts/alerts.component'

import { OrganizationOverviewComponent } from './components/organization/overview/overview.component'
import { OrganizationUsersComponent } from './components/organization/users/users.component'
import { OrganizationBillingComponent } from './components/organization/billing/billing.component'
import { OrganizationUsageComponent } from './components/organization/usage/usage.component'
import { OrganizationCloudApiComponent } from './components/organization/cloud-api/cloud-api.component'

import { CopyToClipboardComponent } from './components/shared/copy-to-clipboard/copy-to-clipboard.component'
import { ListAuditLogsComponent } from './components/shared/list-audit-logs/list-audit-logs.component'

import { CreateClusterDialogComponent } from './components/dialogs/create-cluster-dialog/create-cluster-dialog.component'
import { SelectTemplateDialogComponent } from './components/dialogs/select-template-dialog/select-template-dialog.component'

import { ClusterPlanTreeElementComponent } from './components/admin/list-cluster-plans/cluster-plan-tree-element/cluster-plan-tree-element.component'
import { ClusterPlanTreeComponent } from './components/admin/list-cluster-plans/cluster-plan-tree/cluster-plan-tree.component'
import { ListClusterPlansComponent } from './components/admin/list-cluster-plans/list-cluster-plans.component'
import { ListClusterPlanTypesComponent } from './components/admin/list-cluster-plan-types/list-cluster-plan-types.component'
import { ListChannelsComponent } from './components/admin/list-channels/list-channels.component'
import { ListGenerationsComponent } from './components/admin/list-generations/list-generations.component'
import { GenerationDetailsComponent } from './components/admin/generation-details/generation-details.component'
import { ClusterPlanDetailsComponent } from './components/admin/cluster-plan-details/cluster-plan-details.component'

import { BpmnFormDesignerComponent } from './components/bpmn-form-designer/bpmn-form-designer.component'
import { BpmnModelerComponent } from './components/bpmn-modeler/bpmn-modeler.component'
import { CreditCardComponent } from './components/credit-card-component/credit-card.component'
import { ListBpmnFormsComponent } from './components/list-bpmn-forms/list-bpmn-forms.component'
import { ListBpmnModelsComponent } from './components/list-bpmn-models/list-bpmn-models.component'
import { ListClustersComponent } from './components/list-clusters/list-clusters.component'
import { ModalComponent } from './components/modal-component/modal.component'
import { NavBarComponent } from './components/nav-bar/nav-bar.component'
import { ReservedClustersComponent } from './components/reserved-clusters/reserved-clusters.component'
import { TasklistOperateRedirectComponent } from './components/taskist-operate-redirect/tasklist-operate-redirect.component'
import { CacheInterceptorService } from './services/cacheInterceptor.service'
import { BpmnViewerComponent } from './components/bpmn-viewer/bpmn-viewer.component'
import { ListConnectorsComponent } from './components/list-connectors/list-connectors.component'
import { ExternalOrgDetailsComponent } from './components/external-org-details/external-org-details.component'
import { ExternalOrgMembersComponent } from './components/external-org-members/external-org-members.component'
import { ExternalOrgMemberMeComponent } from './components/external-org-member-me/external-org-member-me.component'
import { ExternalClustersComponent } from './components/external-clusters/external-clusters.component'
import { ExternalInternalClientComponent } from './components/external-internal-client/external-internal-client.component'
import { ExternalInternalClientOrgLevelComponent } from './components/external-internal-client-org-level/external-internal-client-org-level.component'
import { IpWhitelistComponent } from './components/cluster/ip-whitelist/ip-whitelist.component'
import { ChannelDetailsComponent } from './components/admin/channel-details/channel-details.component'
import { ChannelEditComponent } from './components/admin/channel-edit/channel-edit.component'

@NgModule({
  declarations: [
    AppComponent,

    DashboardPage,
    ClusterDetailsPage,
    TermsPage,
    NoOrganizationPage,
    OrganizationRedirectPage,
    VersionManagementPage,
    KubernetesContextPage,
    ClusterPlanManagementPage,
    GenerationDetailsPage,
    CorpseManagementPage,
    ClusterPlanDetailsPage,
    OrganizationManagementPage,
    UserSettingsPage,
    ErrorPage,
    SignupPage,
    CheckoutSuccessPage,
    ProductWrapperPage,
    ExternalViewPage,

    ClusterClientsComponent,
    ClusterPlanDetailsComponent,
    ClusterApplicationsComponent,
    ClusterAlertsComponent,
    ClusterMetricsComponent,
    LineChartComponent,

    OrganizationUsageComponent,
    OrganizationOverviewComponent,
    OrganizationUsersComponent,
    OrganizationCloudApiComponent,

    CreateClusterDialogComponent,
    SelectTemplateDialogComponent,

    CopyToClipboardComponent,
    ListAuditLogsComponent,

    NavBarComponent,
    ClusterDetailsComponent,
    ListClustersComponent,
    ListGenerationsComponent,
    ListChannelsComponent,
    GenerationDetailsComponent,
    ListClusterPlansComponent,
    ListClusterPlanTypesComponent,
    ReservedClustersComponent,
    ModalComponent,
    ListBpmnModelsComponent,
    ListBpmnFormsComponent,
    BpmnModelerComponent,
    BpmnFormDesignerComponent,
    TasklistOperateRedirectComponent,
    ClusterPlanTreeElementComponent,
    ClusterPlanTreeComponent,
    OrganizationBillingComponent,
    CreditCardComponent,
    BpmnViewerComponent,
    ListConnectorsComponent,
    ExternalOrgDetailsComponent,
    ExternalOrgMembersComponent,
    ExternalOrgMemberMeComponent,
    ExternalClustersComponent,
    ExternalInternalClientComponent,
    ExternalInternalClientOrgLevelComponent,
    IpWhitelistComponent,
    ChannelDetailsComponent,
    ChannelEditComponent,
  ],
  entryComponents: [
    CreateClusterDialogComponent,
    SelectTemplateDialogComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    MatCardModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatSliderModule,
    MatTabsModule,
    MatTableModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MatSelectModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonUiModule,
    AuthModule.forRoot({
      domain: config.domain,
      clientId: config.clientId,
      redirectUri: window.location.origin,
      audience: environment.camundaCloudAudience,
      leeway: 10,
      httpInterceptor: {
        allowedList: [
          // Attach access tokens to any calls to '/api' (exact match)
          {
            uri: '*',
          },
        ],
      },
      errorPath: 'error',
    }),
    ChargebeeJsAngularWrapperModule,
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [],
})
export class AppModule {}
