/* eslint-disable id-length */

import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { first } from 'rxjs/operators'
import { NotificationService } from '../../../services/notification.service'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { ClusterIpWhitelistDto } from '../../../../../../commons/ClusterIpWhitelistDto'
import { ApiService } from '../../../services/api.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'ip-whitelist',
  templateUrl: './ip-whitelist.component.html',
  styleUrls: ['./ip-whitelist.component.scss'],
})
export class IpWhitelistComponent implements OnInit {
  @Input()
  public cluster: ClusterDto

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('createModal', { read: ElementRef })
  public createModal: ElementRef<CmModal>

  @ViewChild('updateModal', { read: ElementRef })
  public updateModal: ElementRef<CmModal>

  public whitelists: ClusterIpWhitelistDto[]

  public ips: string = ''
  public description: string = ''

  public whitelistCreateHandler: () => void
  public whitelistColumns = [
    { name: 'IP', width: '1fr', ellipsis: 'right' },
    { name: 'Description', width: 'minmax(150px, 1fr)', ellipsis: 'right' },
    {
      name: '',
      width: '20px',
      overrideCSS: {
        justifyContent: 'end',
      },
    },
  ]
  public whitelistEntities = []
  public updateButtonDirty = false
  public whitelistLoading = false
  public validInput = false
  public ipValidation = {
    type: 'custom',
    validator: (values) => {
      const ips: string[] = values.split(',')

      const isValidShape =
        ips.length > 0 &&
        ips.reduce((prev, curr) => {
          return (
            prev &&
            /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(\/\d{1,2})?$/u.test(curr)
          )
        }, true)
      // eslint-disable-next-line no-invalid-this
      this.validInput = isValidShape
      if (isValidShape) {
        return { isValid: true }
      } else {
        return {
          isValid: false,
          type: 'incomplete',
          message: 'Please only enter valid IP(s)',
        }
      }
    },
  }

  constructor(
    private apiService: ApiService,
    public vvs: ViewVisibilitiesService,
    private ngZone: NgZone,
    private mixpanelService: MixpanelService,
    private notificationService: NotificationService,
  ) {}

  public ngOnInit() {
    this.whitelistCreateHandler = () => this.openCreateWhitelistModal()
    if (this.cluster.ipWhitelist) {
      this.updateList()
    }
  }

  private async updateCluster() {
    this.cluster = await this.apiService
      .clusterDetails(this.cluster.ownerId, this.cluster.uuid)
      .pipe(first())
      .toPromise()
  }

  private updateList() {
    this.whitelistEntities = this.cluster.ipWhitelist.map((entry, index) => {
      return {
        data: [
          {
            type: 'text',
            content: entry.ip,
          },
          { type: 'text', content: entry.description },
          {
            type: 'contextMenu',
            options: [
              {
                options: [
                  {
                    label: 'Update',
                    handler: () => {
                      this.openUpdateWhitelistModal(entry, index)
                    },
                    isDisabled: this.vvs.visibilities.ipWhitelist.update
                      .disabled,
                  },
                ],
              },
              {
                options: [
                  {
                    label: 'Delete',
                    isDangerous: true,
                    handler: () => {
                      this.openDeleteWhitelistModal(entry, index)
                    },
                    isDisabled: this.vvs.visibilities.ipWhitelist.delete
                      .disabled,
                  },
                ],
              },
            ],
          },
        ],
      }
    })
  }

  public openCreateWhitelistModal() {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_IP_WHITELIST_CREATE_MODAL_OPEN,
    )

    this.ips = ''
    this.description = ''

    this.ngZone.run(() => {
      this.createModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.whitelistLoading = true
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_IP_WHITELIST_CREATE_MODAL_CONFIRM,
          )
          if (!this.cluster.ipWhitelist) {
            this.cluster.ipWhitelist = []
          }
          this.cluster.ipWhitelist.push({
            ip: result.formData.whitelistIp as string,
            description: result.formData.whitelistDescription as string,
          })
          await this.apiService
            .updateClusterWhitelist(
              this.cluster.ownerId,
              this.cluster.uuid,
              this.cluster.ipWhitelist,
            )
            .pipe(first())
            .toPromise()

          await this.updateCluster()
          await this.updateList()
          this.whitelistLoading = false
        } else {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_IP_WHITELIST_CREATE_MODAL_CANCEL,
          )
        }
      })
    })
  }

  public openUpdateWhitelistModal(
    whitelist: ClusterIpWhitelistDto,
    index: number,
  ) {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_IP_WHITELIST_UPDATE_MODAL_OPEN,
    )
    this.updateButtonDirty = false
    this.ips = whitelist.ip
    this.description = whitelist.description

    this.ngZone.run(() => {
      this.updateModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.whitelistLoading = true
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_IP_WHITELIST_UPDATE_MODAL_CONFIRM,
          )
          this.cluster.ipWhitelist[index].ip = result.formData
            .whitelistIp as string
          this.cluster.ipWhitelist[index].description = result.formData
            .whitelistDescription as string

          await this.apiService
            .updateClusterWhitelist(
              this.cluster.ownerId,
              this.cluster.uuid,
              this.cluster.ipWhitelist,
            )
            .pipe(first())
            .toPromise()

          await this.updateCluster()
          await this.updateList()
          this.whitelistLoading = false
          this.notificationService.enqueueNotification({
            headline: 'IP(s) updated',
            appearance: 'success',
          })
        } else {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_IP_WHITELIST_UPDATE_MODAL_CANCEL,
          )
        }
      })
    })
  }

  public openDeleteWhitelistModal(
    _whitelist: ClusterIpWhitelistDto,
    index: number,
  ) {
    this.mixpanelService.track(
      FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_OPEN,
    )

    this.ips = this.cluster.ipWhitelist[index].ip
    this.description = this.cluster.ipWhitelist[index].description

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_CONFIRM,
          )

          this.cluster.ipWhitelist.splice(index, 1)
          await this.apiService
            .updateClusterWhitelist(
              this.cluster.ownerId,
              this.cluster.uuid,
              this.cluster.ipWhitelist,
            )
            .pipe(first())
            .toPromise()

          await this.updateCluster()
          await this.updateList()
        } else {
          this.mixpanelService.track(
            FrontendAnalyticEvents.CLUSTER_OVERVIEW_ALERTS_DELETE_MODAL_CANCEL,
          )
        }
      })
    })
  }
}
