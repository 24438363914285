import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  CommonClusterDto,
  OrganizationDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { ZeebeClientDto } from '../../../../../../commons/ZeebeClient.dto'
import { environment } from '../../../../environments/environment'
import { ApiService } from '../../../services/api.service'
import { ClusterService } from '../../../services/cluster.service'
import {
  Component as MixpanelComponent,
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { NotificationService } from '../../../services/notification.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { getTasklistUrl } from '../../taskist-operate-redirect/tasklist-operate-redirect.component'

@Component({
  selector: 'cluster-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClusterClientsComponent implements OnInit {
  public MIXPANEL_COMPONENT = MixpanelComponent.clusterClients
  @Input() public cluster: ClusterDto

  public clients: ZeebeClientDto[]
  public connectionInfo: string
  public connectionInfoJson: string
  public connectionInfoKubernetesSecret: string
  public connectionStrings: Map<string, any> = new Map<string, any>()
  public showCreate: boolean = false
  public createdClient:
    | {
        name: string
        cluster_url: string
        client_id: string
        client_secret: string
      }
    | undefined
  public clientToBeDeleted: ZeebeClientDto
  public zeebePermissionChecked = false
  public tasklistPermissionChecked = false
  public zeebeUrl = environment.zeebeUrl
  public audience = environment.camundaCloudAudience
  public showClientNameHint = false
  public clientUpdate = false
  public productionStage = environment.production

  public clientDetails: ZeebeClientDto

  @ViewChild('createModal', { static: true, read: ElementRef })
  public createModal: ElementRef<CmModal>

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('clientDetailsModal', { read: ElementRef })
  public clientDetailsModal: ElementRef<CmModal>

  @ViewChild('connectionInfoModal', { read: ElementRef })
  public connectionInfoModal: ElementRef<CmModal>

  @ViewChild('clientSecretModal', { read: ElementRef })
  public clientSecretModal: ElementRef<CmModal>

  // @ViewChild('clientList', { static: true, read: ElementRef })
  // public clientList: ElementRef<HTMLCmEntityListElement>

  public clientListColumns = [
    {
      name: '',
      width: '20px',
    },
    {
      name: 'Name',
      width: '2fr',
      ellipsis: 'right',
    },
    {
      name: 'Client Id',
      width: '2fr',
      ellipsis: 'right',
      overrideCSS: {
        gridAutoColumns: 'minmax(0px, max-content)',
      },
    },
    {
      name: 'Scopes',
      width: '1fr',
      ellipsis: 'right',
    },
    {
      name: 'Connection Information',
      width: 'minmax(150px, 1fr)',
      ellipsis: 'right',
    },
    {
      name: '',
      width: 'minmax(32px, 1fr)',
      overrideCSS: {
        justifyContent: 'end',
      },
    },
  ]
  public clientListCreateHandler: () => void
  public clientListEntities = []
  clientListLoading: boolean = true

  public clientNameValidation
  public clientName: string = ''
  private currentOrg: OrganizationDto

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private mixpanel: MixpanelService,
    private ngZone: NgZone,
    private clusterService: ClusterService,
    public vvs: ViewVisibilitiesService,
  ) {
    this.clientNameValidation = {
      type: 'custom',
      validator: (value) => {
        const isValidShape = /^[a-zA-Z0-9_-]+$/u.test(value)

        if (isValidShape) {
          const isUnique = !this.clients
            .map((client) => client.name)
            .includes(value)

          if (isUnique) {
            return { isValid: true }
          } else {
            return {
              isValid: false,
              type: 'invalid',
              message:
                'This name is already in use, please choose a different one.',
            }
          }
        } else {
          return {
            isValid: false,
            type: 'incomplete',
            message: 'Please only use letters, dashes, underscores, or digits.',
          }
        }
      },
    }
  }

  public checkZeebeUrl() {
    if (!this.cluster.status?.zeebeUrl) {
      setTimeout(() => {
        this.clusterService
          .getCluster(this.currentOrg.uuid, this.cluster.uuid)
          .subscribe((cluster) => {
            this.cluster = cluster
            this.checkZeebeUrl()
          })
      }, 1000)
    } else {
      this.showCreate = !this.vvs.visibilities.clients.create.disabled
    }
  }

  public ngOnInit() {
    this.clients = []
    this.currentOrg = this.route.snapshot.data.org

    this.checkZeebeUrl()

    this.clientListCreateHandler = () => this.openCreateClientModal()

    this.loadClients()
  }

  public openDeleteModal(clientId) {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_CLIENTS_DELETE_DIALOG)
    this.clientToBeDeleted = this.clients.find(
      (client) => client.clientId === clientId,
    )!

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.clientListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_CLIENTS_DELETE_DIALOG_CONFIRMED,
          )
          this.apiService
            .deleteZeebeClient(
              this.currentOrg.uuid,
              this.cluster.uuid,
              this.clientToBeDeleted.clientId,
            )
            .subscribe(
              (_) => {
                this.notificationService.enqueueNotification({
                  headline: 'Client deleted',
                  appearance: 'success',
                  showCreationTime: false,
                })
                this.loadClients()
              },
              (_error) => {
                this.notificationService.enqueueNotification({
                  headline: 'Client could not be deleted',
                  appearance: 'error',
                  showCreationTime: false,
                })
                this.clientListLoading = false
              },
            )
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_CLIENTS_DELETE_DIALOG_CANCELED,
          )
        }
      })
    })
  }

  public openCreateClientModal() {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_CLIENTS_CREATE_DIALOG)
    this.clientUpdate = false
    this.zeebePermissionChecked = true
    this.tasklistPermissionChecked = false

    this.ngZone.run(() => {
      this.createModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.clientListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_CLIENTS_CREATE_DIALOG_CONFIRMED,
          )

          const newClientName = result.formData.clientName as string
          let permissions: string[] = []

          if (!this.cluster.generation.versions.tasklist) {
            permissions.push('Zeebe')
          } else {
            if (this.zeebePermissionChecked) {
              permissions.push('Zeebe')
            }

            if (this.tasklistPermissionChecked) {
              permissions.push('Tasklist')
            }
          }
          this.apiService
            .createZeebeClient(
              this.currentOrg.uuid,
              this.cluster.uuid,
              newClientName,
              permissions,
            )
            .subscribe(
              (createdClient) => {
                this.createdClient = {
                  name: newClientName,
                  cluster_url: `${this.getZeebeUrl(this.cluster)}:443`,
                  client_id: createdClient.clientId,
                  client_secret: createdClient.clientSecret,
                }
                this.loadClients()

                this.ngZone.run(() => {
                  this.clientSecretModal.nativeElement
                    .open()
                    .then((result2) => {
                      if (result2.result === 'confirm') {
                        this.downloadEnv(this.createdClient!)
                      }
                    })
                })
              },
              (_error) => {
                this.notificationService.enqueueNotification({
                  headline: 'Client could not be created',
                  appearance: 'error',
                })
              },
            )
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_CLIENTS_CREATE_DIALOG_CANCELED,
          )
        }

        this.zeebePermissionChecked = false
        this.tasklistPermissionChecked = false
      })
    })
  }

  public openUpdateClientModal(event$: MouseEvent, client: ZeebeClientDto) {
    if (
      event$ &&
      !(event$.target as any).classList.contains('client-list-item-click')
    ) {
      return
    }

    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_CLIENTS_UPDATE_DIALOG)
    this.clientUpdate = true
    this.clientName = client.name
    this.zeebePermissionChecked = client.permissions.includes('Zeebe')
    this.tasklistPermissionChecked = client.permissions.includes('Tasklist')

    this.ngZone.run(() => {
      this.createModal.nativeElement
        .open({ preventFormReset: true })
        .then((result) => {
          if (result.result === 'confirm') {
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_CLIENTS_UPDATE_DIALOG_CONFIRMED,
            )

            this.clientUpdate = false

            this.apiService
              .updateZeebeClient(
                client.uuid,
                this.currentOrg.uuid,
                this.cluster.uuid,
                result.formData.clientName as string,
              )
              .subscribe((_updatedClient) => {
                this.notificationService.enqueueNotification({
                  headline: 'Client Credential updated',
                })

                this.apiService
                  .listZeebeClients(this.currentOrg.uuid, this.cluster.uuid)
                  .subscribe((clients) => {
                    this.clients = clients
                    this.updateClients()
                  })
              })
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_CLIENTS_UPDATE_DIALOG_CANCELED,
            )
          }

          this.zeebePermissionChecked = false
          this.tasklistPermissionChecked = false
        })
    })
  }

  public openDetailsModal(client) {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_CLIENTS_DETAILS_DIALOG)

    this.clientDetails = client

    this.ngZone.run(() => {
      this.clientDetailsModal.nativeElement.open().then((_result) => {
        this.mixpanel.track(
          FrontendAnalyticEvents.CLUSTER_CLIENTS_DETAILS_DIALOG_CLOSE,
        )
      })
    })
  }

  public openConnectionInfoModal(client) {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_CLIENTS_CONNECTIONINFO_DIALOG,
    )

    this.clientDetails = client

    this.ngZone.run(() => {
      this.connectionInfoModal.nativeElement.open().then((_result) => {
        this.mixpanel.track(
          FrontendAnalyticEvents.CLUSTER_CLIENTS_CONNECTIONINFO_DIALOG_CLOSE,
        )
      })
    })
  }

  public checkPermission(permission: string, isChecked: boolean) {
    if (permission === 'Zeebe') {
      this.zeebePermissionChecked = isChecked
    }

    if (permission === 'Tasklist') {
      this.tasklistPermissionChecked = isChecked
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public calculateLastUsed(date: Date) {
    if (!date) {
      return '-'
    }
    let then = new Date(date)
    let now = new Date()
    const day = 1000 * 60 * 60 * 24
    const week = day * 7
    const month = week * 30

    if (now.getDate() - then.getDate() > day) {
      if (now.getDate() - then.getDate() > week) {
        if (now.getDate() - then.getDate() > month) {
          return 'Not used within last month'
        }
        return 'Last used within last month'
      }
      return 'Last used within last week'
    }
    return 'Last used within last day'
  }

  // eslint-disable-next-line class-methods-use-this
  public getTasklistBackendApiUrl(cluster: CommonClusterDto) {
    return `${getTasklistUrl(cluster)}/graphql`
  }

  private downloadEnv(client: {
    name: string
    client_id: string
    client_secret: string
  }) {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_CLIENTS_DOWNLOAD_ENV)
    const a = document.createElement('a')
    a.download = `CamundaCloudMgmtAPI-Client-${client.name}.txt`
    let content = `export ZEEBE_ADDRESS='${this.getZeebeUrl(this.cluster)}:443'
export ZEEBE_CLIENT_ID='${client.client_id}'
export ZEEBE_CLIENT_SECRET='${client.client_secret}'
export ZEEBE_AUTHORIZATION_SERVER_URL='https://login.${
      this.audience
    }/oauth/token'`
    a.href = window.URL.createObjectURL(
      new Blob([content], { type: 'text/plain' }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }

  // eslint-disable-next-line class-methods-use-this
  public getZeebeUrl(cluster: ClusterDto) {
    // eslint-disable-next-line require-unicode-regexp
    return cluster.status.zeebeUrl?.replace(/^grpcs:\/\//, '')
  }

  // eslint-disable-next-line class-methods-use-this
  public getZeebeUrlWithPort(cluster: ClusterDto) {
    // eslint-disable-next-line require-unicode-regexp
    return `${this.getZeebeUrl(cluster)}:443`
  }

  private loadClients() {
    this.apiService
      .listZeebeClients(this.currentOrg.uuid, this.cluster.uuid)
      .subscribe((clients) => {
        this.clients = clients
        this.updateClients()
      })
  }

  private updateClients() {
    this.clientListEntities = this.clients.map((client) => {
      return {
        data: [
          { type: 'image', src: this.CLIENT_ICON },
          { type: 'text', content: client.name },
          { type: 'text', content: client.clientId, showCopyButton: true },
          { type: 'text', content: client.permissions.join(', ') },
          {
            type: 'button',
            label: 'View',
            onPress: () => this.openConnectionInfoModal(client),
          },
          this.getContextMenu(client),
        ],
      }
    })

    this.clientListLoading = false
  }

  private getContextMenu(client: ZeebeClientDto) {
    const contextMenu: { type: 'contextMenu'; options: any[] } = {
      type: 'contextMenu',
      options: [
        {
          options: [
            {
              label: 'Details',
              handler: (_event) => {
                this.openDetailsModal(client)
              },
            },
          ],
        },
      ],
    }

    if (this.vvs.visibilities.clients.update.visible) {
      contextMenu.options[0].options.push({
        label: 'Update',
        handler: () => this.openUpdateClientModal(null, client),
        isDisabled: this.vvs.visibilities.clients.update.disabled,
      })
    }

    if (this.vvs.visibilities.clients.delete.visible) {
      contextMenu.options.push({
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            isDisabled: this.vvs.visibilities.clients.delete.disabled,
            handler: (_event) => {
              this.openDeleteModal(client.clientId)
            },
          },
        ],
      })
    }

    return contextMenu
  }

  private CLIENT_ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="21" height="20" viewBox="0 0 21 20" fill="#62626E">
      <path fill-rule="evenodd" d="M7.18485971,8.99679487 L11.9366173,13.7485524 L10.2652597,15.41991 C9.17470745,16.5104623 7.59440029,16.7602773 6.32896098,16.1884199 L6.32896098,16.1884199 L2.83167264,19.6857082 L1.24774108,18.1017766 L4.74502942,14.6044883 C4.16894342,13.3432033 4.42294988,11.7587047 5.51350216,10.6681524 L5.51350216,10.6681524 L7.18485971,8.99679487 Z M18.7687913,0.398898178 C19.2283979,-0.0607084992 19.9568601,-0.0843513636 20.3963977,0.355186212 C20.8359353,0.794723787 20.8122924,1.52318594 20.3526857,1.98279262 L20.3526857,1.98279262 L17.0100098,5.32546854 L18.5939414,6.9094001 L13.5799079,11.9234336 L8.82815032,7.171676 L13.8421838,2.15764253 L15.4261154,3.7415741 Z"/>
  </svg>
  `),
    ),
  )}`
}
