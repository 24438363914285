<div class="page">
  <div class="header">
    <svg
      width="14"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M8 0v5.5a.5.5 0 0 0 .41.492L8.5 6H14v11a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7Zm1.5.5 4 4h-4v-4Z"
      />
    </svg>
    <div class="name">
      <div class="label">{{ this.formName }}</div>
      <cm-dropdown [options]="dropdownOptions" [trigger]="dropdownTrigger">
      </cm-dropdown>
    </div>
    <div class="buttons">
      <cm-button
        (cmPress)="saveForm({ confirmation: true })"
        label="Save"
        appearance="primary"
        [disabled]="vvs.visibilities.modeler.save.disabled"
      ></cm-button>
    </div>
  </div>
  <div class="modeler">
    <div id="form" style="width: 100%; height: 100%"></div>
  </div>
</div>

<cm-modal
  #deleteModal
  headline="Delete Form"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete
    <cm-text appearance="emphasis">{{ formName }}</cm-text>
    ?
  </cm-text>
</cm-modal>

<cm-modal
  #renameModal
  headline="Rename Form"
  cancelLabel="Cancel"
  confirmLabel="Rename"
>
  <cm-form>
    <cm-textfield
      label="Current Name"
      disabled
      [value]="formName"
    ></cm-textfield>
    <cm-textfield
      label="New Name"
      maxlength="50"
      formName="newName"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>
  </cm-form>
</cm-modal>
