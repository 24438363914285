import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { Observable } from 'rxjs'
import { ConnectorDto } from '../../../../commons/Connector.dto'
import { ApiService } from '../services/api.service'

@Injectable({
  providedIn: 'root',
})
export class ConnectorsResolverService implements Resolve<ConnectorDto[]> {
  constructor(private apiService: ApiService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<ConnectorDto[]> {
    return this.apiService.listConnectors(route.paramMap.get('orgUuid'))
  }
}
