import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { GenerationDto } from '../../../../commons/Generation.dto'
import { ApiService } from '../services/api.service'
import { OrganizationDto, SalesPlanDto } from '@camunda-cloud/cloud-node-libs'
import { flatten } from 'flat'
export interface GenerationDetailData {
  currentGeneration: GenerationDto & { clusterCount?: number }
  otherGenerations: Array<GenerationDto & { clusterCount?: number }>
  selectedUpgradableGenerations: string[]
  currentUpgradableGenerations: Array<
    GenerationDto & { clusterCount?: number; diff: {} }
  >
  clustersForCurrentGeneration: {
    upgradableClusters: ClusterDto[]
    clusters: ClusterDto[]
  }
  allOrganizations: OrganizationDto[]
  allSalesPlans: SalesPlanDto[]
}

@Injectable({
  providedIn: 'root',
})
export class GenerationResolverService
  implements Resolve<GenerationDetailData> {
  constructor(private apiService: ApiService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<GenerationDetailData> {
    const generationId = route.paramMap.get('generationId')
    return forkJoin([
      this.apiService.getClusterByGeneration(generationId),
      this.apiService.listGenerations(),
      this.apiService.getAllOrganizations(),
      this.apiService.getSalesPlan(),
    ]).pipe(
      map(
        ([
          clustersForCurrentGeneration,
          generations,
          allOrganizations,
          allSalesPlans,
        ]) => {
          const currentGeneration = generations.find(
            (generation) => generation.uuid === generationId,
          ) as GenerationDto
          const otherGenerations = generations.filter(
            (generation) => generation.uuid !== generationId,
          )
          const selectedUpgradableGenerations = currentGeneration.upgradeableFrom.map(
            (generation) => generation.uuid,
          )

          const currentGenFlatVersions = flatten(currentGeneration.versions)
          const currentUpgradableGenerations = otherGenerations
            .filter(
              (generation) =>
                selectedUpgradableGenerations.indexOf(generation.uuid) !== -1 &&
                (generation as any).clusterCount > 0,
            )
            .map((generation) => {
              const flatVersions = flatten(generation.versions)
              const differentEntryKeys = Object.keys(
                currentGenFlatVersions,
              ).filter((key) => {
                return currentGenFlatVersions[key] !== flatVersions[key]
              })
              const diff = {}
              differentEntryKeys.forEach((key) => {
                diff[
                  key
                ] = `${currentGenFlatVersions[key]} => ${flatVersions[key]}`
              })
              return { ...generation, ...{ diff } }
            })
          return {
            currentGeneration,
            otherGenerations,
            selectedUpgradableGenerations,
            currentUpgradableGenerations,
            clustersForCurrentGeneration,
            allOrganizations,
            allSalesPlans,
          }
        },
      ),
    )
  }
}
