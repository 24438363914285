import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'

@Component({
  templateUrl: './error.page.html',
})
export class ErrorPage {
  public title: string
  public message: string

  constructor(private route: ActivatedRoute, private auth: AuthService) {
    this.auth.error$.subscribe((error) => {
      this.setTitle(`Login Error`)
      this.setMessage(error.message)
    })
    this.setTitle(
      this.route.snapshot.paramMap.get('title')
        ? this.route.snapshot.paramMap.get('title')
        : this.route.snapshot.data.title,
    )
    this.setMessage(
      this.route.snapshot.paramMap.get('message')
        ? this.route.snapshot.paramMap.get('message')
        : this.route.snapshot.data.message,
    )
  }

  private setTitle(title: string) {
    if (!this.title && title) {
      this.title = title
    }
  }

  private setMessage(message: string) {
    if (!this.message && message) {
      this.message = message
    }
  }
}
