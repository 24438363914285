import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core'

export interface BpmnViewerOptions {
  fitViewPort?: boolean
  height?: string
}

@Component({
  selector: 'bpmn-viewer',
  templateUrl: './bpmn-viewer.component.html',
  styleUrls: ['./bpmn-viewer.component.scss'],
})
export class BpmnViewerComponent implements OnChanges, OnDestroy {
  @Input()
  bpmnXml: string

  @Input()
  options: BpmnViewerOptions

  bpmnViewerId: string
  private bpmnViewer: any

  constructor() {
    this.bpmnViewerId = `bpmnviewerid${Math.floor(Math.random() * 1000)}`
  }

  async ngOnChanges(_changes: SimpleChanges) {
    if (this.bpmnXml && this.bpmnViewerId) {
      await this.initBpmnViewer()
      await this.updateBpmnXml(this.bpmnXml)
    }
  }

  ngOnDestroy() {
    if (this.bpmnViewer) {
      this.bpmnViewer.detach()
    }
  }

  private async updateBpmnXml(xml: string) {
    await this.bpmnViewer.importXML(xml)
    if (this.options?.fitViewPort === true) {
      const canvas = this.bpmnViewer.get('canvas')
      canvas.zoom('fit-viewport')
    }
  }

  private async initBpmnViewer() {
    if (this.bpmnViewer) {
      this.bpmnViewer.detach()
    }
    this.bpmnViewerId = `bpmnviewerid${Math.floor(Math.random() * 1000)}`
    const BpmnJS = (await import('bpmn-js')).default
    this.bpmnViewer = new BpmnJS({
      container: `#${this.bpmnViewerId}`,
      height: this.options?.height ?? '300px',
    })
  }
}
