import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'

@Component({
  selector: 'external-page',
  templateUrl: './external-page.component.html',
  styleUrls: ['./external-page.component.scss'],
})
export class ExternalViewPage {
  public orgIds: string[]
  constructor(private route: ActivatedRoute) {}

  public ngOnInit() {
    const orgs = this.route.snapshot.data.orgs as OrganizationDto[]
    this.orgIds = orgs.map((org) => org.uuid)
  }
}
