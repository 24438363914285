<div class="list-header">
  <h2 class="list-title">Cluster Metrics</h2>
</div>
<div class="filters" *ngIf="!filters[0].disabled">
  <cm-text>Time Range</cm-text>
  <div class="filterSeparator"></div>
  <cm-filter
    (cmFilterSelected)="filterSelected($event)"
    [filters]="filters"
  ></cm-filter>
</div>
<div class="charts" *ngIf="cluster && !filters[0].disabled">
  <ng-container *ngFor="let type of chartTypes">
    <ng-container *ngIf="type === 'capacity-zeebe'">
      <cm-card>
        <h2 slot="header">Zeebe Disk Usage</h2>
        <line-chart
          [clusterId]="cluster.uuid"
          [orgId]="cluster.ownerId"
          [timeframe]="metricsTimeFrame"
          [type]="type"
          [soldLimit]="clusterDetails.cluster.planType.zeebeStorageLimitGiB"
        ></line-chart>
      </cm-card>
    </ng-container>

    <ng-container *ngIf="type === 'capacity-elastic'">
      <cm-card>
        <h2 slot="header">Elasticsearch Disk Usage</h2>
        <line-chart
          [clusterId]="cluster.uuid"
          [orgId]="cluster.ownerId"
          [timeframe]="metricsTimeFrame"
          [type]="type"
          [soldLimit]="clusterDetails.cluster.planType.elasticStorageLimitGiB"
        ></line-chart>
      </cm-card>
    </ng-container>
  </ng-container>
</div>

<cm-text *ngIf="filters[0].disabled" style="margin-top: 10px; display: block"
  >No Metrics data available yet. Please come back after the Cluster is at least
  an hour old.</cm-text
>
