<h1>
  Cluster Plan Types
  <cm-button
    *ngIf="vss.visibilities.admin.clusterplantypes.create.visible"
    label="Add Cluster Plan Type"
    appearance="primary"
    (cmPress)="openCreateOrUpdateClusterPlanTypeDialog()"
  ></cm-button>
</h1>
<div class="cards">
  <cm-card *ngFor="let clusterPlanType of clusterPlanTypes">
    <h3 slot="header">
      <span class="tag internal" *ngIf="clusterPlanType.internal"></span>
      <span class="tag external" *ngIf="!clusterPlanType.internal"></span>
      {{ clusterPlanType.name }}
      <div class="buttons">
        <cm-button
          *ngIf="vss.visibilities.admin.clusterplantypes.update.visible"
          label="Edit"
          appearance="primary"
          size="small"
          (cmPress)="openCreateOrUpdateClusterPlanTypeDialog(clusterPlanType)"
        ></cm-button>

        <cm-button
          *ngIf="vss.visibilities.admin.clusterplantypes.delete.visible"
          label="Delete"
          appearance="danger"
          size="small"
          (cmPress)="deleteClusterPlanType(clusterPlanType.uuid)"
          [disabled]="clusterPlanType.clusterCount > 0"
        ></cm-button>

        <cm-button
          *ngIf="vss.visibilities.admin.clusterplantypes.forceDelete.visible"
          label="FORCE Delete"
          appearance="danger"
          size="small"
          (cmPress)="forceDeleteClusterPlanType(clusterPlanType.uuid)"
        ></cm-button>
      </div>
    </h3>

    <cm-datalist>
      <cm-datalist-item label="UUID">
        {{ clusterPlanType.uuid }}
      </cm-datalist-item>
      <cm-datalist-item label="Created at">
        {{ clusterPlanType.created | date: 'MMMM d, y' }} at
        {{ clusterPlanType.created | date: 'hh:mm' }}
      </cm-datalist-item>
      <cm-datalist-item
        label="Created by"
        *ngIf="clusterPlanType.createdByName"
      >
        {{ clusterPlanType.createdByName }}
      </cm-datalist-item>
      <cm-datalist-item
        label="Created by"
        *ngIf="!clusterPlanType.createdByName"
      >
        {{ clusterPlanType.createdBy }}
      </cm-datalist-item>
      <cm-datalist-item label="Region">
        {{ clusterPlanType.k8sContext.name }}
      </cm-datalist-item>
      <cm-datalist-item
        label="Active ClusterPlan"
        *ngIf="clusterPlanType.activePlan"
      >
        <cm-button
          appearance="link"
          label="{{ clusterPlanType.activePlan.name }}"
          (cmPress)="routeToClusterPlan(clusterPlanType.activePlan.uuid)"
        ></cm-button>
      </cm-datalist-item>
      <cm-datalist-item label="Clustercount">
        {{ clusterPlanType.clusterCount }}
      </cm-datalist-item>
    </cm-datalist>
  </cm-card>
</div>

<cm-modal
  #createOrUpdateClusterPlanTypeModal
  headline="{{
    clusterPlanTypeInModal
      ? 'Modify Cluster Plan Type'
      : 'Add Cluster Plan Type'
  }}"
  cancelLabel="Cancel"
  confirmLabel="{{ clusterPlanTypeInModal ? 'Save' : 'Add' }}"
  position="top"
>
  <cm-form>
    <cm-textfield
      label="Type Name"
      [disabled]="clusterPlanTypeInModal"
      required
      [value]="clusterPlanTypeInModal?.name"
      formName="name"
    ></cm-textfield>
    <cm-checkbox
      label="Internal"
      [checked]="clusterPlanTypeInModal?.internal"
      formName="internal"
    ></cm-checkbox>
    <cm-select
      label="Active Plan"
      [options]="planOptions"
      [selectedOptions]="
        clusterPlanTypeInModal ? [clusterPlanTypeInModal.activePlan.uuid] : []
      "
      required
      formName="activePlan"
    ></cm-select>
    <cm-select
      label="Region"
      [options]="regionOptions"
      [selectedOptions]="
        clusterPlanTypeInModal ? [clusterPlanTypeInModal.k8sContext.uuid] : []
      "
      required
      formName="region"
    ></cm-select>
    <cm-textfield
      type="multiline"
      label="Description"
      helperText="HTML"
      required
      [value]="clusterPlanTypeInModal?.description"
      formName="description"
    ></cm-textfield>
    <cm-textfield
      type="number"
      label="Zeebe Storage Limit"
      helperText="GiB"
      min="1"
      required
      [value]="clusterPlanTypeInModal?.zeebeStorageLimitGiB"
      formName="zeebeStorageLimitGiB"
    ></cm-textfield>
    <cm-textfield
      type="number"
      label="Elastic Storage Limit"
      helperText="GiB"
      min="1"
      required
      [value]="clusterPlanTypeInModal?.elasticStorageLimitGiB"
      formName="elasticStorageLimitGiB"
    ></cm-textfield>
  </cm-form>
</cm-modal>
