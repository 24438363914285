import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { FeatureService } from './feature.service'

@Injectable({
  providedIn: 'root',
})
export class OrgGuard implements CanActivate {
  constructor(private featureService: FeatureService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    _state: import('@angular/router').RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.featureService.accessToken$.pipe(
      map((accessToken) => {
        let incl = accessToken.orgs.find(
          (org) => org.id === route.paramMap.get('orgUuid'),
        )
        return incl !== undefined
      }),
    )
  }
}
