export enum AlertSubscriptionType {
  ClusterAlert = 'ClusterAlert',
  ProcessAlert = 'ProcessAlert',
  AllAlerts = 'AllAlerts',
}

export enum AlertSubscriptionReportType {
  Email = 'Email',
  Webhook = 'Webhook',
}

export interface AlertSubscriptionDto {
  uuid: string
  userIdOrWebhook: string
  type: AlertSubscriptionType
  report: AlertSubscriptionReportType
  createdBy: string
  created: Date
  updatedBy: string
  updated: Date
  lastTimeTriggered?: Date
  numberOfTimesTriggered: number
}

export interface AlertSubscriptionEntryDto {
  errorMessage?: string
  errorType?: string
  flowNodeId?: string
  jobKey?: string
  creationTime?: string
  processInstanceId?: string
  processName?: string
  processVersion?: number
  bpmnProcessId?: string
}
