import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Dict } from 'mixpanel-browser'
import { MixpanelEvent, MixpanelService } from './mixpanel.service'
import { ViewVisibilitiesService } from './view.visibilities.service'

export interface NavigationBaseParams {
  external?: boolean
  mixpanel?: {
    event: MixpanelEvent
    data?: Dict
  }
  routeParams?: any
}

export interface NavigationParams extends NavigationBaseParams {
  route: string | string[]
}

export interface NavigationInAppParams extends NavigationBaseParams {
  dest:
    | 'diagrams:list'
    | 'diagrams:new'
    | 'diagrams:template'
    | 'forms:list'
    | 'cluster:list'
    | 'connector:list'
    | 'org:management'
  context?: {
    orgId?: string
    clusterId?: string
    diagramId?: string
    fromId?: string
    templateId?: string
  }
}

export interface NavigationExternalParams extends NavigationBaseParams {
  dest: ExternalLink
}

export enum ExternalLink {
  DOCS = 'https://docs.camunda.io/',
  DOCS_WHAT_IS_CAMUNDA_CLOUD = 'https://docs.camunda.io/docs/product-manuals/concepts/what-is-camunda-cloud',
  DOCS_MODEL_YOUR_FIRST_PROCESS = 'https://docs.camunda.io/docs/guides/getting-started/model-your-first-process',
  DOCS_CLIENTS = 'https://docs.camunda.io/docs/product-manuals/clients/overview',
  DOCS_MESSAGE_CORRELATION = 'https://docs.camunda.io/docs/guides/message-correlation',
  ENTERPRISE_SUPPORT = 'https://camunda.com/services/support/',
  FORUM = 'https://forum.camunda.io',
  GITHUB_GSE = 'https://github.com/camunda-cloud/camunda-cloud-get-started',
  JIRA = 'https://jira.camunda.com/projects/SUPPORT/queues',
  PRIVACY = 'https://camunda.com/legal/privacy/',
  SLACK = 'https://zeebe-slack-invite.herokuapp.com/',
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private mixpanel: MixpanelService,
    private vvs: ViewVisibilitiesService,
  ) {}

  public navigateInApp(params: NavigationInAppParams) {
    let route: string[]
    switch (params.dest) {
      case 'diagrams:list':
        if (params.context && params.context.orgId) {
          route = ['org', params.context.orgId, 'diagrams']
        }
        break
      case 'diagrams:new':
        if (params.context && params.context.orgId) {
          route = ['org', params.context.orgId, 'modeler']
        }
        break
      case 'diagrams:template':
        if (
          params.context &&
          params.context.orgId &&
          params.context.templateId
        ) {
          route = [
            'org',
            params.context.orgId,
            'modeler',
            'templates',
            params.context.templateId,
          ]
        }
        break
      case 'forms:list':
        if (params.context && params.context.orgId) {
          route = ['org', params.context.orgId, 'forms']
        }
        break
      case 'cluster:list':
        if (params.context && params.context.orgId) {
          route = ['org', params.context.orgId, 'clusters']
        }
        break
      case 'connector:list':
        if (params.context && params.context.orgId) {
          route = ['org', params.context.orgId, 'connectors']
        }
        break
      case 'org:management':
        if (params.context && params.context.orgId) {
          route = ['org', params.context.orgId, 'management']
        }
        break
    }
    if (route) {
      this.navigate({
        route,
        mixpanel: params.mixpanel,
        routeParams: params.routeParams,
      })
    }
  }

  public navigateExternal(params: NavigationExternalParams) {
    this.navigate({
      route: params.dest,
      external: true,
      mixpanel: params.mixpanel,
    })
  }

  public navigate(params: NavigationParams) {
    if (params.mixpanel) {
      this.mixpanel.track(params.mixpanel.event, params.mixpanel.data)
    }
    if (params.external === true) {
      if (typeof params.route === 'string') {
        NavigationService.openExternalLink(params.route)
      }
    } else {
      const route =
        typeof params.route === 'string' ? [params.route] : params.route
      if (params.routeParams) {
        this.router.navigate([...route, params.routeParams])
      } else {
        this.router.navigate(route)
      }
    }
  }

  public static openExternalLink(url: string) {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = url
    a.click()
    window.URL.revokeObjectURL(a.href)
  }
}
