import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import {
  ClusterPlanTypeDto,
  ClusterPlanTypesToReserved,
  OrganizationDto,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'
import { forkJoin, Observable, iif, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { ApiService } from '../services/api.service'
import { ClusterPlanApiService } from '../services/clusterPlan.api.service'
import { HardwareParameterUtils } from './utils/hw-parameter.utils'

export interface HardwareParameter {
  cpu: number
  memory: number
  storage: number
}

export interface ExtendedClusterPlanTypeReservation {
  clusterPlanName: string
  clusterPlanUuid: string
  region: string
  currentReservations: number
  maxReservations: number
  minReservations: number
  usage: number
  partitions: number
  price: string
  description: string
  editable: boolean
  hardware: HardwareParameter
}

export interface SetReservationsData {
  reservations: ExtendedClusterPlanTypeReservation[]
  newReservationsBuffer: ClusterPlanTypesToReserved
  originalReservations: ClusterPlanTypesToReserved
}

const ALLOWED_SALES_PLAN_TYPES: string[] = [
  SalesPlanType.PAID,
  SalesPlanType.PAID_CC,
  SalesPlanType.ENTERPRISE,
]

@Injectable({
  providedIn: 'root',
})
export class ReservationsResolverService
  implements Resolve<SetReservationsData> {
  constructor(
    private apiService: ApiService,
    private clusterPlanService: ClusterPlanApiService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<SetReservationsData> {
    return this.apiService.getOrganization(route.paramMap.get('orgUuid')!).pipe(
      mergeMap((org) =>
        iif(
          () =>
            ALLOWED_SALES_PLAN_TYPES.includes(
              org!.organizationToSalesPlan!.salesPlan!.salesPlanType,
            ) && org!.permissions!.seeBilling,
          this.resolveData(org),
          of({
            reservations: [],
            newReservationsBuffer: {},
            originalReservations: {},
          }),
        ),
      ),
    )
  }

  public resolveData(org: OrganizationDto) {
    return forkJoin([
      this.clusterPlanService.getActive(org.uuid),
      this.apiService.listClusters(org.uuid),
    ]).pipe(
      map(([clusterPlanTypes, clusters]) => {
        const newReservationsBuffer = {
          ...org.organizationToSalesPlan!.clusterPlanTypesToReserved,
          ...{},
        }
        const originalReservations = {
          ...org.organizationToSalesPlan!.clusterPlanTypesToReserved,
          ...{},
        }
        Object.keys(
          org.organizationToSalesPlan!.salesPlan.clusterPlanTypesToConfig,
        ).forEach((key) => {
          const minReserved = org.organizationToSalesPlan!.salesPlan
            .clusterPlanTypesToConfig[key].minReserved
          newReservationsBuffer[key] = newReservationsBuffer[key] - minReserved
          originalReservations[key] = originalReservations[key] - minReserved
        })
        const reservations = this.updateData(org, clusters, clusterPlanTypes)
        return {
          reservations,
          newReservationsBuffer,
          originalReservations,
        } as any
      }),
    )
  }

  // eslint-disable-next-line class-methods-use-this
  private updateData(
    currentOrg: OrganizationDto,
    clusters: ClusterDto[],
    cpt: ClusterPlanTypeDto[],
  ): ExtendedClusterPlanTypeReservation[] {
    const newReservations = currentOrg.organizationToSalesPlan!
      .clusterPlanTypesToReserved
    const reservations: ExtendedClusterPlanTypeReservation[] = []

    cpt.forEach((clusterPlan) => {
      let partitions

      if (clusterPlan.activePlan.plan.zeebe) {
        if (clusterPlan.activePlan.plan.zeebe.broker) {
          if (clusterPlan.activePlan.plan.zeebe.broker.partitionsCount) {
            partitions =
              clusterPlan.activePlan.plan.zeebe.broker.partitionsCount
          } else {
            partitions = 1
          }
        } else {
          partitions = 1
        }
      } else {
        partitions = 1
      }

      const amountOfClusters = clusters!.filter(
        (cluster) => cluster.planType.uuid === clusterPlan.uuid,
      ).length

      let hasFree = false

      if (
        currentOrg!.organizationToSalesPlan!.salesPlan.clusterPlanTypesToConfig[
          clusterPlan.uuid
        ].minReserved > 0
      ) {
        reservations.push({
          clusterPlanName: `${clusterPlan.name} (Included)`,
          clusterPlanUuid: clusterPlan.uuid,
          region: clusterPlan.k8sContext.name,
          currentReservations: currentOrg!.organizationToSalesPlan!.salesPlan
            .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved,
          maxReservations: 999,
          minReservations: 0,
          usage:
            amountOfClusters >
            currentOrg!.organizationToSalesPlan!.salesPlan
              .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved
              ? currentOrg!.organizationToSalesPlan!.salesPlan
                  .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved
              : amountOfClusters,
          partitions,
          price: '',
          // '<p>Single partition Zeebe cluster without replication. Recommended for development use cases.</p><h4>Specification</h4><table width="100%"><tr><td><p>Partitions</p><p><strong>1</strong></p></td><td><p>Zeebe Storage</p><p><strong>20GB</strong></p></td></tr><tr><td><p>Fault-tolerant</p><p><strong>No</strong></p></td><td><p>Operate Storage</p><p><strong>20GB</strong></p></td></tr><tr><td><p>Flow Nodes/s</p><p><strong>30</strong></p></td></tr></table>',
          description: clusterPlan.description,
          editable: false,
          hardware: HardwareParameterUtils.getHardwareParameter(clusterPlan),
        })

        hasFree = true
      }

      reservations.push({
        clusterPlanName: hasFree
          ? `${clusterPlan.name} (Additional)`
          : clusterPlan.name,
        clusterPlanUuid: clusterPlan.uuid,
        region: clusterPlan.k8sContext.name,
        currentReservations:
          newReservations[clusterPlan.uuid] -
          currentOrg!.organizationToSalesPlan!.salesPlan
            .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved,
        maxReservations:
          currentOrg!.organizationToSalesPlan!.salesPlan
            .clusterPlanTypesToConfig[clusterPlan.uuid].maxReserved -
          currentOrg!.organizationToSalesPlan!.salesPlan
            .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved,
        minReservations:
          amountOfClusters -
            currentOrg!.organizationToSalesPlan!.salesPlan
              .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved <
          0
            ? 0
            : amountOfClusters -
              currentOrg!.organizationToSalesPlan!.salesPlan
                .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved,
        usage:
          amountOfClusters -
            currentOrg!.organizationToSalesPlan!.salesPlan
              .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved <
          0
            ? 0
            : amountOfClusters -
              currentOrg!.organizationToSalesPlan!.salesPlan
                .clusterPlanTypesToConfig[clusterPlan.uuid].minReserved,
        partitions,
        price: currentOrg!.organizationToSalesPlan!.salesPlan!
          .clusterPlanTypesToConfig[clusterPlan.uuid].price,
        // '<p>Single partition Zeebe cluster without replication. Recomended for development use cases.</p><h4>Specification</h4><table width="100%"><tr><td><p>Partitions</p><p><strong>1</strong></p></td><td><p>Zeebe Storage</p><p><strong>20GB</strong></p></td></tr><tr><td><p>Fault-tolerant</p><p><strong>No</strong></p></td><td><p>Operate Storage</p><p><strong>20GB</strong></p></td></tr><tr><td><p>Flow Nodes/s</p><p><strong>30</strong></p></td></tr></table>',
        description: clusterPlan.description,
        editable: true,
        hardware: HardwareParameterUtils.getHardwareParameter(clusterPlan),
      })
    })
    return reservations
  }
}
