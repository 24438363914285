import { AfterViewInit, Component, Input, OnChanges } from '@angular/core'
import uPlot, { AlignedData, Series } from 'uplot'
import { v4 } from 'uuid'
import {
  TimeFrame,
  ZeebeMetrics,
  ZeebeMetricType,
} from '../../../../../../../commons/ZeebeMetrics.dto'
import { ClusterMetricsService } from '../../../../services/cluster.metrics.service'

const strokeColors = [
  '#0ac247',
  '#0088cc',
  '#ff9f56',
  '#f7c85f',
  '#ca472f',
  '#6f4d7c',
]

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges, AfterViewInit {
  @Input()
  public clusterId: string

  @Input()
  public orgId: string

  @Input()
  public timeframe: TimeFrame

  @Input()
  public type: string

  @Input()
  public soldLimit: number = 0

  public id = v4()

  private element: HTMLElement | null
  private chart: uPlot

  private metrics: ZeebeMetrics | undefined

  constructor(private clusterMetricsService: ClusterMetricsService) {}

  public async ngAfterViewInit() {
    this.element = document.getElementById(this.id)

    await this.getData()
    this.renderLineChart()

    window.addEventListener('resize', (_event) => {
      if (this.chart) {
        this.chart.setSize({
          height: this.calculateHeight(),
          width: this.calculateWidth(),
        })
      }
    })
  }

  public async ngOnChanges() {
    if (this.element) {
      await this.getData()
      this.renderLineChart()
    }
  }

  public async getData() {
    switch (this.type) {
      case ZeebeMetricType.PROCESSES:
      case ZeebeMetricType.CAPACITY:
      case ZeebeMetricType.CAPACITY_ZEEBE:
      case ZeebeMetricType.CAPACITY_ELASTIC:
      case ZeebeMetricType.THROUGHPUT:
        this.metrics = await this.clusterMetricsService
          .get(this.orgId, this.clusterId, this.timeframe, this.type)
          .toPromise()
        break
      default:
        this.metrics = {}
    }
  }

  public renderLineChart() {
    if (this.chart) {
      this.chart.destroy()
    }

    if (!this.metrics) {
      return
    }

    if (!this.metrics.capacity || this.metrics.capacity.length === 0) {
      return
    }

    let data: AlignedData = [[]]
    let series: Array<Series> = [{}]

    data[0] = this.metrics.capacity[0].values.map(
      (value) => value.timestamp / 1000,
    )

    let biggestValue = 0

    // Dont incorporate timestamps into biggestValue calculation
    for (let metric of this.metrics.capacity) {
      biggestValue = Math.max(
        ...metric.values.map((value) => value.value / 1024 / 1024 / 1024),
        biggestValue,
      )
    }

    // #region Render Capacity Limit
    data.push(this.metrics.capacity[0].values.map(() => this.soldLimit))

    series.push({
      spanGaps: true,
      label: 'Capacity Limit',
      stroke: '#000',
      // eslint is being an idiot
      // eslint-disable-next-line no-implicit-coercion
      width: 1 * devicePixelRatio,
      points: {
        show: false,
      },
    })

    //#endregion

    let seriesIndex = 0
    for (const metric of this.metrics.capacity) {
      let name = `Used Space Node ${seriesIndex + 1}`
      let color = strokeColors[seriesIndex]

      data.push(metric.values.map((value) => value.value / 1024 / 1024 / 1024))

      series.push({
        spanGaps: true,
        label: name,
        stroke: color,
        // eslint is being an idiot
        // eslint-disable-next-line no-implicit-coercion
        width: 1 * devicePixelRatio,
      })

      seriesIndex++
    }

    this.chart = new uPlot(
      {
        id: `chart-${this.id}`,
        width: this.calculateWidth(),
        height: this.calculateHeight(),
        series,
        legend: {
          fill: (self, seriesIdx) => {
            return (self.series[seriesIdx].stroke as any)(self, seriesIdx)
          },
          live: false,
        },
        scales: {
          // eslint-disable-next-line id-length
          y: {
            auto: false,
            range: [0, Math.max(this.soldLimit, biggestValue)],
          },
        },
        axes: [
          {
            label: 'Time',
            labelSize: 20,
            labelFont: 'IBM Plex Sans',
            // font: 'IBM Plex Sans',
            grid: {
              show: true,
              stroke: '#DFDFDF',
              width: 1,
            },
          },
          {
            side: 3,
            labelSize: 20,
            label: 'Disk usage (GB)',
            labelFont: 'IBM Plex Sans',
            // font: 'IBM Plex Sans',
            grid: {
              show: true,
              stroke: '#DFDFDF',
              width: 1,
            },
          },
        ],

        // eslint-disable-next-line id-length
        cursor: { drag: { x: false, y: false } },
      },
      data,
      this.element,
    )
  }

  // eslint-disable-next-line class-methods-use-this
  public calculateWidth(): number {
    let fullWidth = document.documentElement.clientWidth

    if (fullWidth < 1300) {
      return document.documentElement.clientWidth - (65 * 2 + 14) - 70
    } else {
      return (document.documentElement.clientWidth - (65 * 2 + 14)) / 2 - 70
    }
  }

  public calculateHeight(): number {
    return (this.calculateWidth() / 16) * 10
  }
}
