<cm-entity-list
  [enableCreateButton]="showCreate"
  [hideCreateButton]="!vvs.visibilities.clients.create.visible"
  headline="Client Credentials"
  createButtonLabel="Create"
  [createHandler]="clientListCreateHandler"
  [columns]="clientListColumns"
  [entities]="clientListEntities"
  [loading]="clientListLoading"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList">
      <h3>No Clients configured</h3>

      <cm-text>
        Want to deploy processes, start instances, and interact with them?
        <cm-button
          appearance="link"
          (cmPress)="openCreateClientModal()"
          label="Create a new Client Credential"
          [disabled]="!showCreate"
        ></cm-button>
        to access your Cluster and check the
        <cm-link
          href="https://docs.camunda.io/docs/guides/getting-started/setup-client-connection-credentials"
          label="Client Configuration"
        ></cm-link>
        documentation for details on how to configure a Client.
      </cm-text>
    </div>
  </div>
</cm-entity-list>
<br />

<cm-text *ngIf="clients.length !== 0"
  >Client Credentials can be used to request an OAuth access token. See the
  <cm-link
    href="https://docs.camunda.io/docs/guides/getting-started/setup-client-connection-credentials"
    label="Client Configuration"
  ></cm-link>
  documentation to connect the Client with the Cluster.
</cm-text>

<cm-modal
  #createModal
  headline="{{
    clientUpdate ? 'Update Client Credential' : 'Create New Client Credential'
  }}"
  cancelLabel="Cancel"
  confirmLabel="{{ clientUpdate ? 'Update' : 'Create' }}"
  width="555"
>
  <cm-form>
    <cm-textfield
      label="Client Name"
      helperText="can contain letters, dashes, underscores and digits"
      maxlength="50"
      formName="clientName"
      required
      [(value)]="clientName"
      [validation]="clientNameValidation"
      validationStyle="live"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>

    <div style="display: grid; gap: 8px">
      <cm-text appearance="emphasis">Scopes</cm-text>
      <cm-text>
        Scopes define the access for Client Credentials. Read more about
        <cm-link
          href="http://docs.camunda.io/docs/guides/getting-started/setup-client-connection-credentials"
          label="Scopes for Client Credentials"
        ></cm-link>
        .
      </cm-text>
    </div>

    <cm-checkbox-group>
      <cm-checkbox
        label="Zeebe"
        [(checked)]="zeebePermissionChecked"
        [disabled]="clientUpdate"
        (cmInput)="checkPermission('Zeebe', $event.detail.isChecked)"
      ></cm-checkbox>

      <cm-checkbox
        label="Tasklist"
        [(checked)]="tasklistPermissionChecked"
        [disabled]="!cluster.generation.versions.tasklist || clientUpdate"
        (cmInput)="checkPermission('Tasklist', $event.detail.isChecked)"
      ></cm-checkbox>
    </cm-checkbox-group>
  </cm-form>
</cm-modal>

<cm-modal
  #clientSecretModal
  headline="Client Credentials"
  cancelLabel="Close"
  confirmLabel="Download Credentials"
  width="580"
>
  <cm-form *ngIf="createdClient">
    <cm-textfield
      label="Cluster URL"
      [value]="createdClient.cluster_url"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      label="Client Id"
      [value]="createdClient.client_id"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      label="Client Secret"
      [value]="createdClient.client_secret"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-banner>
      <cm-text appearance="emphasis">
        Make sure to save the Client Secret, because it won’t be shown again.
      </cm-text>
    </cm-banner>
  </cm-form>
</cm-modal>

<cm-modal
  #connectionInfoModal
  headline="Connection Information of {{
    clientDetails ? clientDetails.name : ''
  }}"
  confirmLabel="Close"
  width="555"
  position="top"
>
  <cm-form *ngIf="clientDetails">
    <cm-text>
      To connect to this Cluster, check the
      <cm-link
        href="https://docs.camunda.io/docs/guides/getting-started/setup-client-connection-credentials"
        label="Client Configuration"
      ></cm-link>
      documentation to learn what information is needed from below.</cm-text
    >

    <div style="display: grid; gap: 8px">
      <cm-text appearance="emphasis">Client</cm-text>
      <cm-textfield
        label="Client Name"
        [value]="clientDetails.name"
        [fieldSuffix]="{ type: 'copy' }"
        disabled
      ></cm-textfield>
    </div>

    <cm-textfield
      label="Client Id"
      [value]="clientDetails.clientId"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <div class="secret">
      <span>Client Secret</span>
      <span>only visible on Client creation</span>
    </div>

    <cm-textfield
      *ngIf="clientDetails.permissions.includes('Zeebe')"
      label="Cluster URL"
      [value]="getZeebeUrl(cluster)"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      label="Cluster Id"
      [value]="cluster.uuid"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      *ngIf="clientDetails.permissions.includes('Tasklist')"
      label="Tasklist URL"
      [value]="getTasklistBackendApiUrl(cluster)"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>

    <cm-textfield
      label="OAuth URL"
      [value]="'https://login.' + audience + '/oauth/token'"
      [fieldSuffix]="{ type: 'copy' }"
      disabled
    ></cm-textfield>
  </cm-form>
</cm-modal>

<cm-modal
  #clientDetailsModal
  headline="Client Credential Details"
  confirmLabel="Close"
  paddings="horizontal"
>
  <cm-datalist *ngIf="clientDetails">
    <cm-datalist-item label="Name">
      <cm-text>{{ clientDetails.name }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Client Id">
      <cm-text>{{ clientDetails.clientId }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Scopes">
      <cm-text>
        {{ clientDetails.permissions.join(', ') }}
      </cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Created at">
      <cm-text>{{ clientDetails.created | date: 'medium' }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Created by">
      <cm-text>{{ clientDetails.createdBy }}</cm-text>
    </cm-datalist-item>
    <cm-datalist-item label="Last used">
      <cm-text *ngIf="clientDetails.lastUsed">{{
        calculateLastUsed(clientDetails.lastUsed)
      }}</cm-text>
      <cm-text *ngIf="!clientDetails.lastUsed">-</cm-text>
    </cm-datalist-item>
  </cm-datalist>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Client"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text *ngIf="clientToBeDeleted">
    Are you sure you want to delete
    <b>"{{ clientToBeDeleted.name }}"</b>?
  </cm-text>
</cm-modal>
