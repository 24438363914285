import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { ClusterPlanTypeDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { OptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-select/cm-select'
import { BehaviorSubject } from 'rxjs'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { ApiService } from '../../../../app/services/api.service'
import { ClusterPlanApiService } from '../../../services/clusterPlan.api.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { NotificationService } from '../../../services/notification.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

type EnhancedClusterPlanTypeDto = ClusterPlanTypeDto & {
  createdByName: string
  internal: boolean
  clusterCount: number
}

@Component({
  selector: 'list-cluster-plan-types',
  templateUrl: './list-cluster-plan-types.component.html',
  styleUrls: ['./list-cluster-plan-types.component.scss'],
})
export class ListClusterPlanTypesComponent {
  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @Input()
  public clusterPlanTypes: Array<EnhancedClusterPlanTypeDto>

  @ViewChild('createOrUpdateClusterPlanTypeModal', {
    read: ElementRef,
  })
  public createOrUpdateClusterPlanTypeModal: ElementRef<CmModal>

  public generations$: BehaviorSubject<GenerationDto[]> = new BehaviorSubject(
    [],
  )
  public clusterPlanTypeInModal: EnhancedClusterPlanTypeDto | undefined

  public planOptions: Array<OptionGroup> = []
  public regionOptions: Array<OptionGroup> = []

  constructor(
    private clusterPlanApiService: ClusterPlanApiService,
    private router: Router,
    private modalService: ConfirmationModalService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    public vss: ViewVisibilitiesService,
  ) {}

  public async openCreateOrUpdateClusterPlanTypeDialog(
    clusterPlanType?: EnhancedClusterPlanTypeDto,
  ) {
    await Promise.all([
      this.clusterPlanApiService
        .getAll()
        .toPromise()
        .then((clusterPlans) => {
          let plans = [{ options: [] }]
          for (let clusterPlan of clusterPlans) {
            plans[0].options.push({
              value: clusterPlan.uuid,
              label: `${clusterPlan.name}`,
              description: `uuid: ${clusterPlan.uuid}`,
            })
          }

          this.planOptions = plans
        }),
      this.apiService
        .getK8sContextsInDb()
        .toPromise()
        .then((k8sContexts) => {
          let regions = [{ options: [] }]
          for (let region of k8sContexts) {
            regions[0].options.push({
              value: region.uuid,
              label: `${region.name}`,
              description: `uuid: ${region.uuid}`,
            })
          }

          this.regionOptions = regions
        }),
    ])

    this.clusterPlanTypeInModal = clusterPlanType

    requestAnimationFrame(() => {
      this.createOrUpdateClusterPlanTypeModal.nativeElement
        .open({ preventFormReset: Boolean(clusterPlanType) })
        .then((result) => {
          if (result.result === 'confirm') {
            const formData = result.formData
            if (this.clusterPlanTypeInModal) {
              this.clusterPlanApiService
                .updateType(
                  this.clusterPlanTypeInModal.uuid,
                  formData.internal as boolean,
                  formData.description as string,
                  formData.activePlan as string,
                  formData.region as string,
                  false,
                  formData.zeebeStorageLimitGiB as number,
                  formData.elasticStorageLimitGiB as number,
                )
                .subscribe((_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'ClusterPlanType Updated',
                    appearance: 'success',
                    showCreationTime: false,
                  })

                  this.router.navigate(['admin/cluster-plan'])
                })
            } else {
              this.clusterPlanApiService
                .createType(
                  formData.name as string,
                  formData.internal as boolean,
                  formData.description as string,
                  formData.activePlan as string,
                  formData.region as string,
                  formData.zeebeStorageLimitGiB as number,
                  formData.elasticStorageLimitGiB as number,
                )
                .subscribe((_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'ClusterPlanType Created',
                    appearance: 'success',
                    showCreationTime: false,
                  })

                  this.router.navigate(['admin/cluster-plan'])
                })
            }
          }
        })
    })
  }

  public deleteClusterPlanType(uuid: string) {
    const clusterPlanTypeToDelete = this.clusterPlanTypes.find(
      (clusterPlanType) => clusterPlanType.uuid === uuid,
    ).name

    this.modalService.openModal({
      title: 'Delete Cluster Plan Type',
      body: `Are you sure to delete Cluster Plan Type ${clusterPlanTypeToDelete}?`,
      confirmButton: {
        text: 'Delete Cluster Plan Type',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService.deleteType(uuid).subscribe((_) => {
            this.notificationService.enqueueNotification({
              headline: 'ClusterPlanType Deleted',
              appearance: 'success',
              showCreationTime: false,
            })

            this.router.navigate([`/admin/cluster-plan`])
          })
        },
      },
      cancelButton: {
        text: 'Cancel',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public forceDeleteClusterPlanType(uuid: string) {
    const clusterPlanTypeToDelete = this.clusterPlanTypes.find(
      (clusterPlanType) => clusterPlanType.uuid === uuid,
    ).name

    this.modalService.openModal({
      title: 'FORCE Delete Cluster Plan Type',
      body: `Are you sure to FORCE delete Cluster Plan Type ${clusterPlanTypeToDelete}? This will remove this Type from all SalesPlans! AND it will DELETE ALL CLUSTERS with this Cluster Plan Type`,
      confirmButton: {
        text: 'FORCE Delete Cluster Plan Type',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService.deleteTypeForce(uuid).subscribe((_) => {
            this.notificationService.enqueueNotification({
              headline: 'ClusterPlanType Deleted',
              appearance: 'success',
              showCreationTime: false,
            })
            this.router.navigate([`/admin/cluster-plan`])
          })
        },
      },
      cancelButton: {
        text: 'Cancel',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public routeToClusterPlan(clusterPlanUuid: string) {
    this.router.navigate([`/admin/cluster-plan/plans/${clusterPlanUuid}`])
  }
}
