<h1>Usage</h1>

<div class="billing-grid">
  <cm-card *ngIf="usage">
    <h2 slot="header">Usage History</h2>
    <div slot="header" style="justify-self: end">
      <cm-select
        style="min-width: 210px"
        label="Group by"
        labelAlignment="horizontal"
        disabled
        [options]="[
          { options: [{ label: 'Monthly Period', value: 'monthly' }] }
        ]"
        [selectedOptions]="['monthly']"
      ></cm-select>
    </div>

    <div class="historyTable">
      <div class="row foldableHeader">
        <div class="spacer"></div>
        <div class="header">Period</div>
        <div class="header">Process Instances</div>
        <div class="header">Task Users</div>
      </div>
      <ng-container *ngFor="let period of usage.selectedPeriodData">
        <div
          class="row foldable"
          [class.cursor]="period.processInstances > 0 && period.taskUsers > 0"
          [class.extended]="period.showCluster"
          (click)="
            period.showCluster =
              period.processInstances > 0 && period.taskUsers > 0
                ? !period.showCluster
                : false
          "
        >
          <cm-icon
            *ngIf="!period.showCluster"
            icon="right"
            [class.hidden]="
              period.processInstances === 0 && period.taskUsers === 0
            "
          ></cm-icon>
          <cm-icon
            *ngIf="period.showCluster"
            icon="down"
            [class.hidden]="
              period.processInstances === 0 && period.taskUsers === 0
            "
          ></cm-icon>
          <div>
            {{ period.start | date: 'mediumDate' }} -
            {{ usage.getEndDate(period.start) | date: 'mediumDate' }}
          </div>
          <div>{{ period.processInstances }}</div>
          <div>{{ period.taskUsers }}</div>
          <div class="folded" *ngIf="period.showCluster">
            <div class="row" *ngFor="let cluster of period.perCluster">
              <div class="spacer"></div>
              <div *ngIf="!cluster.name">{{ cluster.uuid }}</div>
              <div *ngIf="cluster.name">{{ cluster.name }}</div>
              <div>{{ cluster.processInstances }}</div>
              <div>{{ cluster.taskUsers }}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </cm-card>
</div>
