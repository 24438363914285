import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import {
  ClusterPlanDto,
  OrganizationDto,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { flatten } from 'flat'
import { forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ApiService } from '../services/api.service'
import {
  ClusterCountClusterPlanDto,
  ClusterPlanApiService,
} from '../services/clusterPlan.api.service'
import { ClusterDto } from '../../../../commons/Cluster.dto'

export interface ClusterPlanDtoWithDiff extends ClusterPlanDto {
  diff: {}
}

export interface ExtendedClusterPlanDto extends ClusterCountClusterPlanDto {
  migratableFromPlan: ClusterPlanDtoWithDiff[]
  flatPlan: any
  clusterCount: number
}

export interface ClusterPlanDetailData {
  clusterPlan: ExtendedClusterPlanDto
  otherPlans: ClusterPlanDto[]
  salesPlans: SalesPlanDto[]
  allOrganizations: OrganizationDto[]
  upgradableClusters: ClusterDto[]
}

@Injectable({
  providedIn: 'root',
})
export class ClusterPlanDetailsResolverService
  implements Resolve<ClusterPlanDetailData> {
  constructor(
    private clusterPlanService: ClusterPlanApiService,
    private apiService: ApiService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<ClusterPlanDetailData> {
    return forkJoin([
      this.clusterPlanService.get(route.paramMap.get('clusterPlanId')),
      this.clusterPlanService.getClusters(route.paramMap.get('clusterPlanId')),
      this.clusterPlanService.getUpgradableClusters(
        route.paramMap.get('clusterPlanId'),
      ),
      this.clusterPlanService.getAll(),
      this.apiService.getSalesPlan(),
      this.apiService.getAllOrganizations(),
    ]).pipe(
      map(
        ([
          clusterPlan,
          clusters,
          upgradableClusters,
          allClusterPlans,
          salesPlans,
          allOrganizations,
        ]) => {
          const otherClusterPlans = allClusterPlans.filter(
            (plan) => plan.uuid !== route.paramMap.get('clusterPlanId'),
          )
          const flatPlan = flatten(clusterPlan.plan)
          Object.keys(flatPlan).forEach((key) => {
            if (
              flatPlan[key] instanceof Object &&
              Object.keys(flatPlan[key].length === 0)
            ) {
              flatPlan[key] = 'unset'
            }
          })
          const extendedClusterPlan: ExtendedClusterPlanDto = {
            ...clusterPlan,
            ...{
              flatPlan,
              clusterCount: clusters.length,
              migratableFromPlan: [],
            },
          }
          extendedClusterPlan.migratableFromPlan = extendedClusterPlan.migratableFrom.map(
            (uuid) => {
              const correspondingClusterPlan = otherClusterPlans.find(
                (plan) => plan.uuid === uuid,
              )
              const flatPlan = flatten(correspondingClusterPlan.plan)
              Object.keys(flatPlan).forEach((key) => {
                if (
                  flatPlan[key] instanceof Object &&
                  Object.keys(flatPlan[key].length === 0)
                ) {
                  flatPlan[key] = 'unset'
                }
              })
              const differentEntryKeys = Object.keys(
                extendedClusterPlan.flatPlan,
              ).filter((key) => {
                return extendedClusterPlan.flatPlan[key] !== flatPlan[key]
              })
              const diff = {}
              differentEntryKeys.forEach((key) => {
                diff[
                  key
                ] = `${flatPlan[key]} => ${extendedClusterPlan.flatPlan[key]}`
              })
              return { ...correspondingClusterPlan, ...{ diff } }
            },
          )
          return {
            clusterPlan: extendedClusterPlan,
            otherPlans: otherClusterPlans,
            salesPlans,
            allOrganizations,
            upgradableClusters,
          }
        },
      ),
    )
  }
}
