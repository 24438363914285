/* eslint-disable no-invalid-this */
/* eslint-disable no-await-in-loop */

import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import {
  ClusterPlanTypeDto,
  OrganizationDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import lintModule from 'bpmn-js-bpmnlint'
import { Linter } from 'bpmnlint'
import { first } from 'rxjs/operators'
import * as semver from 'semver'
import { v4 } from 'uuid'
import { BpmnModelDto } from '../../../../../commons/BpmnModel.dto'
import { ChannelDto } from '../../../../../commons/Channel.dto'
import { ClusterDto } from '../../../../../commons/Cluster.dto'
import { ApiService } from '../../services/api.service'
import { ClusterService } from '../../services/cluster.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { NavbarService } from '../../services/navbar.service'
import { NotificationService } from '../../services/notification.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
import { CreateClusterDialogComponent } from '../dialogs/create-cluster-dialog/create-cluster-dialog.component'
import { TemplateById } from '../dialogs/select-template-dialog/template.constants'
import {
  BpmnTemplateConfig,
  Template,
} from '../dialogs/select-template-dialog/Template.types'
import { getOperateUrl } from '../taskist-operate-redirect/tasklist-operate-redirect.component'
import FormSelector from './form-selector'
import bpmnlintConfigZeebe10 from './rules/bundled-config_zeebe_1_0'
import bpmnlintConfigZeebe11 from './rules/bundled-config_zeebe_1_1'
import bpmnlintConfigZeebe12 from './rules/bundled-config_zeebe_1_2'
import { TokenSimulatorTracking } from './token-simulator'
import ExecutionPlatformModule from '@bpmn-io/execution-platform'
import ModelerModdleExtension from 'modeler-moddle/resources/modeler.json'

const latestLintRuleVersion = `1.2.0`
const latestLintRule = bpmnlintConfigZeebe12
const zeebeVersionToLintRule = {
  '1.0.0-alpha1': bpmnlintConfigZeebe10,
  '1.0.0-alpha2': bpmnlintConfigZeebe10,
  '1.0.0-alpha3': bpmnlintConfigZeebe10,
  '1.0.0-alpha4': bpmnlintConfigZeebe10,
  '1.0.0-alpha5': bpmnlintConfigZeebe10,
  '1.0.0-alpha6': bpmnlintConfigZeebe10,
  '1.0.0': bpmnlintConfigZeebe10,
  '1.0.1': bpmnlintConfigZeebe10,
  '1.1.0-alpha1': bpmnlintConfigZeebe11,
  '1.1.0': bpmnlintConfigZeebe11,
  '1.1.1': bpmnlintConfigZeebe11,
  '1.1.2': bpmnlintConfigZeebe11,
  '1.1.3': bpmnlintConfigZeebe11,
}

@Component({
  selector: 'bpmn-modeler',
  templateUrl: './bpmn-modeler.component.html',
  styleUrls: ['./bpmn-modeler.component.scss'],
})
export class BpmnModelerComponent implements OnInit, OnDestroy {
  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('deployModal', { read: ElementRef })
  public deployModal: ElementRef<CmModal>

  @ViewChild('executeModal', { read: ElementRef })
  public executeModal: ElementRef<CmModal>

  @ViewChild('deployFailedModal', { read: ElementRef })
  public deployFailedModal: ElementRef<CmModal>

  @ViewChild('startInstanceModal', { read: ElementRef })
  public startInstanceModal: ElementRef<CmModal>

  @ViewChild('startInstanceFailedModal', { read: ElementRef })
  public startInstanceFailedModal: ElementRef<CmModal>

  @ViewChild('importFailedModal', { static: true, read: ElementRef })
  public importFailedModal: ElementRef<CmModal>

  @ViewChild('invalidFormatModal', { static: true, read: ElementRef })
  public invalidFormatModal: ElementRef<CmModal>

  @ViewChild('fileInput', { static: true, read: ElementRef })
  public fileInput: ElementRef

  @ViewChild('renameModal', { read: ElementRef })
  public renameModal: ElementRef<CmModal>

  @ViewChild(CreateClusterDialogComponent)
  private createDialog: CreateClusterDialogComponent

  public models: BpmnModelDto[]
  public existingModel: BpmnModelDto = undefined
  public deployedVersion: number = undefined
  public modelName: string = 'New Diagram'
  private currentOrg: OrganizationDto
  private channels: ChannelDto[]
  private planTypes: ClusterPlanTypeDto[]
  public selectedClusterUuid: string
  public clusters: ClusterDto[] = []
  public deployErrorMessage: string = ''
  public deployErrorOnClustername: string | undefined
  public deploySuccessOperateAddress: string = ''
  public startInstanceErrorMessage: string = ''
  public startInstanceSuccessOperateAddress: string = ''
  public noClusters: boolean = false
  public clusterCreationDisabled: boolean = false
  public waitingForClusters: boolean = false

  public onboardingEmailModel: BpmnModelDto
  public onboardingOrderModel: BpmnModelDto

  public modelWasImported = false

  public modelDirty = false

  public propertiesPanelHidden = false

  public bpmnModeler

  public defaultProcess = `
  <?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_0rbumvm" targetNamespace="http://bpmn.io/schema/bpmn" xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:modeler="http://camunda.org/schema/modeler/1.0" modeler:executionPlatform="Camunda Cloud" modeler:executionPlatformVersion="1.0.0">
  <bpmn:process id="camunda-cloud-quick-start" isExecutable="true">
    <bpmn:startEvent id="StartEvent_1" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="camunda-cloud-quick-start">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="179" y="159" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>  
`
  private elementRegistry: any
  private instanceKey: number = null
  public moreDropdownOptions = []

  public moreDropdownTrigger = {
    type: 'icon',
    icon: 'contextMenu',
  }

  public deploymentDropdownOptions = []
  public deploymentDropdownTrigger: HTMLCmDropdownElement['trigger'] = {
    type: 'button',
    label: 'Execute',
    appearance: 'main',
  }

  public isLoading = true

  public lintReports: { clusterUuid: string; report: any } = {} as any
  public lowestSupportedZeebeVersion: string
  public hasLintErrors = false
  public hasGeneralLintErrors = true
  public selectedClusterHasLintErrors = false

  public template: Template
  public templateId: string
  public templateGuide: boolean = true
  public showTemplateGuide: boolean = true
  public parentTemplate: Template

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private clusterService: ClusterService,
    private notificationService: NotificationService,
    private navbarService: NavbarService,
    private mixpanel: MixpanelService,
    public authService: AuthService,
    public vvs: ViewVisibilitiesService,
    private ngZone: NgZone,
  ) {
    this.noClusters = this.vvs.visibilities.modeler.message.noclusters.visible
    this.clusterCreationDisabled = this.vvs.visibilities.modeler.cluster.create.disabled
  }

  public async ngOnInit() {
    this.currentOrg = this.route.snapshot.data.org
    this.existingModel = this.route.snapshot.data.model
    this.clusters = this.route.snapshot.data.clusters
    this.channels = this.route.snapshot.data.channels
    this.planTypes = this.route.snapshot.data.planTypes
    this.models = this.route.snapshot.data.models
    this.templateId = this.route.snapshot.params.templateId
    if (this.templateId && !this.existingModel) {
      this.template = TemplateById(this.templateId)
    }

    if (Boolean(this.route.snapshot.paramMap.get('uploadDiagram')) === true) {
      this.openFileImport()
    }

    this.clusterService.getClustersStatus().subscribe((clusters) => {
      this.clusters = clusters
      this.recalcAllowDeploy()
    })

    this.setModelName()

    await this.initBpmnModeler()
    await this.loadBpmnDiagram()

    if (!this.template && this.existingModel) {
      this.parentTemplate = TemplateById(this.existingModel.processId)
    }

    if (this.parentTemplate && this.parentTemplate.config) {
      BpmnModelerComponent.generateDiagramOverlays(
        this.bpmnModeler,
        this.parentTemplate.config,
      )
    }

    if (this.template) {
      if (this.template.config) {
        BpmnModelerComponent.generateDiagramOverlays(
          this.bpmnModeler,
          this.template.config,
        )
        this.templateGuide = true
        this.showTemplateGuide = true
      }

      await this.saveModel({ confirmation: true })

      return
    }

    const eventBus = this.bpmnModeler.get('eventBus')
    eventBus.on('elements.changed', (_event) => {
      this.modelDirty = true
      this.refreshDeploymentDropdown()
    })

    this.refreshMoreDropdown()
    this.refreshDeploymentDropdown()
  }

  public ngOnDestroy() {
    this.bpmnModeler.destroy()
  }

  public async saveModel(options: { confirmation: boolean }) {
    this.mixpanel.track(
      FrontendAnalyticEvents.MODELER_SAVE_MODEL,
      this.existingModel
        ? { modelId: this.existingModel.uuid, templateId: this.templateId }
        : {},
    )

    const newModel = await this.bpmnModeler.saveXML({ format: true })
    const modelName = this.modelName

    if (this.existingModel) {
      try {
        let result = await this.apiService
          .updateBpmnModel(
            this.currentOrg.uuid,
            modelName,
            this.existingModel.uuid,
            this.existingModel.modelHash,
            newModel.xml,
          )
          .pipe(first())
          .toPromise()

        this.existingModel.modelHash = result.modelHash
        this.existingModel.name = modelName
      } catch (error) {
        this.mixpanel.track(
          FrontendAnalyticEvents.MODELER_SAVE_MODEL_COLLISION_DETECTED,
          { modelId: this.existingModel.uuid, templateId: this.templateId },
        )

        this.notificationService.enqueueNotification({
          headline: `Diagram ${this.existingModel.name} could not be saved`,
          appearance: 'error',
          description:
            'This diagram was changed while you were editing it. Please reload the Diagram and try again.',
        })

        return false
      }
    } else {
      const createdModel = await this.apiService
        .createBpmnModel(this.currentOrg.uuid, modelName, newModel.xml)
        .pipe(first())
        .toPromise()

      this.existingModel = {
        uuid: createdModel.uuid,
        modelHash: createdModel.modelHash,
        model: newModel,
        name: modelName,
        processId: createdModel.processId,
        ownerId: this.currentOrg.uuid,
        version: 0,
        created: new Date(),
      }

      if (options.confirmation) {
        this.router.navigate([
          `org/${this.currentOrg.uuid}/modeler/${this.existingModel.uuid}`,
          { templateId: this.template.id },
        ])
      }
    }

    if (options.confirmation) {
      this.notificationService.enqueueNotification({
        headline: `Diagram saved`,
        appearance: 'success',
        showCreationTime: false,
      })
    }

    this.navbarService.announceNewUuidToName(
      this.existingModel.uuid,
      this.existingModel.name,
    )

    return true
  }

  public async openExecuteModal() {
    let isModelSaved = this.saveModel({ confirmation: false })

    if (this.existingModel?.deployedOnCluster) {
      const deployedOnCluster = this.clusters.find(
        (clus) =>
          clus.uuid === this.existingModel.deployedOnCluster.uuid &&
          !this.isUnsupportedAlpha(clus),
      )

      if (deployedOnCluster) {
        this.selectedClusterUuid = deployedOnCluster.uuid
      }
    } else {
      const supportedCluster = this.clusters.find(
        (clus) => !this.isUnsupportedAlpha(clus),
      )

      if (supportedCluster) {
        this.selectedClusterUuid = supportedCluster.uuid
      }
    }

    this.lintReports = {} as any
    this.hasLintErrors = false
    this.hasGeneralLintErrors = true
    const linter = new Linter(latestLintRule)
    try {
      let lintReport = await linter.lint(this.bpmnModeler.getDefinitions())
      this.hasGeneralLintErrors = Object.keys(lintReport).length > 0
    } catch (error) {
      // This is fine
      this.hasGeneralLintErrors = true
    }
    for (let cluster of this.clusters) {
      const zeebeVersion = cluster.generation.versions.zeebe.split(':')[1]
      if (zeebeVersion.startsWith('0')) {
        this.lintReports[cluster.uuid] = 'unsupported version'
        this.hasLintErrors = true
      } else {
        let lintRule = zeebeVersionToLintRule[zeebeVersion]
        if (!lintRule) {
          lintRule = latestLintRule
        }
        const linter = new Linter(lintRule)
        try {
          let lintReport = await linter.lint(this.bpmnModeler.getDefinitions())
          this.hasLintErrors =
            this.hasLintErrors || Object.keys(lintReport).length > 0
          if (Object.keys(lintReport).length > 0) {
            this.lintReports[cluster.uuid] =
              lintReport[Object.keys(lintReport)[0]][
                Object.keys(lintReport[Object.keys(lintReport)[0]])[0]
              ].message
          }
        } catch (error) {
          this.lintReports[cluster.uuid] = error
        }
      }
    }
    this.lowestSupportedZeebeVersion = await this.calculateLowestSupportedZeebeVersion()

    this.recalcAllowDeploy()

    this.ngZone.run(() => {
      this.bpmnModeler.get('keyboard').unbind()

      let modelId

      this.executeModal.nativeElement
        .open({
          preConfirmationHandler: async () => {
            if (await isModelSaved) {
              modelId = this.existingModel.uuid
              this.modelDirty = false
              this.refreshDeploymentDropdown()
            } else {
              return Promise.reject(new Error('Could not save Model.'))
            }
          },
        })
        .then((result) => {
          this.bpmnModeler.get('keyboard').bind(window)

          this.mixpanel.track(FrontendAnalyticEvents.MODELER_EXECUTE_MODAL, {
            modelId,
            templateId: this.templateId,
          })

          if (result.result === 'confirm') {
            this.selectedClusterUuid = result.formData
              .selectedClusterUuid as string

            this.mixpanel.track(
              FrontendAnalyticEvents.MODELER_EXECUTE_MODAL_CONFIRMED,
              {
                modelId,
                templateId: this.templateId,
              },
            )

            if (this.clusters && this.clusters.length) {
              this.apiService
                .deployBpmnModel(
                  this.currentOrg.uuid,
                  this.selectedClusterUuid,
                  modelId,
                )
                .subscribe(
                  (res: { key: number; workflows: { version: number }[] }) => {
                    this.existingModel.deployedOnCluster = this.clusters.find(
                      (clus) => clus.uuid === this.selectedClusterUuid,
                    )
                    this.deployedVersion =
                      res && res.workflows && res.workflows.length > 0
                        ? res.workflows[0].version
                        : undefined
                    this.deploySuccessOperateAddress = getOperateUrl(
                      this.clusters.find(
                        (cluster) => cluster.uuid === this.selectedClusterUuid,
                      ),
                    )

                    this.modelDirty = false
                    this.refreshDeploymentDropdown()

                    this.apiService
                      .startInstanceBpmnModel(
                        this.currentOrg.uuid,
                        this.existingModel.deployedOnCluster.uuid,
                        this.existingModel.uuid,
                        result.formData.startInstanceMetadata as string,
                      )
                      .subscribe(
                        (res) => {
                          this.instanceKey = (res as any).workflowInstanceKey
                          this.deploySuccessOperateAddress = getOperateUrl(
                            this.clusters.find(
                              (cluster) =>
                                cluster.uuid ===
                                this.existingModel.deployedOnCluster.uuid,
                            ),
                          )

                          this.notificationService.enqueueNotification({
                            headline: 'Instance started',
                            appearance: 'success',
                            navigation: {
                              label: `View Process Instance`,
                              navigationHandler: () => {
                                window.open(
                                  `${this.deploySuccessOperateAddress}/#/instances/${this.instanceKey}`,
                                  '_blank',
                                )
                              },
                            },
                          })
                        },
                        (error) => {
                          this.startInstanceErrorMessage = error.error
                          this.mixpanel.track(
                            FrontendAnalyticEvents.MODELER_START_MODAL_ERROR,
                            {
                              modelId: this.existingModel.uuid,
                              errorMessage: this.startInstanceErrorMessage,
                              templateId: this.templateId,
                            },
                          )
                          this.startInstanceFailedModal.nativeElement.open()
                        },
                      )
                  },
                  (error) => {
                    this.deployErrorMessage = error.error
                    this.deployErrorOnClustername = this.clusters.find(
                      (cluster) => cluster.uuid === this.selectedClusterUuid,
                    ).name
                    this.mixpanel.track(
                      FrontendAnalyticEvents.MODELER_EXECUTE_MODAL_ERROR,
                      {
                        modelId,
                        errorMessage: this.deployErrorMessage,
                        templateId: this.templateId,
                      },
                    )

                    this.deployFailedModal.nativeElement.open()
                  },
                )
            } else {
              // Wait for the close transition to finish, before we open another modal
              setTimeout(() => {
                this.openCreateClusterDialog(true)
              }, 300)
            }
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.MODELER_EXECUTE_MODAL_CANCELED,
              {
                modelId,
                templateId: this.templateId,
              },
            )
          }
        })
    })
  }

  public async saveAndDeployModel() {
    if (await this.saveModel({ confirmation: false })) {
      this.openDeployModal(this.existingModel.uuid)
    }
  }

  public allowDeploy: boolean = false

  public recalcAllowDeploy(newlySelectedCluster?: ClusterDto) {
    if (
      newlySelectedCluster &&
      !this.isUnsupportedAlpha(newlySelectedCluster)
    ) {
      this.selectedClusterUuid = newlySelectedCluster.uuid
    }
    if (this.selectedClusterUuid) {
      const selectedCluster = this.clusters.find(
        (clus) => clus.uuid === this.selectedClusterUuid,
      )
      this.selectedClusterHasLintErrors =
        this.lintReports[selectedCluster.uuid] !== undefined
      this.allowDeploy =
        selectedCluster?.status.ready === 'Healthy' &&
        !this.lintReports[selectedCluster.uuid]
    } else {
      this.allowDeploy = false
    }
  }

  public async calculateLowestSupportedZeebeVersion(): Promise<string> {
    let lowestVersion: string
    for (const zeebeVersion of Object.keys(zeebeVersionToLintRule)) {
      let lintRule = zeebeVersionToLintRule[zeebeVersion]
      const linter = new Linter(lintRule)
      try {
        let lintReport = await linter.lint(this.bpmnModeler.getDefinitions())
        if (Object.keys(lintReport).length === 0) {
          lowestVersion = zeebeVersion
          break
        }
      } catch (error) {
        // this is fine
      }
    }

    if (lowestVersion) {
      return lowestVersion
    }
    return latestLintRuleVersion
  }

  public async openDeployModal(modelId: string) {
    this.mixpanel.track(FrontendAnalyticEvents.MODELER_DEPLOY_MODAL, {
      modelId,
      templateId: this.templateId,
    })

    if (this.existingModel?.deployedOnCluster) {
      const deployedOnCluster = this.clusters.find(
        (clus) =>
          clus.uuid === this.existingModel.deployedOnCluster.uuid &&
          !this.isUnsupportedAlpha(clus),
      )

      if (deployedOnCluster) {
        this.selectedClusterUuid = deployedOnCluster.uuid
      }
    } else {
      const supportedCluster = this.clusters.find(
        (clus) => !this.isUnsupportedAlpha(clus),
      )

      if (supportedCluster) {
        this.selectedClusterUuid = supportedCluster.uuid
      }
    }

    this.lowestSupportedZeebeVersion = await this.calculateLowestSupportedZeebeVersion()

    this.lintReports = {} as any
    this.hasLintErrors = false
    this.hasGeneralLintErrors = true
    for (let cluster of this.clusters) {
      const zeebeVersion = cluster.generation.versions.zeebe.split(':')[1]
      if (zeebeVersion.startsWith('0')) {
        this.lintReports[cluster.uuid] = 'unsupported version'
        this.hasLintErrors = true
      } else {
        let lintRule = zeebeVersionToLintRule[zeebeVersion]
        if (!lintRule) {
          lintRule = latestLintRule
        }
        const linter = new Linter(lintRule)
        try {
          let lintReport = await linter.lint(this.bpmnModeler.getDefinitions())
          this.hasLintErrors =
            this.hasLintErrors || Object.keys(lintReport).length > 0
          this.hasGeneralLintErrors =
            this.hasGeneralLintErrors && Object.keys(lintReport).length > 0
          if (Object.keys(lintReport).length > 0) {
            this.lintReports[cluster.uuid] =
              lintReport[Object.keys(lintReport)[0]][
                Object.keys(lintReport[Object.keys(lintReport)[0]])[0]
              ].message
          }
        } catch (error) {
          this.lintReports[cluster.uuid] = error
        }
      }
    }

    this.recalcAllowDeploy()

    this.ngZone.run(() => {
      this.bpmnModeler.get('keyboard').unbind()

      this.deployModal.nativeElement.open().then((result) => {
        this.bpmnModeler.get('keyboard').bind(window)

        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.MODELER_DEPLOY_MODAL_CONFIRMED,
            {
              modelId,
              templateId: this.templateId,
            },
          )

          if (this.clusters && this.clusters.length) {
            this.apiService
              .deployBpmnModel(
                this.currentOrg.uuid,
                this.selectedClusterUuid,
                modelId,
              )
              .subscribe(
                (res: { key: number; workflows: { version: number }[] }) => {
                  this.existingModel.deployedOnCluster = this.clusters.find(
                    (clus) => clus.uuid === this.selectedClusterUuid,
                  )
                  this.deployedVersion =
                    res && res.workflows && res.workflows.length > 0
                      ? res.workflows[0].version
                      : undefined
                  this.deploySuccessOperateAddress = getOperateUrl(
                    this.clusters.find(
                      (clus) => clus.uuid === this.selectedClusterUuid,
                    ),
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'Diagram deployed',
                    appearance: 'success',
                    showCreationTime: false,
                  })
                  this.modelDirty = false
                  this.refreshDeploymentDropdown()
                },
                (error) => {
                  this.deployErrorMessage = error.error
                  this.deployErrorOnClustername = this.clusters.find(
                    (cluster) => cluster.uuid === this.selectedClusterUuid,
                  ).name
                  this.mixpanel.track(
                    FrontendAnalyticEvents.MODELER_DEPLOY_MODAL_ERROR,
                    {
                      modelId,
                      errorMessage: this.deployErrorMessage,
                      templateId: this.templateId,
                    },
                  )

                  this.deployFailedModal.nativeElement.open()
                },
              )
          } else {
            // Wait for the close transition to finish, before we open another modal
            setTimeout(() => {
              this.openCreateClusterDialog(false)
            }, 300)
          }
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.MODELER_DEPLOY_MODAL_CANCELED,
            {
              modelId,
              templateId: this.templateId,
            },
          )
        }
      })
    })
  }

  public getHealthyClusters() {
    return this.clusters.filter(
      (clus) => clus.status.ready && clus.status.ready === 'Healthy',
    )
  }

  public async openOperate() {
    await this.mixpanel.track(FrontendAnalyticEvents.MODELER_OPEN_OPERATE, {
      modelId: this.existingModel.uuid,
      clusterId: this.existingModel.deployedOnCluster
        ? this.existingModel.deployedOnCluster.uuid
        : undefined,
      templateId: this.templateId,
    })
    window.open(
      `${
        this.existingModel.deployedOnCluster.status.operateUrl
      }#/instances?process=${this.existingModel.processId}&version=${
        this.deployedVersion !== undefined ? this.deployedVersion : 'all'
      }&active=true&incidents=true&completed=true&canceled=true`,
      '_blank',
    )
  }

  public async openTasklist() {
    await this.mixpanel.track(FrontendAnalyticEvents.MODELER_OPEN_TASKLIST, {
      modelId: this.existingModel.uuid,
      clusterId: this.existingModel.deployedOnCluster
        ? this.existingModel.deployedOnCluster.uuid
        : undefined,
      templateId: this.templateId,
    })
    window.open(
      this.existingModel.deployedOnCluster.status.tasklistUrl,
      '_blank',
    )
  }

  public openCreateClusterDialog(execute: boolean) {
    this.ngZone.run(() => {
      this.bpmnModeler.get('keyboard').unbind()
      this.mixpanel.track(
        FrontendAnalyticEvents.MODELER_CREATE_CLUSTER_DIALOG,
        {
          modelId: this.existingModel ? this.existingModel.uuid : undefined,
          templateId: this.templateId,
        },
      )
      this.waitingForClusters = true
      this.createDialog
        .open({
          currentOrg: this.currentOrg,
          clusters: this.clusters,
          channels: this.channels,
          planTypes: this.planTypes,
        })
        .catch((_error) => {
          this.notificationService.enqueueNotification({
            headline: 'Cluster could not be created',
            appearance: 'error',
            showCreationTime: false,
          })
          this.waitingForClusters = false
        })
        .then((createdCluster) => {
          this.bpmnModeler.get('keyboard').bind(window)

          if (createdCluster) {
            this.noClusters = false
            this.clusterService.getClustersStatus().subscribe((clusters) => {
              this.clusters = clusters
              if (clusters.length > 0) {
                this.waitingForClusters = false
              }
              if (
                this.clusters.filter(
                  (clus) => clus.status.ready && !this.isUnsupportedAlpha(clus),
                ).length > 0 &&
                !this.selectedClusterUuid
              ) {
                this.selectedClusterUuid = this.clusters.filter(
                  (clus) => clus.status.ready && !this.isUnsupportedAlpha(clus),
                )[0].uuid
              }
              this.recalcAllowDeploy()
              if (execute) {
                this.openExecuteModal()
              } else {
                this.openDeployModal(this.existingModel.uuid)
              }
            })
          } else {
            this.waitingForClusters = false
            if (execute) {
              this.openExecuteModal()
            } else {
              this.openDeployModal(this.existingModel.uuid)
            }
          }
        })
    })
  }

  public openFileImport() {
    this.mixpanel.track(FrontendAnalyticEvents.MODELER_IMPORT_FILE)
    this.fileInput.nativeElement.click()
  }

  public onFileSelected() {
    this.mixpanel.track(FrontendAnalyticEvents.MODELER_IMPORT_FILE_SELECTED)
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader()

      reader.onload = (res: any) => {
        const model = res.target.result

        if (BpmnModelerComponent.isZeebeModel(model)) {
          this.bpmnModeler.importXML(model, (error) => {
            if (error) {
              this.mixpanel.track(
                FrontendAnalyticEvents.MODELER_IMPORT_FILE_ERROR_IN_IMPORT,
              )
              this.ngZone.run(() => {
                this.importFailedModal.nativeElement.open()
              })
            } else {
              this.modelWasImported = true
              if (!this.modelName) {
                let processKey = Object.keys(
                  this.elementRegistry._elements,
                ).find((elementKey) => {
                  let element = this.elementRegistry.get(elementKey)
                  let found = element.type === 'bpmn:Process'
                  return found
                })
                if (processKey) {
                  let processElement = this.elementRegistry.get(processKey)
                  this.modelName = processElement.businessObject.name
                }
              }
            }
          })
        } else {
          this.invalidFormatModal.nativeElement.open()
        }
      }

      reader.readAsText((this.fileInput as any).nativeElement.files[0])
    }
  }

  public openStartInstanceModal() {
    this.mixpanel.track(FrontendAnalyticEvents.MODELER_START_MODAL, {
      templateId: this.templateId,
    })

    this.ngZone.run(() => {
      this.bpmnModeler.get('keyboard').unbind()
      this.startInstanceModal.nativeElement.open().then((result) => {
        this.bpmnModeler.get('keyboard').bind(window)

        if (result.result === 'confirm') {
          let meta = result.formData.startInstanceMetadata as string

          this.mixpanel.track(
            FrontendAnalyticEvents.MODELER_START_MODAL_CONFIRMED,
            {
              modelId: this.existingModel.uuid,
              variables: meta,
              templateId: this.templateId,
            },
          )

          this.apiService
            .startInstanceBpmnModel(
              this.currentOrg.uuid,
              this.existingModel.deployedOnCluster.uuid,
              this.existingModel.uuid,
              meta,
            )
            .subscribe(
              (res) => {
                this.instanceKey = (res as any).workflowInstanceKey
                this.deploySuccessOperateAddress = getOperateUrl(
                  this.clusters.find(
                    (clus) =>
                      clus.uuid === this.existingModel.deployedOnCluster.uuid,
                  ),
                )
                this.notificationService.enqueueNotification({
                  headline: 'Instance started',
                  appearance: 'success',
                  navigation: {
                    label: `View Process Instance`,
                    navigationHandler: () => {
                      window.open(
                        `${this.deploySuccessOperateAddress}/#/instances/${this.instanceKey}`,
                        '_blank',
                      )
                    },
                  },
                })
              },
              (error) => {
                this.startInstanceErrorMessage = error.error
                this.mixpanel.track(
                  FrontendAnalyticEvents.MODELER_START_MODAL_ERROR,
                  {
                    modelId: this.existingModel.uuid,
                    errorMessage: this.startInstanceErrorMessage,
                    templateId: this.templateId,
                  },
                )
                this.startInstanceFailedModal.nativeElement.open()
              },
            )
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.MODELER_START_MODAL_CANCELED,
            { modelId: this.existingModel.uuid, templateId: this.templateId },
          )
        }
      })
    })
  }

  public isValidJson = {
    type: 'custom',
    validator: (value: string) => {
      try {
        JSON.parse(value)
        return { isValid: true }
      } catch (error) {
        return {
          isValid: false,
          type: 'invalid',
          message: 'Please enter a valid JSON Object.',
        }
      }
    },
  }

  public async exportAsXml() {
    const model = await this.bpmnModeler.saveXML({ format: true })
    const a = document.createElement('a')
    a.download = `${
      this.existingModel ? this.existingModel.name : this.template.name
    }.bpmn`
    a.href = window.URL.createObjectURL(
      new Blob([model.xml], { type: 'application/xml' }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }

  public openDeleteModal() {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_MODELS_DELETE_MODEL, {
      templateId: this.templateId,
    })

    this.ngZone.run(() => {
      this.bpmnModeler.get('keyboard').unbind()
      this.deleteModal.nativeElement
        .open({
          preConfirmationHandler: () => {
            return this.apiService
              .deleteBpmnModel(this.currentOrg.uuid, this.existingModel.uuid)
              .pipe(first())
              .toPromise()
              .then()
          },
        })
        .then((result) => {
          this.bpmnModeler.get('keyboard').bind(window)
          if (result.result === 'confirm') {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_MODELS_DELETE_MODEL_CONFIRMED,
              { templateId: this.templateId },
            )

            this.router.navigate([`org/${this.currentOrg.uuid}/diagrams`])
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_MODELS_DELETE_MODEL_CANCELED,
              { templateId: this.templateId },
            )
          }
        })
    })
  }

  public static goToGithupRepo() {
    window.open(
      'https://github.com/camunda-cloud/camunda-cloud-get-started',
      '_blank',
    )
  }

  public static goToModelDoc() {
    window.open(
      'https://docs.camunda.io/docs/guides/getting-started/model-your-first-process',
      '_blank',
    )
  }

  public createNewDiagram() {
    this.router.navigate([`org/${this.currentOrg.uuid}/modeler`])
  }

  public isExpiredTrial() {
    return this.vvs.visibilities.cluster.list.message.trialExpired.visible
  }

  public toggleTemplateGuide(event: any) {
    this.templateGuide = event.detail.isChecked

    if (this.templateGuide) {
      BpmnModelerComponent.generateDiagramOverlays(
        this.bpmnModeler,
        this.template?.config ?? this.parentTemplate.config,
      )
    } else {
      this.showTemplateGuide = false
      BpmnModelerComponent.removeDiagramOverlays(
        this.bpmnModeler,
        this.template?.config ?? this.parentTemplate.config,
      )
    }
  }

  private async initBpmnModeler() {
    const BpmnModeler = (
      await import('camunda-bpmn-js/lib/camunda-cloud/Modeler')
    ).default
    const TokenSimulationModule = (await import('bpmn-js-token-simulation'))
      .default

    const formSelector = FormSelector(
      [{ name: '', form: '{}' }].concat(this.route.snapshot.data.forms),
    )

    const tokenSimulatorTracking = TokenSimulatorTracking(
      (eventType: string) => {
        this.mixpanel.track(
          FrontendAnalyticEvents.MODELER_TOKEN_SIMULATOR_INNER,
          { eventType, templateId: this.templateId },
        )
      },
    )

    this.bpmnModeler = new BpmnModeler({
      container: '#canvas',
      keyboard: {
        bindTo: window,
      },
      height: '100%',
      propertiesPanel: {
        parent: '#properties',
      },
      linting: {
        bpmnlint: latestLintRule,
        active: true,
      },
      additionalModules: [
        formSelector,
        TokenSimulationModule,
        tokenSimulatorTracking,
        lintModule,
        ExecutionPlatformModule,
      ],
      enableZeebeUserTasks: true,
      moddleExtensions: {
        modeler: ModelerModdleExtension,
      },
      executionPlatform: {
        name: 'Camunda Cloud',
        version: latestLintRuleVersion,
      },
    })

    this.elementRegistry = this.bpmnModeler.get('elementRegistry')

    this.bpmnModeler.on('import.done', () => {
      this.isLoading = false
    })
  }

  private setModelName() {
    let name = 'New Diagram'

    if (this.template) {
      name = this.template.name
    } else if (this.route.snapshot.data.model) {
      name = this.route.snapshot.data.model.name
    }

    this.modelName = name
  }

  private async loadBpmnDiagram() {
    let xml = this.defaultProcess
    if (this.template) {
      xml = this.template.diagram
    } else if (this.existingModel) {
      xml = this.existingModel.model
    }

    await this.bpmnModeler.importXML(xml)
    if (this.existingModel) {
      this.navbarService.announceNewUuidToName(
        this.existingModel.uuid,
        this.existingModel.name,
      )
    }
  }

  private refreshDeploymentDropdown() {
    this.deploymentDropdownOptions = [
      {
        options: [
          {
            label: 'Save and Deploy',
            isDisabled: this.vvs.visibilities.modeler.save.disabled,
            handler: () => {
              this.saveAndDeployModel()
            },
          },
          {
            label: 'Start Instance',
            isDisabled:
              this.vvs.visibilities.modeler.start.disabled ||
              !this.existingModel ||
              !this.existingModel.deployedOnCluster ||
              this.modelDirty,
            handler: () => {
              this.openStartInstanceModal()
            },
          },
        ],
      },
      {
        options: [
          {
            title: 'Operate',
            label: 'View Process Instances',
            isDisabled:
              !this.existingModel || !this.existingModel.deployedOnCluster,
            handler: () => {
              this.openOperate()
            },
          },
          {
            title: 'Tasklist',
            label: 'View User Tasks',
            isDisabled:
              !this.existingModel || !this.existingModel.deployedOnCluster,
            handler: () => {
              this.openTasklist()
            },
          },
        ],
      },
    ]

    this.deploymentDropdownTrigger = {
      type: 'defaultAction',
      label: 'Execute',
      appearance: 'primary',
      defaultHandler: () => {
        this.openExecuteModal()
      },
    }
  }

  private refreshMoreDropdown() {
    this.moreDropdownOptions = []
    if (this.vvs.visibilities.modeler.save.visible) {
      this.moreDropdownOptions.push({
        options: [
          {
            label: 'Rename',
            isDisabled: this.vvs.visibilities.modeler.save.disabled,
            handler: () => this.openRenameModal(),
          },
          {
            label: 'Export as XML',
            handler: () => {
              this.exportAsXml()
            },
          },
        ],
      })
    } else {
      this.moreDropdownOptions.push({
        options: [
          {
            label: 'Export as XML',
            handler: () => {
              this.exportAsXml()
            },
          },
        ],
      })
    }

    if (this.vvs.visibilities.modeler.save.visible) {
      this.moreDropdownOptions.push({
        options: [
          {
            label: 'Create New Diagram',
            isDisabled: this.vvs.visibilities.modeler.save.disabled,
            handler: () => {
              this.createNewDiagram()
            },
          },
          {
            label: 'Import New Diagram',
            isDisabled: this.vvs.visibilities.modeler.save.disabled,
            handler: () => {
              this.openFileImport()
            },
          },
        ],
      })
    }

    this.moreDropdownOptions.push({
      title: 'Documentation',
      options: [
        {
          label: 'Model your First Diagram',
          handler: () => {
            BpmnModelerComponent.goToModelDoc()
          },
        },
      ],
    })

    if (this.vvs.visibilities.modeler.delete.visible) {
      this.moreDropdownOptions.push({
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            isDisabled: this.vvs.visibilities.modeler.delete.disabled,
            handler: () => {
              this.openDeleteModal()
            },
          },
        ],
      })
    }
  }
  // eslint-disable-next-line class-methods-use-this
  public isUnsupportedAlpha(cluster: ClusterDto) {
    return BpmnModelerComponent.isUnsupportedAlpaVersion(
      cluster.generation.versions.zeebe,
    )
  }
  public static isUnsupportedAlpaVersion(zeebeVersion: string) {
    const version = zeebeVersion.replace('camunda/zeebe:', '')
    if (version === 'SNAPSHOT') {
      return false
    }
    const zeebeSemver = semver.coerce(version)
    const zeebeTag = version.replace(`${zeebeSemver}-`, '')
    if (semver.lt(zeebeSemver, '1.0.0')) {
      return false
    }
    if (semver.gt(zeebeSemver, '1.0.0')) {
      return false
    }
    if (
      zeebeTag === 'alpha3' ||
      zeebeTag === 'alpha4' ||
      zeebeTag === 'alpha5' ||
      zeebeTag === 'alpha6'
    ) {
      return true
    }
    return false
  }

  public togglePropertyPanel() {
    this.propertiesPanelHidden = !this.propertiesPanelHidden
    this.mixpanel.track(FrontendAnalyticEvents.MODELER_TOGGLE_PROPERTY_PANEL, {
      hidden: this.propertiesPanelHidden,
      templateId: this.templateId,
    })
  }

  public static generateDiagramOverlays(
    modeler: any,
    config: BpmnTemplateConfig,
  ) {
    if (config && config.diagram && config.diagram.tooltips) {
      let overlays = modeler.get('overlays')
      config.diagram.tooltips.forEach((tooltip) => {
        try {
          overlays.add(tooltip.id, {
            position: {
              top: 0,
              left: 0,
            },
            html: BpmnModelerComponent.createTooltipHtml(tooltip.text),
          })
        } catch (_error) {
          // Element not found, diagram was modified
        }
      })
    }
  }

  public static removeDiagramOverlays(
    modeler: any,
    config: BpmnTemplateConfig,
  ) {
    if (config && config.diagram && config.diagram.tooltips) {
      let overlays = modeler.get('overlays')
      config.diagram.tooltips.forEach((tooltip) => {
        try {
          overlays.remove({ element: tooltip.id })
        } catch (_error) {
          // Element not found, diagram was modified
        }
      })
    }
  }

  public static createTooltipHtml(text: string) {
    return `
    <style type="text/css">
      .trigger {
        width: 24px;
        height: 24px;

        position: absolute;
        border-radius: 50%;
        
        margin-left: -36px;

        box-shadow: 0 0 0 1px var(--cm-color-orange-base),
                    inset 0 0 0 1px var(--cm-color-orange-base),
                    0 0 4px 2px var(--cm-color-orange-base),
                    inset 0 0 4px 0px var(--cm-color-orange-base);
      }

      .trigger::before {
        content: "";
        display: block;
        border-radius: 50%;

        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: var(--cm-color-orange-base);
      }

      .trigger::after {
        content: "";
        display: block;
        border-radius: 50%;

        position: absolute;
        width: 18px;
        height: 18px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        box-shadow: 0 0 0 1px var(--cm-color-orange-base),
                    inset 0 0 0 1px var(--cm-color-orange-base);
      }

      .tooltip {
        display: none;

        z-index: 1000;

        position: relative;
        left: -322px;
        top: 40px;

        width: 350px;
        padding: 15px 20px 20px 15px;

        background-color: var(--cm-color-ui-dark4);
        border-radius: 3px;
        
        color: #FFF;
        font-size: 13px;
        font-family: var(--cm-font-text);
      }

      .tooltip::before {
        content: '';
    
        position: absolute;
    
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 8px solid var(--cm-color-ui-dark4);
        top: -8px;
        right: 45px;
      }

      .tooltip::after {
        content: '';
    
        position: absolute;
        top: -50px;
        right: 12px;


        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-bottom: 50px solid transparent;
      }

      .trigger:hover + .tooltip {
        display: block;
      }
      
      .tooltip:hover {
        display: block;
      }

      .tooltip a {
        font-family: var(--cm-font-text);
        font-size: 14px;
        font-weight: 400;
      
        border-radius: 3px;
        text-decoration: none;
        color: var(--cm-color-link-dark);
      
        transition: all linear 200ms;
      }

      .tooltip a::after {
        content: '';
        padding-left: 15px;
        margin-right: 2px;
        background-image: url("data:image/svg+xml,%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' %3E%3Cpath d='M6,0 L6,1.4 L1.4,1.4 L1.4,10.6 L10.6,10.6 L10.6,6.485 L12,6.485 L12,12 L0,12 L0,0 L6,0 Z M12,0 L12,5.204 L10.203,3.205 L6.707,6.702 L5.293,5.288 L8.863,1.716 L7.32,0 L12,0 Z' fill='%23dbebff' fill-rule='nonzero' %3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        background-position: right;
        background-repeat: no-repeat;
        transform: translateY(1px);
      }
    
      .tooltip a:hover {
        color: var(--cm-color-link-hover-dark);
      }
    
      .tooltip a:focus {
        outline: none;
      }
    
      .tooltip a:focus-visible {
        box-shadow: 0px 0px 0px 1px var(--cm-color-focus-inner-neutral),
          0px 0px 0px 4px var(--cm-color-focus-outer-neutral);
      }
    
      .tooltip a:active {
        color: var(--cm-color-link-active-dark);
      }
    </style>

    <div class="trigger"></div>
    <div class="tooltip">${text}</div>
    `
  }

  public templateGuideDismiss() {
    this.showTemplateGuide = false
  }

  public static isZeebeModel(model: string) {
    if (model.includes('modeler:executionPlatform')) {
      return model.includes('modeler:executionPlatform="Camunda Cloud"')
    }

    return true
  }

  private openRenameModal() {
    this.ngZone.run(() => {
      this.bpmnModeler.get('keyboard').unbind()

      this.renameModal.nativeElement
        .open({
          preConfirmationHandler: (data) => {
            this.renameModel(data.formData.newName as string)
            return this.saveModel({ confirmation: true }).then(() => {
              this.refreshDeploymentDropdown()
            })
          },
        })
        .then((result) => {
          this.bpmnModeler.get('keyboard').bind(window)
        })
    })
  }

  renameModel(newName: string) {
    this.modelName = newName

    if (!this.existingModel && !this.modelWasImported) {
      const elementRegistry = this.bpmnModeler.get('elementRegistry')

      let processKey = Object.keys(elementRegistry._elements).find(
        (elementKey) => {
          let element = elementRegistry.get(elementKey)
          let found = element.type === 'bpmn:Process'
          return found
        },
      )
      let processElement = elementRegistry.get(processKey)
      let modeling = this.bpmnModeler.get('modeling')

      const id = this.modelName.replace(/[^\w\d]/gu, '')
      const random = v4().split('-')[0]

      modeling.updateProperties(processElement, {
        name: this.modelName,
        id: `Process_${id}_${random}`,
      })
    }
  }
}
