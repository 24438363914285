import {
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import {
  ClusterPlanTypeDto,
  OrganizationDto,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { forkJoin, Observable, Subscription } from 'rxjs'
import { filter, first } from 'rxjs/operators'
import { NavbarService } from '../../services/navbar.service'
import { OrgService } from '../../services/org.service'
import { ChannelDto } from '../../../../../commons/Channel.dto'
import { ClusterDto } from '../../../../../commons/Cluster.dto'
import { environment } from '../../../environments/environment'
import { ClusterService } from '../../services/cluster.service'
import { ConfirmationModalService } from '../../services/confirmation-modal.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { NotificationService } from '../../services/notification.service'
import { UserService } from '../../services/user.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
import { CreateClusterDialogComponent } from '../dialogs/create-cluster-dialog/create-cluster-dialog.component'
@Component({
  selector: 'list-clusters',
  templateUrl: './list-clusters.component.html',
  styleUrls: ['./list-clusters.component.scss'],
})
export class ListClustersComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('renameModal', { read: ElementRef })
  public renameModal: ElementRef<CmModal>

  @ViewChild('defaultCallToActionModal', { static: true, read: ElementRef })
  public defaultCallToActionModal: ElementRef<CmModal>

  @ViewChild('onboardingMessage', { read: ElementRef })
  public onboardingMessage: ElementRef

  @ViewChild(CreateClusterDialogComponent)
  private createDialog: CreateClusterDialogComponent

  @ViewChild('createClusterWarningTrial', { static: true, read: ElementRef })
  public warningTrialModal: ElementRef<CmModal>

  @ViewChild('createClusterWarningAdmin', { static: true, read: ElementRef })
  public warningAdminModal: ElementRef<CmModal>

  @ViewChild('createClusterWarningMemberModal', {
    static: true,
    read: ElementRef,
  })
  public warningMember: ElementRef<CmModal>

  public clusterListColumns = [
    { name: '', width: '20px' },
    {
      name: 'Name',
      width: '4fr',
      ellipsis: 'right',
      overrideCSS: {
        fontWeight: '500',
        color: 'var(--cm-color-dark-04)',
      },
    },
    { name: 'Region', width: '3fr', ellipsis: 'right' },
    { name: 'Generation', width: '3fr', ellipsis: 'right' },
    {
      name: 'Type',
      width: '3fr',
      ellipsis: 'right',
    },
    { name: '', width: '12px' },
    { name: 'Status', width: 'minmax(100px, 1fr)', ellipsis: 'right' },
    { name: '', width: '20px' },
  ]
  public clusterListEntities
  public clusterListCreateHandler: () => void

  public environment = environment

  public clusters: ClusterDto[]
  public org: OrganizationDto
  public clusterStatus$: Observable<{}>
  public entityListLoading = false

  public channels: ChannelDto[] = []
  public planTypes: ClusterPlanTypeDto[] = []

  public clusterSubscription: Subscription

  private userId: string
  public clusterToBeDeleted: ClusterDto
  public clusterToBeRenamed: ClusterDto

  constructor(
    private clusterService: ClusterService,
    private route: ActivatedRoute,
    public modalService: ConfirmationModalService,
    private notificationService: NotificationService,
    public router: Router,
    public vvs: ViewVisibilitiesService,
    private mixpanel: MixpanelService,
    private userService: UserService,
    private authService: AuthService,
    private ngZone: NgZone,
    public orgService: OrgService,
    private navbarService: NavbarService,
  ) {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.userId = user.sub
      }
    })
  }

  public ngOnChanges() {
    this.ngOnInit()
  }

  public ngOnInit() {
    this.clusters = this.route.snapshot.data.clusters
    this.org = this.route.snapshot.data.org

    this.channels = this.route.snapshot.data.channels
    this.planTypes = this.route.snapshot.data.planTypes

    this.clusterSubscription = this.clusterService
      .getClustersStatus()
      .subscribe((updatedClusters) => {
        this.clusters = updatedClusters
        this.refreshClusterList()
      })

    this.clusterListCreateHandler = () => this.openCreateClusterDialog()
    // show no clusterType for pricing1
    if (!this.vvs.visibilities.cluster.details.clusterPlanType.visible) {
      this.clusterListColumns = this.clusterListColumns.filter(
        (column) => column.name !== 'Type',
      )
    }

    this.refreshClusterList()
  }

  public ngOnDestroy() {
    if (this.clusterSubscription) {
      this.clusterSubscription.unsubscribe()
    }
  }

  public deleteAllClusters(currentOrg: OrganizationDto) {
    this.modalService.openModal({
      title: 'Delete all Clusters',
      body: 'Are you sure to delete all Clusters?',
      confirmButton: {
        text: 'Delete All Clusters',
        appearance: 'danger',
        action: () => {
          this.deleteClustersAndRefresh(currentOrg, this.clusters)
        },
      },
      cancelButton: {
        text: 'Cancel Cluster Deletion',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteAllUnhealthyClusters(currentOrg: OrganizationDto) {
    this.modalService.openModal({
      title: 'Delete all unhealthy Clusters',
      body: 'Are you sure to delete all unhealthy Clusters?',
      confirmButton: {
        text: 'Delete All Unhealthy Clusters',
        appearance: 'danger',
        action: () => {
          this.deleteClustersAndRefresh(
            currentOrg,
            this.clusters.filter(
              (cluster) => cluster.status.ready !== 'Healthy',
            ),
          )
        },
      },
      cancelButton: {
        text: 'Cancel Cluster Deletion',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteAllHealthyClusters(currentOrg: OrganizationDto) {
    this.modalService.openModal({
      title: 'Delete all healthy Clusters',
      body: 'Are you sure to delete all healthy Clusters?',
      confirmButton: {
        text: 'Delete All Healthy Clusters',
        appearance: 'danger',
        action: () => {
          this.deleteClustersAndRefresh(
            currentOrg,
            this.clusters.filter(
              (cluster) => cluster.status.ready === 'Healthy',
            ),
          )
        },
      },
      cancelButton: {
        text: 'Cancel Cluster Deletion',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  private deleteClustersAndRefresh(
    currentOrg: OrganizationDto,
    clusters: ClusterDto[],
  ) {
    forkJoin(
      clusters.map((cluster) =>
        this.clusterService.delete(currentOrg.uuid, cluster.uuid).pipe(first()),
      ),
    ).subscribe(() =>
      this.clusterService.getClustersStatus().subscribe((updatedClusters) => {
        this.clusters = updatedClusters
        this.refreshClusterList()
        if (updatedClusters.length > clusters.length) {
          this.entityListLoading = false
        }
      }),
    )
  }

  public openCreateClusterDialog() {
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_CLUSTERS_CREATE_CLUSTER_DIALOG,
    )
    if (
      (this.vvs.visibilities.navbar.trial.warning.visible ||
        this.org?.organizationToSalesPlan?.salesPlan?.salesPlanType ===
          SalesPlanType.FREE) &&
      this.vvs.visibilities.cluster.list.message.reachedMaxClusters.visible
    ) {
      this.openCreateClusterWarningTrial()
    } else if (
      this.vvs.visibilities.cluster.list.message.reachedMaxReservations.visible
    ) {
      this.openCreateClusterWarningAdmin()
    } else if (
      this.vvs.visibilities.cluster.list.message.reachedMaxClusters.visible
    ) {
      this.openCreateClusterWarningMember()
    } else {
      this.createDialog
        .open(
          {
            currentOrg: this.org,
            clusters: this.clusters,
            channels: this.channels,
            planTypes: this.planTypes,
          },
          () => {
            this.entityListLoading = true
          },
        )
        .catch((_error) => {
          this.notificationService.enqueueNotification({
            headline: 'Cluster could not be created',
            appearance: 'error',
          })
          this.entityListLoading = false
        })
        .then(async (createdCluster) => {
          if (createdCluster) {
            let hasExistingClusters = this.clusters.length > 0
            this.clusters = await this.clusterService
              .getClusters(this.org.uuid)
              .pipe(first())
              .toPromise()
            this.entityListLoading = false
            this.clusterService
              .getClustersStatus()
              .pipe(
                filter((updatedClusters) => {
                  return (
                    updatedClusters.find(
                      (clu) => clu.uuid === createdCluster.clusterId,
                    ) !== undefined
                  )
                }),
                first(),
              )
              .subscribe((updatedClusters) => {
                if (
                  updatedClusters.find(
                    (clu) => clu.uuid === createdCluster.clusterId,
                  )
                ) {
                  this.entityListLoading = false
                }
              })

            //Show Call to action
            if (
              !hasExistingClusters &&
              !this.vvs.visibilities.featureFlags.newOnboardingExperience
                .visible
            ) {
              requestAnimationFrame(() => {
                this.defaultCallToActionModal.nativeElement
                  .open()
                  .then((result) => {
                    if (result.result === 'confirm') {
                      this.openModeler()
                    }
                  })
              })
            }
          } else {
            this.entityListLoading = false
          }
        })
    }
  }

  public openCreateClusterWarningTrial() {
    requestAnimationFrame(() => {
      this.warningTrialModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.openRequestPaidPlanUrl()
        }
      })
    })
  }

  public openCreateClusterWarningMember() {
    // Note: We just show a warning here, without any actions for the user.
    requestAnimationFrame(() => {
      this.warningMember.nativeElement.open()
    })
  }

  public openCreateClusterWarningAdmin() {
    requestAnimationFrame(() => {
      this.warningAdminModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.openReservations()
        }
      })
    })
  }

  public openModeler(modelUuid?: string) {
    if (modelUuid) {
      this.router.navigate([`org/${this.org.uuid}/modeler/${modelUuid}`])
    } else {
      this.router.navigate([`org/${this.org.uuid}/modeler`])
    }
  }

  public openClusterDetails(clusterUuid: string) {
    this.router.navigate([`org/${this.org.uuid}/cluster/${clusterUuid}`])
  }

  public openReservations() {
    this.router.navigate([`/org/${this.org.uuid}/settings/billing`])
  }

  public openRequestPaidPlanUrl() {
    this.mixpanel.track(FrontendAnalyticEvents.NAVBAR_REQUEST_PAID_PLAN_DIALOG)
    this.navbarService.openSwitchToProfessionalPlanCcModal()
  }

  private refreshClusterList() {
    this.clusterListEntities = this.clusters.map((cluster) => {
      let contextMenuOptions = []
      if (this.vvs.visibilities.cluster.details.menu.rename.visible) {
        contextMenuOptions.push({
          options: [
            {
              label: 'Rename',
              handler: () => this.openRenameModal(cluster),
              isDisabled: this.vvs.visibilities.cluster.details.menu.rename
                .disabled,
            },
          ],
        })
      }

      if (this.vvs.visibilities.cluster.details.menu.delete.visible) {
        contextMenuOptions.push({
          options: [
            {
              label: 'Delete',
              handler: () => this.openDeleteModal(cluster),
              isDangerous: true,
              isDisabled: this.vvs.visibilities.cluster.details.menu.delete
                .disabled,
            },
          ],
        })
      }

      return {
        onPress: () => {
          this.ngZone.run(() => {
            this.openClusterDetails(cluster.uuid)
          })
        },
        data: this.buildTableRowData(cluster, contextMenuOptions),
      }
    })
  }

  private buildTableRowData(cluster: ClusterDto, options) {
    const data = []

    data.push({ type: 'image', src: ListClustersComponent.CLUSTER_ICON })
    data.push({ type: 'text', content: cluster.name })
    data.push({ type: 'text', content: cluster.k8sContext.name })
    data.push({ type: 'text', content: cluster.generation.name })

    if (this.vvs.visibilities.cluster.details.clusterPlanType.visible) {
      data.push({
        type: 'text',
        content:
          cluster.planType.name +
          (cluster.plan ? ` (${cluster.plan.name})` : ''),
      })
    }

    data.push({
      type: 'image',
      src: ListClustersComponent.trafficLightForCluster(cluster),
    })

    data.push({ type: 'text', content: cluster.status.ready })

    if (options.length > 0) {
      data.push({
        type: 'contextMenu',
        options,
      })
    }

    return data
  }

  private openDeleteModal(cluster: ClusterDto) {
    this.mixpanel.track(
      FrontendAnalyticEvents.CLUSTER_LIST_DELETE_CLUSTER_DIALOG,
    )
    this.entityListLoading = true
    this.clusterToBeDeleted = cluster
    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_LIST_DELETE_CLUSTER_DIALOG_CONFIRMED,
          )

          // TODO: Remove after SSE Test
          if (environment.production) {
            this.clusterService
              .delete(this.org.uuid, this.clusterToBeDeleted.uuid)
              .subscribe((_) => {
                this.notificationService.enqueueNotification({
                  headline: 'Cluster deleted',
                  appearance: 'success',
                  showCreationTime: false,
                })
                const waitForIt = this.clusterService
                  .getClustersStatus()
                  .subscribe((newClusters) => {
                    if (
                      !newClusters.find(
                        (loadedCluster) =>
                          loadedCluster.uuid === this.clusterToBeDeleted.uuid,
                      )
                    ) {
                      waitForIt.unsubscribe()
                      this.entityListLoading = false
                    }
                  })
              })
          } else {
            this.entityListLoading = true
            await this.clusterService
              .delete(this.org.uuid, this.clusterToBeDeleted.uuid)
              .pipe(first())
              .toPromise()
            this.clusters = await this.clusterService
              .getClusters(this.org.uuid)
              .pipe(first())
              .toPromise()
            this.entityListLoading = false
          }
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.CLUSTER_LIST_DELETE_CLUSTER_DIALOG_CANCELED,
          )
          this.entityListLoading = false
        }
      })
    })
  }

  private openRenameModal(cluster: ClusterDto) {
    this.mixpanel.track(FrontendAnalyticEvents.CLUSTER_OVERVIEW_RENAME_CLUSTER)
    this.clusterToBeRenamed = cluster

    this.ngZone.run(() => {
      this.renameModal.nativeElement
        .open({
          preConfirmationHandler: (data) => {
            const newClusterName = (data.formData.newName as string).trim()

            return this.clusterService
              .rename(this.org.uuid, cluster.uuid, newClusterName)
              .toPromise()
              .then(() => {
                this.notificationService.enqueueNotification({
                  headline: 'Cluster renamed',
                  appearance: 'success',
                  showCreationTime: false,
                })

                this.clusters.find(
                  (clus) => clus.uuid === cluster.uuid,
                )!.name = newClusterName

                this.refreshClusterList()
              })
          },
        })
        .then((result) => {
          if (result.result === 'confirm') {
            this.mixpanel.track(
              FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_CONFIRMED,
            )
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.CLUSTER_OVERVIEW_RENAME_CLUSTER_CANCEL,
            )
          }
        })
    })
  }

  private static trafficLightForCluster(cluster: ClusterDto) {
    let color = '#icecream'

    if (cluster.status.ready === 'Healthy') {
      color = '#10d070'
    } else if (cluster.status.ready === 'Creating') {
      color = '#ffa533'
    } else {
      color = '#ff3d3d'
    }
    return `data:image/svg+xml;base64,${btoa(
      unescape(
        encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill="${color}">
                <rect id="Rectangle" x="0" y="0" width="12" height="12" rx="6"></rect>
            </g>
        </g>
    </svg>`),
      ),
    )}`
  }

  private static CLUSTER_ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" fill="#62626E">
      <path fill-rule="evenodd" d="M5.00024547,9.33666667 L9.28595975,11.8366667 L9.28595975,16.8366667 L5.00024547,19.3366667 L0.71453118,16.8366667 L0.71453118,11.8366667 L5.00024547,9.33666667 Z M15.0002455,9.33666667 L19.2859598,11.8366667 L19.2859598,16.8366667 L15.0002455,19.3366667 L10.7145312,16.8366667 L10.7145312,11.8366667 L15.0002455,9.33666667 Z M10,0.67 L14.2857143,3.17 L14.2857143,8.17 L10,10.67 L5.71428571,8.17 L5.71428571,3.17 L10,0.67 Z"/>
  </svg>
  `),
    ),
  )}`
}
