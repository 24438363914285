import { DatePipe } from '@angular/common'
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { DropdownOptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-dropdown/cm-dropdown'
import { first } from 'rxjs/operators'
import { BpmnFormDto } from '../../../../../commons/BpmnForm.dto'
import { ApiService } from '../../services/api.service'
import {
  Component as MixpanelComponent,
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
@Component({
  selector: 'list-bpmn-forms',
  templateUrl: './list-bpmn-forms.component.html',
  styleUrls: ['./list-bpmn-forms.component.scss'],
})
export class ListBpmnFormsComponent implements OnInit {
  public MIXPANEL_COMPONENT = MixpanelComponent.listBpmnForms
  public forms: BpmnFormDto[]

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('multiDeleteModal', { read: ElementRef })
  public multiDeleteModal: ElementRef<CmModal>

  @ViewChild('renameModal', { read: ElementRef })
  public renameModal: ElementRef<CmModal>

  public formListColumns = [
    { name: '', width: '14px' },
    { name: 'Name', width: '3fr', ellipsis: 'right' },
    { name: 'Updated By', width: '2fr', ellipsis: 'right' },
    { name: 'Updated At', width: 'minmax(200px, 2fr)' },
    { name: '', width: '20px' },
  ]
  public formListEntities = []
  public formListCreateHandler: () => void
  public formListLoading: boolean = true
  public groupOptions: DropdownOptionGroup[] = []

  public formToBeRenamed: BpmnFormDto
  public formToBeDeleted: BpmnFormDto
  public formsToBeDeleted: BpmnFormDto[]

  public currentOrg: OrganizationDto

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private mixpanel: MixpanelService,
    public vvs: ViewVisibilitiesService,
    private datePipe: DatePipe,
    private ngZone: NgZone,
  ) {}

  public ngOnInit() {
    this.currentOrg = this.route.snapshot.data.org
    this.forms = this.route.snapshot.data.forms

    this.formListCreateHandler = () => this.createNewForm()
    this.groupOptions = [
      {
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            handler: (event: any) => {
              this.openDeleteModals(
                event.detail.selectedEntities.map((entity) => entity.meta),
              )
            },
          },
        ],
      },
    ]

    this.updateList()
  }

  private updateList() {
    this.formListEntities = this.forms.map((form) => {
      return {
        meta: form.uuid,
        data: [
          { type: 'image', src: this.FORM_ICON },
          { type: 'text', content: form.name },
          {
            type: 'text',
            content: form.updatedBy,
          },
          {
            type: 'text',
            content: this.datePipe.transform(form.updated, 'medium'),
          },
          this.getContextMenu(form),
        ],
        onPress: () => {
          this.ngZone.run(() => {
            this.openFormDesigner(form.uuid)
          })
        },
      }
    })

    this.formListLoading = false
  }

  private getContextMenu(form: BpmnFormDto) {
    const contextMenu: { type: 'contextMenu'; options: any[] } = {
      type: 'contextMenu',
      options: [],
    }

    if (this.vvs.visibilities.forms.update.visible) {
      contextMenu.options.push({
        options: [
          {
            label: 'Rename',
            handler: () => {
              this.openRenameForm(form)
            },
          },
          {
            label: 'Export as JSON',
            handler: () => {
              this.download(form)
            },
          },
        ],
      })
    } else {
      contextMenu.options.push({
        options: [
          {
            label: 'Export as JSON',
            handler: () => {
              this.download(form)
            },
          },
        ],
      })
    }

    if (this.vvs.visibilities.forms.delete.visible) {
      contextMenu.options.push({
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            handler: () => {
              this.openDeleteModal(form)
            },
          },
        ],
      })
    }

    return contextMenu
  }

  public openDeleteModals(forms: string[]) {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_FORMS_DELETE_FORM, {
      amount: forms.length,
    })

    this.formsToBeDeleted = this.forms.filter((form) =>
      forms.includes(form.uuid),
    )

    this.ngZone.run(() => {
      this.multiDeleteModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.formListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_FORMS_DELETE_FORM_CONFIRMED,
            { amount: forms.length },
          )

          Promise.all(
            this.formsToBeDeleted.map((form) =>
              this.apiService
                .deleteBpmnForm(this.currentOrg.uuid, form.uuid)
                .pipe(first())
                .toPromise(),
            ),
          ).then(() => {
            this.loadForms()
          })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_FORMS_DELETE_FORM_CANCELED,
            { amount: forms.length },
          )
        }
      })
    })
  }

  public openDeleteModal(form: BpmnFormDto) {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_FORMS_DELETE_FORM)
    this.formToBeDeleted = form

    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.formListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_FORMS_DELETE_FORM_CONFIRMED,
          )
          this.apiService
            .deleteBpmnForm(this.currentOrg.uuid, form.uuid)
            .subscribe((_) => {
              this.loadForms()
            })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_FORMS_DELETE_FORM_CANCELED,
          )
        }
      })
    })
  }

  private openRenameForm(form: BpmnFormDto) {
    this.formToBeRenamed = form

    this.ngZone.run(() => {
      this.renameModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          await this.apiService
            .updateBpmnForm(
              this.currentOrg.uuid,
              result.formData.newName as string,
              form.uuid,
              form.form,
            )
            .pipe(first())
            .toPromise()
          this.loadForms()
        }
      })
    })
  }

  public openFormDesigner(formId: string) {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_FORMS_OPEN_FORM_DESIGNER, {
      formId,
    })
    this.router.navigate([`org/${this.currentOrg.uuid}/formdesigner/${formId}`])
  }

  public createNewForm() {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_FORMS_OPEN_FORM_DESIGNER)
    this.ngZone.run(() => {
      this.router.navigate([`org/${this.currentOrg.uuid}/formdesigner`])
    })
  }

  private loadForms() {
    this.apiService.listBpmnForms(this.currentOrg.uuid).subscribe((forms) => {
      this.forms = forms
      this.updateList()
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public download(form: BpmnFormDto) {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_FORMS_DOWNLOAD, {
      modelId: form.uuid,
    })
    const a = document.createElement('a')
    a.download = `${form.name}.json`
    a.href = window.URL.createObjectURL(
      new Blob([form.form], { type: 'application/json' }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }

  public navigateToClusterList() {
    this.router.navigate([`org/${this.currentOrg.uuid}`])
  }

  private FORM_ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg
      width="14"
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      fill="#62626E"
    >
      <path
        d="M8 0v5.5a.5.5 0 0 0 .41.492L8.5 6H14v11a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h7Zm1.5.5 4 4h-4v-4Z"
      />
    </svg>`),
    ),
  )}`
}
