<cm-page #cmPage>
  <div slot="header" class="clusterHeader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fill-rule="evenodd"
        d="M5.00024547,9.33666667 L9.28595975,11.8366667 L9.28595975,16.8366667 L5.00024547,19.3366667 L0.71453118,16.8366667 L0.71453118,11.8366667 L5.00024547,9.33666667 Z M15.0002455,9.33666667 L19.2859598,11.8366667 L19.2859598,16.8366667 L15.0002455,19.3366667 L10.7145312,16.8366667 L10.7145312,11.8366667 L15.0002455,9.33666667 Z M10,0.67 L14.2857143,3.17 L14.2857143,8.17 L10,10.67 L5.71428571,8.17 L5.71428571,3.17 L10,0.67 Z"
      />
    </svg>

    <h1>{{ clusterDetails.cluster.name }}</h1>
    <cm-dropdown
      slot="right"
      [options]="clusterDetailOptions"
      [trigger]="clusterDetailTrigger"
      *ngIf="vss.visibilities.cluster.details.menu.general.visible"
    >
    </cm-dropdown>
  </div>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.overview.visible"
    label="Overview"
  >
    <cluster-details
      [clusterData]="clusterDetails"
      [tabChangedTo]="tabChangedTo"
    ></cluster-details>
  </cm-page-tab>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.api.visible"
    label="API"
  >
    <cluster-clients [cluster]="clusterDetails.cluster"></cluster-clients>
  </cm-page-tab>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.applications.visible"
    label="Applications"
  >
    <cluster-applications
      [clusterData]="clusterDetails.cluster"
    ></cluster-applications>
  </cm-page-tab>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.metrics.visible"
    label="Metrics"
  >
    <cluster-metrics [cluster]="clusterDetails.cluster"></cluster-metrics>
  </cm-page-tab>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.alerts.visible && alertWorks()"
    label="Alerts"
  >
    <cluster-alerts [cluster]="clusterDetails.cluster"></cluster-alerts>
  </cm-page-tab>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.audits.visible"
    label="Activity"
  >
    <list-audit-logs
      [tabChangedTo]="tabChangedTo"
      [auditLogsResolver]="auditResolverService"
    ></list-audit-logs>
  </cm-page-tab>
  <cm-page-tab
    *ngIf="vss.visibilities.cluster.details.tabs.ipWhitelist.visible"
    label="IP Whitelist"
  >
    <ip-whitelist [cluster]="clusterDetails.cluster"></ip-whitelist>
  </cm-page-tab>
</cm-page>

<cm-modal
  #deleteModal
  headline='Delete Cluster "{{ cluster.name }}"'
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete the Cluster with Id
    <cm-text appearance="emphasis">{{ cluster.uuid }}</cm-text>?
  </cm-text>
</cm-modal>

<cm-modal
  #moveModal
  headline='Move Cluster "{{ cluster.name }}" to another organisation'
  cancelLabel="Cancel"
  confirmLabel="Move"
  confirmAppearance="danger"
>
  <cm-form>
    <cm-text>
      Cluster ID:
      <cm-text appearance="emphasis">{{ cluster.uuid }}</cm-text>
    </cm-text>
    <cm-select
      [options]="targetOrganizationOptions"
      [selectedOptions]="this.targetOrganization"
      label="Target Organisation"
      required
      formName="targetOrganization"
    >
    </cm-select>
  </cm-form>
</cm-modal>

<cm-modal
  #renameModal
  headline="Rename Cluster"
  cancelLabel="Cancel"
  confirmLabel="Rename"
>
  <cm-form>
    <cm-textfield
      label="Current Name"
      disabled
      [value]="cluster ? cluster.name : ''"
    ></cm-textfield>
    <cm-textfield
      label="New Name"
      maxlength="50"
      formName="newName"
      [fieldSuffix]="{ type: 'maxlength' }"
    ></cm-textfield>
  </cm-form>
</cm-modal>
