<h1>
  Generations
  <cm-button
    *ngIf="vss.visibilities.admin.generations.create.visible"
    (cmPress)="openCreateGenerationDialog()"
    label="Add Generation"
    appearance="primary"
  ></cm-button>
</h1>

<div class="cards">
  <cm-card *ngFor="let g of generations">
    <h4 slot="header">
      <cm-button
        appearance="link"
        label="{{ g.name }}"
        (cmPress)="routeToGeneration(g.uuid)"
      ></cm-button>
    </h4>

    <cm-text>
      Created on
      <cm-text appearance="emphasis">
        {{ g.created | date: 'MMMM d, y' }} at
        {{ g.created | date: 'hh:mm' }}
      </cm-text>
      <ng-container *ngIf="g.createdByName">
        by {{ g.createdByName }}
      </ng-container>
    </cm-text>

    <br />
    <br />

    <cm-datalist label-width="185px">
      <cm-datalist-item label="UUID">{{ g.uuid }}</cm-datalist-item>
      <cm-datalist-item label="Zeebe">{{ g.versions.zeebe }}</cm-datalist-item>
      <cm-datalist-item label="Operate">{{
        g.versions.operate
      }}</cm-datalist-item>
      <cm-datalist-item *ngIf="g.versions.tasklist" label="Tasklist">{{
        g.versions.tasklist
      }}</cm-datalist-item>
      <cm-datalist-item *ngIf="g.versions.optimize" label="Optimize">{{
        g.versions.optimize
      }}</cm-datalist-item>
      <cm-datalist-item *ngIf="g.versions.worker" label="Onboarding Worker">{{
        g.versions.worker
      }}</cm-datalist-item>
      <cm-datalist-item *ngIf="g.versions.metricHeap" label="Metric Heap">{{
        g.versions.metricHeap
      }}</cm-datalist-item>
      <cm-datalist-item
        *ngIf="g.versions.zeebeAnalytics"
        label="Zeebe Analytics Thingy"
        >{{ g.versions.zeebeAnalytics }}</cm-datalist-item
      >
      <cm-datalist-item label="ElasticSearch OSS">{{
        g.versions.elasticSearchOss
      }}</cm-datalist-item>
      <cm-datalist-item label="ElasticSearch Curator">{{
        g.versions.elasticSearchCurator
      }}</cm-datalist-item>
      <cm-datalist-item label="Cluster Count">{{
        g.clusterCount
      }}</cm-datalist-item>
    </cm-datalist>

    <ng-container *ngIf="g.upgradeableFrom && g.upgradeableFrom.length > 0">
      <br />
      <cm-text>
        Upgradable from {{ g.upgradeableFrom.length }} Generation<span
          *ngIf="g.upgradeableFrom.length > 1"
          >s</span
        >.
      </cm-text>
    </ng-container>
  </cm-card>
</div>

<cm-modal
  #createGenerationModal
  headline="Add Generation"
  cancelLabel="Cancel"
  confirmLabel="Add"
  width="750"
>
  <cm-form>
    <cm-select
      style="margin-bottom: 8px"
      label="Prepopulate from existing Generation"
      (cmInput)="prepopulateFromGeneration($event.detail.selectedOptions[0])"
      [options]="generationOptions"
    ></cm-select>

    <cm-textfield
      #nameTextfield
      [disabled]="!prepopulationGeneration"
      label="Name"
      formName="name"
      required
    ></cm-textfield>
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.zeebe
          ? prepopulationGeneration.versions.zeebe
          : ''
      "
      formName="zeebeVersion"
      label="Zeebe Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      required
    ></cm-textfield>
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.operate
          ? prepopulationGeneration.versions.operate
          : ''
      "
      formName="operateVersion"
      label="Operate Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      required
    ></cm-textfield>
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.tasklist
          ? prepopulationGeneration.versions.tasklist
          : ''
      "
      formName="tasklistVersion"
      label="Tasklist Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      required
    ></cm-textfield>
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.optimize
          ? prepopulationGeneration.versions.optimize
          : ''
      "
      formName="optimizeVersion"
      label="Optimize Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      showRequired
    ></cm-textfield>
    <!-- the worker is dead and we are not asking for it anymore - https://github.com/camunda-cloud/changes/issues/68
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.worker
          ? prepopulationGeneration.versions.worker
          : ''
      "
      formName="workerVersion"
      label="Onboarding Worker Image & Version (ignored!)"
      validationStyle="delay"
      [validation]="versionValidation"
    ></cm-textfield>
  -->
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.zeebeAnalytics
          ? prepopulationGeneration.versions.zeebeAnalytics
          : ''
      "
      formName="zeebeAnalyticsVersion"
      label="Zeebe Analytics Thingy Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      showRequired
    ></cm-textfield>
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.elasticSearchOss
          ? prepopulationGeneration.versions.elasticSearchOss
          : ''
      "
      formName="elasticOssVersion"
      label="ElasticSearch OSS Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      required
    ></cm-textfield>
    <cm-textfield
      [disabled]="!prepopulationGeneration"
      [value]="
        prepopulationGeneration?.versions?.elasticSearchCurator
          ? prepopulationGeneration.versions.elasticSearchCurator
          : ''
      "
      formName="elasticCuratorVersion"
      label="ElasticSearch Curator Image & Version"
      validationStyle="delay"
      [validation]="versionValidation"
      required
    ></cm-textfield>
    <cm-select
      label="Upgradable from"
      [options]="updatableOptions"
      [selectedOptions]="prepopulationUpgradeableFromGenerationUuids"
      allowMultiple
      required
      formName="updateable"
    ></cm-select>
  </cm-form>
</cm-modal>
