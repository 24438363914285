import { Component, ElementRef, NgZone, ViewChild } from '@angular/core'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import {
  Action,
  Component as AppComponent,
  FrontendAnalyticEvents,
  MixpanelEventFn,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { NavigationService } from '../../../services/navigation.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { TemplateCategories, TemplateCategoryEnum } from './template.constants'
import { Template, TemplateCategory } from './Template.types'

export interface SelectTemplateDialogOptions {
  orgId: string
  preselectedTemplateCategory?: TemplateCategoryEnum
}

export interface SelectTemplateDialogResponse {
  response: 'cancel' | 'blank' | 'template'
  selectedTemplate?: Template
}

@Component({
  selector: 'select-template-dialog',
  templateUrl: './select-template-dialog.component.html',
  styleUrls: ['./select-template-dialog.component.scss'],
})
export class SelectTemplateDialogComponent {
  public bpmnViewerId = 'templatepreview'

  @ViewChild('selectTemplateModal', { read: ElementRef })
  public modalRef: ElementRef<CmModal>

  public templateCategories: TemplateCategory[]
  public selectedTemplate: Template

  private dialogOptions: SelectTemplateDialogOptions
  private dialogResponse: SelectTemplateDialogResponse

  constructor(
    private ngZone: NgZone,
    private navigation: NavigationService,
    private mixpanel: MixpanelService,
    public vvs: ViewVisibilitiesService,
  ) {}

  public open(
    options: SelectTemplateDialogOptions,
  ): Promise<SelectTemplateDialogResponse> {
    this.dialogOptions = options
    const modalResultPromise = new Promise<SelectTemplateDialogResponse>(
      (resolve, _reject) => {
        requestAnimationFrame(() => {
          this.ngZone.run(async () => {
            await this.init(options.preselectedTemplateCategory)

            this.modalRef.nativeElement
              .open({
                preConfirmationHandler: () => {
                  return new Promise((resolve, _reject) => {
                    resolve()
                  })
                },
              })
              .then((result) => {
                if (
                  result.result !== 'confirm' &&
                  !this.vvs.visibilities.modeler.save.disabled &&
                  this.dialogResponse.response !== 'blank'
                ) {
                  this.dialogResponse.response = 'cancel'
                  this.mixpanel.track(
                    FrontendAnalyticEvents.TEMPLATE_PICKER_CANCEL,
                  )
                }

                resolve(this.dialogResponse)

                if (
                  result.result === 'confirm' &&
                  !this.vvs.visibilities.modeler.save.disabled
                ) {
                  setTimeout(() => {
                    this.navigation.navigateInApp({
                      dest: 'diagrams:template',
                      context: {
                        orgId: this.dialogOptions.orgId,
                        templateId: this.selectedTemplate.id,
                      },
                      mixpanel: {
                        event: MixpanelEventFn(
                          AppComponent.templatePicker,
                          `template:${this.selectedTemplate.id}`,
                          Action.open,
                        ),
                      },
                    })
                  }, 1500)
                }
              })
          })
        })
      },
    )

    return modalResultPromise
  }

  public createBlank() {
    this.dialogResponse = {
      response: 'blank',
    }
    this.modalRef.nativeElement.cancel()
    this.navigation.navigateInApp({
      dest: 'diagrams:new',
      context: { orgId: this.dialogOptions.orgId },
      mixpanel: {
        event: FrontendAnalyticEvents.TEMPLATE_PICKER_CREATE_BLANK,
      },
    })
  }

  // eslint-disable-next-line class-methods-use-this
  public expandCategory(category: TemplateCategory, expand: boolean = true) {
    category.expanded = expand
  }

  public selectTemplate(template: Template) {
    let selectedCategory: TemplateCategory
    this.templateCategories.forEach((category) => {
      category.selected = false
      category.templates.forEach((currentTemplate) => {
        if (currentTemplate === template) {
          currentTemplate.selected = true
          this.selectedTemplate = currentTemplate
          selectedCategory = category
        } else {
          currentTemplate.selected = false
        }
      })
    })

    if (selectedCategory) {
      selectedCategory.selected = true
    }

    if (this.selectedTemplate) {
      this.dialogResponse = {
        response: 'template',
        selectedTemplate: this.selectedTemplate,
      }
    }
  }

  private init(preselectedCategoryEnum?: TemplateCategoryEnum) {
    this.templateCategories = TemplateCategories()
    let preselectedCategory: TemplateCategory

    if (preselectedCategoryEnum) {
      preselectedCategory = this.templateCategories.find(
        (category) => category.category === preselectedCategoryEnum,
      )
    }

    if (!preselectedCategory) {
      preselectedCategory = this.templateCategories[0]
    }

    this.expandCategory(preselectedCategory)

    if (preselectedCategory.templates.length > 0) {
      this.selectTemplate(preselectedCategory.templates[0])
    }
  }
}
