import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import {
  CorpseResolverService,
  DatabaseCorpse,
} from '../../../resolver/corpse.resolver'
import { ApiService } from '../../../services/api.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'corpse-management-page',
  templateUrl: './corpse-management.page.html',
  styleUrls: ['./corpse-management.page.scss'],
})
export class CorpseManagementPage implements OnInit {
  public mOrgIdToName: Map<string, string> = new Map<string, string>()
  public databaseCorpses: DatabaseCorpse[] | undefined
  public k8sCorpses: ClusterDto[] | undefined
  public deletingDatabase: boolean = false
  public deletingK8s: boolean = false

  // public resourceServerAuth0Corpses: Auth0ResourceServerDto[] | undefined
  // public zeebeClientScopeCorpses: ZeebeClientScopeCorpses[] | undefined
  public deletingResourceServerAuth0: boolean = false
  public deletingZeebeClientScopeCorpses: boolean = false

  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public corpseResolver: CorpseResolverService,
    public modalService: ConfirmationModalService,
    public vss: ViewVisibilitiesService,
  ) {}

  public ngOnInit() {
    this.mOrgIdToName = this.route.snapshot.data.corpseData.mOrgIdToName
    this.databaseCorpses = this.route.snapshot.data.corpseData.databaseCorpses
    this.k8sCorpses = this.route.snapshot.data.corpseData.k8sCorpses

    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel

    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        let newUrlSegments = location.href.split('/')
        if (
          newUrlSegments.includes('kubernetes') ||
          newUrlSegments.includes('auth0') ||
          newUrlSegments.includes('zeebeclient')
        ) {
          newUrlSegments = newUrlSegments.splice(
            0,
            location.href.split('/').length - 1,
          )
        }
        switch (cmPage.activeLabel) {
          case 'Database Corpses': {
            history.replaceState(
              {},
              '',
              location.href
                .split('/')
                .splice(0, location.href.split('/').length - 1)
                .join('/'),
            )
            break
          }
          case 'Kubernetes Corpses': {
            newUrlSegments.push('kubernetes')
            history.replaceState({}, '', newUrlSegments.join('/'))
            break
          }
        }
      })
    })
  }

  public openDeleteModal(title: string, type: string) {
    switch (type) {
      case 'deletingDatabase':
        this.modalService.openModal({
          title: 'Delete All Database Corpses',
          body: 'Are you sure to delete all Database Corpses?',
          confirmButton: {
            text: 'Delete All Database Corpses',
            appearance: 'danger',
            action: () => {
              this.apiService.deleteClusterDatabaseCorpses().subscribe((_) => {
                this[type] = false
                this.refresh()
              })
            },
          },
          cancelButton: {
            text: 'Cancel',
            appearance: 'secondary',
            action: () => {},
          },
        })
        break
      case 'deletingK8s':
        this.modalService.openModal({
          title: 'Delete All Kubernetes Corpses',
          body: 'Are you sure to delete all Kubernetes Corpses?',
          confirmButton: {
            text: 'Delete All Kubernetes Corpses',
            appearance: 'danger',
            action: () => {
              this.apiService.deleteClusterK8sCorpses().subscribe((_) => {
                this[type] = false
                this.refresh()
              })
            },
          },
          cancelButton: {
            text: 'Cancel',
            appearance: 'secondary',
            action: () => {},
          },
        })
        break
      default:
    }
  }

  private refresh() {
    this.corpseResolver.resolve().subscribe((corpseData) => {
      this.mOrgIdToName = corpseData.mOrgIdToName
      this.databaseCorpses = corpseData.databaseCorpses
      this.k8sCorpses = corpseData.k8sCorpses
    })
  }
}
