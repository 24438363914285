import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { ApiService } from '../services/api.service'
import { MixpanelService } from '../services/mixpanel.service'
import { NavbarService } from '../services/navbar.service'

@Injectable({
  providedIn: 'root',
})
export class OrgResolverService implements Resolve<OrganizationDto> {
  constructor(
    private apiService: ApiService,
    private navbarService: NavbarService,
    private mixpanel: MixpanelService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<OrganizationDto> {
    if (route.paramMap.get('orgUuid')) {
      return this.apiService
        .getOrganization(route.paramMap.get('orgUuid'))
        .pipe(
          map((organization) => {
            this.navbarService.updateCurrentOrg(organization)
            return organization
          }),
        )
    } else {
      return of(this.navbarService.currentOrg)
    }
  }
}
