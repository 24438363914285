<div class="scrollContents">
  <div class="sections">
    <p>
      Created by {{ creator }} on
      {{ generation.created | date: 'MMMM d, y' }} at
      {{ generation.created | date: 'hh:mm' }}
    </p>
    <div class="section" *ngIf="generation.clusterCount > 0">
      <h2>Running Clusters for Generation</h2>
      <div
        style="
          display: grid;
          align-items: center;
          gap: 10px;
          grid-auto-flow: column;
          width: max-content;
        "
      >
        <cm-text>Count: {{ generation.clusterCount }}</cm-text>
        <cm-button
          *ngIf="vss.visibilities.admin.generations.cluster.delete.visible"
          appearance="danger"
          label="Delete all clusters"
          size="small"
          (cmPress)="deleteClusters()"
        ></cm-button>
      </div>
    </div>

    <div class="section">
      <h2>Generation details</h2>

      <cm-datalist label-width="170px">
        <cm-datalist-item label="UUID">
          {{ generationId }}
        </cm-datalist-item>
        <cm-datalist-item label="Zeebe">
          {{ generation.versions.zeebe }}
        </cm-datalist-item>
        <cm-datalist-item label="Operate">
          {{ generation.versions.operate }}
        </cm-datalist-item>
        <cm-datalist-item label="Tasklist">
          {{ generation.versions.tasklist }}
        </cm-datalist-item>
        <cm-datalist-item label="Optimize" *ngIf="generation.versions.optimize">
          {{ generation.versions.optimize }}
        </cm-datalist-item>
        <cm-datalist-item
          label="Onboarding Worker"
          *ngIf="generation.versions.worker"
        >
          {{ generation.versions.worker }}
        </cm-datalist-item>
        <cm-datalist-item
          label="Zeebe Analytics Things"
          *ngIf="generation.versions.zeebeAnalytics"
        >
          {{ generation.versions.zeebeAnalytics }}
        </cm-datalist-item>
        <cm-datalist-item label="ElasticSearch OSS">
          {{ generation.versions.elasticSearchOss }}
        </cm-datalist-item>
        <cm-datalist-item label="ElasticSearch Curator">
          {{ generation.versions.elasticSearchCurator }}
        </cm-datalist-item>
      </cm-datalist>
    </div>

    <div class="section">
      <h2 *ngIf="selectedUpgradeableGenerations">Upgradable from</h2>
      <h2 *ngIf="!selectedUpgradeableGenerations">
        No Upgradable from configured
      </h2>

      <div *ngIf="!otherGenerations || otherGenerations.length === 0">
        There are no more Generations available.
      </div>

      <ul>
        <li *ngFor="let g of selectedUpgradeableGenerations">
          <cm-button
            appearance="link"
            (cmPress)="goTo(g)"
            label="{{ getGenerationDetail(g).name }}"
          ></cm-button
          >&nbsp; <small>{{ getGenerationDetail(g).uuid }}</small
          ><br />
          Zeebe: {{ getGenerationDetail(g).versions.zeebe }}, Operate:
          {{ getGenerationDetail(g).versions.operate }}, Tasklist:
          {{ getGenerationDetail(g).versions.tasklist }}, Optimize:
          {{
            getGenerationDetail(g).versions.optimize
              ? getGenerationDetail(g).versions.optimize
              : 'no optimize'
          }}
        </li>
      </ul>

      <cm-button
        label="Configure Upgradeable From"
        appearance="primary"
        (cmPress)="openUpgradableModal()"
        *ngIf="vss.visibilities.admin.generations.update.visible"
      ></cm-button>
    </div>

    <div class="section" *ngIf="generation.clusterCount > 0">
      <h2>Running Clusters for Generation</h2>

      <div class="cards">
        <cm-card *ngFor="let cluster of currentClusters">
          <div slot="header">
            <h3>
              {{ cluster.name }} <small>({{ cluster.uuid }})</small>
            </h3>
          </div>

          <cm-datalist>
            <cm-datalist-item label="Organization">
              <ng-container *ngIf="!cluster.organization">
                No Organization attached
              </ng-container>
              <ng-container *ngIf="cluster.organization">
                {{ cluster.organization.name }}
                <small>({{ cluster.organization.uuid }})</small>
              </ng-container>
            </cm-datalist-item>
            <cm-datalist-item label="Cluster Plan">{{
              cluster.plan.name
            }}</cm-datalist-item>
            <cm-datalist-item label="Cluster Plan Type">{{
              cluster.planType.name
            }}</cm-datalist-item>
          </cm-datalist>
        </cm-card>
      </div>
    </div>

    <div
      class="section"
      *ngIf="
        currentUpgradeableGenerations.length > 0 &&
        vss.visibilities.admin.generations.cluster.migrate.visible
      "
    >
      <h2>Update all Clusters from selected Generation to this Generation</h2>

      <div *ngFor="let g of currentUpgradeableGenerations">
        <cm-button
          appearance="link"
          (cmPress)="goTo(g.uuid)"
          label="{{ g.name }}"
        ></cm-button
        >&nbsp; Clusters: {{ g.clusterCount }}
        <cm-button
          *ngIf="vss.visibilities.admin.generations.cluster.migrate.visible"
          appearance="secondary"
          size="small"
          (cmPress)="upgradeGenerationFrom(g.uuid)"
          label="Update all Clusters"
        ></cm-button>
        <div>
          <div *ngFor="let d of g.diff | keyvalue">
            <b>{{ d.key }}:</b> {{ d.value }}
          </div>
        </div>
      </div>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Update a specific Cluster to this Generation
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-card *ngFor="let c of upgradableClusters">
          <span *ngIf="c.organization && c.generation && c.planType && c.plan">
            <div>
              Cluster: <b>{{ c.name }}</b> uuid: <b>{{ c.uuid }}</b>
            </div>
            <div>
              Org: <b>{{ c.organization.name }}</b> - Generation:
              <b>{{ c.generation.name }}</b>
            </div>
            <div>
              Cluster Plan Type: <b>{{ c.planType.name }}</b> - Cluster Plan:
              <b>{{ c.plan.name }}</b>
            </div>
            <cm-button
              *ngIf="vss.visibilities.admin.generations.cluster.migrate.visible"
              style="float: right; margin-top: -60px"
              label="Update Cluster"
              appearance="secondary"
              size="small"
              (cmPress)="upgradeGenerationforCluster(c.uuid)"
            ></cm-button>
          </span>
          <span
            *ngIf="!c.organization || !c.generation || !c.planType || !c.plan"
          >
            <div style="background-color: red; color: white">
              Cluster: <b>{{ c.name }}</b>
            </div>
            <div style="background-color: red; color: white">
              uuid: <b>{{ c.uuid }}</b>
            </div>
            <div style="background-color: red; color: white">
              something is wrong with this cluster
            </div>
            <cm-button
              *ngIf="vss.visibilities.admin.generations.cluster.delete.visible"
              style="float: right; margin-top: -60px"
              appearance="danger"
              size="small"
              (cmPress)="deleteCluster(c)"
              label="Delete Cluster"
            >
            </cm-button>
          </span>
        </mat-card>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Update Clusters of a specific Sales Plan
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-card *ngFor="let sp of clustersBySalesPlans">
          <div>
            SalesPlan: <b>{{ sp.salesPlan.name }}</b>
          </div>
          <div>
            Clusters: <b>{{ sp.clusters.length }}</b>
          </div>
          <cm-button
            *ngIf="vss.visibilities.admin.generations.cluster.migrate.visible"
            style="float: right; margin-top: -40px"
            appearance="secondary"
            size="small"
            (cmPress)="upgradeGenerationforClusters(sp.clusters)"
            label="Update Clusters"
          ></cm-button>
        </mat-card>
      </mat-expansion-panel>
    </div>
  </div>
</div>

<div class="buttons">
  <cm-button label="Back" (cmPress)="goBack()"></cm-button>

  <cm-button
    label="Delete"
    appearance="danger"
    (cmPress)="deleteGeneration()"
    *ngIf="vss.visibilities.admin.generations.delete.visible"
  >
  </cm-button>
</div>

<cm-modal
  #updateUpgradable
  headline="Update Upgradable From"
  cancelLabel="Cancel"
  confirmLabel="Save"
  position="top"
>
  <cm-form>
    <cm-select
      label="Generations that should be upgradable to this one"
      [options]="updatableOptions"
      [selectedOptions]="selectedOptions"
      allowMultiple
      required
      formName="updateable"
    ></cm-select>
  </cm-form>
</cm-modal>
