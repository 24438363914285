import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CommonClusterDto } from '@camunda-cloud/cloud-node-libs'
import { ClusterDto } from '../../../../../commons/Cluster.dto'

@Component({
  selector: 'tasklist-operate-redirect',
  templateUrl: './tasklist-operate-redirect.component.html',
})
export class TasklistOperateRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  public ngOnInit() {
    const cluster: ClusterDto = this.route.snapshot.data.clusterDetails.cluster
    const param = this.route.snapshot.paramMap.get('tasklistOrOperate')
    switch (param) {
      case 'tasklist':
        // eslint-disable-next-line no-case-declarations
        const tasklistElements = window.location.href.split('/tasklist')
        if (tasklistElements.length === 2) {
          const optionalParams = tasklistElements[1]
          window.location.href = `${getTasklistUrl(cluster)}${optionalParams}`
        } else {
          window.location.href = getTasklistUrl(cluster)
        }
        break
      case 'operate':
        // eslint-disable-next-line no-case-declarations
        const operateElements = window.location.href.split('/operate')
        if (operateElements.length === 2) {
          const optionalParams = operateElements[1]
          window.location.href = `${getOperateUrl(cluster)}${optionalParams}`
        } else {
          window.location.href = getOperateUrl(cluster)
        }
        break
      case 'optimize':
        // eslint-disable-next-line no-case-declarations
        const optimizeElements = window.location.href.split('/optimize')
        if (tasklistElements.length === 2) {
          const optionalParams = tasklistElements[1]
          window.location.href = `${getOptimizeUrl(cluster)}${optionalParams}`
        } else {
          window.location.href = getOptimizeUrl(cluster)
        }
        break
      default:
        // eslint-disable-next-line no-case-declarations
        const url = this.route.snapshot.url
          .slice(0, -1)
          .map((elem) => elem.path)
        this.router.navigate(url)
        break
    }
  }
}
export function getOperateUrl(cluster: CommonClusterDto) {
  return cluster.status.operateUrl
}

export function getTasklistUrl(cluster: CommonClusterDto) {
  return cluster.status.tasklistUrl
}
export function getOptimizeUrl(cluster: CommonClusterDto) {
  return cluster.status.optimizeUrl
}
