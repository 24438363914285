import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { ClusterPlanDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { ClusterPlanApiService } from '../services/clusterPlan.api.service'

@Injectable({
  providedIn: 'root',
})
export class ClusterPlansResolverService implements Resolve<ClusterPlanDto[]> {
  constructor(private apiService: ClusterPlanApiService) {}
  public resolve(): Observable<ClusterPlanDto[]> {
    return this.apiService.getAll()
  }
}
