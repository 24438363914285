import { Component, OnInit } from '@angular/core'
import { GenerationDetailData } from '../../../resolver/generation.resolver'
import { ActivatedRoute } from '@angular/router'
import { GenerationDto } from '../../../../../../commons/Generation.dto'

@Component({
  selector: 'generation-details-page',
  templateUrl: './generation-details.page.html',
})
export class GenerationDetailsPage implements OnInit {
  public generation: GenerationDto & { clusterCount?: number }

  constructor(private route: ActivatedRoute) {}

  public ngOnInit() {
    const generationData = this.route.snapshot.data
      .generationData as GenerationDetailData

    this.generation = generationData.currentGeneration
  }
}
