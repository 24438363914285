<div class="items">
  <div
    class="item"
    *ngIf="vvs.visibilities.cluster.details.operate.launch.visible"
  >
    <h2>Operate</h2>

    <cm-text>
      Operate is a web application designed for the teams who monitor, manage,
      and troubleshoot running Process Instances. Check out our
      <cm-button
        appearance="link"
        (cmPress)="openOperateDocs()"
        label="Operate User guide"
      ></cm-button>
      for more information.
    </cm-text>

    <cm-button
      label="View Process Instances"
      (cmPress)="openOperate()"
      [disabled]="!vvs.visibilities.cluster.details.operate.launch.visible"
    ></cm-button>
  </div>

  <div
    class="item"
    *ngIf="vvs.visibilities.cluster.details.tasklist.launch.visible"
  >
    <h2>Tasklist</h2>

    <cm-text
      >Tasklist is a web application that allows end users to work on the Tasks
      assigned to them. Tasklist provides additional visibility when using the
      Camunda Cloud Workflow Engine for Human Task Management. Check out our
      <cm-button
        appearance="link"
        (cmPress)="openTasklistDocs()"
        label="Tasklist User Guide"
      ></cm-button>
      for more information
    </cm-text>

    <cm-button
      label="View User Tasks"
      (cmPress)="openTasklist()"
      [disabled]="!vvs.visibilities.cluster.details.tasklist.launch.visible"
    ></cm-button>
  </div>

  <div
    class="item"
    *ngIf="
      clusterData.hasOptimize &&
      vvs.visibilities.cluster.details.optimize.launch.visible
    "
  >
    <h2>Optimize</h2>
    <cm-text>
      Camunda Optimize supports continuous process improvement by providing
      transparency in your automated workflows. Business-friendly reports,
      dashboards, and alerts help you identify process bottlenecks and improve
      your overall end-to-end process. Check out our
      <cm-button
        appearance="link"
        label="Optimize User Guide"
        (cmPress)="openOptimizeDocs()"
      ></cm-button>
      for more information.
    </cm-text>

    <cm-button
      label="Optimize"
      (cmPress)="openOptimize()"
      [disabled]="!vvs.visibilities.cluster.details.optimize.launch.visible"
    ></cm-button>
  </div>
</div>
