import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'cluster-plan-tree-element',
  templateUrl: './cluster-plan-tree-element.component.html',
  styleUrls: ['./cluster-plan-tree-element.component.scss'],
})
export class ClusterPlanTreeElementComponent {
  @Input() public element: string
  @Input() public plan: any
  @Output() public planChange = new EventEmitter<any>()

  public validation
  constructor() {
    this.validation = {
      type: 'custom',
      validator: (input) => {
        const isValid = this.plan.validator(input)
        if (isValid) {
          this.plan.value = input
        }
        return {
          isValid,
          message: !isValid ? `does not pass validation` : undefined,
        }
      },
    }
  }

  public checkboxChecked(checked: boolean) {
    this.plan.value = checked
  }
}
