const eventTypes = [
  'tokenSimulation.toggleMode',
  'tokenSimulation.playSimulation',
  'tokenSimulation.pauseSimulation',
  'tokenSimulation.resetSimulation',
  'tokenSimulation.animationCreated',
  'tokenSimulation.animationSpeedChanged',
  'tokenSimulation.scopeFilterChanged',
]

export const TokenSimulatorTracking = (
  mixpanelTracking: (eventType: string) => void,
) => {
  return {
    __init__: [
      [
        'eventBus',
        function (eventBus) {
          for (let eventType of eventTypes) {
            eventBus.on(eventType, (_event) => {
              mixpanelTracking(eventType)
            })
          }
        },
      ],
    ],
  }
}
