import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router'
import { ClusterService } from '../services/cluster.service'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { ClusterDto } from '../../../../commons/Cluster.dto'

@Injectable({
  providedIn: 'root',
})
export class ClustersResolverService implements Resolve<ClusterDto[]> {
  constructor(private clusterService: ClusterService) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<ClusterDto[]> {
    return this.clusterService.getClusters(route.paramMap.get('orgUuid'))
  }
}
