<cm-modal
  #modal
  [headline]="currentModalOptions.title"
  [cancelLabel]="currentModalOptions.cancelButton.text"
  [cancelAppearance]="currentModalOptions.cancelButton.appearance"
  [confirmLabel]="currentModalOptions.confirmButton.text"
  [confirmAppearance]="currentModalOptions.confirmButton.appearance"
  [confirmDisabled]="currentModalOptions.confirmButton.disabled"
>
  <cm-text>
    {{ currentModalOptions.body }}
  </cm-text>
</cm-modal>
