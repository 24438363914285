import { Component, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import {
  ClusterPlanDto,
  OrganizationDto,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import {
  ClusterPlanDetailData,
  ExtendedClusterPlanDto,
} from '../../../resolver/cluster-plan-details.resolver'
import { ClusterPlanApiService } from '../../../services/clusterPlan.api.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { UserService } from '../../../services/user.service'

import { NotificationService } from '../../../services/notification.service'

interface ClusterWithOrgData extends ClusterDto {
  organization: OrganizationDto | undefined
}
interface ClustersToSalesPlans {
  salesPlan: SalesPlanDto
  clusters: ClusterDto[]
}

@Component({
  selector: 'cluster-plan-details',
  styleUrls: ['./cluster-plan-details.component.scss'],
  templateUrl: './cluster-plan-details.component.html',
})
export class ClusterPlanDetailsComponent implements OnInit {
  public clusterPlan: ExtendedClusterPlanDto
  public creator: string
  public otherClusterPlans: ClusterPlanDto[]
  public selectedUpgradeablePlans: string[] = []
  public deleteAllClustersQuestion: boolean = false
  public planName = new FormControl('', [Validators.required])
  public upgradableClusters: ClusterWithOrgData[] = []
  public clustersBySalesPlans: ClustersToSalesPlans[]

  constructor(
    private route: ActivatedRoute,
    private clusterPlanApiService: ClusterPlanApiService,
    private router: Router,
    public vvs: ViewVisibilitiesService,
    private modalService: ConfirmationModalService,
    private userService: UserService,

    private notificationService: NotificationService,
  ) {}

  public ngOnInit() {
    const clusterPlanDetailData = this.route.snapshot.data
      .clusterPlanData as ClusterPlanDetailData
    this.clusterPlan = clusterPlanDetailData.clusterPlan
    if (this.clusterPlan.createdBy) {
      this.userService.getUserById(this.clusterPlan.createdBy).subscribe(
        (creatorData) => {
          this.creator = `${creatorData.name} (${creatorData.email})`
        },
        (_error) => {
          this.creator = `unknown user with id ${this.clusterPlan.createdBy}`
        },
      )
    }
    this.otherClusterPlans = clusterPlanDetailData.otherPlans
    this.selectedUpgradeablePlans = this.clusterPlan.migratableFrom

    this.upgradableClusters = clusterPlanDetailData.upgradableClusters.map(
      (cluster) => {
        return {
          ...cluster,
          organization: clusterPlanDetailData.allOrganizations.find(
            (organization) => organization.uuid === cluster.ownerId,
          ),
        }
      },
    )
    const salesPlans = clusterPlanDetailData.salesPlans
    this.clustersBySalesPlans = salesPlans
      .map((salesPlan) => {
        const clusters = this.upgradableClusters.filter(
          (cluster) =>
            cluster.organization &&
            cluster.organization!.organizationToSalesPlan!.salesPlan!.uuid ===
              salesPlan.uuid,
        )
        return { clusters, salesPlan }
      })
      .filter((salesPlan) => salesPlan.clusters.length > 0)
  }

  public save(uuid: string) {
    this.clusterPlanApiService
      .setMigratableFrom(uuid, this.selectedUpgradeablePlans)
      .subscribe((_) => {
        this.router.navigate([
          `admin/cluster-plan/plans/${this.clusterPlan.uuid}`,
        ])
      })
  }

  public migratePlan(oldPlanUuid: string, newPlanUuid: string) {
    this.modalService.openModal({
      title: 'Migrate plan',
      body: `Are you sure you want to migrate oldPlan ${oldPlanUuid} to newPlan ${newPlanUuid}?`,
      confirmButton: {
        text: 'Migrate Plan',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService
            .migratePlan(oldPlanUuid, newPlanUuid)
            .subscribe((_) => {
              this.router.navigate([
                `admin/cluster-plan/plans/${this.clusterPlan.uuid}`,
              ])
            })
        },
      },
      cancelButton: {
        text: 'Cancel Migration',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public migrateClusters(clusters: ClusterDto[]) {
    this.modalService.openModal({
      title: 'Migrate all clusters',
      body: `Are you sure you want to migrate all ${clusters.length} clusters to clusterplan ${this.clusterPlan.name}?`,
      confirmButton: {
        text: 'Migrate all clusters',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService
            .migrateClusters(
              clusters.map((cluster) => cluster.uuid),
              this.clusterPlan.uuid,
            )
            .subscribe((_) => {
              this.router.navigate([
                `admin/cluster-plan/plans/${this.clusterPlan.uuid}`,
              ])
            })
        },
      },
      cancelButton: {
        text: 'Cancel Migration',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public checkIfDeleteAllClusters() {
    this.planName.reset()
    this.deleteAllClustersQuestion = true
  }

  public cancelDeleteAllClusters() {
    this.planName.reset()
    this.deleteAllClustersQuestion = false
  }

  public deleteAllClusters(planUuid: string) {
    this.modalService.openModal({
      title: 'Delete all clusters',
      body: `Are you sure you want to delete all clusters of clusterplan ${this.clusterPlan.name}?`,
      confirmButton: {
        text: 'Delete All Clusters',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService
            .deleteClustersOfPlan(planUuid)
            .subscribe((_) => {
              this.deleteAllClustersQuestion = false
              this.notificationService.enqueueNotification({
                headline: 'Clusterplan deleted',
                description: `${this.clusterPlan.name} is no longer among us`,
                appearance: 'success',
              })
              this.planName.reset()
              this.router.navigate([
                `admin/cluster-plan/plans/${this.clusterPlan.uuid}`,
              ])
            })
        },
      },
      cancelButton: {
        text: 'Cancel Deletion',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public delete(clusterPlanUuid: string) {
    this.modalService.openModal({
      title: 'Delete Cluster Plan',
      body: `Are you sure to delete Cluster Plan ${this.clusterPlan.name}?`,
      confirmButton: {
        text: 'Delete Cluster Plan',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService.delete(clusterPlanUuid).subscribe((_) => {
            this.router.navigate(['admin/cluster-plan/plans'])
          })
        },
      },
      cancelButton: {
        text: `Cancel Cluster Plan Deletion`,
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public goBack() {
    this.router.navigate(['admin/cluster-plan/plans'])
  }

  // eslint-disable-next-line class-methods-use-this
  public downloadJson(clusterPlan: ClusterPlanDto) {
    const a = document.createElement('a')
    a.download = `${clusterPlan.name}.json`
    a.href = window.URL.createObjectURL(
      new Blob([JSON.stringify(clusterPlan.plan)], {
        type: 'application/json',
      }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }
}
