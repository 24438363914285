/* eslint-disable class-methods-use-this */

// Require your custom property entries.
import Select from '@bpmn-io/properties-panel/lib/components/entries/Select'
import { BpmnFormDto } from '../../../../../../commons/BpmnForm.dto'

let LOW_PRIORITY = 500

export default function createFormSelector(injectedForms: BpmnFormDto[]) {
  class FormSelectorProvider {
    constructor(propertiesPanel, injector) {
      propertiesPanel.registerProvider(LOW_PRIORITY, this)
      ;(this as any)._injector = injector
    }

    getGroups(_element) {
      return (groups) => {
        const formGroup = groups.find((group) => group.id === 'form')
        if (formGroup) {
          let options = injectedForms.map((form) => {
            return { label: form.name, value: form.form }
          })
          let formEntry = options[0]

          const selectComponent = Select({
            id: 'form-select',
            label: 'SELECT FORM',
            onChange: (_value) => {},
            options,
            value: formEntry.value,
            getValue: () => {
              return formEntry.value
            },
            setValue: (value) => {
              const formInputDomElement: any = document.getElementById(
                'bio-properties-panel-formConfiguration',
              )
              let event = new CustomEvent('input', {})
              formInputDomElement.value = value
              formInputDomElement.dispatchEvent(event)
            },
            getOptions: () => {
              return options
            },
          })
          formGroup.entries.push({
            id: 'formSelector',
            component: selectComponent,
          })
        }
        return groups
      }
    }
  }
  return FormSelectorProvider
}
