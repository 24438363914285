import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import {
  TimeFrame,
  ZeebeMetrics,
  ZeebeMetricType,
} from '../../../../commons/ZeebeMetrics.dto'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class ClusterMetricsService {
  constructor(private http: HttpClient) {}

  public get(
    currentOrgId: string,
    clusterId: string,
    timeframe: TimeFrame,
    metricType: ZeebeMetricType,
  ): Observable<ZeebeMetrics> {
    return this.http
      .get<ZeebeMetrics>(
        `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}/metrics/${metricType}/${timeframe}`,
      )
      .pipe(first())
  }
}
