<cm-page #cmPage>
  <cm-page-tab label="Diagrams" *ngIf="activeLabel === 'Diagrams'">
    <list-bpmn-models></list-bpmn-models>
  </cm-page-tab>
  <cm-page-tab label="Forms" *ngIf="activeLabel === 'Forms'">
    <list-bpmn-forms></list-bpmn-forms>
  </cm-page-tab>
  <cm-page-tab label="Clusters" *ngIf="activeLabel === 'Clusters'">
    <list-clusters></list-clusters>
  </cm-page-tab>
  <cm-page-tab label="Connectors" *ngIf="activeLabel === 'Connectors'">
    <list-connectors></list-connectors>
  </cm-page-tab>
</cm-page>
