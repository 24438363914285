import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'cluster-plan-tree',
  templateUrl: './cluster-plan-tree.component.html',
  styleUrls: ['./cluster-plan-tree.component.scss'],
})
export class ClusterPlanTreeComponent {
  @Input() public plan: any
  @Output() public planChange = new EventEmitter<any>()

  constructor() {}
}
