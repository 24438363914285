import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Resolve } from '@angular/router'
import { AuditDto } from '@camunda-cloud/cloud-node-libs'
import { BehaviorSubject, forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Features } from '../../../../../../commons/Features'
import { ApiService } from '../../../services/api.service'
import { OrgService } from '../../../services/org.service'

@Component({
  selector: 'list-audit-logs',
  templateUrl: './list-audit-logs.component.html',
  styleUrls: ['./list-audit-logs.component.scss'],
})
export class ListAuditLogsComponent implements OnInit {
  public activatedFeatures: Features
  public auditLogs: AuditDto[] = []
  public auditLogsToday: AuditDto[] = []
  public auditLogsYesterday: AuditDto[] = []
  public auditLogsLater: AuditDto[] = []
  public auditLogsLaterTitle = ''

  @Input() tabChangedTo: BehaviorSubject<string> = new BehaviorSubject('')
  @Input() auditLogsResolver: Resolve<AuditDto[]>

  constructor(
    private route: ActivatedRoute,
    private orgService: OrgService,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.auditLogs = this.route.snapshot.data.auditLogs
    this.updateAuditLog()

    this.tabChangedTo.subscribe((tab) => {
      if (tab === 'activity') {
        ;(this.auditLogsResolver.resolve(
          this.route.snapshot,
          null,
        ) as Observable<AuditDto[]>).subscribe((auditLog) => {
          this.auditLogs = auditLog
          this.updateAuditLog()
        })
      }
    })
  }

  private updateAuditLog() {
    this.activatedFeatures = this.route.snapshot.data.activatedFeatures
    const orgId = this.route.snapshot.data.org.uuid

    forkJoin([
      this.orgService.getMembers(orgId),
      this.apiService.listApiClients(orgId),
    ])
      .pipe(
        map(([members, apiClients]) => {
          this.auditLogs.forEach((logline) => {
            const foundMember = members.find(
              (member) => member.userId === logline.userId,
            )

            if (foundMember) {
              logline.userId = foundMember.email
            } else {
              const foundClient = apiClients.find(
                (apiClient) => apiClient.uuid === logline.userId,
              )

              if (foundClient) {
                logline.userId = foundClient.name
              } else {
                logline.userId = 'unknown user'
              }
            }

            this.splitAuditLogs()
          })
        }),
      )
      .subscribe()
  }

  private splitAuditLogs() {
    const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
    const yesterday = today - 1000 * 60 * 60 * 24

    this.auditLogsToday = this.auditLogs.filter(
      (log) => log.timestamp && log!.timestamp >= today,
    )

    this.auditLogsYesterday = this.auditLogs.filter(
      (log) =>
        log.timestamp && log!.timestamp >= yesterday && log!.timestamp < today,
    )

    this.auditLogsLater = this.auditLogs.filter(
      (log) => log.timestamp && log!.timestamp < yesterday,
    )

    if (this.auditLogsToday.length > 0 || this.auditLogsYesterday.length > 0) {
      this.auditLogsLaterTitle = 'Older'
    } else {
      this.auditLogsLaterTitle = 'Activity'
    }
  }
}
