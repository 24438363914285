import { ClusterPlanTypeDto } from '@camunda-cloud/cloud-node-libs'
import { HardwareParameter } from '../reservations.resolver'

export class HardwareParameterUtils {
  public static getHardwareParameter(
    clusterPlan: ClusterPlanTypeDto,
  ): HardwareParameter {
    const zeebe = HardwareParameterUtils.getZeebeHardwareParameter(clusterPlan)
    const operate = HardwareParameterUtils.getOperateHardwareParameter(
      clusterPlan,
    )
    const elastic = HardwareParameterUtils.getElasticHardwareParameter(
      clusterPlan,
    )
    const tasklist = HardwareParameterUtils.getTasklistHardwareParameter(
      clusterPlan,
    )

    const hardware: HardwareParameter = {
      cpu: zeebe.cpu + operate.cpu + elastic.cpu + tasklist.cpu,
      memory: zeebe.memory + operate.memory + elastic.memory + tasklist.memory,
      storage:
        zeebe.storage + operate.storage + elastic.storage + tasklist.storage,
    }

    return hardware
  }

  public static getZeebeHardwareParameter(
    clusterPlan: ClusterPlanTypeDto,
  ): HardwareParameter {
    const hardware: HardwareParameter = {
      cpu: 0,
      memory: 0,
      storage: 0,
    }

    if (
      clusterPlan.activePlan.plan.zeebe &&
      clusterPlan.activePlan.plan.zeebe.broker
    ) {
      const multiplicator = clusterPlan.activePlan.plan.zeebe.broker.clusterSize
        ? clusterPlan.activePlan.plan.zeebe.broker.clusterSize
        : 0
      if (
        clusterPlan.activePlan.plan.zeebe.broker.resources &&
        clusterPlan.activePlan.plan.zeebe.broker.resources.limits
      ) {
        hardware.cpu +=
          multiplicator *
          HardwareParameterUtils.convert(
            clusterPlan.activePlan.plan.zeebe.broker.resources.limits.cpu,
          )
        hardware.memory +=
          multiplicator *
          HardwareParameterUtils.convert(
            clusterPlan.activePlan.plan.zeebe.broker.resources.limits.memory,
          )
      }
      if (
        clusterPlan.activePlan.plan.zeebe.broker.storage &&
        clusterPlan.activePlan.plan.zeebe.broker.storage.resources &&
        clusterPlan.activePlan.plan.zeebe.broker.storage.resources.requests
      ) {
        hardware.storage +=
          multiplicator *
          HardwareParameterUtils.convert(
            clusterPlan.activePlan.plan.zeebe.broker.storage.resources.requests
              .storage,
          )
      }
    }

    return hardware
  }

  public static getOperateHardwareParameter(
    clusterPlan: ClusterPlanTypeDto,
  ): HardwareParameter {
    const hardware: HardwareParameter = {
      cpu: 0,
      memory: 0,
      storage: 0,
    }

    let multiplicator =
      clusterPlan.activePlan.plan.operate &&
      clusterPlan.activePlan.plan.operate.replicas
        ? Number(clusterPlan.activePlan.plan.operate.replicas)
        : 2
    multiplicator++ // increase multiplicator by 1 for importer
    if (
      clusterPlan.activePlan.plan.operate &&
      clusterPlan.activePlan.plan.operate.backend &&
      clusterPlan.activePlan.plan.operate.backend.resources &&
      clusterPlan.activePlan.plan.operate.backend.resources.limits
    ) {
      hardware.cpu +=
        multiplicator *
        HardwareParameterUtils.convert(
          clusterPlan.activePlan.plan.operate.backend.resources.limits.cpu,
        )
      hardware.memory +=
        multiplicator *
        HardwareParameterUtils.convert(
          clusterPlan.activePlan.plan.operate.backend.resources.limits.memory,
        )
    }

    return hardware
  }

  public static getElasticHardwareParameter(
    clusterPlan: ClusterPlanTypeDto,
  ): HardwareParameter {
    const hardware: HardwareParameter = {
      cpu: 0,
      memory: 0,
      storage: 0,
    }

    const multiplicator =
      clusterPlan.activePlan.plan.operate &&
      clusterPlan.activePlan.plan.operate.elasticsearch &&
      clusterPlan.activePlan.plan.operate.elasticsearch.replicationFactor
        ? Number(
            clusterPlan.activePlan.plan.operate.elasticsearch.replicationFactor,
          )
        : 1

    if (
      clusterPlan.activePlan.plan.operate &&
      clusterPlan.activePlan.plan.operate.elasticsearch
    ) {
      if (
        clusterPlan.activePlan.plan.operate.elasticsearch.resources &&
        clusterPlan.activePlan.plan.operate.elasticsearch.resources.limits
      ) {
        hardware.cpu +=
          multiplicator *
          HardwareParameterUtils.convert(
            clusterPlan.activePlan.plan.operate.elasticsearch.resources.limits
              .cpu,
          )
        hardware.memory +=
          multiplicator *
          HardwareParameterUtils.convert(
            clusterPlan.activePlan.plan.operate.elasticsearch.resources.limits
              .memory,
          )
      }
      hardware.storage +=
        multiplicator *
        HardwareParameterUtils.convert(
          clusterPlan.activePlan.plan.operate.elasticsearch
            .persistentStorageCapacity,
        )
    }

    return hardware
  }

  public static getTasklistHardwareParameter(
    clusterPlan: ClusterPlanTypeDto,
  ): HardwareParameter {
    const hardware: HardwareParameter = {
      cpu: 0,
      memory: 0,
      storage: 0,
    }

    let multiplicator =
      clusterPlan.activePlan.plan.tasklist &&
      clusterPlan.activePlan.plan.tasklist.replicas
        ? Number(clusterPlan.activePlan.plan.tasklist.replicas)
        : 1
    multiplicator++ // increase multiplicator by 1 for importer

    if (
      clusterPlan.activePlan.plan.tasklist &&
      clusterPlan.activePlan.plan.tasklist.backend &&
      clusterPlan.activePlan.plan.tasklist.backend.resources &&
      clusterPlan.activePlan.plan.tasklist.backend.resources.limits
    ) {
      hardware.cpu +=
        multiplicator *
        HardwareParameterUtils.convert(
          clusterPlan.activePlan.plan.tasklist.backend.resources.limits.cpu,
        )
      hardware.memory +=
        multiplicator *
        HardwareParameterUtils.convert(
          clusterPlan.activePlan.plan.tasklist.backend.resources.limits.memory,
        )
    }

    return hardware
  }

  public static convert(value: string): number {
    if (!value) {
      return 0
    }

    const conversion: Array<{ unit: string; value: number }> = [
      {
        unit: 'GiB',
        value: 1,
      },
      {
        unit: 'Gi',
        value: 1,
      },
      {
        unit: 'MiB',
        value: 1024,
      },
      {
        unit: 'Mi',
        value: 1024,
      },
      {
        unit: 'm',
        value: 1000,
      },
    ]

    for (const item of conversion) {
      if (value.includes(item.unit)) {
        return Number(value.split(item.unit)[0].trim()) / item.value
      }
    }

    if (!isNaN(Number(value))) {
      return Number(value)
    }
    return 0
  }
}
