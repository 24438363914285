import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { createFormEditor, FormEditor } from '@bpmn-io/form-js'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { first } from 'rxjs/operators'
import { BpmnFormDto } from '../../../../../commons/BpmnForm.dto'
import { ApiService } from '../../services/api.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { NavbarService } from '../../services/navbar.service'
import { NotificationService } from '../../services/notification.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
@Component({
  selector: 'bpmn-form-designer',
  templateUrl: './bpmn-form-designer.component.html',
  styleUrls: ['./bpmn-form-designer.component.scss'],
})
export class BpmnFormDesignerComponent implements OnInit {
  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('importFailedModal', { static: true, read: ElementRef })
  public importFailedModal: ElementRef<CmModal>

  @ViewChild('renameModal', { read: ElementRef })
  public renameModal: ElementRef<CmModal>

  @ViewChild('fileInput', { static: true, read: ElementRef })
  public fileInput: ElementRef

  public forms: BpmnFormDto[]
  public existingForm: BpmnFormDto = undefined
  private currentOrg: OrganizationDto
  public dropdownOptions = []
  public dropdownTrigger = {
    type: 'icon',
    icon: 'contextMenu',
  }

  public formWasImported = false

  public bpmnFormDesigner: FormEditor

  public defaultSchema = {
    components: [],
    type: 'default',
    id: 'default1',
    path: [],
  }

  public formName: string

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private mixpanel: MixpanelService,
    private notificationService: NotificationService,
    private navbarService: NavbarService,
    public vvs: ViewVisibilitiesService,
    private ngZone: NgZone,
  ) {
    this.dropdownOptions = []
    if (this.vvs.visibilities.forms.update.visible) {
      this.dropdownOptions.push({
        options: [
          {
            label: 'Rename',
            isDisabled: this.vvs.visibilities.modeler.save.disabled,
            handler: () => {
              this.openRenameModal()
            },
          },
          {
            label: 'Export as JSON',
            handler: () => {
              this.exportAsJson()
            },
          },
        ],
      })
    } else {
      this.dropdownOptions.push({
        options: [
          {
            label: 'Export as JSON',
            handler: () => {
              this.exportAsJson()
            },
          },
        ],
      })
    }

    if (vvs.visibilities.forms.delete.visible) {
      this.dropdownOptions.push({
        options: [
          {
            label: 'Delete',
            isDisabled: this.vvs.visibilities.modeler.delete.disabled,
            isDangerous: true,
            handler: () => {
              this.openDeleteModal()
            },
          },
        ],
      })
    }
  }

  private initialiseFormName() {
    let name = 'New Form'

    if (this.route.snapshot.data.form) {
      name = this.route.snapshot.data.form.name
    }

    this.formName = name
  }

  public ngOnInit() {
    this.currentOrg = this.route.snapshot.data.org
    this.existingForm = this.route.snapshot.data.form

    this.initialiseFormName()

    this.forms = this.route.snapshot.data.forms

    if (!this.existingForm) {
      createFormEditor({
        container: document.querySelector('#form'),
        schema: this.defaultSchema,
      }).then((formDesigner) => {
        this.bpmnFormDesigner = formDesigner
      })
    } else {
      createFormEditor({
        container: document.querySelector('#form'),
        schema: JSON.parse(this.existingForm.form),
      }).then((formDesigner) => {
        this.bpmnFormDesigner = formDesigner
        this.navbarService.announceNewUuidToName(
          this.existingForm.uuid,
          this.existingForm.name,
        )
      })
    }
  }

  public async saveForm(options: { confirmation: boolean }) {
    this.mixpanel.track(
      FrontendAnalyticEvents.FORM_DESIGNER_SAVE,
      this.existingForm ? { formId: this.existingForm.uuid } : {},
    )

    const formName = this.formName
    const form = this.bpmnFormDesigner.getSchema()
    const formString = JSON.stringify(form)

    if (this.existingForm) {
      try {
        await this.apiService
          .updateBpmnForm(
            this.currentOrg.uuid,
            formName,
            this.existingForm.uuid,
            formString,
          )
          .pipe(first())
          .toPromise()

        this.existingForm.name = formName
        this.existingForm.form = formString
      } catch (error) {
        this.mixpanel.track(FrontendAnalyticEvents.FORM_DESIGNER_SAVE_ERROR, {
          formId: this.existingForm.uuid,
        })

        this.notificationService.enqueueNotification({
          headline: `Form ${this.existingForm.name} could not be saved`,
          appearance: 'error',
          description:
            'This form was changed while you were editing it. Please reload the Diagram and try again.',
        })

        return false
      }
    } else {
      const createdForm = await this.apiService
        .createBpmnForm(this.currentOrg.uuid, formName, formString)
        .pipe(first())
        .toPromise()

      this.existingForm = {
        uuid: createdForm.uuid,
        form: formString,
        name: formName,
        ownerId: this.currentOrg.uuid,
        created: new Date(),
      }

      if (options.confirmation) {
        this.router.navigate([
          `org/${this.currentOrg.uuid}/formdesigner/${this.existingForm.uuid}`,
        ])
      }

      this.navbarService.announceNewUuidToName(
        this.existingForm.uuid,
        this.existingForm.name,
      )
    }

    if (options.confirmation) {
      this.notificationService.enqueueNotification({
        headline: `Form saved`,
        appearance: 'success',
        showCreationTime: false,
      })
    }

    this.navbarService.announceNewUuidToName(
      this.existingForm.uuid,
      this.existingForm.name,
    )

    return true
  }

  public exportAsJson() {
    const form = this.bpmnFormDesigner.getSchema()
    const a = document.createElement('a')
    a.download = `${this.formName}.json`
    a.href = window.URL.createObjectURL(
      new Blob([JSON.stringify(form)], { type: 'application/json' }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }

  public openDeleteModal() {
    this.ngZone.run(() => {
      this.deleteModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          await this.apiService
            .deleteBpmnForm(this.currentOrg.uuid, this.existingForm.uuid)
            .pipe(first())
            .toPromise()
          this.router.navigate([`org/${this.currentOrg.uuid}/forms`])
        }
      })
    })
  }

  public createNewForm() {
    this.router.navigate([`org/${this.currentOrg.uuid}/formdesigner`])
  }

  private openRenameModal() {
    this.ngZone.run(() => {
      this.renameModal.nativeElement.open({
        preConfirmationHandler: (data) => {
          this.formName = data.formData.newName as string
          return this.saveForm({ confirmation: true }).then()
        },
      })
    })
  }
}
