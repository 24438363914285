import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { OptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-select/cm-select'
import { ChannelDto } from '../../../../../../commons/Channel.dto'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { ChannelService } from '../../../services/channel.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { NotificationService } from '../../../services/notification.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'channel-edit',
  templateUrl: './channel-edit.component.html',
  styleUrls: ['./channel-edit.component.scss'],
})
export class ChannelEditComponent {
  @Input() public channel: ChannelDto & { createdByName: string }

  @Output() channelsChanged = new EventEmitter<void>()

  @ViewChild('createOrEditChannelModal', { read: ElementRef })
  public createOrEditChannelModal: ElementRef<CmModal>

  public _generations: Array<GenerationDto & { createdByName: string }> = []

  @Input()
  set generations(
    generations: Array<GenerationDto & { createdByName: string }>,
  ) {
    this._generations = generations
    let generationOptions = [{ options: [] }]
    for (let generation of generations) {
      generationOptions[0].options.push({
        value: generation.uuid,
        label: generation.name,
        description: `
          uuid:${generation.uuid} 
          zeebe:${generation.versions.zeebe} 
          operate:${generation.versions.operate} 
          tasklist:${generation.versions.tasklist} 
          optimize:${generation.versions.optimize ?? 'none'}
          analytics:${generation.versions.zeebeAnalytics ?? 'none'}
        `,
      })
    }

    this.generationOptions = generationOptions
  }

  get generations() {
    return this._generations
  }

  public generationOptions: Array<OptionGroup> = []

  constructor(
    public vvs: ViewVisibilitiesService,
    private channelService: ChannelService,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
    private ngZone: NgZone,
  ) {}

  public allowedGenerationsOptions: Array<OptionGroup> = []
  public selectedGenerationUuids: Array<string> = []

  public openEditChannelDialog(
    channelToEdit?: ChannelDto & { createdByName: string },
  ) {
    return new Promise<boolean>((resolve, _reject) => {
      this.channel = channelToEdit ?? this.channel
      this.selectedGenerationUuids = this.channel.allowedGenerations.map(
        (generation) => generation.uuid,
      )
      this.updateAllowedGenerations()

      this.ngZone.run(() => {
        this.createOrEditChannelModal.nativeElement
          .open({
            preventFormReset: true,
            preConfirmationHandler: (data) => {
              let formData = data.formData

              return this.channelService.update(
                this.channel.uuid,
                formData.name as string,
                formData.description as string,
                formData.defaultChannel as boolean,
                formData.defaultGeneration as string,
                formData.allowedGenerations as string[],
              )
            },
          })
          .then((result) => {
            if (result.result === 'confirm') {
              this.notificationService.enqueueNotification({
                headline: 'Channel Updated',
                appearance: 'success',
                showCreationTime: false,
              })

              this.channelsChanged.emit()
              resolve(true)
            } else {
              resolve(false)
            }
          })
      })
    })
  }
  public openCreateChannelDialog() {
    this.selectedGenerationUuids = []
    this.updateAllowedGenerations()

    return new Promise<boolean>((resolve, _reject) => {
      this.ngZone.run(() => {
        this.createOrEditChannelModal.nativeElement
          .open({
            preConfirmationHandler: (data) => {
              let formData = data.formData

              return this.channelService.create(
                formData.name as string,
                formData.description as string,
                formData.defaultChannel as boolean,
                formData.defaultGeneration as string,
                formData.allowedGenerations as string[],
              )
            },
          })
          .then((result) => {
            if (result.result === 'confirm') {
              this.notificationService.enqueueNotification({
                headline: 'Channel Created',
                appearance: 'success',
                showCreationTime: false,
              })
              resolve(true)
              this.channelsChanged.emit()
            } else {
              resolve(false)
            }
          })
      })
    })
  }
  private updateAllowedGenerations() {
    this.allowedGenerationsOptions = [
      {
        options: this.generations.map((generation) => {
          return {
            label: generation.name,
            value: generation.uuid,
            description: `${generation.uuid} zeebe:${
              generation.versions.zeebe
            } operate:${generation.versions.operate} tasklist:${
              generation.versions.tasklist
            } optimize:${generation.versions.optimize ?? 'no'} analytics:${
              generation.versions.zeebeAnalytics ?? 'no'
            }`,
          }
        }),
      },
    ]
  }

  public notifyNewDefaultGenerationSelected(generationUuid: string) {
    if (!this.selectedGenerationUuids.includes(generationUuid)) {
      this.selectedGenerationUuids = [
        ...this.selectedGenerationUuids,
        generationUuid,
      ]
    }
  }
  public deleteChannel(channelToDelete?: ChannelDto) {
    return new Promise<boolean>((resolve, _reject) => {
      this.channel = (channelToDelete as any) ?? this.channel
      this.ngZone.run(() => {
        this.modalService.openModal({
          title: 'Delete Channel',
          body: `Are you sure to delete Channel ${this.channel.name}?`,
          confirmButton: {
            text: 'Delete Channel',
            appearance: 'danger',
            action: () => {
              this.channelService.delete(this.channel.uuid).then((_) => {
                this.notificationService.enqueueNotification({
                  headline: 'Channel Deleted',
                  appearance: 'success',
                  showCreationTime: false,
                })
                this.channelsChanged.emit()
                resolve(true)
              })
            },
          },
          cancelButton: {
            text: 'Cancel',
            appearance: 'secondary',
            action: () => {
              resolve(false)
            },
          },
        })
      })
    })
  }
}
