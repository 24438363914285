import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ClusterPlanDto,
  ClusterPlanTypeDto,
} from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { environment } from '../../environments/environment'
import { first } from 'rxjs/operators'

export interface ClusterCountClusterPlanDto extends ClusterPlanDto {
  clusterCount: number
}

@Injectable({
  providedIn: 'root',
})
export class ClusterPlanApiService {
  constructor(private http: HttpClient) {}

  // ClusterPlans
  public getActive(orgUuid: string): Observable<ClusterPlanTypeDto[]> {
    return this.http.get<ClusterPlanTypeDto[]>(
      `${environment.backendUrl}/api/plans/active?orgId=${orgUuid}`,
    )
  }

  public getAll(): Observable<ClusterCountClusterPlanDto[]> {
    return this.http.get<ClusterCountClusterPlanDto[]>(
      `${environment.backendUrl}/api/plans`,
    )
  }

  public get(uuid: string): Observable<ClusterPlanDto> {
    return this.http.get<ClusterPlanDto>(
      `${environment.backendUrl}/api/plans/${uuid}`,
    )
  }

  public getClusters(uuid: string): Observable<ClusterDto[]> {
    return this.http.get<ClusterDto[]>(
      `${environment.backendUrl}/api/plans/${uuid}/clusters`,
    )
  }

  public getUpgradableClusters(
    clusterPlanUuid: string,
  ): Observable<ClusterDto[]> {
    return this.http
      .get<any>(
        `${environment.backendUrl}/api/plans/${clusterPlanUuid}/clusters/upgradable`,
      )
      .pipe(first())
  }

  public create(clusterPlan: ClusterPlanDto): Observable<string> {
    return this.http.post(`${environment.backendUrl}/api/plans`, clusterPlan, {
      responseType: 'text',
    })
  }

  public setMigratableFrom(uuid: string, migratableFrom: string[]) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/plans/${uuid}/migratable-from`,
      { migratableFrom },
    )
  }

  public delete(clusterPlanUuid: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/plans/${clusterPlanUuid}`,
    )
  }

  public migratePlan(oldPlanUuid: string, newPlanUuid: string) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/plans/${oldPlanUuid}/migrateto/${newPlanUuid}`,
      {},
    )
  }

  public migrateClusters(clusterUuids: string[], newPlanUuid: string) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/plans/${newPlanUuid}/migrateClusters`,
      { clusterUuids },
    )
  }

  public deleteClustersOfPlan(planUuid: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/plans/${planUuid}/clusters`,
    )
  }

  // ClusterPlanTypes
  public createType(
    name: string,
    internal: boolean,
    description: string,
    activePlan: string,
    k8sContext: string,
    zeebeStorageLimitGiB: number,
    elasticStorageLimitGiB: number,
  ) {
    return this.http.post<void>(`${environment.backendUrl}/api/plans/type`, {
      name,
      internal,
      description,
      activePlan,
      k8sContext,
      zeebeStorageLimitGiB,
      elasticStorageLimitGiB,
    })
  }

  public getType(uuid: string) {
    return this.http.get<ClusterPlanTypeDto>(
      `${environment.backendUrl}/api/plans/type/${uuid}`,
    )
  }

  public getTypes() {
    return this.http.get<ClusterPlanTypeDto[]>(
      `${environment.backendUrl}/api/plans/type`,
    )
  }

  public updateType(
    clusterPlanTypeUuid: string,
    internal: boolean,
    description: string,
    activePlan: string,
    k8sContext: string,
    migrateClusters: boolean,
    zeebeStorageLimitGiB: number,
    elasticStorageLimitGiB: number,
  ) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/plans/type/${clusterPlanTypeUuid}`,
      {
        activePlan,
        internal,
        description,
        k8sContext,
        migrateClusters,
        zeebeStorageLimitGiB,
        elasticStorageLimitGiB,
      },
    )
  }

  public deleteType(clusterPlanTypeUuid: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/plans/type/${clusterPlanTypeUuid}`,
    )
  }
  public deleteTypeForce(clusterPlanTypeUuid: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/plans/type/${clusterPlanTypeUuid}/force`,
    )
  }
}
