import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrganizationDto, SalesPlanDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { OptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-select/cm-select'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { GenerationDetailData } from '../../../resolver/generation.resolver'
import { ApiService } from '../../../services/api.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { GenerationService } from '../../../services/generation.service'
import { NotificationService } from '../../../services/notification.service'
import { UserService } from '../../../services/user.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

interface ClusterWithOrgData extends ClusterDto {
  organization: OrganizationDto | undefined
}
interface ClustersToSalesPlans {
  salesPlan: SalesPlanDto
  clusters: ClusterDto[]
}
@Component({
  selector: 'generation-details',
  templateUrl: './generation-details.component.html',
  styleUrls: ['./generation-details.component.scss'],
})
export class GenerationDetailsComponent implements OnInit {
  @ViewChild('updateUpgradable', { read: ElementRef })
  public updateUpgradableModal: ElementRef<CmModal>

  public generationId: string
  public generation: GenerationDto & { clusterCount?: number }
  public otherGenerations: Array<GenerationDto & { clusterCount?: number }>
  public updatableOptions: Array<OptionGroup> = []
  public selectedUpgradeableGenerations: string[]
  public currentUpgradeableGenerations: Array<
    GenerationDto & { clusterCount?: number; diff: {} }
  >
  public deleteAllClustersQuestion: boolean = false
  public upgradableClusters: ClusterWithOrgData[] = []
  public currentClusters: ClusterWithOrgData[] = []
  public clustersBySalesPlans: ClustersToSalesPlans[]
  public creator: string

  constructor(
    private route: ActivatedRoute,
    private generationService: GenerationService,
    private apiService: ApiService,
    private router: Router,
    private modalService: ConfirmationModalService,
    private notificationService: NotificationService,
    public vss: ViewVisibilitiesService,
    private userService: UserService,
    private ngZone: NgZone,
  ) {}

  public ngOnInit() {
    const generationData = this.route.snapshot.data
      .generationData as GenerationDetailData

    this.generation = generationData.currentGeneration
    this.generationId = generationData.currentGeneration.uuid
    this.otherGenerations = generationData.otherGenerations

    this.selectedUpgradeableGenerations =
      generationData.selectedUpgradableGenerations

    this.currentUpgradeableGenerations =
      generationData.currentUpgradableGenerations

    this.currentClusters = generationData.clustersForCurrentGeneration.clusters.map(
      (cluster) =>
        GenerationDetailsComponent.addOrgDataToCluster(cluster, generationData),
    )

    this.upgradableClusters = generationData.clustersForCurrentGeneration.upgradableClusters.map(
      (cluster) =>
        GenerationDetailsComponent.addOrgDataToCluster(cluster, generationData),
    )

    const salesPlans = generationData.allSalesPlans

    this.clustersBySalesPlans = salesPlans
      .map((salesPlan) => {
        const clusters = this.upgradableClusters.filter(
          (cluster) =>
            cluster.organization &&
            cluster.organization.organizationToSalesPlan &&
            cluster.organization!.organizationToSalesPlan!.salesPlan!.uuid ===
              salesPlan.uuid,
        )
        return { clusters, salesPlan }
      })
      .filter((salesPlan) => salesPlan.clusters.length > 0)

    if (this.generation.createdBy) {
      this.userService.getUserById(this.generation.createdBy).subscribe(
        (creatorData) => {
          this.creator = `${creatorData.name} (${creatorData.email})`
        },
        (_error) => {
          this.creator = `unknown user with id ${this.generation.createdBy}`
        },
      )
    }
    this.updatableOptions = [
      {
        options: this.otherGenerations.map((generation) => {
          return {
            label: generation.name,
            description: `${generation.uuid} zeebe:${
              generation.versions.zeebe
            } operate:${generation.versions.operate} tasklist:${
              generation.versions.tasklist
            } optimize:${generation.versions.optimize ?? 'no'} analytics:${
              generation.versions.zeebeAnalytics ?? 'no'
            }`,
            value: generation.uuid,
          }
        }),
      },
    ]
  }

  private static addOrgDataToCluster(
    cluster: ClusterDto,
    generationData: GenerationDetailData,
  ) {
    return {
      ...cluster,
      organization: generationData.allOrganizations.find(
        (organization) => organization.uuid === cluster.ownerId,
      ),
    }
  }

  public upgradeGenerationFrom(oldGenerationId: string) {
    const oldGeneration = this.otherGenerations.find(
      (generation) => generation.uuid === oldGenerationId,
    )
    this.modalService.openModal({
      title: `Update clusters running on ${oldGeneration.name} to this one`,
      body: `Are you sure you want to migrate all ${oldGeneration.clusterCount} clusters?`,
      confirmButton: {
        text: 'Yes',
        appearance: 'danger',
        action: () =>
          this.generationService
            .upgradeGenerationTo(oldGenerationId, this.generationId)
            .then(() => this.goTo(this.generationId)),
      },
      cancelButton: {
        text: 'Not today',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public upgradeGenerationforCluster(clusterUuid: string) {
    const cluster = this.upgradableClusters.find(
      (cluster) => cluster.uuid === clusterUuid,
    )
    this.modalService.openModal({
      title: `Update cluster ${cluster.name} to this one`,
      body: `Are you sure you want to migrate the cluster with uuid  ${cluster.uuid} to this generation?`,
      confirmButton: {
        text: 'Yes',
        appearance: 'danger',
        action: () =>
          this.generationService
            .upgradeGenerationforCluster(this.generationId, clusterUuid)
            .then(() => this.goTo(this.generationId)),
      },
      cancelButton: {
        text: 'Not today',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public upgradeGenerationforClusters(clusters: ClusterDto[]) {
    let promises: Array<Promise<any>> = []

    this.modalService.openModal({
      title: `Update a lot of clusters  to this one`,
      body: `Are you sure you want to migrate all those ${clusters.length} cluster to this generation?`,
      confirmButton: {
        text: 'Yes',
        appearance: 'danger',
        action: () => {
          for (const cluster of clusters) {
            promises.push(
              this.generationService.upgradeGenerationforCluster(
                this.generationId,
                cluster.uuid,
              ),
            )
          }
          Promise.all(promises).then(() => this.goTo(this.generationId))
        },
      },
      cancelButton: {
        text: 'Not today',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteGeneration() {
    this.modalService.openModal({
      title: 'Delete Generation',
      body: `Are you sure to delete Generation ${this.generation.name}?`,
      confirmButton: {
        text: 'Delete Generation',
        appearance: 'danger',
        action: () => {
          this.generationService.delete(this.generationId).subscribe(
            (_) => {
              this.notificationService.enqueueNotification({
                appearance: 'success',
                headline: 'Generation deleted',
              })
              this.router.navigate(['/admin/versions/generations'])
            },
            (error) => {
              this.notificationService.enqueueNotification({
                appearance: 'error',
                headline: 'Generation could not be deleted',
                description: `${error.status} - ${error.error}`,
              })
            },
          )
        },
      },
      cancelButton: {
        text: 'Cancel Generation Deletion',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteClusters() {
    this.modalService.openModal({
      title: 'Delete all Clusters of this generation',
      body: `Are you sure to delete all ${this.generation.clusterCount} Clusters of Generation ${this.generation.name}?`,
      confirmButton: {
        text: 'Delete all Clusters',
        appearance: 'danger',
        action: () => {
          this.generationService
            .deleteClustersOfGeneration(this.generationId)
            .then((_) => this.goTo(this.generation.uuid))
        },
      },
      cancelButton: {
        text: 'Cancel',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public deleteCluster(cluster: ClusterDto) {
    this.modalService.openModal({
      title: 'Delete Cluster',
      body: `Are you sure to delete ${cluster.name} (${cluster.uuid})?`,
      confirmButton: {
        text: 'Delete Cluster',
        appearance: 'danger',
        action: () => {
          this.apiService
            .deleteCluster(cluster.ownerId, cluster.uuid)
            .subscribe((_) => this.goTo(this.generation.uuid))
        },
      },
      cancelButton: {
        text: 'Cancel Cluster Deletion',
        appearance: 'secondary',
      },
    })
  }
  public goBack() {
    this.router.navigate(['/admin/versions/generations'])
  }

  public getGenerationDetail(generationUuid: string) {
    return this.otherGenerations.find(
      (generation) => generation.uuid === generationUuid,
    )
  }

  public selectedOptions = []
  public openUpgradableModal() {
    this.selectedOptions = this.selectedUpgradeableGenerations
    this.ngZone.run(() => {
      this.updateUpgradableModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          const newUpgradables = result.formData.updateable as []
          this.generationService
            .updateUpgradeableFromGenerations(this.generationId, newUpgradables)
            .subscribe(
              () => {
                this.notificationService.enqueueNotification({
                  headline: 'Generation saved',
                  appearance: 'success',
                })
                this.goTo(this.generationId)
              },
              (error) => {
                this.notificationService.enqueueNotification({
                  headline: 'Generation could not be saved',
                  appearance: 'error',
                  description: `${error.status} - ${error.error}`,
                })
              },
            )
        }
      })
    })
  }
  public goTo(generationId: string) {
    this.router.navigate([`/admin/versions/generations/${generationId}`])
  }
}
