<cm-entity-list
  [enableCreateButton]="!vvs.visibilities.modeler.save.disabled"
  headline="Connectors"
  createButtonLabel="Create Connector"
  [createHandler]="connectorListCreateHandler"
  [columns]="connectorListColumns"
  [entities]="connectorListEntities"
  [loading]="connectorListLoading"
  [groupOptions]="groupOptions"
  [defaultSorting]="{ columnIndex: 3, method: 'descending' }"
>
  <div slot="empty" class="emptyEntityListContainer">
    <div class="emptyEntityList"><h3>No Connectors created</h3></div>
  </div>
</cm-entity-list>

<div *ngIf="!vvs.visibilities.modeler.read.visible">
  Sorry, you are not allowed to see the Connectors
</div>

<cm-modal
  #multiDeleteModal
  headline="Delete Connectors"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete these Connectors?
    <ul>
      <li *ngFor="let connectorToBeDeleted of connectorsToBeDeleted">
        <cm-text appearance="emphasis">{{ connectorToBeDeleted.name }}</cm-text>
      </li>
    </ul>
  </cm-text>
</cm-modal>

<cm-modal
  #deleteModal
  headline="Delete Connector"
  cancelLabel="Cancel"
  confirmLabel="Delete"
  confirmAppearance="danger"
>
  <cm-text>
    Are you sure you want to delete
    <cm-text appearance="emphasis">{{
      connectorToBeDeleted ? connectorToBeDeleted.name : ''
    }}</cm-text>
    ?
  </cm-text>
</cm-modal>

<cm-modal
  #createModal
  headline="Create Connector"
  cancelLabel="Cancel"
  confirmLabel="Create"
  confirmAppearance="primary"
>
  <cm-form>
    <cm-radiobutton-group
      label="Connector Type"
      value="airtable"
      formName="type"
    >
      <cm-radiobutton value="airtable" label="Airtable"></cm-radiobutton>
      <cm-radiobutton value="github" label="Github"></cm-radiobutton>
      <cm-radiobutton value="slack" label="Slack"></cm-radiobutton>
    </cm-radiobutton-group>
    <cm-textfield label="Name" formName="name"></cm-textfield>
    <cm-textfield label="API Key" formName="apiKey"></cm-textfield>
  </cm-form>
</cm-modal>
