import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ClusterPlanTypesToReserved,
  OrganizationDto,
  OrganizationMemberDto,
  OrganizationPermissions,
  OrganizationRole,
  SalesPlanType,
  ValueMetricsPerYearMonth,
} from '@camunda-cloud/cloud-node-libs'
import { BillingViewResponse } from '@camunda-cloud/subscription-management'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  public getAllOrgs() {
    return this.apiService.getAllUserOrganizations()
  }

  public getOrg(orgUuid: string, disableCache = false) {
    return this.apiService.getOrganization(orgUuid, disableCache)
  }

  public getMembers(orgUuid: string): Observable<OrganizationMemberDto[]> {
    return this.http.get<OrganizationMemberDto[]>(
      `${environment.accountsUrl}/api/organizations/${orgUuid}/members`,
    )
  }

  public sendInvite(
    orgUuid: string,
    email: string,
    orgRoles: OrganizationRole[],
  ) {
    return this.http.post(
      `${environment.accountsUrl}/api/organizations/${orgUuid}/invites`,
      { email, orgRoles },
    )
  }

  public changeRolesOfInvite(
    orgUuid: string,
    inviteId: string,
    orgRoles: OrganizationRole[],
  ) {
    return this.http.patch(
      `${environment.accountsUrl}/api/organizations/${orgUuid}/invites/${inviteId}`,
      { orgRoles },
    )
  }

  public assignNewOwner(
    orgId: string,
    userIdOfNewOwner: string,
    newOrgRolesOfOldOwner: OrganizationRole[],
  ) {
    return this.http.patch(
      `${environment.accountsUrl}/api/organizations/${orgId}/owner/${userIdOfNewOwner}`,
      { orgRoles: newOrgRolesOfOldOwner },
    )
  }

  public resendInvite(orgUuid: string, inviteId: string) {
    return this.http.post(
      `${environment.accountsUrl}/api/organizations/${orgUuid}/invites/${inviteId}/resend`,
      {},
    )
  }

  public cancelInvite(orgUuid: string, inviteId: string) {
    return this.http.delete(
      `${environment.accountsUrl}/api/organizations/${orgUuid}/invites/${inviteId}`,
    )
  }

  public getOwner(orgUuid: string) {
    return this.http.get(
      `${environment.accountsUrl}/api/organizations/${orgUuid}/owner`,
    )
  }

  public updateReservations(
    orgUuid: string,
    newReservations: ClusterPlanTypesToReserved,
  ) {
    return new Promise((resolve, reject) =>
      // eslint-disable-next-line no-promise-executor-return
      this.apiService
        .setReservedClusterPlanTypes(orgUuid, newReservations)
        .pipe(first())
        .subscribe(
          (_) => {
            resolve('')
          },
          (_error) => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject()
          },
        ),
    )
  }

  public onboard(request: {
    inviteToken?: string
    campaign?: string
  }): Observable<{
    orgId: string
    invitation?: {
      successful: boolean
    }
  }> {
    return this.http.post<{ orgId: string }>(
      `${environment.backendUrl}/api/onboarding/social`,
      request,
    )
  }

  public getValueMetrics(
    orgId: string,
  ): Observable<ValueMetricsPerYearMonth[]> {
    return this.http.get<ValueMetricsPerYearMonth[]>(
      `${environment.accountsUrl}/api/organizations/${orgId}/valueMetrics`,
    )
  }

  public getValueMetricsHistory(
    orgId: string,
  ): Observable<BillingViewResponse> {
    return this.http.get<BillingViewResponse>(
      `${environment.accountsUrl}/api/organizations/${orgId}/valueMetricsHistory`,
    )
  }
}

export function mayAccessBilling(
  org: OrganizationDto,
  activatedOrgPermissions: OrganizationPermissions,
) {
  return (
    activatedOrgPermissions.seeBilling === true &&
    org &&
    org.organizationToSalesPlan &&
    org.organizationToSalesPlan.salesPlan &&
    (org!.organizationToSalesPlan!.salesPlan!.salesPlanType ===
      SalesPlanType.PAID ||
      org!.organizationToSalesPlan!.salesPlan!.salesPlanType ===
        SalesPlanType.PAID_CC)
  )
}
