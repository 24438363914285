import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UserService } from '../../../services/user.service'
import { ChannelDto } from '../../../../../../commons/Channel.dto'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { ApiService } from '../../../services/api.service'
import { ListChannelsComponent } from '../../../components/admin/list-channels/list-channels.component'
import { ListGenerationsComponent } from '../../../components/admin/list-generations/list-generations.component'

@Component({
  selector: 'version-management-page',
  templateUrl: './version-management.page.html',
})
export class VersionManagementPage implements OnInit {
  public channels: (ChannelDto & { createdByName: string })[] = []
  public generations: (GenerationDto & { createdByName: string })[] = []

  @ViewChild('cmPage', { static: true, read: ElementRef })
  public cmPage: ElementRef

  @ViewChild(ListChannelsComponent)
  private listChannels: ListChannelsComponent

  @ViewChild(ListGenerationsComponent)
  private listGenerations: ListGenerationsComponent

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private apiService: ApiService,
    private ngZone: NgZone,
  ) {}

  updateChannels() {
    this.ngZone.run(() => {
      this.apiService
        .listChannels()
        .toPromise()
        .then((channels) => {
          this.channels = channels as any
          this.addCreatedByField().then(() => {
            this.listChannels.refreshList()
          })
        })
    })
  }

  async addCreatedByField() {
    await Promise.all(
      [
        ...this.channels.map((channel) => channel.createdBy),
        ...this.generations.map((generation) => generation.createdBy),
      ]
        .filter((creator, i, a) => a.indexOf(creator) === i)
        .filter(
          (creator) =>
            creator && !['migration-external', 'console-web'].includes(creator),
        )
        .map((creator) => {
          return new Promise<void>((resolve, _reject) => {
            this.userService
              .getUserById(creator)
              .toPromise()
              .then(
                (richData) => {
                  this.channels
                    .filter((channel) => channel.createdBy === creator)
                    .forEach((channel) => {
                      channel.createdByName = `${richData.name} (${richData.email})`
                    })

                  this.generations
                    .filter((generation) => generation.createdBy === creator)
                    .forEach((generation) => {
                      generation.createdByName = `${richData.name} (${richData.email})`
                    })
                  resolve()
                },
                () => {
                  // maybe the current user is just not allowed to fetch userdetails, thats fine
                  resolve()
                },
              )
          })
        }),
    )
  }

  public ngOnInit() {
    this.channels = this.route.snapshot.data.channels
    this.generations = this.route.snapshot.data.generations

    this.addCreatedByField()

    const cmPage = this.cmPage.nativeElement
    cmPage.activeLabel = this.route.snapshot.data.activeLabel

    this.route.url.subscribe((_urlSegments) => {
      cmPage.addEventListener('tabChanged', (_event) => {
        if (cmPage.activeLabel === 'Generations') {
          history.replaceState({}, '', `${location.href}/generations`)
        } else {
          history.replaceState(
            {},
            '',
            location.href.slice(
              0,
              location.href.length - '/generations'.length,
            ),
          )
        }
      })
    })
  }
}
