import createFormSelector from './form-selector-provider'
import translate from 'diagram-js/lib/i18n/translate'

export default (forms) => {
  const formsSelector = createFormSelector(forms)
  return {
    __depends__: [translate],
    __init__: ['formSelectorPropertiesProvider'],
    formSelectorPropertiesProvider: ['type', formsSelector],
  }
}
