<input
  hidden
  (change)="onFileSelected()"
  #fileInput
  type="file"
  id="file"
  accept=".json"
/>

<h1>
  Cluster Plans
  <cm-button
    *ngIf="vss.visibilities.admin.clusterplans.create.visible"
    label="Add Cluster Plan"
    appearance="primary"
    (cmPress)="openCreateClusterPlanDialog()"
  >
  </cm-button>
</h1>

<div class="cards">
  <cm-card *ngFor="let p of clusterPlans">
    <h3 slot="header">
      <span class="tag active" *ngIf="activeClusterPlansPerType[p.uuid]"></span>
      <span
        class="tag inactive"
        *ngIf="!activeClusterPlansPerType[p.uuid]"
      ></span>

      {{ p.name }}

      <div class="buttons">
        <cm-button
          appearance="primary"
          size="small"
          label="Details"
          (cmPress)="routeToClusterPlan(p.uuid)"
        ></cm-button>

        <cm-button
          appearance="primary"
          size="small"
          label="Download JSON"
          (cmPress)="downloadJson(p)"
        ></cm-button>

        <cm-button
          *ngIf="
            vss.visibilities.admin.clusterplans.delete.visible &&
            !clustersAvailable(p.uuid)
          "
          appearance="danger"
          size="small"
          label="Delete"
          (cmPress)="deleteClusterPlan(p.uuid)"
        ></cm-button>
      </div>
    </h3>

    <cm-datalist>
      <cm-datalist-item label="UUID">
        {{ p.uuid }}
      </cm-datalist-item>

      <cm-datalist-item label="Created at">
        {{ p.created | date: 'MMMM d, y' }} at
        {{ p.created | date: 'hh:mm' }}
      </cm-datalist-item>

      <cm-datalist-item label="Created by">
        {{ p.createdByName }}
      </cm-datalist-item>

      <cm-datalist-item
        label="Clusters"
        *ngIf="clustersPerClusterPlan[p.uuid] >= 0"
      >
        {{ clustersPerClusterPlan[p.uuid] }}
      </cm-datalist-item>
    </cm-datalist>
  </cm-card>
</div>

<cm-modal
  #createClusterPlanModal
  headline="Add Cluster Plan"
  cancelLabel="Cancel"
  confirmAppearance="primary"
  confirmLabel="Add"
  [confirmDisabled]="planValid === false"
>
  <cm-form>
    <cm-textfield
      label="Name"
      formName="name"
      required
      showRequired="true"
      [validation]="clusterPlanNameValidation"
    ></cm-textfield>
    <cm-select
      label="Prepopulate from existing Cluster Plan"
      (cmInput)="prepopulateFromClusterPlan($event.detail.selectedOptions[0])"
      [options]="clusterPlanOptions"
    ></cm-select>
    <cm-button label="Import from JSON" (click)="importJson()"></cm-button>
    <cm-banner appearance="warning" *ngIf="planErrorMessage">{{
      planErrorMessage
    }}</cm-banner>
    <hr />
    <cluster-plan-tree [(plan)]="plan"> </cluster-plan-tree>
  </cm-form>
</cm-modal>
