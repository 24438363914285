import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  ClusterPlanDto,
  ClusterPlanTypesToReserved,
  ClusterUsageMetricsData,
  K8sContextDto,
  OrganizationDto,
  SalesPlanDto,
} from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { first, tap } from 'rxjs/operators'
import { AlertSubscriptionDto } from '../../../../commons/AlertSubscription.dto'
import { ApiClientDto } from '../../../../commons/ApiClient.dto'
import { BpmnFormDto } from '../../../../commons/BpmnForm.dto'
import { BpmnModelDto } from '../../../../commons/BpmnModel.dto'
import { ChannelDto } from '../../../../commons/Channel.dto'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { ClusterIpWhitelistDto } from '../../../../commons/ClusterIpWhitelistDto'
import { ConnectorDto } from '../../../../commons/Connector.dto'
import { ConnectorType } from '../../../../commons/enums/Connector.enum'
import { GenerationDto } from '../../../../commons/Generation.dto'
import { ZeebeClientDto } from '../../../../commons/ZeebeClient.dto'
import { ZeebeClusterVersionsDto } from '../../../../commons/ZeebeClusterVersions.dto'
import { environment } from '../../environments/environment'
import { NotificationService } from './notification.service'

export const CORPSE_PATH = `/api/admin/corpses/`
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {}

  public listClusters(currentOrgId: string) {
    return this.http
      .get<ClusterDto[]>(
        `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters`,
      )
      .pipe(first())
  }

  public clusterDetails(currentOrgId: string, clusterId: string) {
    return this.http
      .get<ClusterDto>(
        `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}`,
      )
      .pipe(first())
  }

  public clusterUsage(currentOrgId: string) {
    return this.http
      .get<ClusterUsageMetricsData[]>(
        `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/usage/value-metrics`,
      )
      .pipe(first())
  }

  public createCluster(
    name: string,
    planTypeId: string,
    currentOrgId: string,
    channelId: string,
    generationId: string,
    k8sContextId: string,
  ) {
    return this.http.post<{ clusterId: string }>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters`,
      {
        name,
        planTypeId,
        channelId,
        generationId,
        k8sContextId,
      },
    )
  }

  public checkForAvailableBoost(clusterId: string) {
    return this.http
      .get<{ availableClusterplan: ClusterPlanDto | undefined }>(
        `${environment.backendUrl}/external/clusters/${clusterId}/boost`,
      )
      .pipe(first())
  }
  public boost(clusterId: string) {
    return this.http
      .post<{ newClusterplan: ClusterPlanDto | undefined }>(
        `${environment.backendUrl}/external/clusters/${clusterId}/boost`,
        {},
      )
      .pipe(first())
  }
  public sendClusterStatus(
    currentOrgId: string,
    clusterId: string,
    status: string,
  ) {
    return this.http.post(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}/status`,
      {
        status,
      },
    )
  }

  public moveCluster(
    currentOrgId: string,
    clusterId: string,
    newOrgId: string,
  ) {
    return this.http
      .patch<void>(
        `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}`,
        { ownerId: newOrgId },
      )
      .pipe(
        tap(
          () => {
            this.notificationService.enqueueNotification({
              headline: 'Cluster has been moved',
              appearance: 'success',
              description: `Cluster ${clusterId} has been moved to organization ${newOrgId}`,
            })
          },
          (error) => {
            this.notificationService.enqueueNotification({
              headline: 'Cluster could not be moved',
              appearance: 'error',
              description: `Cluster moved failed - ${error.status} - ${error.error}`,
            })
          },
        ),
      )
  }

  public renameCluster(
    currentOrgId: string,
    clusterId: string,
    newName: string,
  ) {
    return this.http.patch<void>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}`,
      { name: newName },
    )
  }

  public deleteCluster(currentOrgId: string, clusterId: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}`,
    )
  }

  public listZeebeClients(currentOrgId: string, clusterId: string) {
    return this.http.get<ZeebeClientDto[]>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}/clients`,
    )
  }

  public createZeebeClient(
    currentOrgId: string,
    clusterId: string,
    clientName: string,
    permissions: string[],
  ) {
    return this.http.post<{ clientSecret: string; clientId: string }>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}/clients`,
      { clientName, permissions },
    )
  }

  public updateZeebeClient(
    clientId: string,
    currentOrgId: string,
    clusterId: string,
    clientName: string,
  ): Observable<string> {
    return this.http.put(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}/clients/${clientId}`,
      { clientName },
      { responseType: 'text' },
    )
  }

  public deleteZeebeClient(currentOrgId: string, clusterId: string, clientId) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/clusters/${clusterId}/clients/${clientId}`,
    )
  }

  public loadApiClientPermissions(currentOrgId: string) {
    return this.http.get<string[]>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/api-clients/permissions`,
    )
  }

  public listApiClients(currentOrgId: string) {
    return this.http.get<ApiClientDto[]>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/api-clients`,
    )
  }

  public createApiClient(
    currentOrgId: string,
    clientName: string,
    permissions: string[],
  ) {
    return this.http.post<{ clientSecret: string; clientId: string }>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/api-clients`,
      { clientName, permissions },
    )
  }

  public updateApiClient(
    clientId: string,
    currentOrgId: string,
    clientName: string,
  ): Observable<string> {
    return this.http.put(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/api-clients/${clientId}`,
      { clientName },
      { responseType: 'text' },
    )
  }

  public deleteApiClient(currentOrgId: string, clientId: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/orgs/${currentOrgId}/api-clients/${clientId}`,
    )
  }

  public hasReachedClusterCap(currentOrgId: string, email?: string) {
    return this.http.get<{ clusterCapReached: boolean }>(
      `${
        environment.backendUrl
      }/api/orgs/${currentOrgId}/clusters/clusterCapReached${
        email ? `?email=${email}` : ''
      }`,
    )
  }

  public getK8sContextsInDb(
    withCounts: boolean = false,
  ): Observable<K8sContextDto[]> {
    return this.http.get<K8sContextDto[]>(
      `${environment.backendUrl}/api/admin/k8sContexts${
        withCounts ? '?withClusterCounts=please' : ''
      }`,
    )
  }

  public createInDbContextFromNativeK8sContext(
    k8sContextName: string,
    kubeConfigFilePath: string,
    name: string,
    region: string,
    zone: string,
    thanosEndpoint: string,
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.backendUrl}/api/admin/k8sContexts`,
      {
        k8sContextName,
        kubeConfigFilePath,
        name,
        region,
        zone,
        thanosEndpoint,
      },
    )
  }

  public updateInDbK8sContext(
    uuid: string,
    k8sIdentifier: string,
    kubeConfigFilePath: string,
    name: string,
    region: string,
    zone: string,
    thanosEndpoint: string,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.backendUrl}/api/admin/k8sContexts/${uuid}`,
      { name, k8sIdentifier, kubeConfigFilePath, region, zone, thanosEndpoint },
    )
  }
  public deleteInDbK8sContext(uuid: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/admin/k8sContexts/${uuid}`,
    )
  }

  public getClusterDatabaseCorpses() {
    return this.http.get<
      Array<{
        cluster: ClusterDto
        hasK8sInfo: boolean
        orgInfo: OrganizationDto | undefined
      }>
    >(`${environment.backendUrl}${CORPSE_PATH}cluster-database`)
  }
  public deleteClusterDatabaseCorpses() {
    return this.http.delete<void[]>(
      `${environment.backendUrl}${CORPSE_PATH}cluster-database`,
      {},
    )
  }
  public getClusterK8sCorpses() {
    return this.http.get<ClusterDto[]>(
      `${environment.backendUrl}${CORPSE_PATH}cluster-k8s`,
    )
  }
  public deleteClusterK8sCorpses() {
    return this.http.delete<void[]>(
      `${environment.backendUrl}${CORPSE_PATH}cluster-k8s`,
      {},
    )
  }

  public getAppInfo() {
    return this.http.get<{ info: { shortSha: string } }>(
      `${environment.backendUrl}/api/info`,
    )
  }

  public listGenerations(): Observable<
    Array<GenerationDto & { clusterCount?: number }>
  > {
    return this.http.get<GenerationDto[]>(
      `${environment.backendUrl}/api/generations`,
    )
  }

  public getUpgradableGenerations(
    fromGenerationUuid: string,
  ): Observable<GenerationDto[]> {
    return this.http
      .get<GenerationDto[]>(
        `${environment.backendUrl}/api/generations/${fromGenerationUuid}/upgradable`,
      )
      .pipe(first())
  }

  public createGeneration(
    name: string,
    versions: ZeebeClusterVersionsDto,
    upgradeableFrom: string[],
  ) {
    return this.http.post<{ generationUuid: string }>(
      `${environment.backendUrl}/api/generations`,
      {
        name,
        versions,
        upgradeableFrom,
      },
    )
  }

  public deleteGeneration(generationId: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/generations/${generationId}`,
    )
  }

  public deleteClustersOfGeneration(generationId: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/generations/${generationId}/clusters`,
    )
  }

  public updateUpgradeableFromGenerations(
    generationId: string,
    upgradeableFrom: string[],
  ) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/generations/${generationId}`,
      { upgradeableFrom },
    )
  }

  public listChannels(): Observable<ChannelDto[]> {
    return this.http.get<ChannelDto[]>(`${environment.backendUrl}/api/channels`)
  }

  public upgradeGenerationTo(oldGenerationId: string, newGenerationId: string) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/generations/${oldGenerationId}/upgradeto/${newGenerationId}`,
      {},
    )
  }

  public upgradeGenerationforCluster(
    generationUuid: string,
    clusterUuid: string,
  ) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/generations/${generationUuid}/upgradecluster/${clusterUuid}`,
      {},
    )
  }

  public createChannel(
    name: string,
    description: string,
    isDefault: boolean,
    defaultGenerationId: string,
    allowedGenerationIds: string[],
  ) {
    return this.http.post<void>(`${environment.backendUrl}/api/channels`, {
      name,
      description,
      isDefault,
      defaultGenerationId,
      allowedGenerationIds,
    })
  }

  public deleteChannel(channel: string) {
    return this.http.delete<void>(
      `${environment.backendUrl}/api/channels/${channel}`,
    )
  }

  public updateChannel(
    channelId: string,
    name: string,
    description: string,
    isDefault: boolean,
    defaultGenerationId: string,
    allowedGenerationIds: string[],
  ) {
    return this.http.patch<void>(
      `${environment.backendUrl}/api/channels/${channelId}`,
      {
        name,
        description,
        isDefault,
        defaultGenerationId,
        allowedGenerationIds,
      },
    )
  }

  public getSalesPlan() {
    return this.http.get<SalesPlanDto[]>(
      `${environment.accountsUrl}/api/salesplans`,
    )
  }

  public setReservedClusterPlanTypes(
    orgUuid: string,
    clusterPlanTypesToReserved: ClusterPlanTypesToReserved,
  ) {
    return this.http.patch<void>(
      `${environment.accountsUrl}/api/salesplans/reserved?orgId=${orgUuid}`,
      { clusterPlanTypesToReserved },
    )
  }

  public getClusterByGeneration(generationUuid: string) {
    return this.http.get<{
      upgradableClusters: ClusterDto[]
      clusters: ClusterDto[]
    }>(
      `${environment.backendUrl}/api/admin/clusters/generation/${generationUuid}`,
    )
  }

  public getAllUserOrganizations() {
    return this.http.get<OrganizationDto[]>(
      `${environment.accountsUrl}/api/organizations/my`,
    )
  }

  public getOrganization(orgUuid: string, disableCache = false) {
    let options = {}
    if (disableCache) {
      options = {
        headers: {
          camundaDisableCache: 'true',
        },
      }
    }
    return this.http.get<OrganizationDto>(
      `${environment.accountsUrl}/api/organizations/${orgUuid}`,
      options,
    )
  }

  public getAllOrganizations() {
    return this.http.get<OrganizationDto[]>(
      `${environment.accountsUrl}/api/organizations`,
    )
  }

  public createBpmnModel(orgUuid: string, name: string, model: string) {
    return this.http.post<{
      uuid: string
      modelHash: string
      processId: string
    }>(`${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models`, {
      model,
      name,
    })
  }

  public listBpmnModels(orgUuid: string) {
    return this.http.get<BpmnModelDto[]>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models`,
    )
  }

  public getBpmnModel(orgUuid: string, modelId: string) {
    return this.http.get<BpmnModelDto>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models/${modelId}`,
    )
  }

  public updateBpmnModel(
    orgUuid: string,
    name: string,
    modelId: string,
    lastLoadModelHash: string,
    model: string,
  ) {
    return this.http.put<{ modelHash: string }>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models/${modelId}`,
      { model, name, lastLoadModelHash },
    )
  }

  public deleteBpmnModel(orgUuid: string, modelId: string) {
    return this.http.delete(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models/${modelId}`,
    )
  }

  public deployBpmnModel(
    orgUuid: string,
    clusterUuid: string,
    modelId: string,
  ) {
    return this.http.post(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models/${modelId}/deploy/${clusterUuid}`,
      {},
    )
  }

  public startInstanceBpmnModel(
    orgUuid: string,
    clusterUuid: string,
    modelId: string,
    variables: string,
  ) {
    return this.http.post(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-models/${modelId}/start/${clusterUuid}`,
      { variables },
    )
  }

  public createBpmnForm(orgUuid: string, name: string, form: string) {
    return this.http.post<{
      uuid: string
      modelHash: string
      processId: string
    }>(`${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-forms`, {
      form,
      name,
    })
  }

  public listBpmnForms(orgUuid: string) {
    return this.http.get<BpmnFormDto[]>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-forms`,
    )
  }

  public getBpmnForm(orgUuid: string, formId: string) {
    return this.http.get<BpmnFormDto>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-forms/${formId}`,
    )
  }

  public updateBpmnForm(
    orgUuid: string,
    name: string,
    formId: string,
    form: string,
  ) {
    return this.http.put<{ modelHash: string }>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-forms/${formId}`,
      { form, name },
    )
  }

  public deleteBpmnForm(orgUuid: string, formId: string) {
    return this.http.delete(
      `${environment.backendUrl}/api/orgs/${orgUuid}/bpmn-forms/${formId}`,
    )
  }

  public resolve(hostname: string) {
    return this.http.get<{
      Answer?: [{ data: string }]
    }>(`https://cloudflare-dns.com/dns-query?name=${hostname}&type=AA`, {
      headers: { accept: 'application/dns-json' },
    })
  }

  public getAlerts(orgUuid: string, clusterUuid: string) {
    return this.http.get<AlertSubscriptionDto[]>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/clusters/${clusterUuid}/alerts`,
    )
  }

  public createAlert(
    orgUuid: string,
    clusterUuid: string,
    alertType: string,
    reportType: string,
    webhookUrl?: string,
  ) {
    return this.http.post(
      `${environment.backendUrl}/api/orgs/${orgUuid}/clusters/${clusterUuid}/alerts`,
      {
        alertType,
        reportType,
        webhookUrl,
      },
      {
        responseType: 'text',
      },
    )
  }

  public deleteAlert(
    orgUuid: string,
    clusterUuid: string,
    alertSubscriptionId: string,
  ) {
    return this.http.delete(
      `${environment.backendUrl}/api/orgs/${orgUuid}/clusters/${clusterUuid}/alerts/${alertSubscriptionId}`,
    )
  }

  public updateAlert(
    orgUuid: string,
    clusterUuid: string,
    alertSubscriptionId: string,
    alertType: string,
    reportType: string,
    webhookUrl?: string,
  ) {
    return this.http.put(
      `${environment.backendUrl}/api/orgs/${orgUuid}/clusters/${clusterUuid}/alerts/${alertSubscriptionId}`,
      { alertType, reportType, webhookUrl },
    )
  }

  public getChargebeeSession(orgId: string) {
    return this.http.get<any>(
      `${environment.backendUrl}/api/chargebee/organization/${orgId}/session`,
    )
  }

  public createChargebeeCheckout(orgId: string, selectedCountry: string) {
    return this.http.post<any>(
      `${environment.backendUrl}/api/chargebee/organization/${orgId}/checkout`,
      { country: selectedCountry },
    )
  }

  public getChargebeeSite(countryCode: string) {
    const url = `${environment.backendUrl}/api/chargebee/site/${countryCode}`
    return this.http.get<any>(url)
  }

  public getChargebeePubkey(chargebeeSite: string) {
    return this.http.get<{ publishableKey: string }>(
      `${environment.backendUrl}/api/chargebee/pubkey/${chargebeeSite}`,
    )
  }

  public listConnectors(orgUuid: string) {
    return this.http
      .get<ConnectorDto[]>(
        `${environment.backendUrl}/api/orgs/${orgUuid}/connectors`,
      )
      .pipe(first())
  }

  public createConnector(
    orgUuid: string,
    type: ConnectorType,
    name: string,
    apiKey: string,
  ) {
    return this.http
      .post<any>(`${environment.backendUrl}/api/orgs/${orgUuid}/connectors`, {
        type,
        name,
        apiKey,
      })
      .pipe(first())
  }

  public deleteConnector(orgUuid: string, connectorUuid: string) {
    return this.http.delete(
      `${environment.backendUrl}/api/orgs/${orgUuid}/connectors/${connectorUuid}`,
    )
  }
  public getExternalOrgInfo(orgUuid: string) {
    return this.http.get(
      `${environment.accountsUrl}/external/organizations/${orgUuid}`,
    )
  }
  public getExternalOrgMembers(orgUuid: string) {
    return this.http.get(
      `${environment.accountsUrl}/external/organizations/${orgUuid}/members`,
    )
  }
  public getExternalOrgUserInfo(orgUuid: string, userId: string) {
    return this.http.get(
      `${environment.accountsUrl}/external/organizations/${orgUuid}/members/${userId}`,
    )
  }
  public getExternalClusters(orgUuid: string) {
    return this.http.get<Array<{ uuid: string }>>(
      `${environment.backendUrl}/external/organizations/${orgUuid}/clusters/`,
    )
  }
  public obtainInternalClient(orgUuid: string, clusterUuid: string) {
    return this.http.get(
      `${environment.backendUrl}/external/organizations/${orgUuid}/clusters/${clusterUuid}/internalClient`,
    )
  }
  public updateClusterWhitelist(
    orgUuid: string,
    clusterUuid: string,
    clusterWhitelist: ClusterIpWhitelistDto[],
  ) {
    return this.http.put<void>(
      `${environment.backendUrl}/api/orgs/${orgUuid}/clusters/${clusterUuid}/whitelist`,
      clusterWhitelist,
    )
  }
}
