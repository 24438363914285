import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router'
import { ClusterService } from '../services/cluster.service'
import { GenerationDto } from '../../../../commons/Generation.dto'
import { ApiService } from '../services/api.service'
import { FeatureService } from '../services/feature.service'
import { ClusterDto } from '../../../../commons/Cluster.dto'

export interface ClusterDetailWithGenerationData {
  cluster: ClusterDto
  upgradableGenerations: GenerationDto[]
}

@Injectable({
  providedIn: 'root',
})
export class ClusterDetailsResolverService
  implements Resolve<ClusterDetailWithGenerationData> {
  constructor(
    private clusterService: ClusterService,
    private apiService: ApiService,
  ) {}
  public resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Promise<ClusterDetailWithGenerationData> {
    return this.getClusterDetails(
      route.paramMap.get('orgUuid'),
      route.paramMap.get('clusterId'),
    )
  }
  public async getClusterDetails(orgUuid: string, clusterUuid: string) {
    let cluster = await this.clusterService
      .getCluster(orgUuid, clusterUuid)
      .toPromise()

    let upgradableGenerations = []
    upgradableGenerations = await this.apiService
      .getUpgradableGenerations(cluster.generation.uuid)
      .toPromise()
    return { cluster, upgradableGenerations }
  }
}
