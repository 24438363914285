import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ClusterDto } from '../../../../commons/Cluster.dto'
import { environment } from '../../environments/environment'
import { ApiService } from './api.service'
import { ClusterService as ClusterServiceLegacy } from './cluster-legacy.service'
import { ClusterService as ClusterServiceNew } from './cluster-new.service'
import { NavbarService } from './navbar.service'
import { NotificationService } from './notification.service'
import { SseService } from './sse.service'
@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  private internalClusterService: ClusterServiceLegacy | ClusterServiceNew
  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
    private navbarService: NavbarService,
    private sseService: SseService,
  ) {
    if (environment.production) {
      this.internalClusterService = new ClusterServiceLegacy(
        this.apiService,
        this.notificationService,
        this.navbarService,
      )
    } else {
      this.internalClusterService = new ClusterServiceNew(
        this.apiService,
        this.navbarService,
        this.sseService,
      )
    }
  }

  public getClustersStatus(): Observable<ClusterDto[]> {
    return this.internalClusterService.getClustersStatus() as Observable<
      ClusterDto[]
    >
  }

  public create(
    orgUuid: string,
    name: string,
    planTypeId: string,
    channelId: string,
    generationId: string,
    k8sContextId: string,
  ) {
    return this.internalClusterService.create(
      orgUuid,
      name,
      planTypeId,
      channelId,
      generationId,
      k8sContextId,
    )
  }

  public rename(currentOrgId: string, clusterUuid: string, newName: string) {
    return this.internalClusterService.rename(
      currentOrgId,
      clusterUuid,
      newName,
    )
  }

  public moveToOrg(currentOrgId: string, id: string, newOrgUuid: string) {
    return this.internalClusterService.moveToOrg(currentOrgId, id, newOrgUuid)
  }

  public boost(clusterUuid: string) {
    return this.internalClusterService.boost(clusterUuid)
  }

  public boostAvailable(clusterUuid: string) {
    return this.internalClusterService.boostAvailable(clusterUuid)
  }

  public delete(currentOrgId: string, id: string) {
    return this.internalClusterService.delete(currentOrgId, id)
  }

  public getCluster(
    currentOrgId: string,
    uuid: string,
  ): Observable<ClusterDto> {
    return this.internalClusterService.getCluster(currentOrgId, uuid)
  }

  public getClusters(orgUuid: string): Observable<ClusterDto[]> {
    return this.internalClusterService.getClusters(orgUuid)
  }

  public isClusterInCreation() {
    if (environment.production) {
      return (this.internalClusterService as any).isClusterInCreation()
    }
  }

  public isOneClusterUnhealthy() {
    if (environment.production) {
      return (this.internalClusterService as any).isOneClusterUnhealthy()
    }
  }

  public setOneClusterUnhealthy(state: boolean) {
    if (environment.production) {
      return (this.internalClusterService as any).setOneClusterUnhealthy(state)
    }
  }
}
