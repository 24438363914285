import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ChannelDto } from '../../../../commons/Channel.dto'
import { ApiService } from '../services/api.service'

@Injectable({
  providedIn: 'root',
})
export class ChannelResolverService implements Resolve<ChannelDto> {
  constructor(private apiService: ApiService) {}
  public resolve(route: ActivatedRouteSnapshot): Observable<ChannelDto> {
    return this.apiService
      .listChannels()
      .pipe(
        map(
          (channels) =>
            channels.find(
              (channel) => channel.uuid === route.paramMap.get('channelId'),
            ) as ChannelDto,
        ),
      )
  }
}
