<cm-page #cmPage>
  <h1 slot="header">Version Management</h1>
  <cm-page-tab label="Channels">
    <list-channels
      [channels]="channels"
      [generations]="generations"
      (channelsChanged)="updateChannels()"
    ></list-channels>
  </cm-page-tab>
  <cm-page-tab label="Generations">
    <list-generations [generations]="generations"></list-generations>
  </cm-page-tab>
</cm-page>
