export const SHA_VISIBLE = 'console:gitSha'

export const MULTICLUSTER_ADMIN = 'console:multiCluster:admin'

export const CHANNELS_AND_GENERATIONS_ADMIN_DELETE_ALL_CLUSTERS =
  'console:multiGen:admin:deleteAllClustersOfGeneration'
export const CHANNELS_AND_GENERATIONS_ADMIN_SEE =
  'console:multiGen:admin:canSee'
export const CHANNELS_AND_GENERATIONS_ADMIN =
  'console:multiGen:admin:canModify '
export const CHANNELS_AND_GENERATIONS_ADMIN_MIGRATE =
  'console:multiGen:admin:canMigrate'
export const CHANNELS_AND_GENERATIONS_MIGRATE = 'console:multiGen:canMigrate'
export const ALL_CHANNELS_AND_GENERATIONS: string[] = [
  CHANNELS_AND_GENERATIONS_ADMIN_SEE,
  CHANNELS_AND_GENERATIONS_ADMIN,
  CHANNELS_AND_GENERATIONS_ADMIN_MIGRATE,
]

export const CLUSTERS_READALLCLUSTERINFO = 'console:clusters:readAllClusterInfo'
export const CLUSTERS_DELETE_CLUSTER = 'console:clusters:deleteAllClusters'
export const CLUSTERS_CAN_CREATE_OWN = 'console:clusters:canCreateOwnClusters'
export const CLUSTERS_GODMODE_UNLIMITED_CLUSTERS =
  'console:clusters:godmode:unlimitedClusters'
export const CLUSTERS_CAN_MOVE_TO_OTHER_ORG =
  'console:cluster:canMoveToOtherOrg'
export const CLUSTERS_CAN_BOOST = 'console:cluster:canBoost'

export const CORPSES_SEE = 'console:corpse:canSee'
export const CORPSES_DELETE = 'console:corpse:canDelete'

export const CLUSTER_PLAN_SEE_ALL = 'console:clusterPlan:seeAll'
export const CLUSTER_PLAN_CREATE = 'console:clusterPlan:create'
export const CLUSTER_PLAN_DELETE = 'console:clusterPlan:delete'

export const CLUSTER_PLAN_TYPE_CREATE = 'console:clusterPlan:type:create'
export const CLUSTER_PLAN_TYPE_MODIFY = 'console:clusterPlan:type:modify'
export const CLUSTER_PLAN_TYPE_DELETE = 'console:clusterPlan:type:delete'
export const CLUSTER_PLAN_TYPE_FORCE_DELETE =
  'console:clusterPlan:type:forceDelete'

export const CLUSTER_PLAN_MIGRATE = 'console:clusterPlan:migrate'
export const CLUSTER_PLAN_CAN_DELETE_ALL_CLUSTERS_OF_PLAN =
  'console:clusterPlan:canDeleteAllClustersOfPlan'

export const M2M_UPDATE_CRD = 'console:m2m:update:crd'
export const M2M_SIGNUP = 'console:m2m:signup'
export const M2M_TRIGGER_ALERT = 'console:m2m:triggerAlert'
export const M2M_DELETE_ORG_CLUSTERS = 'console:m2m:delete:orgClusters'
export const M2M_UPDATE_ORG_CLUSTERS = 'console:m2m:update:orgClusters'
export const M2M_ADD_CLUSTER_USAGE_METRICS = 'console:m2m:metrics:add'
export const CLUSTER_CREATE_ON_BEHALF = 'console:cluster:createOnBehalf'
export const FEATURE_NEW_HOME = 'console:features:newHome'
export const FEATURE_TEMPLATE_PICKER = 'console:features:templatePicker'
export const USER_SETTINGS = 'console:user:settings'
export const FEATURE_PENDO = 'console:features:pendo'

export const DEBUG = 'console:debug'

export interface Features {
  multiCluster: {
    admin: boolean
  }
  multiGen: {
    admin: {
      see: boolean
      modify: boolean
      migrate: boolean
      deleteAllClustersOfGeneration: boolean
    }
  }
  corpses: {
    see: boolean
    delete: boolean
  }
  gitSha: boolean
  clusters: {
    canReadAllClusterInfo: boolean
    canDeleteAllClusters: boolean
    canCreateOwnClusters: boolean
    godmodeUnlimitedClusters: boolean
    canMoveToOtherOrg: boolean
    createOnBehalf: boolean
    boost: boolean
  }
  clusterPlan: {
    seeAll: boolean
    create: boolean
    delete: boolean
    type: {
      create: boolean
      modify: boolean
      delete: boolean
      forceDelete: boolean
    }
    migrate: boolean
    deleteAllClustersOfPlan: boolean
  }
  features: {
    newHome: boolean
    templatePicker: boolean
    userSettings: boolean
    debug: boolean
    pendo: boolean
  }
  m2m: {
    updateCrd: boolean
    signup: boolean
    triggerAlert: boolean
    deleteOrgClusters: boolean
    updateOrgClusters: boolean
    addUsageMetrics: boolean
  }
}

export function createEmptyFeatures(): Features {
  return {
    multiCluster: { admin: false },
    multiGen: {
      admin: {
        migrate: false,
        modify: false,
        see: false,
        deleteAllClustersOfGeneration: false,
      },
    },
    corpses: { delete: false, see: false },
    gitSha: false,
    clusters: {
      canReadAllClusterInfo: false,
      canDeleteAllClusters: false,
      canCreateOwnClusters: false,
      godmodeUnlimitedClusters: false,
      canMoveToOtherOrg: false,
      createOnBehalf: false,
      boost: false,
    },
    clusterPlan: {
      seeAll: false,
      create: false,
      delete: false,
      type: {
        create: false,
        modify: false,
        delete: false,
        forceDelete: false,
      },
      migrate: false,
      deleteAllClustersOfPlan: false,
    },
    features: {
      newHome: false,
      templatePicker: false,
      userSettings: false,
      debug: false,
      pendo: false,
    },
    m2m: {
      updateCrd: false,
      signup: false,
      triggerAlert: false,
      deleteOrgClusters: false,
      updateOrgClusters: false,
      addUsageMetrics: false,
    },
  }
}
export function userFeaturesFromPermissions(permissions: string[]) {
  const newFeatures: Features = {
    multiCluster: {
      admin: permissions.includes(MULTICLUSTER_ADMIN),
    },
    multiGen: {
      admin: {
        see: permissions.includes(CHANNELS_AND_GENERATIONS_ADMIN_SEE),
        modify: permissions.includes(CHANNELS_AND_GENERATIONS_ADMIN),
        migrate: permissions.includes(CHANNELS_AND_GENERATIONS_ADMIN_MIGRATE),
        deleteAllClustersOfGeneration: permissions.includes(
          CHANNELS_AND_GENERATIONS_ADMIN_DELETE_ALL_CLUSTERS,
        ),
      },
    },
    corpses: {
      see: permissions.includes(CORPSES_SEE),
      delete: permissions.includes(CORPSES_DELETE),
    },
    gitSha: permissions.includes(SHA_VISIBLE),
    clusters: {
      canReadAllClusterInfo: permissions.includes(CLUSTERS_READALLCLUSTERINFO),
      canDeleteAllClusters: permissions.includes(CLUSTERS_DELETE_CLUSTER),
      canCreateOwnClusters: permissions.includes(CLUSTERS_CAN_CREATE_OWN),
      godmodeUnlimitedClusters: permissions.includes(
        CLUSTERS_GODMODE_UNLIMITED_CLUSTERS,
      ),
      canMoveToOtherOrg: permissions.includes(CLUSTERS_CAN_MOVE_TO_OTHER_ORG),
      createOnBehalf: permissions.includes(CLUSTER_CREATE_ON_BEHALF),
      boost: permissions.includes(CLUSTERS_CAN_BOOST),
    },
    clusterPlan: {
      seeAll: permissions.includes(CLUSTER_PLAN_SEE_ALL),
      create: permissions.includes(CLUSTER_PLAN_CREATE),
      delete: permissions.includes(CLUSTER_PLAN_DELETE),
      type: {
        create: permissions.includes(CLUSTER_PLAN_TYPE_CREATE),
        modify: permissions.includes(CLUSTER_PLAN_TYPE_MODIFY),
        delete: permissions.includes(CLUSTER_PLAN_TYPE_DELETE),
        forceDelete: permissions.includes(CLUSTER_PLAN_TYPE_FORCE_DELETE),
      },
      migrate: permissions.includes(CLUSTER_PLAN_MIGRATE),
      deleteAllClustersOfPlan: permissions.includes(
        CLUSTER_PLAN_CAN_DELETE_ALL_CLUSTERS_OF_PLAN,
      ),
    },
    features: {
      newHome: permissions.includes(FEATURE_NEW_HOME),
      templatePicker: permissions.includes(FEATURE_TEMPLATE_PICKER),
      userSettings: permissions.includes(USER_SETTINGS),
      debug: permissions.includes(DEBUG),
      pendo: permissions.includes(FEATURE_PENDO),
    },
    m2m: {
      updateCrd: permissions.includes(M2M_UPDATE_CRD),
      signup: permissions.includes(M2M_SIGNUP),
      triggerAlert: permissions.includes(M2M_TRIGGER_ALERT),
      deleteOrgClusters: permissions.includes(M2M_DELETE_ORG_CLUSTERS),
      updateOrgClusters: permissions.includes(M2M_UPDATE_ORG_CLUSTERS),
      addUsageMetrics: permissions.includes(M2M_ADD_CLUSTER_USAGE_METRICS),
    },
  }
  return newFeatures
}
