import { Component } from '@angular/core'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'

@Component({
  selector: 'terms-page',
  templateUrl: './terms.page.html',
})
export class TermsPage {
  constructor(private mixpanel: MixpanelService) {
    this.mixpanel.track(FrontendAnalyticEvents.TERMS_PAGE_SEEN).then(() =>
      // TODO: needs final url
      window.open(
        `https://camunda.com/legal/terms/cloud-terms-and-conditions/camunda-cloud-trial-terms/`,
        '_self',
      ),
    )
  }
}
