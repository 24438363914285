import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import {
  ClusterPlanDto,
  ClusterPlanTypeDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { OptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-select/cm-select'
import { BehaviorSubject } from 'rxjs'
import { first } from 'rxjs/operators'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { Plan, validate } from '../../../../../../commons/types/Plan.type'
import {
  ClusterCountClusterPlanDto,
  ClusterPlanApiService,
} from '../../../services/clusterPlan.api.service'
import { ConfirmationModalService } from '../../../services/confirmation-modal.service'
import { UserService } from '../../../services/user.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
@Component({
  selector: 'list-cluster-plans',
  templateUrl: './list-cluster-plans.component.html',
  styleUrls: ['./list-cluster-plans.component.scss'],
})
export class ListClusterPlansComponent implements OnInit, DoCheck {
  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('fileInput', { static: true, read: ElementRef })
  public fileInput: ElementRef

  private _clusterPlans: Array<
    ClusterPlanDto & {
      createdByName: string
    }
  >
  public clusterPlanOptions: Array<OptionGroup>
  @Input() set clusterPlans(
    clusterPlans: Array<
      ClusterPlanDto & {
        createdByName: string
      }
    >,
  ) {
    this._clusterPlans = clusterPlans

    let options = [{ options: [] }]
    clusterPlans.forEach((clusterplan) =>
      options[0].options.push({
        value: clusterplan.uuid,
        label: clusterplan.name,
        description: `
          uuid:${clusterplan.uuid}
        `,
      }),
    )
    this.clusterPlanOptions = options
  }
  get clusterPlans() {
    return this._clusterPlans
  }

  public clusterPlanNameValidation = {
    validator: (input) => input?.length > 0,
  }

  @Input()
  public clusterPlanTypes: (ClusterPlanTypeDto & {
    createdByName: string
  })[]

  public clustersPerClusterPlan: { [key: string]: number } = {}
  public activeClusterPlansPerType: Array<{ [key: string]: boolean }> = []

  // create modal
  @ViewChild('createClusterPlanModal', { read: ElementRef })
  public modalRef: ElementRef<CmModal>

  public generations$: BehaviorSubject<GenerationDto[]> = new BehaviorSubject(
    [],
  )

  public plan = {}
  public existingClusterPlans: ClusterCountClusterPlanDto[] = []
  public existingClusterPlanUuid: string
  public planValid = false
  public planErrorMessage

  constructor(
    private clusterPlanApiService: ClusterPlanApiService,
    private router: Router,
    private modalService: ConfirmationModalService,
    private userService: UserService,
    public vss: ViewVisibilitiesService,
    private cdRef: ChangeDetectorRef,
  ) {}

  public async ngOnInit() {
    this.activeClusterPlansPerType = []
    this.existingClusterPlans = await this.clusterPlanApiService
      .getAll()
      .pipe(first())
      .toPromise()

    this.clusterPlans.forEach((clusterPlan) => {
      if (
        this.clusterPlanTypes.find(
          (clusterPlanType) =>
            clusterPlanType.activePlan &&
            clusterPlanType.activePlan.uuid === clusterPlan.uuid,
        )
      ) {
        this.activeClusterPlansPerType[clusterPlan.uuid] = true
      }
      const foundExistingClusterPlan = this.existingClusterPlans.find(
        (existingClusterPlan) => existingClusterPlan.uuid === clusterPlan.uuid,
      )
      this.clustersPerClusterPlan[
        clusterPlan.uuid
      ] = foundExistingClusterPlan?.clusterCount
        ? foundExistingClusterPlan.clusterCount
        : 0
    })
  }

  public openCreateClusterPlanDialog() {
    this.plan = Object.assign({}, Plan)
    this.existingClusterPlanUuid = undefined
    requestAnimationFrame(() => {
      this.modalRef.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          const plan = this.transformToPlain(this.plan)
          this.clusterPlanApiService
            .create({
              uuid: '',
              name: result.formData.name as string,
              migratableFrom: [],
              plan,
            })
            .subscribe((newClusterPlanUuid) => {
              if (newClusterPlanUuid) {
                this.router.navigate([
                  `admin/cluster-plan/plans/${newClusterPlanUuid}`,
                ])
              }
            })
        }
      })
    })
  }

  public prepopulateFromClusterPlan(selectedClusterPlanUuid) {
    const existingClusterPlan = this.existingClusterPlans.find(
      (clusterPlan) => clusterPlan.uuid === selectedClusterPlanUuid,
    )

    if (existingClusterPlan) {
      this.plan = this.mergePlan(existingClusterPlan.plan, Plan)
    }
  }

  public clustersAvailable(uuid: string) {
    return this.clustersPerClusterPlan[uuid] > 0
  }

  public deleteClusterPlan(uuid: string) {
    const clusterPlanToDelete = this.clusterPlans.find(
      (clusterPlan) => clusterPlan.uuid === uuid,
    ).name
    this.modalService.openModal({
      title: 'Delete Cluster Plan',
      body: `Are you sure to delete Cluster Plan ${clusterPlanToDelete}?`,
      confirmButton: {
        text: 'Delete Cluster Plan',
        appearance: 'danger',
        action: () => {
          this.clusterPlanApiService.delete(uuid).subscribe((_) => {
            this.router.navigate([`admin/cluster-plan/plans`])
          })
        },
      },
      cancelButton: {
        text: 'Cancel Cluster Plan Deletion',
        appearance: 'secondary',
        action: () => {},
      },
    })
  }

  public routeToClusterPlan(clusterplanUuid: string) {
    this.router.navigate([`/admin/cluster-plan/plans/${clusterplanUuid}`])
  }

  public ngDoCheck() {
    try {
      this.planValid = validate(this.transformToPlain(this.plan), Plan)
      this.planErrorMessage = undefined
    } catch (error) {
      this.planErrorMessage = error
      this.planValid = false
    }
  }

  public importJson() {
    this.fileInput.nativeElement.click()
  }

  public onFileSelected() {
    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader()

      reader.onload = (res: any) => {
        const clusterPlanJson = JSON.parse(res.target.result)
        this.plan = this.mergePlan(clusterPlanJson, Plan)
      }

      reader.readAsText((this.fileInput as any).nativeElement.files[0])
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public downloadJson(clusterPlan: ClusterPlanDto) {
    const a = document.createElement('a')
    a.download = `${clusterPlan.name}.json`
    a.href = window.URL.createObjectURL(
      new Blob([JSON.stringify(clusterPlan.plan)], {
        type: 'application/json',
      }),
    )
    a.click()
    window.URL.revokeObjectURL(a.href)
  }

  private mergePlan(plan: any, definiton: any) {
    if (definiton instanceof Object && !definiton.type) {
      let res = {}
      Object.keys(definiton).forEach((key) => {
        res[key] = this.mergePlan(
          plan && plan[key] !== undefined ? plan[key] : undefined,
          definiton[key],
        )
      })
      return res
    } else {
      return Object.assign({ value: plan }, definiton)
    }
  }

  private transformToPlain(plan: any) {
    if (plan.type) {
      if (
        plan.value === '' ||
        plan.value === undefined ||
        plan.value === null
      ) {
        return undefined
      }
      if (plan.type === 'number') {
        return Number(plan.value)
      }
      return plan.value
    } else {
      let res = {}
      Object.keys(plan).forEach((key) => {
        res[key] = this.transformToPlain(plan[key])
      })
      return res
    }
  }
}
