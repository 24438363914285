<cm-page #cmPage>
  <div slot="header" class="userSettingsHeader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62626E"
    >
      <path
        fill-rule="evenodd"
        d="M1.16192798,19.5 C0.792463484,19.5 0.5,19.2181497 0.5,18.8620267 C0.5,15.5090856 3.33239404,12.7941176 6.79583544,12.7941176 L13.2041646,12.7941176 C16.667606,12.7941176 19.5,15.5090856 19.5,18.8620267 C19.5,19.2181497 19.2075365,19.5 18.838072,19.5 L18.838072,19.5 L1.16192798,19.5 Z M9.87447231,10.5588235 C7.1342188,10.5588235 4.91151638,8.28745989 4.91151638,5.52168984 C4.91151638,2.75591979 7.1342188,0.5 9.87447231,0.5 C12.6147258,0.5 14.8374282,2.74047594 14.8374282,5.52168984 C14.8374282,8.30290374 12.6147258,10.5588235 9.87447231,10.5588235 L9.87447231,10.5588235 Z"
      />
    </svg>

    <h1>User Settings</h1>
  </div>

  <cm-page-tab label="Overview">
    <div class="card-container">
      <cm-card *ngIf="vvs.visibilities.featureFlags.prodStageToggle.visible">
        <h3 slot="header">Simulate Production Features</h3>
        <cm-toggle
          slot="header"
          #prodFeatureToggle
          label="Inactive"
          [attr.checked]="env.production"
          (cmInput)="toggleProdFeatures($event.detail.isChecked)"
        ></cm-toggle>
        <cm-text>Just show the Production Features, please.</cm-text>
      </cm-card>

      <cm-card *ngIf="vvs.visibilities.navbar.menu.userSettings.visible">
        <h3 slot="header">Other Tweaks</h3>
        <div class="buttonGrid">
          <cm-button
            label="Reset GSE Overlay"
            (cmPress)="unDismissGseOverlay()"
          ></cm-button>

          <cm-button
            label="Reset Cookie Banner"
            (cmPress)="resetCookieBanner()"
          ></cm-button>

          <cm-button
            label="Reset Welcome Modal"
            (cmPress)="resetWelcomeModal()"
          ></cm-button>

          <cm-button
            label="Reset New Role Definitions Modal"
            (cmPress)="resetNewRolesDefinitionModal()"
          ></cm-button>
        </div>
      </cm-card>
    </div>
  </cm-page-tab>
</cm-page>
