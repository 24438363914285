import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { ExtendedClusterPlanTypeReservation } from '../../../resolver/reservations.resolver'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { UsageData } from '../billing/usage.data'

@Component({
  selector: 'organization-usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.scss'],
})
export class OrganizationUsageComponent implements OnInit {
  public org: OrganizationDto
  public reservations: ExtendedClusterPlanTypeReservation[]

  public usage: UsageData | undefined

  public selectedPeriod

  public loading: boolean = false

  constructor(
    private route: ActivatedRoute,
    public vss: ViewVisibilitiesService,
  ) {}

  public ngOnInit() {
    this.org = this.route.snapshot.data.org
    this.reservations = this.route.snapshot.data.setReservationsData.reservations
    const usageData = this.route.snapshot.data.valueMetricsTrial

    this.usage = new UsageData(
      this.route.snapshot.data.clusters,
      usageData,
      this.reservations,
      this.org,
    )
  }

  public selectPeriod(_sasd) {
    this.org = this.route.snapshot.data.org
  }
}
