import {
  OrganizationMemberDto,
  OrganizationRole,
} from '@camunda-cloud/cloud-node-libs'
import { OptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-select/cm-select'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

// INTERFACES

export interface RoleDescription {
  label: string
  description: string
  role: OrganizationRole
}

// USERS DATA

export class UsersData {
  public emailIsUnused
  public roleOptions: Array<OptionGroup> = []

  private members: OrganizationMemberDto[] = []

  constructor(private vvs: ViewVisibilitiesService) {}

  public setMembers(members: OrganizationMemberDto[]) {
    this.members = members
    this.emailIsUnused = {
      type: 'custom',
      validator: (value) => {
        const emailIsUsed = this.members
          .map((member) => member.email)
          .includes(value)

        if (emailIsUsed) {
          return {
            isValid: false,
            type: 'invalid',
            message: 'This email is already being used.',
          }
        } else {
          return { isValid: true }
        }
      },
    }
  }

  public getMembers(): OrganizationMemberDto[] {
    return this.members
  }

  public getEntityLabelValueForRoles(roles: OrganizationRole[]): string {
    return roles
      .map((role) => {
        return this.getRoleDescription(role).label
      })
      .join(', ')
  }

  // eslint-disable-next-line class-methods-use-this
  public getRoleDescription(role: OrganizationRole): RoleDescription {
    switch (role) {
      case OrganizationRole.OWNER:
        return {
          role,
          label: 'Owner',
          description: 'Full access to everything',
        }
      case OrganizationRole.ADMIN:
        return {
          role,
          label: 'Admin',
          description:
            'Full access to everything, except managing other Admins',
        }
      case OrganizationRole.MEMBER:
        return {
          role,
          label: 'Member',
          description: 'Full access to Clusters',
        }
      case OrganizationRole.SUPPORTAGENT:
        return {
          role,
          label: 'Support Agent',
          description: 'Read-only access',
        }
      case OrganizationRole.OPERATIONS_ENGINEER:
        return {
          role,
          label: 'Operations Engineer',
          description:
            'Full access to Console and Operate, except Cluster deletion privileges',
        }
      case OrganizationRole.TASK_USER:
        return {
          role,
          label: 'Task User',
          description:
            'Full access to Tasklist and read-only access to Clusters',
        }
      case OrganizationRole.DEVELOPER:
        return {
          role,
          label: 'Developer',
          description:
            'Full access to Console, except deletion privileges. Full access to Operate, and Tasklist',
        }
      case OrganizationRole.VISITOR:
        return {
          role,
          label: 'Visitor',
          description: 'Read-only access',
        }
      case OrganizationRole.ANALYST:
        return {
          role,
          label: 'Analyst',
          description:
            'Full access to Optimize and read-only access to Clusters',
        }
      default:
        throw Error(`Unknow Role ${role}`)
    }
  }

  public getRoleOptions(member?: OrganizationMemberDto): Array<OptionGroup> {
    const availableOrgRoles: OrganizationRole[] = []

    if (this.vvs.visibilities.members.add.admin.visible) {
      availableOrgRoles.push(
        ...[
          OrganizationRole.ADMIN,
          OrganizationRole.OPERATIONS_ENGINEER,
          OrganizationRole.ANALYST,
          OrganizationRole.TASK_USER,
          OrganizationRole.DEVELOPER,
          OrganizationRole.VISITOR,
        ],
      )
    } else if (this.vvs.visibilities.members.add.member.visible) {
      availableOrgRoles.push(
        ...[
          OrganizationRole.OPERATIONS_ENGINEER,
          OrganizationRole.ANALYST,
          OrganizationRole.TASK_USER,
          OrganizationRole.DEVELOPER,
          OrganizationRole.VISITOR,
        ],
      )
    }

    if (member?.roles.includes(OrganizationRole.MEMBER)) {
      availableOrgRoles.push(OrganizationRole.MEMBER)
    }

    return [
      {
        options: availableOrgRoles.map((role) => {
          return {
            label: this.getRoleDescription(role).label,
            description: this.getRoleDescription(role).description,
            value: role,
          }
        }),
      },
    ]
  }

  public updateRoleOptions(member?: OrganizationMemberDto) {
    this.roleOptions = this.getRoleOptions(member)
  }
}
