import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import {
  OrganizationDto,
  OrganizationMemberDto,
  OrganizationRole,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { environment } from '../../../../environments/environment'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { NotificationService } from '../../../services/notification.service'
import { OrgService } from '../../../services/org.service'
import { UserService } from '../../../services/user.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { UsersData } from './users.data'

@Component({
  selector: 'organization-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class OrganizationUsersComponent implements OnInit, OnChanges {
  public environment = environment
  public multiRolesEnabled: boolean = false

  public usersData: UsersData | undefined

  @Input() public org: OrganizationDto

  @ViewChild('addModal', { read: ElementRef })
  public addModal: ElementRef<CmModal>

  @ViewChild('addModalMultiRoles', { read: ElementRef })
  public addModalMultiRoles: ElementRef<CmModal>

  @ViewChild('editModalMultiRoles', { read: ElementRef })
  public editModalMultiRoles: ElementRef<CmModal>

  @ViewChild('assignOwnerModal', { read: ElementRef })
  public assignOwnerModal: ElementRef<CmModal>

  @ViewChild('newRoleDefinitionsModal', { read: ElementRef })
  public newRoleDefinitionsModal: ElementRef<CmModal>

  @ViewChild('removeModal', { read: ElementRef })
  public removeModal: ElementRef<CmModal>

  @ViewChild('resendInviteModal', { read: ElementRef })
  public resendInviteModal: ElementRef<CmModal>

  @ViewChild('changeOrgRoleModal', { read: ElementRef })
  public changeOrgRoleModal: ElementRef<CmModal>

  public userListCreateHandler: () => void
  public userListEntities: any[] = []
  public userListColumns = [
    {
      name: '',
      width: '20px',
    },
    { name: 'Name', width: '2fr' },
    { name: 'Email', width: '2fr', ellipsis: 'right' },
    { name: 'Roles', width: '2fr', ellipsis: 'right' },
    {
      name: '',
      width: '32px',
      overrideCSS: {
        justifyContent: 'end',
      },
    },
  ]
  public userListLoading: boolean = false

  public currentUserId: string

  public selectedMember: OrganizationMemberDto
  public selectedOrgRoles: OrganizationRole[] = []
  public targetRole: OrganizationRole

  public processing = false

  public mayRemoveMember(member: OrganizationMemberDto) {
    if (member.invite) {
      return true
    }

    if (
      this.vss.visibilities.members.remove.member.visible &&
      (member.roles.includes(OrganizationRole.MEMBER) ||
        member.roles.includes(OrganizationRole.SUPPORTAGENT))
    ) {
      return true
    }

    if (
      this.vss.visibilities.members.remove.admin.visible &&
      (member.roles.includes(OrganizationRole.MEMBER) ||
        member.roles.includes(OrganizationRole.ADMIN) ||
        member.roles.includes(OrganizationRole.SUPPORTAGENT))
    ) {
      return true
    }

    return false
  }

  public roleChangeAllowedForMember(
    member: OrganizationMemberDto,
    targetRole: OrganizationRole,
  ) {
    if (targetRole === OrganizationRole.OWNER) {
      return false
    }

    if (
      member.roles.includes(OrganizationRole.SUPPORTAGENT) ||
      member.roles.includes(OrganizationRole.OWNER)
    ) {
      return false
    }

    if (member.roles.includes(targetRole)) {
      return false
    }

    if (
      this.vss.visibilities.members.update.member.visible &&
      !this.vss.visibilities.members.update.admin.visible
    ) {
      return false
    }

    if (
      !this.vss.visibilities.members.update.member.visible &&
      !this.vss.visibilities.members.update.admin.visible
    ) {
      return false
    }

    return true
  }

  constructor(
    private orgService: OrgService,
    private userService: UserService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private mixpanel: MixpanelService,
    public vss: ViewVisibilitiesService,
    private ngZone: NgZone,
  ) {
    this.usersData = new UsersData(this.vss)
  }

  public ngOnChanges() {
    this.ngOnInit()
  }

  public ngOnInit() {
    this.multiRolesEnabled = this.vss.visibilities.featureFlags.multiRoles.visible
    this.authService.user$.subscribe((user) => {
      this.currentUserId = user?.sub ?? ''
    })

    this.userListCreateHandler = () =>
      this.ngZone.run(() => this.openAddModal())

    this.loadMembers()

    if (
      this.multiRolesEnabled &&
      this.vss.visibilities.members.newRoleDefinitionsModal.visible
    ) {
      this.openNewRoleDefinitionsModal()
    }
  }

  public openNewRoleDefinitionsModal() {
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL,
    )

    requestAnimationFrame(() => {
      this.newRoleDefinitionsModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          let formData = result.formData!
          if (formData.dontShowAgain === true) {
            this.userService
              .addEvent('console:new-role-definitions-modal')
              .subscribe()
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL_CONFIRMED,
            )
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL_CANCEL,
            )
          }
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_NEW_ROLE_DEFINITIONS_MODAL_CANCEL,
          )
        }
      })
    })
  }

  public openAddModal() {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_ORG_MEMBERS_ADD_DIALOG)
    this.usersData.updateRoleOptions()

    requestAnimationFrame(() => {
      if (this.multiRolesEnabled) {
        this.addModalMultiRoles.nativeElement.open().then((result) => {
          this.processing = true

          if (result.result === 'confirm') {
            let formData = result.formData!

            this.userListLoading = true
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_ORG_MEMBERS_ADD_DIALOG_CONFIRMED,
            )

            this.orgService
              .sendInvite(
                this.org.uuid,
                formData.addUserEmailAddress as string,
                formData.addUserOrgRoles as OrganizationRole[],
              )
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: `Invitation sent`,
                    description: `We sent an email to ${formData.addUserEmailAddress}`,
                    appearance: 'success',
                  })

                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_ADD_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'User could not be added',
                    appearance: 'error',
                  })
                },
              )
          }

          this.processing = false
        })
      } else {
        this.addModal.nativeElement.open().then((result) => {
          this.processing = true

          if (result.result === 'confirm') {
            let formData = result.formData!

            this.userListLoading = true
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_ORG_MEMBERS_ADD_DIALOG_CONFIRMED,
            )

            this.orgService
              .sendInvite(
                this.org.uuid,
                formData.addUserEmailAddress as string,
                formData.addUserOrgRole
                  ? ([formData.addUserOrgRole] as OrganizationRole[])
                  : [OrganizationRole.MEMBER],
              )
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: `Invitation sent`,
                    description: `We sent an email to ${formData.addUserEmailAddress}`,
                    appearance: 'success',
                  })

                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_ADD_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'User could not be added',
                    appearance: 'error',
                  })
                },
              )
          }

          this.processing = false
        })
      }
    })
  }

  public openEditModal(member: OrganizationMemberDto) {
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG,
    )

    requestAnimationFrame(() => {
      this.selectedMember = member
      this.selectedOrgRoles = [...member.roles]
      this.usersData.updateRoleOptions(member)
      this.editModalMultiRoles.nativeElement.open().then((result) => {
        this.processing = true

        if (result.result === 'confirm') {
          let formData = result.formData!

          this.userListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_CONFIRMED,
          )

          const newOrgRoles = formData.editUserOrgRoles as OrganizationRole[]

          if (member.invite) {
            this.orgService
              .changeRolesOfInvite(
                this.org.uuid,
                member.invite.inviteId,
                newOrgRoles,
              )
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: `Roles updated`,
                    description: `We've updated the roles for invitation of ${member.email}`,
                    appearance: 'success',
                  })

                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'Roles could not be added',
                    appearance: 'error',
                  })
                },
              )
          } else if (member.userId) {
            this.userService
              .changeOrgRoleOfUser(member.userId, this.org.uuid, newOrgRoles)
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'User roles updated',
                    appearance: 'success',
                  })
                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'User roles could not be updated',
                    appearance: 'error',
                  })
                },
              )
          }
        }

        this.processing = false
      })
    })
  }

  public openAssignNewOwnerModal(member: OrganizationMemberDto) {
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG,
    )

    requestAnimationFrame(() => {
      this.usersData.updateRoleOptions(member)
      this.selectedMember = member
      this.assignOwnerModal.nativeElement.open().then((result) => {
        this.processing = true

        if (result.result === 'confirm') {
          let formData = result.formData!

          this.userListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG_CONFIRMED,
          )

          const newOrgRoles = formData.editUserOrgRoles as OrganizationRole[]

          this.orgService
            .assignNewOwner(this.org.uuid, member.userId!, newOrgRoles)
            .subscribe(
              (_) => {
                this.notificationService.enqueueNotification({
                  headline: 'New Owner assigned',
                  appearance: 'success',
                })
                // make sure access token is updated
                window.location.reload()
              },
              (_error) => {
                this.mixpanel.track(
                  FrontendAnalyticEvents.LLIST_ORG_MEMBERS_ASSIGN_NEW_OWNER_DIALOG_ERROR,
                )
                this.notificationService.enqueueNotification({
                  headline: 'New Owner could not be assigned',
                  appearance: 'error',
                })
              },
            )
        }

        this.processing = false
      })
    })
  }

  public openResendInviteModal(selectedMember: OrganizationMemberDto) {
    this.selectedMember = selectedMember
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG,
    )

    requestAnimationFrame(() => {
      this.resendInviteModal.nativeElement.open().then((result) => {
        this.processing = true

        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG_CONFIRMED,
          )
          if (
            this.selectedMember.invite &&
            this.selectedMember.invite.accepted === false
          ) {
            this.orgService
              .resendInvite(this.org.uuid, this.selectedMember.invite.inviteId)
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'Invitation sent',
                    appearance: 'success',
                  })
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'Invitation could not be sent',
                    appearance: 'error',
                  })
                },
              )
          }
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_RESEND_INVITE_DIALOG_CANCELED,
          )
        }

        this.processing = false
      })
    })
  }

  public openRemoveModal(selectedMember: OrganizationMemberDto) {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_ORG_MEMBERS_REMOVE_DIALOG)
    this.selectedMember = selectedMember

    requestAnimationFrame(() => {
      this.removeModal.nativeElement.open().then((result) => {
        this.processing = true

        if (result.result === 'confirm') {
          this.userListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_REMOVE_DIALOG_CONFIRMED,
          )
          if (this.selectedMember.userId) {
            this.userService
              .removeUserFromOrganization(
                this.selectedMember.userId,
                this.org.uuid,
              )
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'User removed',
                    appearance: 'success',
                  })
                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_REMOVE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'User could not be removed',
                    appearance: 'error',
                  })
                },
              )
          } else if (this.selectedMember.invite) {
            this.orgService
              .cancelInvite(this.org.uuid, this.selectedMember.invite.inviteId)
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'Invitation cancelled',
                    appearance: 'success',
                  })
                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_REMOVE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'Invitation not be removed',
                    appearance: 'error',
                  })
                },
              )
          }
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_REMOVE_DIALOG_CANCELED,
          )
        }

        this.processing = false
      })
    })
  }

  public openChangeOrgRoleModal(
    selectedMember: OrganizationMemberDto,
    targetRole: OrganizationRole,
  ) {
    this.selectedMember = selectedMember
    this.targetRole = targetRole
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG,
    )

    // This is needed to allow the Modal to take focus, due to some stupid shit that materials menus are doing
    requestAnimationFrame(() => {
      this.changeOrgRoleModal.nativeElement.open().then((result) => {
        this.processing = true

        if (result.result === 'confirm') {
          this.userListLoading = true
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_CONFIRMED,
          )
          if (this.selectedMember.userId) {
            this.userService
              .changeOrgRoleOfUser(this.selectedMember.userId, this.org.uuid, [
                this.targetRole,
              ])
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'User role updated',
                    appearance: 'success',
                  })
                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'User role could not be updated',
                    appearance: 'error',
                  })
                },
              )
          } else if (this.selectedMember.invite) {
            this.orgService
              .changeRolesOfInvite(
                this.org.uuid,
                this.selectedMember.invite.inviteId,
                [this.targetRole],
              )
              .subscribe(
                (_) => {
                  this.notificationService.enqueueNotification({
                    headline: 'User role updated',
                    appearance: 'success',
                  })
                  this.loadMembers()
                },
                (_error) => {
                  this.mixpanel.track(
                    FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_ERROR,
                  )
                  this.notificationService.enqueueNotification({
                    headline: 'User role could not be updated',
                    appearance: 'error',
                  })
                },
              )
          }
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.LIST_ORG_MEMBERS_CHANGE_ROLE_DIALOG_CANCELED,
          )
        }

        this.processing = false
      })
    })
  }

  private loadMembers() {
    this.userListLoading = true
    this.orgService.getMembers(this.org.uuid).subscribe((members) => {
      this.usersData.setMembers(members)
      this.userListEntities = this.usersData.getMembers().map((member) => {
        return {
          data: [
            { type: 'image', src: this.USER_ICON },
            {
              type: 'text',
              content: `${member.name}${member.invite ? ' (pending)' : ''}`,
            },
            { type: 'text', content: member.email },
            {
              type: 'text',
              content: this.usersData.getEntityLabelValueForRoles(member.roles),
            },
            this.vss.visibilities.members.update.general.visible &&
            member.userId !== this.currentUserId
              ? this.getContextMenu(member)
              : { type: 'text', content: '' },
          ],
        }
      })

      this.userListLoading = false
    })
  }

  private getContextMenu(member: OrganizationMemberDto) {
    let contextMenu: {
      type: 'contextMenu'
      options: any[]
    } = {
      type: 'contextMenu',
      options: [],
    }
    let numberOfEntries = 0

    if (this.multiRolesEnabled) {
      // MULTI ORG ROLES
      contextMenu = {
        type: 'contextMenu',
        options: [],
      }
      // MAIN

      let mayEditMember: boolean = false
      let mayRemoveMember: boolean = false
      if (!member.roles.includes(OrganizationRole.OWNER)) {
        if (member.roles.includes(OrganizationRole.ADMIN)) {
          if (this.vss.visibilities.members.update.admin.visible) {
            mayEditMember = true
          }
          if (this.vss.visibilities.members.remove.admin.visible) {
            mayRemoveMember = true
          }
        } else {
          if (this.vss.visibilities.members.update.member.visible) {
            mayEditMember = true
          }
          if (this.vss.visibilities.members.remove.member.visible) {
            mayRemoveMember = true
          }
        }
        if (member.roles.includes(OrganizationRole.SUPPORTAGENT)) {
          mayEditMember = false
        }
      }

      const mainEntry = {
        options: [],
      }

      if (mayEditMember) {
        numberOfEntries++
        mainEntry.options.push(
          // EDIT
          {
            label: 'Edit',
            handler: (_event) =>
              this.ngZone.run(() => this.openEditModal(member)),
            isDisabled:
              member.roles.includes(OrganizationRole.OWNER) ||
              (member.roles.includes(OrganizationRole.ADMIN) &&
                !this.vss.visibilities.members.update.admin.visible) ||
              !this.vss.visibilities.members.update.member.visible,
          },
        )
      }

      if (
        !member.invite &&
        !member.roles.includes(OrganizationRole.OWNER) &&
        !member.roles.includes(OrganizationRole.SUPPORTAGENT) &&
        this.vss.visibilities.members.update.owner.visible
      ) {
        numberOfEntries++
        // ASSIGN NEW OWNER
        mainEntry.options.push({
          label: 'Assign as Owner',
          handler: (_event) =>
            this.ngZone.run(() => this.openAssignNewOwnerModal(member)),
          isDisabled: false,
        })
      }
      if (member.invite) {
        numberOfEntries++
        // RESEND INVITE
        mainEntry.options.push({
          label: 'Resend Invitation',
          handler: (_event) =>
            this.ngZone.run(() => this.openResendInviteModal(member)),
          isDisabled: member.invite.accepted === true,
        })
      }
      contextMenu.options.push(mainEntry)

      // REMOVE

      if (mayRemoveMember) {
        numberOfEntries++
        contextMenu.options.push({
          options: [
            {
              label: 'Remove',
              handler: (_event) =>
                this.ngZone.run(() => this.openRemoveModal(member)),
              isDangerous: true,
            },
          ],
        })
      }
    } else {
      // SINGLE ORG ROLES
      numberOfEntries = 3
      contextMenu = {
        type: 'contextMenu',
        options: [
          {
            title: 'Roles',
            options: [
              {
                title: member.roles.includes(OrganizationRole.ADMIN)
                  ? 'Active'
                  : '',
                label: 'Change Role to Admin',
                handler: (_event) =>
                  this.ngZone.run(() =>
                    this.openChangeOrgRoleModal(member, OrganizationRole.ADMIN),
                  ),
                isDisabled: !this.roleChangeAllowedForMember(
                  member,
                  OrganizationRole.ADMIN,
                ),
              },
              {
                title: member.roles.includes(OrganizationRole.MEMBER)
                  ? 'Active'
                  : '',
                label: 'Change Role to Member',
                handler: (_event) =>
                  this.ngZone.run(() =>
                    this.openChangeOrgRoleModal(
                      member,
                      OrganizationRole.MEMBER,
                    ),
                  ),
                isDisabled: !this.roleChangeAllowedForMember(
                  member,
                  OrganizationRole.MEMBER,
                ),
              },
            ],
          },
          {
            options: [
              {
                label: 'Resend Invitation',
                handler: (_event) =>
                  this.ngZone.run(() => this.openResendInviteModal(member)),
                isDisabled:
                  !member || !member.invite || member.invite.accepted === true,
              },
              {
                label: 'Remove',
                handler: (_event) =>
                  this.ngZone.run(() => this.openRemoveModal(member)),
                isDisabled: !this.mayRemoveMember(member),
                isDangerous: true,
              },
            ],
          },
        ],
      }
    }

    if (numberOfEntries > 0) {
      return contextMenu
    } else {
      return { type: 'text', content: '' }
    }
  }

  private USER_ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" fill="#62626E">
      <path fill-rule="evenodd" d="M1.16192798,19.5 C0.792463484,19.5 0.5,19.2181497 0.5,18.8620267 C0.5,15.5090856 3.33239404,12.7941176 6.79583544,12.7941176 L13.2041646,12.7941176 C16.667606,12.7941176 19.5,15.5090856 19.5,18.8620267 C19.5,19.2181497 19.2075365,19.5 18.838072,19.5 L18.838072,19.5 L1.16192798,19.5 Z M9.87447231,10.5588235 C7.1342188,10.5588235 4.91151638,8.28745989 4.91151638,5.52168984 C4.91151638,2.75591979 7.1342188,0.5 9.87447231,0.5 C12.6147258,0.5 14.8374282,2.74047594 14.8374282,5.52168984 C14.8374282,8.30290374 12.6147258,10.5588235 9.87447231,10.5588235 L9.87447231,10.5588235 Z"/>
  </svg>
  `),
    ),
  )}`
}
