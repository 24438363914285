/* eslint-disable class-methods-use-this */

import {
  RouteReuseStrategy,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
} from '@angular/router'
import { Injectable } from '@angular/core'

@Injectable()
export class NoCacheRouteReuseStrategy implements RouteReuseStrategy {
  public storedRouteHandles = new Map<string, DetachedRouteHandle>()

  public allowRetriveCache = {
    'search-results': true,
  }

  public shouldReuseRoute(
    before: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot,
  ): boolean {
    return false
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.storedRouteHandles.get(
      this.getPath(route),
    ) as DetachedRouteHandle
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getPath(route)
    if (Object.prototype.hasOwnProperty.call(this.allowRetriveCache, path)) {
      return true
    }
    return false
  }

  public store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle,
  ): void {
    this.storedRouteHandles.set(this.getPath(route), detachedTree)
  }

  private getPath(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig !== null && route.routeConfig.path !== null) {
      return route.routeConfig.path
    } else {
      return ''
    }
  }
}
