/* eslint-disable id-length */
// eslint-disable-next-line prefer-named-capture-group
const quantityRegexp = /^([+-]?[0-9.]+)([BeEinumkKMGTP]*[-+]?[0-9]*)$/u
const thresholdRegexp = /^[0-9]{1,3}%$/u
// eslint-disable-next-line prefer-named-capture-group
const storageClassRegexp = /^(standard|fast)(-v\d)?$/u
const envVarRegex = /^[a-zA-Z_]+[a-zA-Z0-9_]*=[A-Za-z0-9.,-:[\]]+$/u

// const undefinedOrNumber = t.union([t.number, t.undefined]);
const numberValidator = (val: any) => {
  let num = Number(val)
  return !isNaN(num)
}

const notEmptyStringValidator = (val: string) => {
  return val !== undefined && val.length > 0
}

const numberMin1Validator = (val: any) => {
  return numberValidator(val) && Number(val) > 0
}

const numberMin0OrUndefinedValidator = (val: any) => {
  return !val || (numberValidator(val) && Number(val) >= 0)
}

const thresholdValidator = (val: string) => {
  return thresholdRegexp.test(val)
}

const quantityValidator = (val: string) => {
  return quantityRegexp.test(val)
}

const storageClassValidator = (val: string) => {
  return storageClassRegexp.test(val)
}

const envVarsValidator = (val: string) => {
  return (
    val === undefined ||
    val.split(' ').reduce((a, b) => a && envVarRegex.test(b), true)
  )
}

const undefinedOrQuantityValidator = (val: string) => {
  return val === undefined || quantityValidator(val)
}

const undefinedOrStorageClassValidator = (val: string) => {
  return val === undefined || storageClassValidator(val!)
}

const undefinedOrEnvVarsValidator = (val: string) => {
  return val === undefined || envVarsValidator(val!)
}
const undefinedOrBooleanStringValidator = (val: any) => {
  return val === undefined || typeof val === 'boolean'
}

export function validate(val: any, type: any) {
  if (type.validator) {
    const isValid = type.validator(val)
    if (!isValid) {
      throw new Error(`${type.description} - was: ${val}`)
    }
  }
  const typeKeys = Object.keys(type)
  return typeKeys.reduce((prev, cur) => {
    if (
      val[cur] === undefined ||
      type[cur] === undefined ||
      val[cur] === null ||
      type[cur] === null
    ) {
      return true
    }
    return prev && validate(val[cur], type[cur])
  }, true)
}

export const Plan = {
  zeebe: {
    broker: {
      clusterSize: {
        type: 'number',
        description: 'the amount of borkers',
        placeholder: 'e.g. 1',
        validator: numberMin1Validator,
      },
      replicationFactor: {
        type: 'number',
        placeholder: 'e.g. 1',
        description: 'the replication factor configured into the zeebe nodes',
        validator: numberMin1Validator,
      },
      partitionsCount: {
        type: 'number',
        placeholder: 'e.g. 1',
        description: 'the number of partitions configured into the zeebe nodes',
        validator: numberMin1Validator,
      },
      persistentStorageCapacity: {
        type: 'text',
        validator: undefinedOrQuantityValidator,
        placeholder: 'e.g. 1GiB',
        description:
          'LEGACY-pre-autoscale-PVC! If the zeebe.broker.storage part is set, this will be ignored. The amount of storage to get provisioned. something like 32Gi or 42Mi or similar. undefined is okay',
      },
      persistentStorageClass: {
        type: 'text',
        placeholder: 'e.g. standard',
        validator: undefinedOrStorageClassValidator,
        description:
          'LEGACY-pre-autoscale-PVC! If the zeebe.broker.storage part is set, this will be ignored. Storage class, standard and fast are ok. undefined is ok',
      },
      storage: {
        storageClassName: {
          type: 'text',
          placeholder: 'e.g. fast-v2',
          validator: notEmptyStringValidator,
          description:
            'This needs to be set to a storageclass present in the k8s system. Can not be undefined if one value in storage: is set',
        },
        resources: {
          requests: {
            storage: {
              type: 'text',
              placeholder: 'e.g. 1GiB',
              validator: quantityValidator,
              description: 'The initial storage size of the statefulset',
            },
          },
          limits: {
            storage: {
              type: 'text',
              placeholder: 'e.g. 1GiB',
              validator: quantityValidator,
              description: 'The upper limit the pvc autoscaler can scale up to',
            },
          },
        },
        autoResizing: {
          threshold: {
            type: 'text',
            placeholder: 'e.g. 10%',
            validator: thresholdValidator,
            description:
              'The free space percentage threshold where vpc autoscaling will kicks in. (example 10%)',
          },
          increase: {
            type: 'text',
            placeholder: 'e.g. 1GiB',
            validator: quantityValidator,
            description:
              'The amount of disk space which is added as soon as autoscaling kicks in. (example 2Gi)',
          },
        },
      },
      resources: {
        limits: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory limit of a zeebe broker, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU limit of a zeebe broker, e.g. 150m, 0.5, 1',
          },
        },
        requests: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory request of a zeebe broker, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU request of a zeebe broker, e.g. 150m, 0.5, 1',
          },
        },
      },
      envVars: {
        type: 'textarea',
        placeholder: 'e.g. TEST=123',
        validator: undefinedOrEnvVarsValidator,
        description:
          'env vars going into the system, no linebreakes are allowed',
      },
    },
    gateway: {
      replicas: {
        type: 'number',
        placeholder: 'e.g. 0',
        validator: numberValidator,
        description: 'number of gateways',
      },
      standalone: {
        type: 'checkbox',
        placeholder: undefined,
        validator: undefinedOrBooleanStringValidator,
        description: 'if this is a standalone gateway',
      },
      backend: {
        resources: {
          limits: {
            memory: {
              type: 'text',
              placeholder: 'e.g. 150Mi',
              validator: undefinedOrQuantityValidator,
              description: 'Memory limit of an gateway pod, e.g. 150Mi, 2Gi',
            },
            cpu: {
              type: 'text',
              placeholder: 'e.g. 200m',
              validator: undefinedOrQuantityValidator,
              description: 'CPU limit of an gateway pod, e.g. 150m, 0.5, 1',
            },
          },
          requests: {
            memory: {
              type: 'text',
              placeholder: 'e.g. 150Mi',
              validator: undefinedOrQuantityValidator,
              description: 'Memory request of an gateway pod, e.g. 150Mi, 2Gi',
            },
            cpu: {
              type: 'text',
              placeholder: 'e.g. 200m',
              validator: undefinedOrQuantityValidator,
              description: 'CPU request of a an gateway pod, e.g. 150m, 0.5, 1',
            },
          },
        },
        envVars: {
          type: 'textarea',
          placeholder: 'e.g. TEST=123',
          validator: undefinedOrEnvVarsValidator,
          description:
            'env vars going into the system, no linebreakes are allowed',
        },
      },
    },
  },
  operate: {
    backend: {
      resources: {
        limits: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory limit of an operate pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU limit of an operate pod, e.g. 150m, 0.5, 1',
          },
        },
        requests: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory request of an operate pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU request of a an operate pod, e.g. 150m, 0.5, 1',
          },
        },
      },
      envVars: {
        type: 'textarea',
        placeholder: 'e.g. TEST=123',
        validator: undefinedOrEnvVarsValidator,
        description:
          'env vars going into the system, no linebreakes are allowed',
      },
    },
    replicas: {
      type: 'number',
      placeholder: 'e.g. 1',
      validator: numberMin1Validator,
      description: 'the replication for operate',
    },
    elasticsearch: {
      resources: {
        limits: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory limit of an elastic node, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU limit of an elastic node, e.g. 150m, 0.5, 1',
          },
        },
        requests: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory request of an elastic node, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU request of a an elastic node, e.g. 150m, 0.5, 1',
          },
        },
      },
      replicationFactor: {
        type: 'number',
        placeholder: 'e.g. 1',
        validator: numberMin1Validator,
        description:
          'the replication for elastic search (the value has no effect so far until it is evaluated in the k8s controller)',
      },
      persistentStorageCapacity: {
        type: 'text',
        placeholder: 'e.g. 1GiB',
        validator: undefinedOrQuantityValidator,
        description: 'storage for elasticsearch',
      },
      persistentStorageClass: {
        type: 'text',
        placeholder: 'e.g. standard',
        validator: undefinedOrStorageClassValidator,
        description: 'storageclass for elasticsearch. "", standard or fast',
      },
    },
  },
  tasklist: {
    backend: {
      resources: {
        limits: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory limit of a tasklist pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU limit of a tasklist pod, e.g. 150m, 0.5, 1',
          },
        },
        requests: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory request of a tasklist pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU request of a a tasklist pod, e.g. 150m, 0.5, 1',
          },
        },
      },
      envVars: {
        type: 'textarea',
        placeholder: 'e.g. TEST=123',
        validator: undefinedOrEnvVarsValidator,
        description:
          'env vars going into the system, no linebreakes are allowed',
      },
    },
    replicas: {
      type: 'number',
      placeholder: 'e.g. 1',
      validator: numberMin0OrUndefinedValidator,
      description: 'the replication for tasklist',
    },
  },
  optimize: {
    backend: {
      resources: {
        limits: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory limit of a optimize pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU limit of a optimize pod, e.g. 150m, 0.5, 1',
          },
        },
        requests: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description: 'Memory request of a optimize pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU request of a a optimize pod, e.g. 150m, 0.5, 1',
          },
        },
      },
      envVars: {
        type: 'textarea',
        placeholder: 'e.g. TEST=123',
        validator: undefinedOrEnvVarsValidator,
        description:
          'env vars going into the system, no linebreakes are allowed',
      },
    },
    replicas: {
      type: 'number',
      placeholder: 'e.g. 1',
      validator: numberMin0OrUndefinedValidator,
      description: 'the replication for optimize',
    },
  },
  worker: {
    replicas: {
      type: 'number',
      placeholder: 'e.g. 0',
      validator: numberValidator,
      description:
        'the amount of onboarding workers you want to have in this clusterplan',
    },
  },
  zeebeAnalytics: {
    backend: {
      resources: {
        limits: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description:
              'Memory limit of a zeebeAnalytics pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description: 'CPU limit of a zeebeAnalytics pod, e.g. 150m, 0.5, 1',
          },
        },
        requests: {
          memory: {
            type: 'text',
            placeholder: 'e.g. 150Mi',
            validator: undefinedOrQuantityValidator,
            description:
              'Memory request of a zeebeAnalytics pod, e.g. 150Mi, 2Gi',
          },
          cpu: {
            type: 'text',
            placeholder: 'e.g. 200m',
            validator: undefinedOrQuantityValidator,
            description:
              'CPU request of a a zeebeAnalytics pod, e.g. 150m, 0.5, 1',
          },
        },
      },
      envVars: {
        type: 'textarea',
        placeholder: 'e.g. TEST=123',
        validator: undefinedOrEnvVarsValidator,
        description:
          'env vars going into the system, no linebreakes are allowed',
      },
    },
    replicas: {
      type: 'number',
      placeholder: 'e.g. 1',
      validator: numberMin0OrUndefinedValidator,
      description: 'the replication for zeebeAnalytics',
    },
  },
}
