<cm-context></cm-context>
<div
  class="main-component"
  [ngClass]="{
    trial: vvs.visibilities.cluster.list.message.trialExpired.visible
  }"
>
  <div
    class="trial-expired-bar"
    *ngIf="vvs.visibilities.cluster.list.message.trialExpired.visible"
  >
    Your trial has expired. If you want to continue with Camunda Cloud, you need
    to upgrade to a paid plan.
    <cm-button
      size="small"
      appearance="secondary"
      label="Update Subscription"
      (cmPress)="navbar.openRequestPaidPlanModal()"
    ></cm-button>
  </div>

  <nav-bar #navbar></nav-bar>

  <main id="routerWrapper">
    <router-outlet></router-outlet>
  </main>

  <cm-footer
    text="{{ sha$ | async }} © Camunda Services GmbH 2021. All rights reserved."
  ></cm-footer>

  <div class="footerOverlays">
    <cm-button
      appearance="link"
      label="Cookie Preferences"
      (cmPress)="openOsano()"
    ></cm-button>

    <span *ngIf="stage()" class="stageIndicator" [ngClass]="stage()"></span>

    <span
      *ngIf="vvs.visibilities.navbar.supportAgent.visible"
      class="supportAgentIndicator"
    ></span>
  </div>
</div>
<cm-notification-container #notificationContainer></cm-notification-container>
<modal></modal>
