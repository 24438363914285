<cm-page #cmPage>
  <cm-page-tab label="Clusters">
    <div class="page">
      <div class="header">
        <h1>Camunda Cloud Console</h1>
        <div class="organizationName">{{currentOrg.name}}</div>
      </div>

      <div class="left">
        <cm-card>
          <h3 slot="header">Camunda Cloud Service</h3>
          <div class="serviceBoxes">
            <div
              class="serviceBox"
              *ngIf="vvs.visibilities.modeler.read.visible || vvs.visibilities.forms.read.visible"
            >
              <h4>Modeling</h4>
              <div class="linkBoxes">
                <a
                  class="linkBox"
                  (click)="navigateToDiagramsList($event)"
                  *ngIf="vvs.visibilities.modeler.read.visible"
                >
                  <img src="/assets/home/diagram.svg" width="34" height="34" />
                  <h5>Diagrams</h5>
                  <cm-text>Model & deploy Processes with BPMN</cm-text>
                </a>
                <a
                  class="linkBox"
                  (click)="navigateToFormsList($event)"
                  *ngIf="vvs.visibilities.forms.read.visible"
                >
                  <img src="/assets/home/form.svg" width="24" height="30" />
                  <h5>Forms</h5>
                  <cm-text>
                    Design Forms using a graphical Form Builder
                  </cm-text>
                </a>
              </div>
            </div>
            <div class="serviceBox">
              <h4>Execution</h4>
              <div class="linkBoxes">
                <a class="linkBox" (click)="navigateToClustersList($event)">
                  <img src="/assets/home/cluster.svg" width="32" height="32" />
                  <h5>Clusters</h5>
                  <cm-text>
                    Manage your Cloud Instances of Zeebe, Operate, Tasklist and
                    Optimize
                  </cm-text>
                </a>
                <a
                  class="linkBox"
                  (click)="navigateToConnectorsList($event)"
                  *ngIf="vvs.visibilities.featureFlags.nonProdFeatures.visible"
                >
                  <img src="/assets/home/cluster.svg" width="32" height="32" />
                  <h5>Connectors</h5>
                  <cm-text> Manage your Cloud Connectors </cm-text>
                </a>
              </div>
            </div>
          </div>
        </cm-card>

        <!-- Templates -->
        <cm-card *ngIf="vvs.visibilities.modeler.create.visible">
          <h3 slot="header">Start with templates</h3>
          <cm-text>
            Select a template to get started and customize it based on your use
            case.
          </cm-text>

          <br />
          <br />

          <div class="actionBoxes">
            <div
              class="actionBox"
              (click)="openSelectTemplateModal(templateCategory)"
              *ngFor="let templateCategory of templateCategories"
            >
              <h4>{{templateCategory.name}}</h4>
              <cm-text> {{templateCategory.description}} </cm-text>
              <div style="width: 100%; text-align: center">
                <img [src]="templateCategory.icon" width="34" height="34" />
              </div>
            </div>
          </div>
        </cm-card>
      </div>

      <div class="right">
        <cm-card>
          <h3 slot="header">Documentation</h3>
          <!-- TRIAL PLANS -->
          <div
            class="documentationBoxes"
            *ngIf="vvs.visibilities.home.documentation.showTrial.visible"
          >
            <div class="documentationBox">
              <h4>What is Camunda Cloud</h4>
              <cm-text>
                Find out more about Camunda Cloud and learn about its horizontal
                scalability, high availability and fault tolerance.
              </cm-text>
              <cm-link
                label="Learn more"
                href="{{externalLink.DOCS_WHAT_IS_CAMUNDA_CLOUD}}"
                (click)="openExternalLink(externalLink.DOCS_WHAT_IS_CAMUNDA_CLOUD)"
              ></cm-link>
            </div>

            <div class="documentationBox">
              <h4>Model and Deploy Processes</h4>
              <cm-text>
                Get to know BPMN and learn how to model and deploy your first
                process to Camunda Cloud.
              </cm-text>
              <cm-link
                label="Learn more"
                href="{{externalLink.DOCS_MODEL_YOUR_FIRST_PROCESS}}"
                (click)="openExternalLink(externalLink.DOCS_MODEL_YOUR_FIRST_PROCESS)"
              ></cm-link>
            </div>

            <div class="documentationBox">
              <h4>Using Camunda Cloud Clients</h4>
              <cm-text>
                Learn how to start process instances or integrate with other
                systems from your favourite language.
              </cm-text>
              <cm-link
                label="Learn more"
                href="{{externalLink.DOCS_CLIENTS}}"
                (click)="openExternalLink(externalLink.DOCS_CLIENTS)"
              ></cm-link>
            </div>
          </div>
          <!-- PROFESSIONAL PLANS -->
          <div
            class="documentationBoxes"
            *ngIf="vvs.visibilities.home.documentation.showProfessional.visible"
          >
            <div class="documentationBox">
              <h4>Model and Deploy Processes</h4>
              <cm-text>
                Get to know BPMN and learn how to model and deploy your first
                process to Camunda Cloud.
              </cm-text>
              <cm-link
                label="Learn more"
                href="{{externalLink.DOCS_MODEL_YOUR_FIRST_PROCESS}}"
                (click)="openExternalLink(externalLink.DOCS_MODEL_YOUR_FIRST_PROCESS)"
              ></cm-link>
            </div>

            <div class="documentationBox">
              <h4>Using Camunda Cloud Clients</h4>
              <cm-text>
                Learn how to start process instances or integrate with other
                systems from your favourite language.
              </cm-text>
              <cm-link
                label="Learn more"
                href="{{externalLink.DOCS_CLIENTS}}"
                (click)="openExternalLink(externalLink.DOCS_CLIENTS)"
              ></cm-link>
            </div>

            <div class="documentationBox">
              <h4>Message Correlation</h4>
              <cm-text>
                Learn more about the powerful feature of Message correlation and
                in which situations this can be useful for you.
              </cm-text>
              <cm-link
                label="Learn more"
                href="{{externalLink.DOCS_MESSAGE_CORRELATION}}"
                (click)="openExternalLink(externalLink.DOCS_MESSAGE_CORRELATION)"
              ></cm-link>
            </div>
          </div>
        </cm-card>

        <cm-card *ngIf="vvs.visibilities.home.documentation.showTrial.visible">
          <h3 slot="header">Feedback & Support</h3>
          <cm-text>
            Join the
            <cm-link
              label="Camunda Cloud forum"
              href="{{externalLink.FORUM}}"
              (click)="openExternalLink(externalLink.FORUM)"
            ></cm-link>
            or
            <cm-link
              label="Slack Community"
              href="{{externalLink.SLACK}}"
              (click)="openExternalLink(externalLink.SLACK)"
            ></cm-link
            >, where you can exchange ideas with other Zeebe and Camunda Cloud
            users, as well as the product developers.
          </cm-text>
        </cm-card>

        <cm-card
          *ngIf="vvs.visibilities.home.documentation.showProfessional.visible"
        >
          <h3 slot="header">Feedback & Support</h3>
          <cm-text>
            Do you have questions, feedback or requests? Please open an issue in
            our
            <cm-link
              label="Jira Support Project"
              href="{{externalLink.JIRA}}"
              (click)="openExternalLink(externalLink.JIRA)"
            ></cm-link>
          </cm-text>
          <br />
          <br />
          <cm-text>
            For more information about Enterprise support and additional support
            resources, please see
            <cm-link
              label="Enterprise Support"
              href="{{externalLink.ENTERPRISE_SUPPORT}}"
              (click)="openExternalLink(externalLink.ENTERPRISE_SUPPORT)"
            ></cm-link>
          </cm-text>
        </cm-card>
      </div>
    </div>
  </cm-page-tab>
</cm-page>

<select-template-dialog></select-template-dialog>
