import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { GenerationDto } from '../../../../commons/Generation.dto'
import { ApiService } from '../services/api.service'

@Injectable({
  providedIn: 'root',
})
export class GenerationsResolverService implements Resolve<GenerationDto[]> {
  constructor(private apiService: ApiService) {}
  public resolve(): Observable<GenerationDto[]> {
    return this.apiService.listGenerations()
  }
}
