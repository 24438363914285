import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  ClusterPlanTypesToConfig,
  ClusterPlanTypesToReserved,
  OrganizationDto,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
  ExtendedClusterPlanTypeReservation,
  ReservationsResolverService,
  SetReservationsData,
} from '../../resolver/reservations.resolver'
import { ApiService } from '../../services/api.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { NotificationService } from '../../services/notification.service'
import { OrgService } from '../../services/org.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'

@Component({
  selector: 'reserved-clusters',
  templateUrl: './reserved-clusters.component.html',
  styleUrls: ['./reserved-clusters.component.scss'],
})
export class ReservedClustersComponent implements OnInit {
  public org: OrganizationDto
  public reservations: ExtendedClusterPlanTypeReservation[]
  public edit = false
  public originalReservations: ClusterPlanTypesToReserved = {}
  public showSavedMessage: false
  public dirty$: Observable<boolean>
  public dirtyTrigger$: BehaviorSubject<undefined> = new BehaviorSubject(
    undefined,
  )
  public newReservationsBuffer: ClusterPlanTypesToReserved = {}

  public loading: boolean = false

  @ViewChild('confirmReservationChangeModal', { read: ElementRef })
  public confirmReservationChangeModal: ElementRef<CmModal>

  public deltas: Array<{
    name: string
    region: string
    update: string
    price: string
  }> = []
  constructor(
    private route: ActivatedRoute,
    private resolver: ReservationsResolverService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private orgService: OrgService,
    private mixpanel: MixpanelService,
    public vss: ViewVisibilitiesService,
  ) {
    this.dirty$ = this.dirtyTrigger$.asObservable().pipe(
      map((_) => {
        return Object.keys(this.newReservationsBuffer)
          .map((key) => {
            const delta =
              this.originalReservations[key] - this.newReservationsBuffer[key]
            return delta !== 0
          })
          .reduce((prev, curr) => {
            return prev || curr
          }, false)
      }),
    )
  }

  public ngOnInit() {
    this.org = this.route.snapshot.data.org
    this.newReservationsBuffer = this.route.snapshot.data.setReservationsData.newReservationsBuffer
    this.originalReservations = this.route.snapshot.data.setReservationsData.originalReservations
    this.reservations = this.route.snapshot.data.setReservationsData.reservations.sort(
      (a, b) => {
        // check if there is a real price in there
        const aPrice = parseInt(a.price.replace('$', '').replace('€', ''))
        const bPrice = parseInt(b.price.replace('$', '').replace('€', ''))

        if (!isNaN(aPrice) && !isNaN(bPrice)) {
          if (aPrice < bPrice) {
            return -1
          }
          if (aPrice > bPrice) {
            return 1
          }
          return 0
        }
        // just stupidly compare strings
        if (a.price > b.price) {
          return 1
        }
        if (a.price < b.price) {
          return -1
        }
        return 0
      },
    )
  }

  public triggerDirty() {
    this.dirtyTrigger$.next(undefined)
  }

  public editing(start: boolean) {
    this.edit = start

    if (start) {
      this.mixpanel.track(FrontendAnalyticEvents.RESERVATION_EDIT)
    } else {
      this.mixpanel.track(FrontendAnalyticEvents.RESERVATION_EDIT_STOP)
    }
  }

  public showSaveModal(
    currentOrg: OrganizationDto,
    reservations: ExtendedClusterPlanTypeReservation[],
  ) {
    this.deltas = []
    const data: {
      org: OrganizationDto
      reservations: ExtendedClusterPlanTypeReservation[]
      newReservations: ClusterPlanTypesToReserved
      originalReservations: ClusterPlanTypesToReserved
      salesPlanConfig: ClusterPlanTypesToConfig
    } = {
      org: this.org,
      reservations,
      newReservations: this.newReservationsBuffer,
      originalReservations: this.originalReservations,
      salesPlanConfig: currentOrg.organizationToSalesPlan!.salesPlan!
        .clusterPlanTypesToConfig,
    }

    data.reservations.forEach((res) => {
      if (res.editable) {
        const prevRes = data.originalReservations[res.clusterPlanUuid]
        const newRes = data.newReservations[res.clusterPlanUuid]
        const delta = newRes - prevRes

        let action: 'removed' | 'added'
        let object: 'reservation' | 'reservations'

        if (delta < 0) {
          action = 'removed'
        } else {
          action = 'added'
        }

        if (Math.abs(delta) === 1) {
          object = 'reservation'
        } else {
          object = 'reservations'
        }

        this.deltas.push({
          name: res.clusterPlanName,
          region: res.region,
          update: `${Math.abs(delta)} ${object} will be ${action}`,
          price: res.price,
        })
      }
    })

    this.mixpanel.track(FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG)

    requestAnimationFrame(() => {
      this.confirmReservationChangeModal.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_CONFIRMED,
          )
          this.loading = true
          let newReservations: ClusterPlanTypesToReserved = {}

          data.reservations.forEach((reservationLine) => {
            newReservations[reservationLine.clusterPlanUuid] =
              data.newReservations[reservationLine.clusterPlanUuid] +
              data.salesPlanConfig[reservationLine.clusterPlanUuid].minReserved
          })

          this.orgService
            .updateReservations(data.org.uuid, newReservations)
            .then(() => {
              this.loading = false
              this.notificationService.enqueueNotification({
                headline: 'Reservations updated',
                appearance: 'success',
              })
              this.apiService
                .getOrganization(this.org.uuid)
                .subscribe((org) => {
                  this.org = org
                  this.resolver
                    .resolveData(this.org)
                    .subscribe((setReservationsData: SetReservationsData) => {
                      this.newReservationsBuffer =
                        setReservationsData.newReservationsBuffer
                      this.originalReservations =
                        setReservationsData.originalReservations
                      this.reservations = setReservationsData.reservations
                    })
                })
            })
            .catch((_error) => {
              this.loading = false

              this.mixpanel.track(
                FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_ERROR,
              )
              this.notificationService.enqueueNotification({
                headline: 'Reservations could not be updated',
                appearance: 'error',
              })
            })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_CANCELED,
          )
        }
      })
    })
  }
}
