<cm-modal
  #createOrEditChannelModal
  [headline]="channel ? 'Edit Channel' : 'Add Channel'"
  [confirmLabel]="channel ? 'Save' : 'Create'"
  cancelLabel="Cancel"
  width="550"
>
  <cm-form>
    <cm-textfield
      label="Name"
      formName="name"
      [value]="channel?.name"
      required
      showRequired
    ></cm-textfield>
    <cm-textfield
      label="Description"
      formName="description"
      [value]="channel?.description"
      required
      showRequired
    ></cm-textfield>
    <cm-select
      label="Default Generation"
      formName="defaultGeneration"
      required
      showRequired
      [options]="generationOptions"
      [selectedOptions]="channel ? [channel.defaultGeneration.uuid] : []"
      (cmInput)="
        notifyNewDefaultGenerationSelected($event.detail.selectedOptions[0])
      "
    ></cm-select>
    <cm-checkbox
      label="Default Channel"
      formName="defaultChannel"
      [checked]="channel?.isDefault"
    ></cm-checkbox>
    <cm-select
      label="Allowed Generations"
      [options]="allowedGenerationsOptions"
      [selectedOptions]="selectedGenerationUuids"
      allowMultiple
      required
      formName="allowedGenerations"
    ></cm-select>
  </cm-form>
</cm-modal>
