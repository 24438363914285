<div class="flex-container">
  <h1>Billing</h1>
  <credit-card
    *ngIf="vvs.visibilities.billing.manageCcPayments.visible"
    [organization]="org"
  ></credit-card>
</div>

<div>
  <!-- VALUE METRICS FROM CAMUNDA DATABASE -->
  <div class="billing-grid" *ngIf="valueMetrics && valueMetrics.length > 0">
    <cm-card class="current-usage-card">
      <h2 *ngIf="valueMetricsOfCurrentPeriod" slot="header">
        Current Usage ({{
          getDate(valueMetricsOfCurrentPeriod) | date: 'MMMM, y'
        }})
      </h2>
      <h2 *ngIf="!valueMetricsOfCurrentPeriod" slot="header">Current Usage</h2>

      <div class="historyTable" *ngIf="taskUsersVisible">
        <div class="row">
          <div class="header">Type</div>
          <div class="header">Current Usage</div>
          <div class="header">Included in subscription</div>
        </div>
        <div class="row">
          <div>Process Instances</div>
          <div>{{ valueMetricsOfCurrentPeriod.processInstances }}</div>
          <div>{{ valueMetricsParameters.processInstances.included }}</div>
        </div>
        <div class="row">
          <div>Task Users</div>
          <div>{{ valueMetricsOfCurrentPeriod.taskUsers }}</div>
          <div>{{ valueMetricsParameters.taskUsers.included }}</div>
        </div>
      </div>

      <span *ngIf="!taskUsersVisible">
        <h3 class="subheadline">Process Instance</h3>
        <cm-datalist>
          <cm-datalist-item label="Current usage">
            <cm-text>{{
              valueMetricsOfCurrentPeriod.processInstances
            }}</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Included in subscription">
            <cm-text>{{
              valueMetricsParameters.processInstances.included
            }}</cm-text>
          </cm-datalist-item>
        </cm-datalist>
      </span>

      <span *ngIf="!valueMetricsOfCurrentPeriod" class="no-data"
        >No data available yet</span
      >
    </cm-card>
  </div>

  <!-- ENTERPRISE VALUE METRICS -->
  <div class="billing-grid" *ngIf="enterpriseData">
    <cm-card class="current-usage-card">
      <h2 slot="header">
        Current Yearly Period ({{
          enterpriseData.currentPeriod.start | date: 'mediumDate'
        }})
      </h2>

      <div class="historyTable">
        <div class="row">
          <div class="header">Type</div>
          <div class="header">Current Usage</div>
          <div class="header">Included in subscription</div>
        </div>
        <div class="row">
          <div>Process Instances</div>
          <div>
            {{ enterpriseData.currentPeriod.processInstances }}
          </div>
          <div>
            {{ enterpriseData.currentPeriod.processInstancesIncluded }}
          </div>
        </div>
        <div class="row">
          <div>Task Users</div>
          <div>
            {{ enterpriseData.currentPeriod.taskUsers }}
          </div>
          <div>
            {{ enterpriseData.currentPeriod.taskUsersIncluded }}
          </div>
        </div>
      </div>
    </cm-card>
  </div>

  <!-- PROFESSIONAL VALUE METRICS (CHARGEBEE) -->
  <div class="billing-grid" *ngIf="valueMetricsHistory">
    <cm-card class="current-usage-card">
      <h2 slot="header">Current Usage</h2>

      <div class="historyTable" *ngIf="taskUsersVisible">
        <div class="row">
          <div class="header">Type</div>
          <div class="header">Current Usage</div>
          <div class="header">Included in subscription</div>
        </div>
        <div class="row">
          <div>Process Instances</div>
          <div>
            {{
              getValueMetricTypeQuantity(
                valueMetricsHistory.current,
                'process-instances'
              )
            }}
          </div>
          <div>
            {{
              getValueMetricTypeFreeQuantity(
                valueMetricsHistory.current,
                'process-instances'
              )
            }}
          </div>
        </div>
        <div class="row">
          <div>Task Users</div>
          <div>
            {{
              getValueMetricTypeQuantity(
                valueMetricsHistory.current,
                'task-users'
              )
            }}
          </div>
          <div>
            {{
              getValueMetricTypeFreeQuantity(
                valueMetricsHistory.current,
                'task-users'
              )
            }}
          </div>
        </div>
      </div>

      <span *ngIf="!taskUsersVisible">
        <h3 class="subheadline">Process Instance</h3>
        <cm-datalist>
          <cm-datalist-item label="Current usage">
            <cm-text>{{
              getValueMetricTypeQuantity(
                valueMetricsHistory.current,
                'process-instances'
              )
            }}</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Included in subscription">
            <cm-text>{{
              getValueMetricTypeFreeQuantity(
                valueMetricsHistory.current,
                'process-instances'
              )
            }}</cm-text>
          </cm-datalist-item>
        </cm-datalist>
      </span>

      <span
        *ngIf="!valueMetricsOfCurrentPeriod && !valueMetricsHistory?.current"
        class="no-data"
        >No data available yet</span
      >
    </cm-card>
  </div>

  <div class="billing-grid">
    <!-- ENTERPRISE PLAN USAGE HISTORY -->
    <cm-card *ngIf="enterpriseData">
      <h2 slot="header">Usage History</h2>
      <div slot="header" style="justify-self: end">
        <cm-select
          style="min-width: 210px"
          label="Group by"
          labelAlignment="horizontal"
          (cmInput)="
            enterpriseData.selectPeriod($event.detail.selectedOptions[0])
          "
          [options]="[
            {
              options: [
                { label: 'Monthly Period', value: 'monthly' },
                { label: 'Yearly Period', value: 'yearly' }
              ]
            }
          ]"
          [selectedOptions]="['monthly']"
        ></cm-select>
      </div>

      <div class="historyTable">
        <div class="row foldableHeader">
          <div class="spacer"></div>
          <div class="header">Period</div>
          <div class="header">Process Instances</div>
          <div class="header">Task Users</div>
        </div>
        <ng-container *ngFor="let period of enterpriseData.selectedPeriodData">
          <div
            class="row foldable"
            [class.cursor]="period.processInstances > 0 && period.taskUsers > 0"
            [class.extended]="period.showCluster"
            (click)="
              period.showCluster =
                period.processInstances > 0 && period.taskUsers > 0
                  ? !period.showCluster
                  : false
            "
          >
            <cm-icon
              *ngIf="!period.showCluster"
              icon="right"
              [class.hidden]="
                period.processInstances === 0 && period.taskUsers === 0
              "
            ></cm-icon>
            <cm-icon
              *ngIf="period.showCluster"
              icon="down"
              [class.hidden]="
                period.processInstances === 0 && period.taskUsers === 0
              "
            ></cm-icon>
            <div>
              {{ period.start | date: 'mediumDate' }} -
              {{ enterpriseData.getEndDate(period.start) | date: 'mediumDate' }}
            </div>
            <div>{{ period.processInstances }}</div>
            <div>{{ period.taskUsers }}</div>
            <div class="folded" *ngIf="period.showCluster">
              <div class="row" *ngFor="let cluster of period.perCluster">
                <div class="spacer"></div>
                <div *ngIf="!cluster.name">{{ cluster.uuid }}</div>
                <div *ngIf="cluster.name">{{ cluster.name }}</div>
                <div>{{ cluster.processInstances }}</div>
                <div>{{ cluster.taskUsers }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </cm-card>

    <!-- PROFESSIONAL PLAN USAGE HISTORY -->
    <cm-card *ngIf="!enterpriseData">
      <h2 slot="header">Usage History</h2>

      <span
        *ngIf="
          valueMetrics.length === 0 &&
          (!valueMetricsHistory || valueMetricsHistory.terms?.length === 0)
        "
        class="no-data"
        >No data available yet</span
      >

      <div class="historyTable" *ngIf="valueMetrics.length > 0">
        <div class="row">
          <div class="header">Date</div>
          <div class="header">Process Instances</div>
          <div class="header" *ngIf="taskUsersVisible">Task Users</div>
        </div>
        <div class="row" *ngFor="let yearmonth of valueMetrics">
          <div>{{ getDate(yearmonth) | date: 'MMM, y' }}</div>
          <div>{{ yearmonth.processInstances }}</div>
          <div *ngIf="taskUsersVisible">{{ yearmonth.taskUsers }}</div>
        </div>
      </div>

      <div class="historyTable" *ngIf="valueMetricsHistory?.terms?.length > 0">
        <div class="row">
          <div class="header">Date</div>
          <div class="header">Process Instances</div>
          <div class="header" *ngIf="taskUsersVisible">Task Users</div>
        </div>
        <div class="row" *ngFor="let term of valueMetricsHistory.terms">
          <div>{{ term.invoiceDate | date: 'mediumDate' }}</div>
          <div>
            {{ getValueMetricTypeQuantity(term.usages, 'process-instances') }}
          </div>
          <div *ngIf="taskUsersVisible">
            {{ getValueMetricTypeQuantity(term.usages, 'task-users') }}
          </div>
        </div>
      </div>
    </cm-card>

    <cm-card>
      <h2 slot="header">Hardware Packages</h2>

      <!-- ENTERPRISE PLAN -->
      <span *ngIf="vvs.visibilities.billing.view.enterprise && enterpriseData">
        <cm-datalist labelWidth="200px">
          <cm-datalist-item label="Current usage">
            <cm-text>{{ enterpriseData.reservations.usage }}</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Included in Subscription">
            <cm-text>{{ enterpriseData.reservations.included }}</cm-text>
          </cm-datalist-item>
        </cm-datalist>
      </span>

      <!-- PROFESSIONAL PLAN (CHARGEBEE) -->
      <span *ngIf="!enterpriseData">
        <div class="loader" *ngIf="mode === 'processing'">
          <cm-loader></cm-loader>
        </div>

        <h3 class="subheadline">Base Package</h3>
        <cm-datalist labelWidth="150px">
          <cm-datalist-item label="Current usage">
            <cm-text>{{ basePackageIncluded.usage }}</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Reserved">
            <cm-text
              >{{ basePackageIncluded.currentReservations
              }}<span style="padding-left: 60px"
                ><i>(included in subscription)</i></span
              ></cm-text
            >
          </cm-datalist-item>
        </cm-datalist>

        <div class="subheader">
          <h3 class="subheadline">Additional Package</h3>
          <ng-container *ngIf="mode === 'view'">
            <cm-button
              appearance="primary"
              size="small"
              label="Edit"
              (cmPress)="edit()"
              [disabled]="vvs.visibilities.billing.reservations.update.disabled"
            >
            </cm-button>
          </ng-container>
          <ng-container *ngIf="mode === 'edit' || mode === 'processing'">
            <cm-button
              appearance="secondary"
              size="small"
              label="Cancel"
              (cmPress)="cancel()"
              [disabled]="mode === 'processing'"
            >
            </cm-button>
            <cm-button
              appearance="primary"
              size="small"
              label="Save"
              [disabled]="!dirty || mode === 'processing' || !allowed"
              (cmPress)="showSaveModal()"
            >
            </cm-button>
          </ng-container>
        </div>

        <cm-datalist labelWidth="150px">
          <cm-datalist-item label="Current usage">
            <cm-text>{{ basePackageAdditional.usage }}</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Reserved">
            <cm-text *ngIf="mode === 'view'">{{
              basePackageAdditional.currentReservations
            }}</cm-text>
            <input
              class="edit-reservation-input"
              *ngIf="mode === 'edit'"
              min="{{ basePackageAdditional.minReservations }}"
              max="{{ basePackageAdditional.maxReservations }}"
              type="number"
              [(ngModel)]="
                newReservationsBuffer[basePackageAdditional.clusterPlanUuid]
              "
              (change)="triggerDirty()"
            />
          </cm-datalist-item>
        </cm-datalist>

        <h3 class="subheadline">Specifications</h3>
        <cm-text
          >The maximum hardware resource limits a Cluster can consume per
          package:</cm-text
        >
        <cm-datalist labelWidth="150px">
          <cm-datalist-item label="CPU">
            <cm-text>{{ basePackageIncluded.hardware.cpu }}</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Memory">
            <cm-text>{{ basePackageIncluded.hardware.memory }} GiB</cm-text>
          </cm-datalist-item>
          <cm-datalist-item label="Storage">
            <cm-text>{{ basePackageIncluded.hardware.storage }} GiB</cm-text>
          </cm-datalist-item>
        </cm-datalist>
      </span>
    </cm-card>
  </div>
</div>

<cm-modal
  #confirmReservationChangeModal
  headline="Billing Summary"
  confirmLabel="Confirm"
  cancelLabel="Cancel"
>
  <cm-datalist *ngIf="deltas && deltas.length > 0">
    <cm-datalist-item label="Package Update">
      <cm-text>{{ deltas[0].update }}</cm-text>
    </cm-datalist-item>
  </cm-datalist>
  <p>
    Additional Packages will be charged directly to the account at the point of
    creation.
  </p>
</cm-modal>

<cm-modal
  #checkoutSuccessModal
  headline="Professional Plan activated"
  confirmLabel="Ok"
>
  <div class="checkout-success">
    <p class="head">
      We are happy to welcome you as a professional customer. <span> 🎉 </span>
    </p>
    <p class="text">
      Access your usage metrics and manage your Billing Account on the
      <b>Billing</b> page under your <b>Organization Settings</b>.
    </p>
  </div>
</cm-modal>
