import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import {
  ClusterPlanTypesToConfig,
  ClusterPlanTypesToReserved,
  ClusterUsageMetricsData,
  OrganizationDto,
  ValueMetricsPerYearMonth,
} from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import {
  BillingViewResponse,
  BillingViewTermUsage,
} from '@camunda-cloud/subscription-management'
import { environment } from '../../../../environments/environment'
import {
  ExtendedClusterPlanTypeReservation,
  ReservationsResolverService,
  SetReservationsData,
} from '../../../resolver/reservations.resolver'
import { ApiService } from '../../../services/api.service'
import {
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../../services/mixpanel.service'
import { NotificationService } from '../../../services/notification.service'
import { OrgService } from '../../../services/org.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'
import { UsageData } from './usage.data'

export interface ValueMetricsParameters {
  processInstances: {
    included: number
    pricePerAdditionalUnit: string
  }
  taskUsers: {
    included: number
    pricePerAdditionalUnit: string
  }
}

@Component({
  selector: 'organization-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class OrganizationBillingComponent implements OnInit {
  public org: OrganizationDto
  public reservations: ExtendedClusterPlanTypeReservation[]
  public originalReservations: ClusterPlanTypesToReserved = {}
  public newReservationsBuffer: ClusterPlanTypesToReserved = {}
  public valueMetricsOfCurrentPeriod: ValueMetricsPerYearMonth
  public valueMetrics: ValueMetricsPerYearMonth[] = []
  public valueMetricsHistory: BillingViewResponse
  public valueMetricsEnterprise: ClusterUsageMetricsData[]

  public mode: 'view' | 'edit' | 'processing' = 'view'
  public dirty: boolean = false
  public allowed: boolean = true

  public taskUsersVisible: boolean = true

  public basePackageIncluded: ExtendedClusterPlanTypeReservation
  public basePackageAdditional: ExtendedClusterPlanTypeReservation

  // ENTERPRISE
  public enterpriseData: UsageData | undefined

  public valueMetricsParameters: ValueMetricsParameters = {
    processInstances: {
      included: environment.includedProcessInstances,
      pricePerAdditionalUnit: environment.pricePerAdditionalProcessInstance,
    },
    taskUsers: {
      included: environment.includedTaskUsers,
      pricePerAdditionalUnit: environment.pricePerAdditionalTaskUser,
    },
  }

  @ViewChild('confirmReservationChangeModal', { read: ElementRef })
  public modalRef: ElementRef<CmModal>

  public deltas: Array<{
    name: string
    region: string
    update: string
    price: string
  }> = []

  @ViewChild('checkoutSuccessModal', { read: ElementRef })
  public checkoutSuccessModal: ElementRef<CmModal>

  constructor(
    private route: ActivatedRoute,
    private resolver: ReservationsResolverService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private orgService: OrgService,
    private mixpanel: MixpanelService,
    private ngZone: NgZone,
    public vvs: ViewVisibilitiesService,
  ) {}

  ngOnInit() {
    this.org = this.route.snapshot.data.org
    this.taskUsersVisible =
      this.org.chargebeeSite && this.org.subscriptionIds?.length > 0
    this.newReservationsBuffer = this.route.snapshot.data.setReservationsData.newReservationsBuffer
    this.originalReservations = this.route.snapshot.data.setReservationsData.originalReservations

    if (
      this.route.snapshot.data.valueMetrics &&
      this.route.snapshot.data.valueMetrics.length > 0
    ) {
      this.valueMetrics = this.route.snapshot.data.valueMetrics.sort((a, b) => {
        const yearMonthA = Number(
          `${a.year}${a.month < 10 ? '0' : ''}${a.month}`,
        )
        const yearMonthB = Number(
          `${b.year}${b.month < 10 ? '0' : ''}${b.month}`,
        )
        return yearMonthB - yearMonthA
      })
      this.valueMetricsOfCurrentPeriod = this.valueMetrics[0]
    }

    if (this.route.snapshot.data.valueMetricsHistory) {
      this.valueMetricsHistory = this.route.snapshot.data.valueMetricsHistory
    }

    this.reservations = this.route.snapshot.data.setReservationsData.reservations

    if (
      this.route.snapshot.data.valueMetricsEnterprise &&
      this.route.snapshot.data.valueMetricsEnterprise.length > 0
    ) {
      this.enterpriseData = new UsageData(
        this.route.snapshot.data.clusters,
        this.route.snapshot.data.valueMetricsEnterprise,
        this.reservations,
        this.org,
      )
    }
    this.basePackageIncluded = this.reservations.find(
      (reservation) => !reservation.editable,
    )
    this.basePackageAdditional = this.reservations.find(
      (reservation) =>
        reservation.clusterPlanUuid ===
          this.basePackageIncluded.clusterPlanUuid && reservation.editable,
    )

    setTimeout(() => {
      if (this.route.snapshot.queryParams.checkout) {
        this.ngZone.run(() => {
          this.checkoutSuccessModal.nativeElement.open()
        })
      }
    }, 1000)
  }

  public triggerDirty() {
    this.dirty = Object.keys(this.newReservationsBuffer)
      .map((key) => {
        const delta =
          this.originalReservations[key] - this.newReservationsBuffer[key]
        return delta !== 0
      })
      .reduce((prev, curr) => {
        return prev || curr
      }, false)

    this.allowed = Object.keys(this.newReservationsBuffer)
      .map((key) => {
        const delta =
          this.basePackageAdditional.usage - this.newReservationsBuffer[key]
        return delta <= 0
      })
      .reduce((prev, curr) => {
        return prev || curr
      }, false)

    this.allowed =
      this.allowed &&
      this.newReservationsBuffer[this.basePackageAdditional.clusterPlanUuid] <=
        this.basePackageAdditional.maxReservations
  }

  public edit() {
    this.mode = 'edit'
    this.mixpanel.track(FrontendAnalyticEvents.RESERVATION_EDIT)
  }

  public cancel() {
    this.dirty = false
    Object.keys(this.originalReservations).forEach((key) => {
      this.newReservationsBuffer[key] = this.originalReservations[key]
    })
    this.mode = 'view'
    this.mixpanel.track(FrontendAnalyticEvents.RESERVATION_EDIT_STOP)
  }

  // eslint-disable-next-line class-methods-use-this
  public getDate(valueMetrics: ValueMetricsPerYearMonth) {
    return new Date(
      `${valueMetrics.year}-${valueMetrics.month < 10 ? '0' : ''}${
        valueMetrics.month
      }-01`,
    )
  }

  // eslint-disable-next-line class-methods-use-this
  public getValueMetricTypeQuantity(
    usages: BillingViewTermUsage[],
    dataType: string,
  ): number {
    const usageItem = usages.find((usage) => usage.data === dataType)
    return usageItem ? usageItem.quantity : 0
  }

  // eslint-disable-next-line class-methods-use-this
  public getValueMetricTypeFreeQuantity(
    usages: BillingViewTermUsage[],
    dataType: string,
  ): number {
    const usageItem = usages.find((usage) => usage.data === dataType)
    return usageItem ? usageItem.included : 0
  }

  public showSaveModal() {
    const currentOrg = this.org
    const reservations = this.reservations
    this.deltas = []
    const data: {
      org: OrganizationDto
      reservations: ExtendedClusterPlanTypeReservation[]
      newReservations: ClusterPlanTypesToReserved
      originalReservations: ClusterPlanTypesToReserved
      salesPlanConfig: ClusterPlanTypesToConfig
    } = {
      org: this.org,
      reservations,
      newReservations: this.newReservationsBuffer,
      originalReservations: this.originalReservations,
      salesPlanConfig: currentOrg.organizationToSalesPlan!.salesPlan!
        .clusterPlanTypesToConfig,
    }

    data.reservations.forEach((res) => {
      if (res.editable) {
        const prevRes = data.originalReservations[res.clusterPlanUuid]
        const newRes = data.newReservations[res.clusterPlanUuid]
        const delta = newRes - prevRes

        let action: 'removed' | 'added'
        let object: 'Package' | 'Packages'

        if (delta < 0) {
          action = 'removed'
        } else {
          action = 'added'
        }

        if (Math.abs(delta) === 1) {
          object = 'Package'
        } else {
          object = 'Packages'
        }

        this.deltas.push({
          name: res.clusterPlanName,
          region: res.region,
          update: `${Math.abs(delta)} Additional ${object} will be ${action}`,
          price: res.price,
        })
      }
    })
    this.mixpanel.track(FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG)

    requestAnimationFrame(() => {
      this.modalRef.nativeElement.open().then((result) => {
        if (result.result === 'confirm') {
          this.mixpanel.track(
            FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_CONFIRMED,
          )
          this.mode = 'processing'
          let newReservations: ClusterPlanTypesToReserved = {}

          data.reservations.forEach((reservationLine) => {
            newReservations[reservationLine.clusterPlanUuid] =
              data.newReservations[reservationLine.clusterPlanUuid] +
              data.salesPlanConfig[reservationLine.clusterPlanUuid].minReserved
          })

          this.orgService
            .updateReservations(data.org.uuid, newReservations)
            .then(() => {
              this.notificationService.enqueueNotification({
                headline: 'Hardware Packages updated',
                appearance: 'success',
              })
              this.apiService
                .getOrganization(this.org.uuid)
                .subscribe((org) => {
                  this.org = org
                  this.resolver.resolveData(this.org).subscribe(
                    (setReservationsData: SetReservationsData) => {
                      this.newReservationsBuffer =
                        setReservationsData.newReservationsBuffer
                      this.originalReservations =
                        setReservationsData.originalReservations
                      this.reservations = setReservationsData.reservations
                      this.basePackageAdditional = this.reservations.find(
                        (reservation) =>
                          reservation.clusterPlanUuid ===
                            this.basePackageIncluded.clusterPlanUuid &&
                          reservation.editable,
                      )
                      this.mode = 'view'
                    },
                    (_error) => {
                      this.mode = 'view'
                    },
                  )
                })
            })
            .catch((_error) => {
              this.mode = 'view'

              this.mixpanel.track(
                FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_ERROR,
              )
              this.notificationService.enqueueNotification({
                headline: 'Hardware Packages could not be updated',
                appearance: 'error',
              })
            })
        } else {
          this.mixpanel.track(
            FrontendAnalyticEvents.RESERVATION_CONFIRM_DIALOG_CANCELED,
          )
        }
      })
    })
  }
}
