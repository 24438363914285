import { DatePipe } from '@angular/common'
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { CmModal } from '@camunda-cloud/common-ui-angular'
import { DropdownOptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-dropdown/cm-dropdown'
import { first } from 'rxjs/operators'
import { ConnectorDto } from '../../../../../commons/Connector.dto'
import { ConnectorType } from '../../../../../commons/enums/Connector.enum'
import { ApiService } from '../../services/api.service'
import {
  Component as MixpanelComponent,
  FrontendAnalyticEvents,
  MixpanelService,
} from '../../services/mixpanel.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'
@Component({
  selector: 'list-connectors',
  templateUrl: './list-connectors.component.html',
  styleUrls: ['./list-connectors.component.scss'],
})
export class ListConnectorsComponent implements OnInit {
  public MIXPANEL_COMPONENT = MixpanelComponent.listConnectors
  public connectors: ConnectorDto[]

  @ViewChild('deleteModal', { read: ElementRef })
  public deleteModal: ElementRef<CmModal>

  @ViewChild('multiDeleteModal', { read: ElementRef })
  public multiDeleteModal: ElementRef<CmModal>

  @ViewChild('createModal', { read: ElementRef })
  public createModal: ElementRef<CmModal>

  public connectorListColumns = [
    { name: '', width: '20px' },
    { name: 'Name', width: '3fr', ellipsis: 'right' },
    { name: 'Updated By', width: '2fr', ellipsis: 'right' },
    { name: 'Updated At', width: '2fr' },
    { name: 'Api Key', width: 'minmax(200px, 2fr)' },
    { name: '', width: '20px' },
  ]
  public connectorListEntities = []
  public connectorListCreateHandler: () => void
  public connectorListLoading: boolean = true
  public groupOptions: DropdownOptionGroup[] = []

  public connectorToBeDeleted: ConnectorDto
  public connectorsToBeDeleted: ConnectorDto[]

  public selectedConnector: ConnectorDto
  public currentOrg: OrganizationDto

  constructor(
    public vvs: ViewVisibilitiesService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private mixpanel: MixpanelService,
    private datePipe: DatePipe,
    private ngZone: NgZone,
  ) {}

  public ngOnInit() {
    this.currentOrg = this.route.snapshot.data.org
    this.connectors = this.route.snapshot.data.connectors

    this.connectorListCreateHandler = () => this.createNewConnector()
    this.groupOptions = [
      {
        options: [
          {
            label: 'Delete',
            isDangerous: true,
            handler: (event: any) => {
              this.openDeleteModals(
                event.detail.selectedEntities.map((entity) => entity.meta),
              )
            },
          },
        ],
      },
    ]

    this.connectorListLoading = true
    this.loadConnectors().then(() => {
      this.connectorListLoading = false
    })
  }

  private updateList() {
    this.connectorListEntities = this.connectors.map((connector) => {
      return {
        meta: connector.uuid,
        data: [
          { type: 'image', src: this.FORM_ICON },
          { type: 'text', content: connector.name },
          {
            type: 'text',
            content: connector.updatedBy,
          },
          {
            type: 'text',
            content: this.datePipe.transform(connector.updated, 'medium'),
          },
          {
            type: 'text',
            content: connector.apiKey,
          },
          {
            type: 'contextMenu',
            options: [
              {
                options: [
                  {
                    label: 'Delete',
                    isDangerous: true,
                    handler: () => {
                      this.openDeleteModal(connector)
                    },
                  },
                ],
              },
            ],
          },
        ],
      }
    })
  }

  private loadConnectors() {
    return this.apiService
      .listConnectors(this.currentOrg.uuid)
      .toPromise()
      .then((connectors) => {
        this.connectors = connectors
        this.updateList()
      })
  }

  public openDeleteModals(connectors: string[]) {
    this.mixpanel.track(
      FrontendAnalyticEvents.LIST_CONNECTORS_DELETE_CONNECTOR,
      {
        amount: connectors.length,
      },
    )

    this.connectorsToBeDeleted = this.connectors.filter((connector) =>
      connectors.includes(connector.uuid),
    )

    this.ngZone.run(() => {
      this.multiDeleteModal.nativeElement
        .open({
          preConfirmationHandler: () => {
            return new Promise((resolve, reject) => {
              Promise.all(
                this.connectorsToBeDeleted.map((connector) =>
                  this.apiService
                    .deleteConnector(this.currentOrg.uuid, connector.uuid)
                    .pipe(first())
                    .toPromise(),
                ),
              ).then(() => {
                this.loadConnectors().then(resolve, reject)
              }, reject)
            })
          },
        })
        .then((result) => {
          if (result.result === 'confirm') {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_CONNECTORS_DELETE_CONNECTOR_CONFIRMED,
              { amount: connectors.length },
            )
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_CONNECTORS_DELETE_CONNECTOR_CANCELED,
              { amount: connectors.length },
            )
          }
        })
    })
  }

  public openDeleteModal(connector: ConnectorDto) {
    this.mixpanel.track(FrontendAnalyticEvents.LIST_CONNECTORS_DELETE_CONNECTOR)
    this.connectorToBeDeleted = connector

    this.ngZone.run(() => {
      this.deleteModal.nativeElement
        .open({
          preConfirmationHandler: () => {
            return this.apiService
              .deleteConnector(this.currentOrg.uuid, connector.uuid)
              .toPromise()
              .then(() => {
                return this.loadConnectors()
              })
          },
        })
        .then((result) => {
          if (result.result === 'confirm') {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_CONNECTORS_DELETE_CONNECTOR_CONFIRMED,
            )
          } else {
            this.mixpanel.track(
              FrontendAnalyticEvents.LIST_CONNECTORS_DELETE_CONNECTOR_CANCELED,
            )
          }
        })
    })
  }

  public createNewConnector() {
    this.ngZone.run(() => {
      this.createModal.nativeElement.open({
        preConfirmationHandler: (data) => {
          return this.apiService
            .createConnector(
              this.currentOrg.uuid,
              data.formData?.type as ConnectorType,
              data.formData?.name as string,
              data.formData?.apiKey as string,
            )
            .toPromise()
            .then(() => {
              return this.loadConnectors()
            })
        },
      })
    })
  }

  public navigateToClusterList() {
    this.router.navigate([`org/${this.currentOrg.uuid}`])
  }

  private FORM_ICON = `data:image/svg+xml;base64,${btoa(
    unescape(
      encodeURIComponent(`<svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#62626E"
    >
      <path
        fill-rule="evenodd"
        d="M8,0 L8,5.5 C8,5.74545989 8.17687516,5.94960837 8.41012437,5.99194433 L8.5,6 L14,6 L14,17 C14,17.5522847 13.5522847,18 13,18 L1,18 C0.44771525,18 6.76353751e-17,17.5522847 0,17 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 L8,0 Z M9.5,0.5 L13.5,4.5 L9.5,4.5 L9.5,0.5 Z"
      />
    </svg>`),
    ),
  )}`
}
