import { Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { CmModal, CmTextfield } from '@camunda-cloud/common-ui-angular'
import { OptionGroup } from '@camunda-cloud/common-ui/dist/types/components/cm-select/cm-select'
import * as semver from 'semver'
import { GenerationDto } from '../../../../../../commons/Generation.dto'
import { GenerationService } from '../../../services/generation.service'
import { ViewVisibilitiesService } from '../../../services/view.visibilities.service'

@Component({
  selector: 'list-generations',
  templateUrl: './list-generations.component.html',
  styleUrls: ['./list-generations.component.scss'],
})
export class ListGenerationsComponent {
  public _generations: Array<
    GenerationDto & { createdByName: string; clusterCount: number }
  > = []

  @Input()
  set generations(
    generations: Array<
      GenerationDto & { createdByName: string; clusterCount: number }
    >,
  ) {
    this._generations = generations

    let generationOptions = [{ options: [] }]

    for (let generation of generations) {
      generationOptions[0].options.push({
        value: generation.uuid,
        label: `${generation.name}`,
        description: `
          uuid:${generation.uuid} 
          zeebe:${generation.versions.zeebe} 
          operate:${generation.versions.operate} 
          tasklist:${generation.versions.tasklist} 
          optimize:${generation.versions.optimize ?? 'none'}
          analytics:${generation.versions.zeebeAnalytics ?? 'none'}
        `,
      })
    }

    this.generationOptions = generationOptions
  }

  get generations() {
    return this._generations
  }

  @ViewChild('createGenerationModal', { read: ElementRef })
  public createGenerationModal: ElementRef<CmModal>

  @ViewChild('nameTextfield', { read: ElementRef })
  public nameTextfield: ElementRef<CmTextfield>

  public existingGenerationUuid: string

  public generationOptions: Array<OptionGroup>
  public prepopulationGeneration: GenerationDto
  public prepopulationUpgradeableFromGenerationUuids: Array<string> = []

  constructor(
    private generationService: GenerationService,
    private router: Router,
    public vss: ViewVisibilitiesService,
    private ngZone: NgZone,
  ) {}

  versionValidation = {
    type: 'custom',
    validator: (value: string) => {
      const version = value.split(':')[1]

      if (value.includes(' ')) {
        return {
          isValid: false,
          type: 'invalid',
          message: 'Spaces are not allowed.',
        }
      } else if (version === 'SNAPSHOT' || version.length === 7) {
        return { isValid: true }
      } else if (!semver.valid(version)) {
        return {
          isValid: false,
          type: 'invalid',
          message: 'Please enter a valid SemVer version.',
        }
      } else {
        return { isValid: true }
      }
    },
  }

  public updatableOptions: Array<OptionGroup> = []
  public openCreateGenerationDialog() {
    this.nameTextfield.nativeElement.value = ''
    this.updatableOptions = [
      {
        options: this.generations.map((generation) => {
          return {
            label: generation.name,
            description: `${generation.uuid} zeebe:${
              generation.versions.zeebe
            } operate:${generation.versions.operate} tasklist:${
              generation.versions.tasklist
            } optimize:${generation.versions.optimize ?? 'no'} analytics:${
              generation.versions.zeebeAnalytics ?? 'no'
            }`,
            value: generation.uuid,
          }
        }),
      },
    ]
    this.ngZone.run(() => {
      this.createGenerationModal.nativeElement.open().then(async (result) => {
        if (result.result === 'confirm') {
          const data = result.formData

          const createdGeneration = await this.generationService.create(
            data.name as string,
            {
              zeebe: data.zeebeVersion as string,
              operate: data.operateVersion as string,
              tasklist: data.tasklistVersion as string,
              optimize:
                (data.optimizeVersion as string)?.length > 0
                  ? (data.optimizeVersion as string)
                  : undefined,
              elasticSearchCurator: data.elasticCuratorVersion as string,
              elasticSearchOss: data.elasticOssVersion as string,
              zeebeAnalytics: data.zeebeAnalyticsVersion as string,
              // was: data.workerVersion as string - we will ignore this for now
              // https://github.com/camunda-cloud/changes/issues/68
              worker: undefined,
            },
            data.updateable as string[],
          )
          this.routeToGeneration(createdGeneration.generationUuid)
        }

        this.prepopulationGeneration = undefined
        this.prepopulationUpgradeableFromGenerationUuids = []
      })
    })
  }

  public prepopulateFromGeneration(selectedGenerationUuid) {
    this.prepopulationGeneration = this.generations.find(
      (generation) => generation.uuid === selectedGenerationUuid,
    )

    this.prepopulationUpgradeableFromGenerationUuids = this.prepopulationGeneration.upgradeableFrom.map(
      (generation) => generation.uuid,
    )
  }

  public routeToGeneration(generationUuid: string) {
    this.router.navigate([`/admin/versions/generations/${generationUuid}`])
  }
}
