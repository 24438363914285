import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { OrganizationRole } from '@camunda-cloud/cloud-node-libs'
import { first } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public newUserMetadataEvents: string[] = []

  public getUserById(userId: string) {
    return this.http.get<{ name: string; email: string }>(
      `${environment.accountsUrl}/api/users/${userId}`,
    )
  }

  public removeUserFromOrganization(userId: string, orgUuid: string) {
    return this.http.delete(
      `${environment.accountsUrl}/api/users/${userId}/organization/${orgUuid}`,
    )
  }

  public changeOrgRoleOfUser(
    userId: string,
    orgUuid: string,
    orgRoles: OrganizationRole[],
  ) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/organization/${orgUuid}/roles`,
      { orgRoles },
    )
  }

  public addEvent(event: string) {
    if (!this.newUserMetadataEvents.includes(event)) {
      this.newUserMetadataEvents.push(event)
    }
    return this.http.post(
      `${environment.accountsUrl}/api/users/settings/events/${event}`,
      {},
    )
  }

  public removeEvent(event: string) {
    return this.http.delete(
      `${environment.accountsUrl}/api/users/settings/events/${event}`,
      {},
    )
  }

  public acknowledgePrivacyNotice(userId: string, ack: boolean = true) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      { hasAcknowledgedPrivacyNotice: ack },
    )
  }

  public acknowledgeCookieBanner(
    userId: string,
    optIntoTracking: boolean = false,
    hasAcknowledgedCookieBanner: boolean = true,
  ) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      {
        hasAcknowledgedCookieBanner,
        hasOptedIntoPerformanceCookies: optIntoTracking,
      },
    )
  }

  public optIntoTracking(userId: string) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      { hasOptedIntoPerformanceCookies: true },
    )
  }

  public optOutOfTracking(userId: string) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      { hasOptedIntoPerformanceCookies: false },
    )
  }

  public dismissGseOverlay(userId: string) {
    return this._dismissGseOverlay(userId, true)
  }

  public dismissCardZeebeModeler(userId: string) {
    return this._dismissCardZeebeModeler(userId, true)
  }

  public dismissCardGithubSamples(userId: string) {
    return this._dismissCardGithubSamples(userId, true)
  }

  public async unDismissGseOverlay(userId) {
    await this._dismissCardZeebeModeler(userId, false).pipe(first()).toPromise()
    await this._dismissCardGithubSamples(userId, false)
      .pipe(first())
      .toPromise()
    await this._dismissGseOverlay(userId, false).pipe(first()).toPromise()
  }
  private _dismissGseOverlay(userId: string, dismiss: boolean) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      { hasDismissedGseOverlay: dismiss },
    )
  }
  private _dismissCardZeebeModeler(userId: string, dismiss: boolean) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      { hasDismissedCardZeebeModeler: dismiss },
    )
  }
  private _dismissCardGithubSamples(userId: string, dismiss: boolean) {
    return this.http.patch(
      `${environment.accountsUrl}/api/users/${userId}/settings/`,
      { hasDismissedCardGithubSamples: dismiss },
    )
  }
}
