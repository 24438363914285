<cm-modal
  #createClusterModal
  headline="Create New Cluster"
  cancelLabel="Cancel"
  confirmLabel="Create"
  position="top"
  width="530"
>
  <div *ngIf="!(readyToCreate$ | async)" class="loaderContainer">
    <cm-loader></cm-loader>
  </div>

  <div *ngIf="readyToCreate$ | async">
    <cm-form>
      <cm-form-group>
        <cm-textfield
          label="Cluster Name"
          required
          maxlength="50"
          show-required
          required
          form-name="name"
          [fieldSuffix]="{ type: 'maxlength' }"
        ></cm-textfield>
      </cm-form-group>
    </cm-form>

    <div class="filter-wrapper" *ngIf="formData.planTypes.length > 1">
      <h2 class="filter-title">Type</h2>
      <cm-filter
        [filters]="typeFilters"
        [activeFilterIndex]="typeFilterDefault"
        (cmFilterSelected)="selectPlanType($event)"
      ></cm-filter>

      <cm-text>
        {{ formData.planTypeIdToPlanType[selectedPlanTypeUuid].description }}
      </cm-text>
    </div>

    <div class="filter-wrapper">
      <h2 class="filter-title">Channels</h2>
      <cm-filter
        [filters]="channelFilters"
        [activeFilterIndex]="channelFilterDefault"
        (cmFilterSelected)="selectChannel($event)"
      ></cm-filter>

      <cm-text *ngIf="channelDescriptions[selectedChannelId]">
        {{ channelDescriptions[selectedChannelId] }}
      </cm-text>
    </div>

    <div
      class="filter-wrapper"
      *ngIf="
        formData.channelIdToGenerations[selectedChannelId] &&
        formData.channelIdToGenerations[selectedChannelId].length > 0
      "
    >
      <h2 class="filter-title">Generations</h2>
      <cm-filter
        [filters]="generationFilters"
        [activeFilterIndex]="generationFilterDefault"
        (cmFilterSelected)="selectGeneration($event)"
      ></cm-filter>
    </div>

    <div class="filter-wrapper">
      <h2 class="filter-title">Region</h2>
      <cm-filter
        [filters]="regionFilters"
        [activeFilterIndex]="regionFilterDefault"
        (cmFilterSelected)="selectRegion($event)"
      ></cm-filter>
    </div>
  </div>
</cm-modal>
