import { Component, Input, OnInit } from '@angular/core'
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'external-internal-client-org-level',
  templateUrl: './external-internal-client-org-level.component.html',
  styleUrls: ['./external-internal-client-org-level.component.scss'],
})
export class ExternalInternalClientOrgLevelComponent implements OnInit {
  @Input() orgUuid: string
  public clusterIds

  constructor(private apiService: ApiService) {}

  public ngOnInit() {
    this.apiService.getExternalClusters(this.orgUuid).subscribe((clusters) => {
      this.clusterIds = clusters.map((cluster) => cluster.uuid)
    })
  }
}
