import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { OrganizationDto } from '@camunda-cloud/cloud-node-libs'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { SelectTemplateDialogComponent } from '../../components/dialogs/select-template-dialog/select-template-dialog.component'
import { TemplateCategories } from '../../components/dialogs/select-template-dialog/template.constants'
import { TemplateCategory } from '../../components/dialogs/select-template-dialog/Template.types'
import {
  FrontendAnalyticEvents,
  MixpanelEvent,
} from '../../services/mixpanel.service'
import { NavbarService } from '../../services/navbar.service'
import {
  ExternalLink,
  NavigationService,
} from '../../services/navigation.service'
import { ViewVisibilitiesService } from '../../services/view.visibilities.service'

@Component({
  selector: 'dashboard-page',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  public userName$: Observable<string>
  public initials$: Observable<string>
  public tabChangedTo: BehaviorSubject<string>
  public currentOrg: OrganizationDto

  @ViewChild(SelectTemplateDialogComponent)
  private selectTemplateDialog: SelectTemplateDialogComponent
  public templateCategories = TemplateCategories()

  public externalLink = ExternalLink

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    public navigation: NavigationService,
    private navbarService: NavbarService,
    public vvs: ViewVisibilitiesService,
  ) {
    this.tabChangedTo = new BehaviorSubject('')

    this.userName$ = this.authService.user$.pipe(
      filter((userProfile) => userProfile !== null),
      map((userProfile) => {
        return userProfile.given_name
          ? userProfile.given_name
          : userProfile.name
      }),
    )

    this.initials$ = this.authService.user$.pipe(
      filter((userProfile) => userProfile !== null),
      map((userProfile) => {
        return userProfile.given_name && userProfile.family_name
          ? userProfile.given_name[0] + userProfile.family_name[0]
          : userProfile.email[0] + userProfile.email[1]
      }),
    )
  }

  public ngOnInit() {
    if (this.route.snapshot.data.checkout === true) {
      this.navbarService.openSwitchToProfessionalPlanCcModal()
    }
    this.currentOrg = this.route.snapshot.data.org
  }

  public navigateToDiagramsList(event) {
    event.preventDefault()

    this.navigation.navigateInApp({
      dest: 'diagrams:list',
      context: { orgId: this.currentOrg.uuid },
      mixpanel: {
        event: FrontendAnalyticEvents.HOME_LINK_TO_DIAGRAMS,
      },
    })
  }

  public navigateToFormsList(event) {
    event.preventDefault()

    this.navigation.navigateInApp({
      dest: 'forms:list',
      context: { orgId: this.currentOrg.uuid },
      mixpanel: {
        event: FrontendAnalyticEvents.HOME_LINK_TO_FORMS,
      },
    })
  }

  public navigateToClustersList(event) {
    event.preventDefault()

    this.navigation.navigateInApp({
      dest: 'cluster:list',
      context: { orgId: this.currentOrg.uuid },
      mixpanel: {
        event: FrontendAnalyticEvents.HOME_LINK_TO_CLUSTERS,
      },
    })
  }

  public navigateToConnectorsList(event) {
    event.preventDefault()

    this.navigation.navigateInApp({
      dest: 'connector:list',
      context: { orgId: this.currentOrg.uuid },
      mixpanel: {
        event: FrontendAnalyticEvents.HOME_LINK_TO_CLUSTERS,
      },
    })
  }

  public openGithubGse() {
    this.navigation.navigateExternal({
      dest: ExternalLink.GITHUB_GSE,
      mixpanel: {
        event: FrontendAnalyticEvents.HOME_LINK_TO_GITHUB_GSE,
      },
    })
  }

  public openExternalLink(externalLink: ExternalLink) {
    const dest: ExternalLink = externalLink
    let event: MixpanelEvent
    switch (externalLink) {
      case ExternalLink.DOCS_WHAT_IS_CAMUNDA_CLOUD:
        event = FrontendAnalyticEvents.HOME_LINK_TO_DOCS_WHAT_IS_CAMUNDA_CLOUD
        break
      case ExternalLink.DOCS_CLIENTS:
        event = FrontendAnalyticEvents.HOME_LINK_TO_DOCS_CLIENTS
        break
      case ExternalLink.DOCS_MESSAGE_CORRELATION:
        event = FrontendAnalyticEvents.HOME_LINK_TO_DOCS_MESSAGE_CORRELATION
        break
      case ExternalLink.DOCS_MODEL_YOUR_FIRST_PROCESS:
        event =
          FrontendAnalyticEvents.HOME_LINK_TO_DOCS_MODEL_YOUR_FIRST_PROCESS
        break
      case ExternalLink.JIRA:
        event = FrontendAnalyticEvents.HOME_LINK_TO_FEEDBACK_JIRA
        break
      case ExternalLink.ENTERPRISE_SUPPORT:
        event = FrontendAnalyticEvents.HOME_LINK_TO_FEEDBACK_ENTERPRISE_SUPPORT
        break
      case ExternalLink.SLACK:
        event = FrontendAnalyticEvents.HOME_LINK_TO_FEEDBACK_SLACK
        break
      case ExternalLink.FORUM:
        event = FrontendAnalyticEvents.HOME_LINK_TO_FEEDBACK_FORUM
        break
    }
    if (event) {
      this.navigation.navigateExternal({
        dest,
        mixpanel: {
          event,
        },
      })
    }
  }

  public openSelectTemplateModal(templateCategory?: TemplateCategory) {
    this.selectTemplateDialog
      .open({
        preselectedTemplateCategory: templateCategory
          ? templateCategory.category
          : undefined,
        orgId: this.currentOrg.uuid,
      })
      .then((_response) => {})
  }
}
