export interface GenericMetricValue {
  timestamp: number
  value: number
}

export interface GenericMetricValues {
  metric: any
  name: string
  values: GenericMetricValue[]
}

export interface ZeebeMetrics {
  activatedProcesses?: GenericMetricValues[]
  deployments?: GenericMetricValues[]
  incidents?: GenericMetricValues[]
  capacity?: GenericMetricValues[]
  throughput?: GenericMetricValues[]
}

export enum ZeebeMetricType {
  PROCESSES = 'processes',
  CAPACITY = 'capacity',
  THROUGHPUT = 'throughput',
  CAPACITY_ZEEBE = 'capacity-zeebe',
  CAPACITY_ELASTIC = 'capacity-elastic',
}

export enum TimeFrame {
  H1 = '1h',
  H6 = '6h',
  H12 = '12h',
  D1 = '1d',
  D2 = '2d',
  D4 = '4d',
  W1 = '1w',
}

export interface CapacityConfig {
  clusterId: string
  sumBy: 'namespace' | 'persistentvolumeclaim'
  persistentvolumeclaim: 'data-zeebe' | 'elasticsearch'
}

export interface ValueMetricsProcessInstancesConfig {
  orgId: string
  clusterIds: string[]
  timeframe: TimeFrame
  latestAvailableTimestamp: number
}

export interface ValueMetricsRequestConfig {
  orgId?: string
  perCluster?: boolean
  clusterIds: string[]
  timeframe: TimeFrame
  latestAvailableTimestamp: number
}
