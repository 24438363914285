<cm-entity-list
  [enableCreateButton]="vvs.visibilities.admin.channels.create.visible"
  headline="Channels"
  createButtonLabel="Add Channel"
  [createHandler]="createHandler"
  [columns]="columns"
  [entities]="entities"
  [loading]="loading"
></cm-entity-list>

<channel-edit
  [generations]="generations"
  (channelsChanged)="updateChannels()"
></channel-edit>
