import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, of, throwError } from 'rxjs'
import {
  catchError,
  concatMap,
  delay,
  retryWhen,
  shareReplay,
} from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { Component, MixpanelService } from '../services/mixpanel.service'
import { NotificationService } from '../services/notification.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorNotificationInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private mixpanelService: MixpanelService,
  ) {}

  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(req)
      .pipe(
        retryWhen((error) =>
          error.pipe(
            concatMap((error, count) => {
              if (error.status === 521 || error.status === 523) {
                return of(error)
              }
              return throwError(error)
            }),
            delay(3000),
          ),
        ),
        catchError((err) => {
          let expectedFail = false
          switch (err.status) {
            case 401:
              this.router.navigate([
                'error',
                {
                  title: 'Unauthorized',
                  message:
                    'Please contact Camunda Cloud Support (Menu in Navigation Bar)',
                },
              ])
              break
            case 403:
              this.router.navigate([
                'error',
                {
                  title: 'Forbidden',
                  message:
                    'Please contact Camunda Cloud Support (Menu in Navigation Bar)',
                },
              ])
              break
            case 404:
              if (!req.url.includes('/api/users')) {
                this.router.navigate([
                  'error',
                  {
                    title: 'Not found',
                    message: 'Requested resource not found',
                  },
                ])
              } else {
                expectedFail = true
              }
              break
            case 500:
              this.router.navigate([
                'error',
                {
                  title: 'Something went wrong',
                  message: 'Please try again later',
                },
              ])
              break
            default:
              break
          }
          if (!expectedFail) {
            let fullUrl = req.url
            if (!req.url.startsWith('http')) {
              fullUrl = environment.backendUrl + req.url
            }
            const url = new URL(fullUrl)
            const path = url.pathname.replace(
              /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gu,
              '',
            )
            const host = url.hostname

            this.mixpanelService.track(
              {
                view: Component.backendError,
                action: req.method,
                element: null,
              },
              {
                httpCode: err.status,
                path,
                host,
              },
            )

            if (!environment.production) {
              this.notificationService.enqueueNotification({
                headline: `${req.method} on ${req.url} bricked`,
                description: `${err.status} - ${err.error}`,
                appearance: 'error',
              })
            }
          }
          return throwError(err)
        }),
      )
      .pipe(shareReplay(1))
  }
}
