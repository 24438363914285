<cm-entity-list
  [enableCreateButton]="!vss.visibilities.members.add.general.disabled"
  [loading]="userListLoading"
  headline="Users"
  createButtonLabel="Add New User"
  [createHandler]="userListCreateHandler"
  [columns]="userListColumns"
  [entities]="userListEntities"
>
</cm-entity-list>

<cm-modal
  #removeModal
  headline="Remove User"
  cancelLabel="Cancel"
  confirmLabel="Remove"
  confirmAppearance="danger"
  [confirmDisabled]="processing"
>
  <cm-text *ngIf="selectedMember && selectedMember.userId">
    Are you sure you want to remove
    <b>{{ selectedMember ? selectedMember.name : '' }}</b> from your
    organization?
  </cm-text>
  <cm-text *ngIf="selectedMember && selectedMember.invite">
    Are you sure you want to cancel the invitation for
    <b>{{ selectedMember ? selectedMember.email : '' }}</b
    >?
  </cm-text>
</cm-modal>

<cm-modal
  #addModal
  headline="Add New User"
  cancelLabel="Cancel"
  confirmLabel="Add"
  width="555"
>
  <cm-form>
    <cm-textfield
      label="Email Address"
      type="email"
      required
      formName="addUserEmailAddress"
      validationStyle="delay"
      [validation]="usersData.emailIsUnused"
    ></cm-textfield>

    <ng-container *ngIf="vss.visibilities.members.add.admin.visible">
      <cm-radiobutton-group
        formName="addUserOrgRole"
        label="User Role"
        required
        value="member"
      >
        <cm-radiobutton
          label="Member"
          helperText="Can manage and use Clusters and Clients, has access to Operate."
          value="member"
        ></cm-radiobutton>
        <cm-radiobutton
          label="Admin"
          helperText="Full Member access and can manage Organization."
          value="admin"
        ></cm-radiobutton>
      </cm-radiobutton-group>
    </ng-container>

    <cm-text
      *ngIf="
        !vss.visibilities.members.add.admin.visible &&
        vss.visibilities.members.add.member.visible
      "
    >
      User will be added as a Member who can manage and use Clusters and
      Clients. User has access to Operate.
    </cm-text>
  </cm-form>
</cm-modal>

<!-- MULTI ROLES -->

<cm-modal
  #addModalMultiRoles
  headline="Add New User"
  cancelLabel="Cancel"
  confirmLabel="Add"
  width="555"
>
  <cm-form>
    <cm-textfield
      label="Email Address"
      type="email"
      required
      formName="addUserEmailAddress"
      validationStyle="delay"
      [validation]="usersData.emailIsUnused"
    ></cm-textfield>

    <cm-select
      label="Role(s)"
      [options]="usersData.roleOptions"
      allowMultiple
      required
      formName="addUserOrgRoles"
    ></cm-select>
  </cm-form>
</cm-modal>

<cm-modal
  #editModalMultiRoles
  headline="Edit User"
  cancelLabel="Cancel"
  confirmLabel="Edit"
  width="555"
>
  <cm-form>
    <cm-textfield
      label="Email Address"
      [value]="selectedMember ? selectedMember.email : ''"
      type="email"
      disabled
      formName="editUserEmailAddress"
    ></cm-textfield>

    <cm-select
      label="Role(s)"
      [options]="usersData.roleOptions"
      [selectedOptions]="selectedOrgRoles"
      allowMultiple
      required
      formName="editUserOrgRoles"
    ></cm-select>
  </cm-form>
</cm-modal>

<cm-modal
  #assignOwnerModal
  headline="Assign New Owner"
  cancelLabel="Cancel"
  confirmLabel="Assign"
  width="555"
>
  <cm-banner appearance="warning">
    By assigning a new owner, you are stepping down as owner. The new owner will
    have full access rights to the organization.
  </cm-banner>

  <cm-form style="padding-top: 10px">
    <cm-textfield
      label="New Owner"
      [value]="selectedMember ? selectedMember.email : ''"
      type="email"
      disabled
    ></cm-textfield>

    <cm-text> Select your new role(s) for this organization: </cm-text>

    <cm-select
      label="Role(s)"
      [options]="usersData.roleOptions"
      allowMultiple
      required
      formName="editUserOrgRoles"
    ></cm-select>
  </cm-form>
</cm-modal>

<cm-modal
  #newRoleDefinitionsModal
  headline="New Role Definitions in Camunda Cloud"
  confirmLabel="OK"
  width="555"
>
  <cm-text>
    In the latest release, we added new roles to the user management. The new
    roles allow you to control better who has access to which application and
    resources.
  </cm-text>
  <cm-text>
    With this change, the old "Member" role is deprecated, and we recommend
    assigning users to the new role definitions.
  </cm-text>
  <cm-text>
    You can read more about the new role definitions in our
    <cm-link
      href="https://docs.camunda.io/docs/components/cloud-console/manage-organization/manage-users/"
      label="documentation"
    ></cm-link
    >.
  </cm-text>
  <cm-form style="padding-top: 10px">
    <cm-checkbox
      label="Do not show this message again"
      formName="dontShowAgain"
    ></cm-checkbox>
  </cm-form>
</cm-modal>

<cm-modal
  #resendInviteModal
  headline="Resend Invitation"
  cancelLabel="Cancel"
  confirmLabel="Resend Invitation"
  [confirmDisabled]="processing"
>
  <cm-text>
    Are you sure you want to resend the invitation to
    <b>{{ selectedMember ? selectedMember.email : '' }}</b
    >?
  </cm-text>
</cm-modal>

<cm-modal
  #changeOrgRoleModal
  headline="Change Role of User"
  cancelLabel="Cancel"
  confirmLabel="Change Role"
  [confirmDisabled]="processing"
>
  <cm-text>
    Are you sure you want to change the Role of
    <b>{{ selectedMember ? selectedMember.name : '' }}</b> to
    <b>{{ targetRole }}</b
    >?
  </cm-text>
</cm-modal>
