const convertSVGToBase64 = (content) =>
  `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(content)))}`

export const TAG_DEFAULT = convertSVGToBase64(
  `<svg width="52" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#4D90FF" width="52" height="18" rx="5"/><path d="M9.023 13h2.574c1.914 0 3.146-1.276 3.146-3.839 0-2.563-1.232-3.839-3.146-3.839H9.023V13Zm.924-.814v-6.05h1.65c1.265 0 2.156.88 2.156 2.398v1.254c0 1.518-.891 2.398-2.156 2.398h-1.65Zm8.503.946c1.067 0 1.881-.528 2.244-1.298l-.627-.451c-.297.627-.847.979-1.562.979-1.056 0-1.683-.737-1.683-1.716v-.264h4.081v-.418c0-1.606-.935-2.772-2.453-2.772-1.54 0-2.552 1.166-2.552 2.97 0 1.804 1.012 2.97 2.552 2.97Zm0-5.203c.902 0 1.507.671 1.507 1.672v.121h-3.135v-.077c0-.99.671-1.716 1.628-1.716ZM22.663 13h.88V8.094h1.221v-.77h-1.221V5.63h1.221v-.77h-.858c-.836 0-1.243.539-1.243 1.32v1.144h-.924v.77h.924V13Zm7.876 0v-.77h-.649V9.106c0-1.199-.814-1.914-2.178-1.914-1.023 0-1.705.506-2.013 1.111l.528.495c.308-.517.759-.847 1.441-.847.902 0 1.342.44 1.342 1.243v.561h-1.122c-1.65 0-2.42.594-2.42 1.661 0 1.056.704 1.716 1.903 1.716.792 0 1.397-.363 1.639-1.056h.055c.066.528.33.924.979.924h.495Zm-3.025-.616c-.682 0-1.122-.319-1.122-.88v-.231c0-.55.451-.858 1.452-.858h1.166v.935c0 .583-.627 1.034-1.496 1.034Zm7.777.616h.88V7.324h-.88v3.927c0 .748-.682 1.089-1.375 1.089-.847 0-1.298-.517-1.298-1.529V7.324h-.88v3.641c0 1.375.748 2.167 1.914 2.167.946 0 1.375-.517 1.595-1.056h.044V13Zm4.466 0v-.77h-.836V4.86h-.88v7.227c0 .528.33.913.935.913h.781Zm3.575 0v-.77h-1.155V8.094h1.243v-.77h-1.243V5.751h-.792v1.056c0 .385-.121.517-.495.517h-.528v.77h.935v3.971c0 .55.33.935.935.935h1.1Z" fill="#FFF" fill-rule="nonzero"/></g></svg>`,
)

export const TAG_INTERNAL = convertSVGToBase64(
  `<svg width="56" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#4D90FF" width="56" height="18" rx="5"/><path d="M11.74 13v-.77h-1.078V6.092h1.078v-.77H8.66v.77h1.078v6.138H8.66V13zm2.475 0V9.062c0-.726.682-1.078 1.386-1.078.847 0 1.287.517 1.287 1.529V13h.88V9.359c0-1.375-.737-2.167-1.903-2.167-.847 0-1.331.418-1.606 1.056h-.044v-.924h-.88V13h.88Zm7.722 0v-.77h-1.155V8.094h1.243v-.77h-1.243V5.751h-.792v1.056c0 .385-.121.517-.495.517h-.528v.77h.935v3.971c0 .55.33.935.935.935h1.1Zm3.597.132c1.067 0 1.881-.528 2.244-1.298l-.627-.451c-.297.627-.847.979-1.562.979-1.056 0-1.683-.737-1.683-1.716v-.264h4.081v-.418c0-1.606-.935-2.772-2.453-2.772-1.54 0-2.552 1.166-2.552 2.97 0 1.804 1.012 2.97 2.552 2.97Zm0-5.203c.902 0 1.507.671 1.507 1.672v.121h-3.135v-.077c0-.99.671-1.716 1.628-1.716ZM30.319 13V9.15c0-.539.572-.946 1.518-.946h.517v-.88h-.341c-.924 0-1.441.506-1.639 1.045h-.055V7.324h-.88V13h.88Zm4.037 0V9.062c0-.726.682-1.078 1.386-1.078.847 0 1.287.517 1.287 1.529V13h.88V9.359c0-1.375-.737-2.167-1.903-2.167-.847 0-1.331.418-1.606 1.056h-.044v-.924h-.88V13h.88Zm9.988 0v-.77h-.649V9.106c0-1.199-.814-1.914-2.178-1.914-1.023 0-1.705.506-2.013 1.111l.528.495c.308-.517.759-.847 1.441-.847.902 0 1.342.44 1.342 1.243v.561h-1.122c-1.65 0-2.42.594-2.42 1.661 0 1.056.704 1.716 1.903 1.716.792 0 1.397-.363 1.639-1.056h.055c.066.528.33.924.979.924h.495Zm-3.025-.616c-.682 0-1.122-.319-1.122-.88v-.231c0-.55.451-.858 1.452-.858h1.166v.935c0 .583-.627 1.034-1.496 1.034Zm5.995.616v-.77h-.836V4.86h-.88v7.227c0 .528.33.913.935.913h.781Z" fill="#FFF" fill-rule="nonzero"/></g></svg>`,
)

export const TAG_ACTIVE = convertSVGToBase64(
  `<svg width="47" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#4D90FF" width="47" height="18" rx="5"/><path d="m14.798 13-2.684-7.678h-1.177L8.253 13h.935l.77-2.266h3.102L13.83 13h.968Zm-1.969-3.08h-2.651l1.298-3.74h.055l1.298 3.74Zm5.269 3.212c1.078 0 1.815-.528 2.178-1.342l-.638-.429c-.308.649-.825.99-1.54.99-1.045 0-1.584-.715-1.584-1.705v-.968c0-.99.539-1.705 1.584-1.705.693 0 1.177.341 1.386.924l.737-.374c-.33-.781-1.001-1.331-2.123-1.331-1.595 0-2.53 1.155-2.53 2.97 0 1.815.935 2.97 2.53 2.97ZM23.873 13v-.77h-1.155V8.094h1.243v-.77h-1.243V5.751h-.792v1.056c0 .385-.121.517-.495.517h-.528v.77h.935v3.971c0 .55.33.935.935.935h1.1Zm1.947-7.007c.374 0 .539-.198.539-.495v-.143c0-.297-.165-.495-.539-.495s-.539.198-.539.495v.143c0 .297.165.495.539.495ZM25.38 13h.88V7.324h-.88V13Zm5.027 0 2.002-5.676h-.847L30.55 10.14l-.616 1.936h-.055l-.616-1.936-.99-2.816h-.88L29.384 13zm5.181.132c1.067 0 1.881-.528 2.244-1.298l-.627-.451c-.297.627-.847.979-1.562.979-1.056 0-1.683-.737-1.683-1.716v-.264h4.081v-.418c0-1.606-.935-2.772-2.453-2.772-1.54 0-2.552 1.166-2.552 2.97 0 1.804 1.012 2.97 2.552 2.97Zm0-5.203c.902 0 1.507.671 1.507 1.672v.121H33.96v-.077c0-.99.671-1.716 1.628-1.716Z" fill="#FFF" fill-rule="nonzero"/></g></svg>`,
)

export const TAG_EXTERNAL = convertSVGToBase64(
  `<svg width="57" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#FFA533" width="57" height="18" rx="5"/><path d="M13.709 13v-.814H9.947V9.524h3.542V8.71H9.947V6.136h3.762v-.814H9.023V13zm.935 0h.979l1.595-2.222h.033L18.725 13h1.034l-2.046-2.926 2.002-2.75h-.979L17.24 9.436h-.033l-1.441-2.112h-1.034l2.013 2.816zm8.635 0v-.77h-1.155V8.094h1.243v-.77h-1.243V5.751h-.792v1.056c0 .385-.121.517-.495.517h-.528v.77h.935v3.971c0 .55.33.935.935.935h1.1Zm3.597.132c1.067 0 1.881-.528 2.244-1.298l-.627-.451c-.297.627-.847.979-1.562.979-1.056 0-1.683-.737-1.683-1.716v-.264h4.081v-.418c0-1.606-.935-2.772-2.453-2.772-1.54 0-2.552 1.166-2.552 2.97 0 1.804 1.012 2.97 2.552 2.97Zm0-5.203c.902 0 1.507.671 1.507 1.672v.121h-3.135v-.077c0-.99.671-1.716 1.628-1.716ZM31.661 13V9.15c0-.539.572-.946 1.518-.946h.517v-.88h-.341c-.924 0-1.441.506-1.639 1.045h-.055V7.324h-.88V13h.88Zm4.037 0V9.062c0-.726.682-1.078 1.386-1.078.847 0 1.287.517 1.287 1.529V13h.88V9.359c0-1.375-.737-2.167-1.903-2.167-.847 0-1.331.418-1.606 1.056h-.044v-.924h-.88V13h.88Zm9.988 0v-.77h-.649V9.106c0-1.199-.814-1.914-2.178-1.914-1.023 0-1.705.506-2.013 1.111l.528.495c.308-.517.759-.847 1.441-.847.902 0 1.342.44 1.342 1.243v.561h-1.122c-1.65 0-2.42.594-2.42 1.661 0 1.056.704 1.716 1.903 1.716.792 0 1.397-.363 1.639-1.056h.055c.066.528.33.924.979.924h.495Zm-3.025-.616c-.682 0-1.122-.319-1.122-.88v-.231c0-.55.451-.858 1.452-.858h1.166v.935c0 .583-.627 1.034-1.496 1.034Zm5.995.616v-.77h-.836V4.86h-.88v7.227c0 .528.33.913.935.913h.781Z" fill="#FFF" fill-rule="nonzero"/></g></svg>`,
)
