import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { NotificationService } from '../../services/notification.service'
import { OrgService } from '../../services/org.service'

@Component({
  selector: 'organization-redirect-page',
  template: '',
})
export class OrganizationRedirectPage implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private orgService: OrgService,
    private authService: AuthService,
    private notificationService: NotificationService,
  ) {}

  public ngOnInit() {
    const allOrgUuids = this.route.snapshot.data.allOrgUuids
    const inviteToken = this.route.snapshot.params.inviteToken
    const campaign = this.route.snapshot.queryParams.campaign
    const inviteState: 'success' | 'failure' | undefined = this.route.snapshot
      .params.inviteState
    if (!allOrgUuids || allOrgUuids.length === 0) {
      this.orgService.onboard({ inviteToken, campaign }).subscribe(
        (response) => {
          const orgId = response ? response.orgId : undefined
          if (orgId) {
            this.authService
              .getAccessTokenSilently({ ignoreCache: true })
              .subscribe((_token) => {
                if (inviteToken) {
                  const base = `${location.origin}/onboarding/organization/${orgId}/invitation/${inviteToken}`
                  const target = response.invitation
                    ? `${base}/${
                        response.invitation.successful ? 'success' : 'failure'
                      }`
                    : window.location.href
                  window.location.href = target
                } else {
                  window.location.reload()
                }
              })
          } else {
            this.router.navigate(['/no-organization'])
          }
        },
        (_) => {
          this.authService.logout({ returnTo: window.location.origin })
        },
      )
    } else {
      switch (inviteState) {
        case 'success':
          this.notificationService.enqueueNotification({
            headline: `You have been successfully added to the organization from the invitation.`,
          })
          break
        case 'failure':
          this.notificationService.enqueueNotification({
            headline: `The invitation was sent to a different email address.`,
            appearance: `error`,
            description: `Please ask the owner to send the invitation to your current email address.`,
          })
          break
      }
      this.router.navigate(['/org', allOrgUuids[0]])
    }
  }
}
