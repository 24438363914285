<div class="items">
  <cm-datalist headline="Today" *ngIf="auditLogsToday.length > 0">
    <cm-datalist-item
      label="{{ auditLog.timestamp | date: 'medium' }}"
      *ngFor="let auditLog of auditLogsToday"
    >
      <cm-text>{{ auditLog.userId }}: {{ auditLog.audit }}</cm-text>
    </cm-datalist-item>
  </cm-datalist>

  <cm-datalist headline="Yesterday" *ngIf="auditLogsYesterday.length > 0">
    <cm-datalist-item
      label="{{ auditLog.timestamp | date: 'medium' }}"
      *ngFor="let auditLog of auditLogsYesterday"
    >
      <cm-text>{{ auditLog.userId }}: {{ auditLog.audit }}</cm-text>
    </cm-datalist-item>
  </cm-datalist>

  <cm-datalist
    headline="{{ auditLogsLaterTitle }}"
    *ngIf="auditLogsLater.length > 0"
  >
    <cm-datalist-item
      label="{{ auditLog.timestamp | date: 'medium' }}"
      *ngFor="let auditLog of auditLogsLater"
    >
      <cm-text>{{ auditLog.userId }}: {{ auditLog.audit }}</cm-text>
    </cm-datalist-item>
  </cm-datalist>

  <p *ngIf="!auditLogs || auditLogs.length === 0">
    No Activities have been tracked so far.
  </p>
</div>
