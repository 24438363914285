import { Component, Input, OnInit } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { environment } from '../../../environments/environment'
import { ApiService } from '../../services/api.service'
@Component({
  selector: 'external-org-member-me',
  templateUrl: './external-org-member-me.component.html',
  styleUrls: ['./external-org-member-me.component.scss'],
})
export class ExternalOrgMemberMeComponent implements OnInit {
  @Input() orgUuid: string

  public data
  public myUserid
  public baseUrl

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {
    this.baseUrl = `${environment.accountsUrl}/external/organizations/`
  }

  ngOnInit() {
    this.authService.user$.subscribe((user) => {
      if (user && user.sub) {
        this.myUserid = encodeURI(user.sub)
        this.apiService
          .getExternalOrgUserInfo(this.orgUuid, user.sub)
          .subscribe((data) => {
            this.data = JSON.stringify(data, null, 2)
          })
      } else {
        this.myUserid = undefined
      }
    })
  }
}
