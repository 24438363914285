<cm-page #cmPage>
  <div slot="header" class="organizationManagementHeader">
    <ng-container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="20"
        height="20"
      >
        <defs>
          <path
            id="a"
            d="M4.28571429 8.66666667l4.28571428 2.50000003v5l-4.28571428 2.5L0 16.1666667v-5l4.28571429-2.50000003zm10.00000001 0l4.2857143 2.50000003v5l-4.2857143 2.5-4.2857143-2.5v-5l4.2857143-2.50000003zM9.28546882 0l4.28571428 2.5v5L9.28546882 10 4.99975453 7.5v-5L9.28546882 0z"
          />
        </defs>
        <g
          fill="none"
          fill-rule="evenodd"
          transform="translate(.714531 .714286)"
        >
          <mask id="b" fill="#fff"><use xlink:href="#a" /></mask>
          <use fill="#000" xlink:href="#a" />
          <g mask="url(#b)">
            <path fill="#62626E" d="M-.833333-.833333h20v20h-20z" />
          </g>
        </g>
      </svg>
      <h1>Organization Management</h1>
    </ng-container>
  </div>
  <cm-page-tab
    label="Overview"
    *ngIf="vss.visibilities.settings.tabs.overview.visible"
  >
    <organization-overview [org]="org"></organization-overview>
  </cm-page-tab>
  <cm-page-tab
    label="Users"
    *ngIf="vss.visibilities.settings.tabs.members.visible"
    id="orgMemberTab"
  >
    <organization-users [org]="org"></organization-users>
  </cm-page-tab>
  <cm-page-tab
    label="Activity"
    *ngIf="vss.visibilities.settings.tabs.audit.visible"
    id="orgActivityTab"
  >
    <list-audit-logs
      [tabChangedTo]="tabChangedTo"
      [auditLogsResolver]="auditResolverService"
    ></list-audit-logs>
  </cm-page-tab>
  <cm-page-tab
    label="Billing"
    *ngIf="vss.visibilities.settings.tabs.billing.visible"
    id="orgBillingTab"
  >
    <reserved-clusters
      *ngIf="vss.visibilities.billing.view.pricing0.visible && !vss.visibilities.billing.view.enterprise.visible"
    ></reserved-clusters>
    <organization-billing
      *ngIf="vss.visibilities.billing.view.pricing1.visible || vss.visibilities.billing.view.enterprise.visible"
    ></organization-billing>
  </cm-page-tab>

  <cm-page-tab
    label="Usage"
    *ngIf="vss.visibilities.settings.tabs.usage.visible"
    id="orgUsageTab"
  >
    <organization-usage></organization-usage>
  </cm-page-tab>
  <cm-page-tab
    label="Cloud Management API"
    *ngIf="vss.visibilities.settings.tabs.api.visible"
    id="cloudApiTab"
  >
    <organization-cloud-api></organization-cloud-api>
  </cm-page-tab>
</cm-page>
