<ul>
  <li>
    {{ element }}
    <span *ngIf="plan.description">
      -
      {{ plan.description }}
    </span>
    <cm-textfield
      *ngIf="plan.type === 'text'"
      label="{{ plan.placeholder }}"
      [validation]="validation"
      validationStyle="live"
      [value]="plan.value"
      type="{{ plan.type }}"
    ></cm-textfield>
    <cm-textfield
      *ngIf="plan.type === 'number'"
      label="{{ plan.placeholder }}"
      [validation]="validation"
      validationStyle="live"
      [value]="plan.value"
      type="{{ plan.type }}"
      min="0"
    ></cm-textfield>
    <br *ngIf="plan.type === 'checkbox'" />
    <cm-checkbox
      *ngIf="plan.type === 'checkbox'"
      label="{{ plan.placeholder }}"
      [checked]="plan.value"
      (cmInput)="checkboxChecked($event.detail.isChecked)"
    ></cm-checkbox>
    <cm-textfield
      *ngIf="plan.type === 'textarea'"
      label="{{ plan.placeholder }}"
      [validation]="validation"
      validationStyle="live"
      [value]="plan.value"
      type="multiline"
    ></cm-textfield>
    <span *ngIf="!plan.type">
      <cluster-plan-tree-element
        *ngFor="let child of plan | keyvalue"
        [element]="child.key"
        [(plan)]="plan[child.key]"
      ></cluster-plan-tree-element>
    </span>
  </li>
</ul>
