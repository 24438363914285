import {
  ClusterUsageMetricsData,
  OrganizationDto,
  SalesPlanType,
} from '@camunda-cloud/cloud-node-libs'
import { addMonths } from 'date-fns'
import { ClusterDto } from '../../../../../../commons/Cluster.dto'
import { ExtendedClusterPlanTypeReservation } from '../../../resolver/reservations.resolver'

interface UsageValueMetrics {
  start?: Date
  showCluster?: boolean
  processInstances: number
  processInstancesIncluded: number
  taskUsers: number
  taskUsersIncluded: number
  showPerCluster: boolean
  perCluster: UsageValueMetricsPerCluster[]
}

interface UsageValueMetricsPerCluster {
  uuid: string
  name?: string
  processInstances: number
  taskUsers: number
}

interface UsageReservations {
  usage: number
  included: number
}

export class UsageData {
  public currentPeriod: UsageValueMetrics | undefined
  public yearlyPeriods: UsageValueMetrics[] = []
  public monthlyPeriods: UsageValueMetrics[] = []

  public reservations: UsageReservations | undefined

  public numberOfMonthsSinceStart: number = 0
  public startMonthCurrentPeriod: number = 0

  public selectedPeriod: 'yearly' | 'monthly' = 'monthly'
  public selectedPeriodData: UsageValueMetrics[] = []

  private processInstancesIncluded: number = 0
  private taskUsersIncluded: number = 0
  private termInMonths: number = 0

  private clusterIdToName: Map<string, string> = new Map<string, string>()

  constructor(
    private clusters: ClusterDto[],
    private valueMetrics: ClusterUsageMetricsData[],
    private allReservations: ExtendedClusterPlanTypeReservation[],
    private org: OrganizationDto,
  ) {
    this.processInstancesIncluded =
      this.org.organizationToSalesPlan?.enterpriseIncludingValueMetrics
        ?.processInstances ?? 0
    this.taskUsersIncluded =
      this.org.organizationToSalesPlan?.enterpriseIncludingValueMetrics
        ?.taskUsers ?? 0
    this.termInMonths =
      this.org.organizationToSalesPlan?.enterpriseDurationInMonth ?? 1

    this.clusters.forEach((cluster) => {
      this.clusterIdToName.set(cluster.uuid, cluster.name)
    })

    this.transformReservations()
    this.transformValueMetricsYearlyPeriods()
    this.selectPeriod('monthly')
  }

  public selectPeriod(period: 'monthly' | 'yearly') {
    this.selectedPeriod = period
    if (period === 'monthly') {
      this.selectedPeriodData = this.monthlyPeriods
    } else {
      this.selectedPeriodData = this.yearlyPeriods
    }
  }

  public getStartDate(monthsSinceStart: number, entryStartDate?: Date): Date {
    let startDate = entryStartDate
    let orgStartDate
    // TODO: add to dto in cloud node libs
    // eslint-disable-next-line dot-notation
    const orgCreated = this.org['created']
    switch (this.org.organizationToSalesPlan.salesPlan.salesPlanType) {
      case SalesPlanType.IN_NEGOTIATION:
        orgStartDate = orgCreated ? new Date(orgCreated) : new Date()
        break
      case SalesPlanType.TRIAL:
        if (orgCreated) {
          orgStartDate = new Date(orgCreated)
        } else {
          orgStartDate = new Date(
            this.org.organizationToSalesPlan?.trialPlanStartDate,
          )
        }
        break
      case SalesPlanType.ENTERPRISE:
        orgStartDate = new Date(
          this.org.organizationToSalesPlan?.enterpriseStartDate,
        )
        break
    }
    if (orgStartDate) {
      startDate = addMonths(orgStartDate, monthsSinceStart)
    }
    return startDate
  }

  public getEndDate(startDate: Date) {
    if (this.selectedPeriod === 'monthly') {
      return new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        startDate.getDate() - 1,
      )
    } else {
      return new Date(
        startDate.getFullYear() + 1,
        startDate.getMonth(),
        startDate.getDate() - 1,
      )
    }
  }

  private transformValueMetricsYearlyPeriods() {
    this.numberOfMonthsSinceStart = Math.max(
      ...this.valueMetrics
        .filter((entry) => entry.monthsSinceStart >= 0)
        .map((entry) => entry.monthsSinceStart),
    )
    this.startMonthCurrentPeriod =
      this.numberOfMonthsSinceStart -
      (this.numberOfMonthsSinceStart % this.termInMonths)

    // transform monthly period
    this.monthlyPeriods = []
    for (let month = 0; month <= this.numberOfMonthsSinceStart; month++) {
      const foundEntries = this.valueMetrics.filter(
        (entry) => entry.monthsSinceStart === month,
      )
      this.transformValueMetrics(this.monthlyPeriods, foundEntries, month)
    }

    // transform yearly period
    this.yearlyPeriods = []
    for (
      let month = 0;
      month <= this.numberOfMonthsSinceStart;
      month += this.termInMonths
    ) {
      const foundEntries = this.valueMetrics.filter(
        (entry) =>
          entry.monthsSinceStart >= month &&
          entry.monthsSinceStart < month + this.termInMonths,
      )
      // add entry for start period if no value metrics created in this period
      if (
        foundEntries.filter((entry) => entry.monthsSinceStart === month)
          .length === 0
      ) {
        foundEntries.push({
          decisionInstances: 0,
          processInstances: 0,
          taskUsers: 0,
          monthsSinceStart: month,
          orgId: this.org.uuid,
          startDate: this.getStartDate(month),
        })
      }
      this.transformValueMetrics(this.yearlyPeriods, foundEntries, month)
    }

    this.yearlyPeriods.sort((a, b) => b.start.getTime() - a.start.getTime())
    this.currentPeriod = this.yearlyPeriods[0]
    this.monthlyPeriods.sort((a, b) => b.start.getTime() - a.start.getTime())
  }

  private transformReservations() {
    const included = this.allReservations.reduce((acc, element) => {
      if (!element.editable) {
        return acc + element.currentReservations
      } else {
        return acc
      }
    }, 0)
    const usage = this.allReservations.reduce((acc, element) => {
      if (!element.editable) {
        return acc + element.usage
      } else {
        return acc
      }
    }, 0)
    this.reservations = {
      included,
      usage,
    }
  }

  private transformValueMetrics(
    targetArray: UsageValueMetrics[],
    entries: ClusterUsageMetricsData[],
    monthsSinceStart: number,
  ) {
    entries.sort((a, b) => {
      if (a.startDate && b.startDate) {
        return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      } else {
        return 0
      }
    })
    const allClusterIds = entries
      .filter((entry) => entry.clusterId)
      .map((entry) => entry.clusterId)
    const clusterIds = Array.from(new Set(allClusterIds))
    targetArray.push({
      processInstances: entries.reduce(
        (acc, element) => acc + element.processInstances,
        0,
      ),
      processInstancesIncluded: this.processInstancesIncluded,
      taskUsers: entries.reduce((acc, element) => acc + element.taskUsers, 0),
      taskUsersIncluded: this.taskUsersIncluded,
      start:
        entries.length > 0
          ? this.getStartDate(entries[0].monthsSinceStart, entries[0].startDate)
          : this.getStartDate(monthsSinceStart),
      showPerCluster: false,
      perCluster:
        clusterIds.length > 0
          ? clusterIds.map((clusterId) => {
              const entriesForClusterId = entries.filter(
                (entry) => entry.clusterId === clusterId,
              )
              const entryPerCluster: UsageValueMetricsPerCluster = {
                uuid: clusterId,
                name: this.clusterIdToName.get(clusterId),
                processInstances: entriesForClusterId.reduce(
                  (acc, element) => acc + element.processInstances,
                  0,
                ),
                taskUsers: entriesForClusterId.reduce(
                  (acc, element) => acc + element.taskUsers,
                  0,
                ),
              }
              return entryPerCluster
            })
          : [],
    })
  }

  public static generateTrialTestData(): ClusterUsageMetricsData[] {
    const testData: ClusterUsageMetricsData[] = []

    const numberOfMonths = 2
    const start = new Date().setMonth(new Date().getMonth() - numberOfMonths)
    const clusterIds: string[] = ['cluster1', 'cluster2']
    const orgId: string = 'testOrg'
    const startMonth = new Date(start).getMonth()

    for (let month = 0; month < numberOfMonths; month++) {
      const startDate = new Date(new Date(start).setMonth(startMonth + month))
      for (const clusterId of clusterIds) {
        testData.push({
          orgId,
          decisionInstances: 0,
          processInstances: month * 2,
          taskUsers: (month * 3) % 12,
          startDate,
          clusterId,
          monthsSinceStart: month,
        })
      }
    }

    return testData
  }
}
