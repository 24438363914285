import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuditDto } from '@camunda-cloud/cloud-node-libs'
import { Observable } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(private http: HttpClient) {}

  public getAuditLogs(orgId: string): Observable<AuditDto[]> {
    return this.http.get<AuditDto[]>(
      `${environment.auditBackendUrl}/api/audit/orgs/${orgId}`,
    )
  }
  public getClusterAuditLogs(orgId: string, clusterId: string) {
    return this.http.get<AuditDto[]>(
      `${environment.auditBackendUrl}/api/audit/orgs/${orgId}/cluster/${clusterId}`,
    )
  }
}
