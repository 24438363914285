import { Injectable } from '@angular/core'
import { AuthService } from '@auth0/auth0-angular'
import { environment } from '../../environments/environment'
import { FeatureService } from './feature.service'

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  private pendo: any

  constructor(
    private authService: AuthService,
    private featureService: FeatureService,
  ) {
    // eslint-disable-next-line dot-notation
    this.pendo = window['pendo']
    let accountId: string
    if (environment.production) {
      accountId = 'console:prod'
    } else if (environment.integration) {
      accountId = 'console:int'
    } else {
      accountId = 'console:dev'
    }
    if (this.pendoEnabled()) {
      this.featureService.accessToken$.subscribe((accessToken) => {
        if (accessToken && accessToken.activated.features.pendo) {
          this.authService.user$.subscribe((user) => {
            if (user) {
              this.pendo.initialize({
                visitor: {
                  id: user.sub, // Required if user is logged in
                  email: user.email, // Recommended if using Pendo Feedback, or NPS Email
                  full_name: user.name, // Recommended if using Pendo Feedback
                  // role:         // Optional
                  // You can add any additional visitor level key-values here,
                  // as long as it's not one of the above reserved names.
                },

                account: {
                  id: accountId, // Required if using Pendo Feedback
                  // name:         // Optional
                  // is_paying:    // Recommended if using Pendo Feedback
                  // monthly_value:// Recommended if using Pendo Feedback
                  // planLevel:    // Optional
                  // planPrice:    // Optional
                  // creationDate: // Optional

                  // You can add any additional account level key-values here,
                  // as long as it's not one of the above reserved names.
                },
              })
            }
          })
        }
      })
    }
  }

  private pendoEnabled(): boolean {
    return !environment.integration && !environment.production && this.pendo
  }

  // eslint-disable-next-line class-methods-use-this
  // private osanoAnalyticsEnabled(): boolean {
  //   const osano = (window as any).Osano
  //   return osano?.cm?.analytics
  // }
}
