import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Resolve } from '@angular/router'
import { Observable } from 'rxjs'
import { ViewVisibilities } from '../../../../commons/Visibility'
import { ViewVisibilitiesService } from '../services/view.visibilities.service'

@Injectable({
  providedIn: 'root',
})
export class ViewVisibilitiesResolverService
  implements Resolve<ViewVisibilities> {
  constructor(private viewVisibilitiesService: ViewVisibilitiesService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<ViewVisibilities> {
    const orgId = route.paramMap.get('orgUuid')
    return this.viewVisibilitiesService.get(orgId)
  }
}
